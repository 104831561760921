define("frontend/pods/components/agent/current-call/say-something/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aPpUuUt2",
    "block": "{\"symbols\":[\"text\",\"@text\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"say-something\"],[[\"from\"],[\"frontend/pods/components/agent/current-call/say-something/styles\"]]]]]],[12],[2,\"\\n  \"],[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,2]],[[\"use\",\"duration\"],[[35,1],300]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"text\"],[[\"from\"],[\"frontend/pods/components/agent/current-call/say-something/styles\"]]]]]],[12],[2,\"\\n        “\"],[1,[32,2]],[2,\"”\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"toUp\",\"animated-value\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/current-call/say-something/template.hbs"
    }
  });

  _exports.default = _default;
});