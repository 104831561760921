define("frontend/pods/components/file-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FileUploadComponent = Ember.Component.extend({
    url: null,
    method: 'POST',
    dataType: 'json',
    fieldName: 'file',
    buttonText: null,
    filename: null,
    progress: null,
    response: null,
    isSaving: false,
    onSaved: function onSaved() {},
    // JSON object indicating the additional fields to specify --
    // the easy way to specify this is to bind it to model.getProperties(...)
    fields: null,
    _initializeFileUpload: Ember.on('didInsertElement', function () {
      var _this = this;

      return this.$('form').fileupload({
        //url: @get('url')
        type: this.method,
        dataType: this.dataType,
        autoUpload: false,
        formData: function formData() {
          var result = [];
          var fields = _this.get('fields') || {};

          for (var _i = 0, _Object$keys = Object.keys(fields || {}); _i < _Object$keys.length; _i++) {
            var name = _Object$keys[_i];
            var value = fields[name];
            result.push({
              name: name,
              value: value
            });
          }

          return result;
        },
        add: function add(e, data) {
          _this.$('form').attr('action', _this.get('url'));

          _this.setProperties({
            filename: data.files[0].name
          });

          _this.set('isSaving', true);

          return data.submit();
        },
        done: function done(e, data) {
          _this.set('response', data.result);

          _this.set('isSaving', false);

          return _this.onSaved();
        },
        progress: function progress(e, data) {
          return _this.set('progress', parseInt(data.loaded / data.total * 100, 10));
        }
      });
    }),
    progressStyle: Ember.computed('progress', function () {
      return "width: ".concat(this.progress, "%;");
    })
  });
  var _default = FileUploadComponent;
  _exports.default = _default;
});