define("frontend/pods/components/inline-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fkSOr1CT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"editor\"],[12],[2,\"\\n\\t\\t\"],[18,1,null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n\"],[6,[37,1],[[35,3,[\"isSaving\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\"],[11,\"a\"],[24,6,\"\"],[4,[38,2],[[32,0],\"save\"],null],[12],[10,\"span\"],[14,0,\"badge badge-success\"],[12],[10,\"i\"],[14,0,\"fa fa-check\"],[12],[13],[13],[13],[2,\"\\n\\t\\t\\t\"],[11,\"a\"],[24,6,\"\"],[4,[38,2],[[32,0],\"cancel\"],null],[12],[10,\"span\"],[14,0,\"badge badge-danger\"],[12],[10,\"i\"],[14,0,\"fa fa-times\"],[12],[13],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"table\"],[14,0,\"inline-editor-display\"],[12],[2,\"\\n\\t\\t\"],[10,\"tr\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"td\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"innerLR\"],[12],[11,\"a\"],[24,6,\"\"],[24,0,\"edit\"],[4,[38,2],[[32,0],\"edit\"],null],[12],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[13],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"valueToDisplay\",\"if\",\"action\",\"content\",\"isEditing\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/inline-editor/template.hbs"
    }
  });

  _exports.default = _default;
});