define("frontend/pods/service-providers/service-provider/edit/route", ["exports", "frontend/pods/base/edit/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ServiceProviderEditRoute = _route.default.extend({
    model: function model() {
      var serviceProvider = this.modelFor('service-providers.service-provider');
      var serviceProviderExtendedInfo = this.store.find('service-provider-extended-info', serviceProvider.get('id'));
      return Ember.RSVP.hash({
        serviceProvider: serviceProvider,
        serviceProviderExtendedInfo: serviceProviderExtendedInfo
      });
    },
    modelPath: 'controller.model.serviceProvider',
    rollbackModel: function rollbackModel() {
      this._super.apply(this, arguments);

      var model = this.get(this.modelPath);
      return model.reload();
    },
    afterSave: function afterSave() {
      return this.transitionTo('service-providers');
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('service-providers');
    }
  });

  var _default = ServiceProviderEditRoute;
  _exports.default = _default;
});