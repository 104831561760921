define("frontend/pods/components/ui-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UiButtonComponent = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['isPrimary:btn-primary', 'isSuccess:btn-success', 'isDanger:btn-danger', 'isInverse:btn-inverse'],
    attributeBindings: ['disabled'],
    icon: null,
    text: null,
    waitText: 'Please Wait...',
    isWaiting: false,
    isPrimary: false,
    isSuccess: false,
    isDanger: false,
    isInverse: false,
    onClick: function onClick() {},
    isDisabled: Ember.computed.bool('isWaiting'),
    disabled: Ember.computed.alias('isDisabled'),
    isEnabled: Ember.computed('isDisabled', {
      get: function get() {
        return !this.isDisabled;
      },
      set: function set(key, value) {
        this.set('isDisabled', !value);
        return !this.isDisabled;
      }
    }),
    iconClass: Ember.computed('icon', function () {
      var icon = this.icon;

      if (icon) {
        return "fa fa-".concat(icon);
      }
    }),
    click: function click(e) {
      this.onClick();
      return e.preventDefault();
    },
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('isDisabled', 'isEnabled');
    })
  });
  var _default = UiButtonComponent;
  _exports.default = _default;
});