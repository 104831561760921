define("frontend/pods/components/partial/lead/call-history/outbound-call/component", ["exports", "frontend/models/outbound-call", "frontend/models/lead"], function (_exports, _outboundCall, _lead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    call: Ember.computed.alias('model'),
    isEditing: false,
    tagName: '',
    authenticationService: Ember.inject.service('authentication'),
    didUserDial: Ember.computed.alias('model.isPowerDial'),
    connectedText: Ember.computed('reasonText', 'dialResultText', 'model.voiceMailDeliveredAt', function () {
      if (this.model.voiceMailDeliveredAt) {
        return "Left voicemail";
      } else {
        return this.reasonText || this.dialResultText || 'Call attempted';
      }
    }),
    dialResultText: Ember.computed('model.dialResult', function () {
      var result = this.model.dialResult;

      if (!result) {
        return;
      }

      var resultValue = _outboundCall.default.dialResultValues.findBy('value', result);

      if (resultValue) {
        return resultValue.text;
      } else {
        return result;
      }
    }),
    agentConversationResultText: Ember.computed('model.agentConversationResult', function () {
      var result = this.model.agentConversationResult;

      if (!result) {
        return;
      }

      var resultValue = _outboundCall.default.agentConversationResultValues.findBy('value', result);

      if (resultValue) {
        return resultValue.text;
      } else {
        return result;
      }
    }),
    reasonValues: Ember.computed('model.status', function () {
      var status = this.model.status;

      if (status) {
        return _lead.default.reasonValues[status];
      }
    }),
    reasonText: Ember.computed('model.reason', 'reasonValues', function () {
      var reason = this.model.reason;

      if (!reason) {
        return;
      }

      var reasonValues = this.reasonValues;

      if (!reasonValues) {
        return;
      }

      var resultValue = reasonValues.findBy('value', reason);

      if (resultValue) {
        return resultValue.text;
      } else {
        return reason;
      }
    }),
    canHearClientRecording: Ember.computed.and('model.clientRecording', 'authenticationService.canHearClientRecordings'),
    canHearAgentRecording: Ember.computed.and('model.agentRecording', 'authenticationService.canHearAgentRecordings'),
    actions: {
      edit: function edit() {
        // disable editing until we find it necessary (the form needs to be updated)
        // @set('isEditing', true) if @get('canEdit')
        return this.set('call.isEditing', true);
      },
      save: function save() {
        var _this = this;

        return this.model.saveChanges().then(function () {
          return _this.set('call.isEditing', false);
        });
      },
      cancel: function cancel() {
        this.model.rollbackAttributes();
        return this.set('call.isEditing', false);
      }
    }
  });

  _exports.default = _default;
});