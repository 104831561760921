define("frontend/routes/users/new", ["exports", "frontend/pods/base/new/route", "frontend/mixins/additional-models"], function (_exports, _route, _additionalModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_additionalModels.default, {
    model: function model(params) {
      return this.store.createRecord('user', {
        dialLeadsStartingAtMinute: 8 * 60,
        dialLeadsUntilMinute: 18 * 60,
        availableCountryCodes: '1',
        teamId: params.teamId
      });
    },
    additionalModels: function additionalModels(model) {
      return {
        organizations: this.store.findAll('organization')
      };
    },
    afterSave: function afterSave() {
      return this.transitionTo('users.index');
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('users.index');
    }
  });

  _exports.default = _default;
});