define("frontend/pods/components/ui/tabs-component/tab-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AZoFCCzX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,2],null,[[\"isActive\"],[[35,1]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"isActive\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui/tabs-component/tab-header/template.hbs"
    }
  });

  _exports.default = _default;
});