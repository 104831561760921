define("frontend/pods/organizations/organization/appointments/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationAppointmentsRoute = Ember.Route.extend();
  var _default = OrganizationAppointmentsRoute;
  _exports.default = _default;
});