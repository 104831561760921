define("frontend/pods/leads/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadsIndexController = Ember.Controller.extend({
    search: null,
    direction: true,
    sortBy: 'lead_last_name',
    // use a different parameter than "search" to only use the executed searches
    queryParams: ['q'],
    sortOptions: [{
      value: 'lead_last_name',
      text: 'Sort: Last Name'
    }, {
      value: 'last_dialed_at',
      text: 'Sort: Last Dialed'
    }, {
      value: 'dial_count',
      text: 'Sort: Dial Count'
    }],
    _searchFn: function _searchFn() {
      this.set('q', this.search);
      this.set('sort_by', this.sortBy);
      this.set('direction', this.direction);
      return this.model.find('lead', {
        q: this.search,
        sort_by: this.sortBy,
        direction: this.direction
      });
    },
    _performSearch: Ember.observer('search', function () {
      return Ember.run.debounce(this, this._searchFn, 700);
    }),
    _performSort: Ember.observer('sortBy', function () {
      return Ember.run.debounce(this, this._searchFn, 700);
    }),
    _performSortDirection: Ember.observer('direction', function () {
      return Ember.run.debounce(this, this._searchFn, 700);
    }),
    // update the search criteria when the query parameter is resolved
    _updateSearch: Ember.observer('q', function () {
      var q = this.q;
      var search = this.search;

      if (!search || q !== search) {
        return this.set('search', q);
      }
    }),
    // update the search criteria when the controller is activated
    clear: function clear() {
      return this.set('search', this.q);
    },
    actions: {
      dialLead: function dialLead(lead) {
        return this.callService.dialLead(lead);
      },
      toggleDirection: function toggleDirection() {
        return this.toggleProperty('direction');
      }
    }
  });
  var _default = LeadsIndexController;
  _exports.default = _default;
});