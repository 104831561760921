define("frontend/pods/leads/lead/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadController = Ember.Controller.extend({
    isAgent: Ember.computed.alias('callService.authentication.isAgent'),
    actions: {
      dial: function dial() {
        var lead = this.model;
        return this.callService.dialLead(lead);
      },
      dialMulti: function dialMulti(leadFormattedPhoneNumber, leadPhoneNumberId) {
        var lead = this.model;
        return this.callService.dialLeadMulti(lead, leadFormattedPhoneNumber, leadPhoneNumberId);
      }
    }
  });
  var _default = LeadController;
  _exports.default = _default;
});