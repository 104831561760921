define("frontend/pods/components/view/call-control-button/component", ["exports", "frontend/pods/components/view/ui-button/component", "frontend/mixins/tool-tip"], function (_exports, _component, _toolTip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_toolTip.default, {
    tagName: 'button',
    style: 'inverse',
    isShowing: true,
    classNameBindings: ['isShowing::hidden']
  });

  _exports.default = _default;
});