define("frontend/pods/components/call-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VlEH2FUi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[2,\"Status: \"],[1,[34,2]],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,1],null,[[\"startTime\",\"classNames\"],[[35,0],\"label pull-right\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"startedAt\",\"time-diff\",\"statusText\",\"showTimer\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/call-status/template.hbs"
    }
  });

  _exports.default = _default;
});