define("frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "boxes": "_boxes_sufrw8",
    "box": "_box_sufrw8",
    "title": "_title_sufrw8",
    "body": "_body_sufrw8",
    "footer": "_footer_sufrw8",
    "back": "_back_sufrw8"
  };
  _exports.default = _default;
});