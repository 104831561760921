define("frontend/pods/leads/new/route", ["exports", "frontend/pods/base/new/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LeadsNewRoute = _route.default.extend({
    model: function model(params) {
      var result = this.store.createRecord('lead', {
        referredBy: params.referredBy
      });

      if (params.referredBy) {
        return this.store.find('lead', params.referredBy).then(function (originalLead) {
          result.set('companyName', originalLead.companyName);

          if (originalLead.name) {
            result.set('followUpScript', "".concat(originalLead.name, " referred me to you."));
          }

          return result;
        });
      } else {
        return result;
      }
    },
    saveModel: function saveModel() {
      var _this = this;

      var model = this.get('controller.model');
      return model.save().then(function () {
        _this.store.find('lead', model.referredBy).then(function (oldLead) {
          oldLead.set('doNotCall', true);
          oldLead.save();
          return _this.alertsService.success('Your new referral lead has been created and the original lead has been marked "Do Not Call".');
        });
      });
    },
    afterSave: function afterSave() {
      return this.transitionTo('lead-lists.index');
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('leads.index');
    }
  });

  var _default = LeadsNewRoute;
  _exports.default = _default;
});