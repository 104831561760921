define("frontend/pods/organizations/organization/statuses/route", ["exports", "frontend/pods/base/edit/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var OrganizationStatusesRoute = _route.default.extend({
    requiresLogin: true,
    authorizedRoles: ['admin', 'client_manager'],
    model: function model() {
      var model = this.modelFor('organizations.organization');
      return model.store.query('lead-status', {
        organization_id: model.id
      }).then(function () {
        return model;
      });
    },
    afterSave: function afterSave() {
      return this.transitionTo('organizations.organization.index', this.get('controller.model'));
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('organizations.organization.index', this.get('controller.model'));
    }
  });

  var _default = OrganizationStatusesRoute;
  _exports.default = _default;
});