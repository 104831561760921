define("frontend/routes/users/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    requiresLogin: true,
    authorizedRoles: ['admin', 'client_manager', 'team_manager'],
    model: function model(params) {
      return this.store.find('user', params.user_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('users').addToRecentlyViewed(model);
    }
  });

  _exports.default = _default;
});