define("frontend/pods/components/organization-dashboard-users/component", ["exports", "frontend/utils/time-format", "frontend/models/lead", "frontend/models/organization", "frontend/models/outbound-call"], function (_exports, _timeFormat, _lead, _organization, _outboundCall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationDashboardComponent = Ember.Component.extend({
    organization: null,
    dashboard: null,
    dialsPerHour: Ember.computed('dashboard.dialsPerHour', function () {
      return this._round(this.get('dashboard.dialsPerHour'));
    }),
    connectionsPerHour: Ember.computed('dashboard.connectionsPerHour', function () {
      return this._round(this.get('dashboard.connectionsPerHour'));
    }),
    _round: function _round(val) {
      if (val) {
        return Math.round(val * 10) / 10;
      } else {
        return 0;
      }
    },
    usageByUserChartData: Ember.computed('dashboard.usageByUser', function () {
      var u;
      var usageByUser = this.get('dashboard.usageByUser') || [];
      usageByUser = usageByUser.filter(function (u) {
        return u.paused_time || u.idle_time || u.connected_time;
      });
      var columns = [];
      var row = ['x'];

      for (var _i = 0, _Array$from = Array.from(usageByUser); _i < _Array$from.length; _i++) {
        u = _Array$from[_i];
        var userId = u.user_id;
        var user = this.get('dashboard.store').peekRecord('user', userId);
        row.push(user.get('name'));
      }

      columns.push(row);

      for (var _i2 = 0, _arr = ['paused_time', 'idle_time', 'connected_time']; _i2 < _arr.length; _i2++) {
        var t = _arr[_i2];
        row = [t];

        for (var _i3 = 0, _Array$from2 = Array.from(usageByUser); _i3 < _Array$from2.length; _i3++) {
          u = _Array$from2[_i3];
          row.push((u[t] || 0) / 60 / 60);
        }

        columns.push(row);
      }

      return {
        data: {
          x: 'x',
          type: 'bar',
          columns: columns,
          groups: [['paused_time', 'idle_time', 'connected_time']],
          names: {
            paused_time: 'Paused Time',
            idle_time: 'Idle Time',
            connected_time: 'Connected Time'
          },
          order: null
        },
        tooltip: {
          format: {
            value: function value(_value) {
              var totalSeconds = _value * 3600;
              return "".concat(_timeFormat.default.hhmmss(totalSeconds));
            }
          }
        },
        axis: {
          rotated: true,
          x: {
            type: 'category'
          },
          y: {
            label: {
              text: 'Hours',
              position: 'outer-center'
            }
          }
        },
        color: {
          pattern: ['#FAA43A', '#DECF3F', '#60BD68']
        }
      };
    }),
    conversionRateByUserChartData: Ember.computed('dashboard.conversionRateByUser', function () {
      var u;
      var conversionRateByUser = this.get('dashboard.conversionRateByUser') || [];
      conversionRateByUser = conversionRateByUser.filter(function (u) {
        return u.connections;
      });
      conversionRateByUser = conversionRateByUser.map(function (u) {
        var rate = (u.appointments || 0) / (u.connections || 1) * 100;
        var userId = u.user_id;
        return {
          user_id: userId,
          rate: rate
        };
      });
      var columns = [];
      var row = ['x'];

      for (var _i4 = 0, _Array$from3 = Array.from(conversionRateByUser); _i4 < _Array$from3.length; _i4++) {
        u = _Array$from3[_i4];
        var userId = u.user_id;
        var user = this.get('dashboard.store').peekRecord('user', userId);

        if (user) {
          row.push(user.get('name'));
        }
      }

      columns.push(row);
      row = ['rate'];

      for (var _i5 = 0, _Array$from4 = Array.from(conversionRateByUser); _i5 < _Array$from4.length; _i5++) {
        u = _Array$from4[_i5];
        row.push(this._round(u.rate));
      }

      columns.push(row);
      return {
        data: {
          x: 'x',
          type: 'bar',
          columns: columns,
          groups: [['rate']],
          names: {
            rate: 'Conversion Rate'
          },
          order: null
        },
        tooltip: {
          format: {
            value: function value(_value2) {
              return "".concat(_value2, " %");
            }
          }
        },
        axis: {
          rotated: true,
          x: {
            type: 'category'
          },
          y: {
            label: {
              text: 'Conversion Rate %',
              position: 'outer-center'
            }
          }
        },
        color: {
          pattern: ['#FAA43A']
        }
      };
    }),
    activityByUserChartData: Ember.computed('dashboard.usageByUser', function () {
      var u;
      var activityByUser = this.get('dashboard.conversionRateByUser') || [];
      activityByUser = activityByUser.filter(function (u) {
        return u.connections;
      });
      activityByUser = activityByUser.map(function (u) {
        var connections = (u.connections || 0) - (u.appointments || 0);
        var appointments = u.appointments || 0;
        var userId = u.user_id;
        return {
          user_id: userId,
          connections: connections,
          appointments: appointments
        };
      });
      var columns = [];
      var row = ['x'];

      for (var _i6 = 0, _Array$from5 = Array.from(activityByUser); _i6 < _Array$from5.length; _i6++) {
        u = _Array$from5[_i6];
        var userId = u.user_id;
        var user = this.get('dashboard.store').peekRecord('user', userId);

        if (user) {
          row.push(user.get('name'));
        }
      }

      columns.push(row);

      for (var _i7 = 0, _arr2 = ['connections', 'appointments']; _i7 < _arr2.length; _i7++) {
        var t = _arr2[_i7];
        row = [t];

        for (var _i8 = 0, _Array$from6 = Array.from(activityByUser); _i8 < _Array$from6.length; _i8++) {
          u = _Array$from6[_i8];
          row.push(this._round(u[t] || 0));
        }

        columns.push(row);
      }

      return {
        data: {
          x: 'x',
          type: 'bar',
          columns: columns,
          groups: [['connections', 'appointments']],
          names: {
            connections: 'No Appointment',
            appointments: 'Appointments'
          },
          order: null
        },
        tooltip: {
          format: {
            value: function value(_value3) {
              return "".concat(_value3);
            }
          }
        },
        axis: {
          rotated: true,
          x: {
            type: 'category'
          },
          y: {
            label: {
              text: 'Conversations',
              position: 'outer-center'
            }
          }
        },
        color: {
          pattern: ['#FAA43A', '#DECF3F']
        }
      };
    })
  });
  var _default = OrganizationDashboardComponent;
  _exports.default = _default;
});