define("frontend/pods/components/lead-filter-editor/component", ["exports", "frontend/models/lead", "frontend/models/lead-list", "frontend/models/lead-list-search"], function (_exports, _lead, _leadList, _leadListSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadFilterEditorComponent = Ember.Component.extend({
    filter: null,
    dataSources: null,
    mode: 'advanced',
    autoSearch: false,
    showSearchButton: true,
    isBasicMode: Ember.computed.equal('mode', 'basic'),
    isAdvancedMode: Ember.computed.equal('mode', 'advanced'),
    store: Ember.inject.service(),
    authenticationService: Ember.inject.service('authentication'),
    statusValues: Ember.computed('model.leadStatuses.@each.name', 'organizationId', 'authenticationService.activeUser', function () {
      var adapter = this.store.adapterFor('application');
      var organizationId = this.organizationId ? this.organizationId : this.get('authenticationService.activeUser.organizationId');
      var result = [];
      adapter.executeServerAction('organization', organizationId, 'unique_lead_statuses').then(function (data) {
        return result.addObjects(data.unique_lead_statuses);
      });
      return result;
    }),
    filterObject: Ember.computed('filter', function () {
      return _leadListSearch.default.load(this.filter);
    }),
    filterHash: Ember.computed('filterObject', function () {
      var c = Ember.Object.extend({
        filterObject: null,
        isDirty: false,
        getFieldName: function getFieldName(key) {
          return key.underscore();
        },
        getItemByField: function getItemByField(field) {
          var criteria = this.get('filterObject.criteria');
          var fieldName = this.getFieldName(field);

          if (criteria) {
            var item;
            return item = criteria.findBy('field', fieldName);
          }
        },
        unknownProperty: function unknownProperty(key) {
          var item = this.getItemByField(key);

          if (item) {
            return item.get('value');
          }
        },
        setUnknownProperty: function setUnknownProperty(key, value) {
          var criteria = this.get('filterObject.criteria');
          var fieldName = key.underscore();
          var item = this.getItemByField(fieldName);

          if (value) {
            if (!item) {
              item = _leadListSearch.default.Single.create({
                field: fieldName
              });
              criteria.addObject(item);
            }

            if (value !== item.get('value')) {
              item.set('value', value);
              return this.set('isDirty', true);
            }
          } else {
            if (item) {
              criteria.removeObject(item);
              return this.set('isDirty', true);
            }
          }
        }
      });
      return c.create({
        filterObject: this.filterObject
      });
    }),
    applyFilterObject: function applyFilterObject() {
      var filter = this.get('filterObject.hash');

      if (filter.criteria.length === 0) {
        filter = null;
      }

      return this.set('filter', filter);
    },
    additionalFields: Ember.computed('filterObject', function () {
      var result = [];

      for (var _i = 0, _Array$from = Array.from(this.get('filterObject.criteria')); _i < _Array$from.length; _i++) {
        var c = _Array$from[_i];
        var m = (c.get('field') || '').match(/^full_record\.(.+)$/);

        if (m) {
          result.pushObject(_leadListSearch.default.Single.create({
            field: m[1],
            value: c.get('value')
          }));
        }
      }

      result.pushObject(_leadListSearch.default.Single.create());
      return result;
    }),
    _addEmptyAdditionalField: Ember.observer('additionalFields.@each.field', 'additionalFields.@each.value', function () {
      var additionalFields = this.additionalFields;
      var emptyField = additionalFields.find(function (item) {
        return !item.get('field') && !item.get('value');
      });

      if (!emptyField) {
        return additionalFields.addObject(_leadListSearch.default.Single.create());
      }
    }),
    _changeAdditionalFields: Ember.observer('additionalFields.@each.field', 'additionalFields.@each.value', function () {
      var additionalFields = this.additionalFields;
      var criteria = this.get('filterObject.criteria');
      var fields = [];

      for (var _i2 = 0, _Array$from2 = Array.from(additionalFields); _i2 < _Array$from2.length; _i2++) {
        var f = _Array$from2[_i2];
        var field = f.get('field');
        var value = f.get('value');

        if (field && value) {
          fields.addObject(_leadListSearch.default.Single.create({
            field: "full_record.".concat(field),
            value: value
          }));
        }
      } // remove the old custom fields -- do a full replacement to ensure any fields that no longer exist are removed


      criteria.removeObjects(criteria.filter(function (c) {
        return (c.get('field') || '').match(/^full_record\.(.+)$/);
      })); // add the currently specified fields

      return criteria.addObjects(fields);
    }),
    hasAdvancedCriteria: Ember.computed('filterObject.criteria.@each.field', function () {
      return this.get('filterObject.criteria').any(function (item) {
        return item.get('field') !== 'full_text';
      });
    }),
    _checkAdvancedMode: Ember.observer('filterObject', function () {
      if (this.hasAdvancedCriteria) {
        return this.set('mode', 'advanced');
      }
    }),
    _triggerAutoSearch: Ember.observer('filterHash.isDirty', function () {
      if (!this.get('filterHash.isDirty')) {
        return;
      }

      if (this.autoSearch) {
        return Ember.run.debounce(this, this.applyFilterObject, 700);
      }
    }),
    _initializeProperties: Ember.on('init', function () {
      this.getProperties('filterObject', 'filterHash');
      return this._checkAdvancedMode();
    }),
    actions: {
      search: function search() {
        return this.applyFilterObject();
      },
      showBasicMode: function showBasicMode() {
        if (!this.hasAdvancedCriteria) {
          return this.set('mode', 'basic');
        }
      },
      showAdvancedMode: function showAdvancedMode() {
        return this.set('mode', 'advanced');
      }
    }
  });
  var _default = LeadFilterEditorComponent;
  _exports.default = _default;
});