define("frontend/pods/data-sources/data-source/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DataSourceIndexController = Ember.Controller.extend({
    isDynamicsDataSource: Ember.computed.equal('model.type', 'DynamicsDataSource'),
    isHubspotDataSource: Ember.computed.equal('model.type', 'HubspotDataSource'),
    isOutreachDataSource: Ember.computed.equal('model.type', 'OutreachDataSource'),
    isSalesloftDataSource: Ember.computed.equal('model.type', 'SalesloftDataSource'),
    isZohoDataSource: Ember.computed.equal('model.type', 'ZohoDataSource'),
    isPipedriveDataSource: Ember.computed.equal('model.type', 'PipedriveDataSource'),
    isSharpSpringDataSource: Ember.computed.equal('model.type', 'SharpSpringDataSource'),
    isAsyncDataSource: Ember.computed.or('isDynamicsDataSource', 'isHubspotDataSource', 'isOutreachDataSource', 'isSalesloftDataSource', 'isZohoDataSource', 'isPipedriveDataSource', 'isSharpSpringDataSource')
  });
  var _default = DataSourceIndexController;
  _exports.default = _default;
});