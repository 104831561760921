define("frontend/pods/components/call-session/manager-live-view/list-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "list-item": "_list-item_1qbv96",
    "name": "_name_1qbv96",
    "duration": "_duration_1qbv96",
    "status": "_status_1qbv96",
    "lead": "_lead_1qbv96",
    "actions": "_actions_1qbv96"
  };
  _exports.default = _default;
});