define("frontend/pods/components/agent/agent-console/states/connected/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "connected": "_connected_1vhj3j",
    "top": "_top_1vhj3j",
    "agent-info": "_agent-info_1vhj3j",
    "client-info": "_client-info_1vhj3j",
    "actions": "_actions_1vhj3j"
  };
  _exports.default = _default;
});