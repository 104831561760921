define("frontend/pods/components/inline-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InlineEditorComponent = Ember.Component.extend({
    classNames: ['inline-editor'],
    classNameBindings: ['isEditing'],
    content: null,
    properties: null,
    value: null,
    displayValue: null,
    isEditing: false,
    onSave: function onSave() {},
    onCancel: function onCancel() {},
    valueToDisplay: Ember.computed('value', 'displayValue', function () {
      var p = this.getProperties('value', 'displayValue');

      if (!Ember.isEmpty(p.displayValue)) {
        return p.displayValue;
      }

      return p.value;
    }),
    _storeOriginalValues: function _storeOriginalValues() {
      var values;
      var properties = (this.properties || '').split(' ');
      var model = this.content;

      if (model && properties) {
        values = model.getProperties.apply(model, properties);
      }

      return this.set('originalValues', values);
    },
    _revertOriginalValues: function _revertOriginalValues() {
      var originalValues = this.originalValues;

      if (Ember.isEmpty(originalValues)) {
        return;
      }

      return this.content.setProperties(originalValues);
    },
    _hasChanged: function _hasChanged() {
      var currentValues, p, v;
      var properties = (this.properties || '').split(' ');
      var model = this.content;

      if (!model || !properties) {
        return true;
      }

      if (model && properties) {
        currentValues = model.getProperties.apply(model, properties);
      }

      var originalValues = this.originalValues || {};

      for (var _i = 0, _Object$keys = Object.keys(originalValues || {}); _i < _Object$keys.length; _i++) {
        p = _Object$keys[_i];
        v = originalValues[p];

        if (currentValues[p] !== originalValues[p]) {
          return true;
        }
      }

      for (var _i2 = 0, _Object$keys2 = Object.keys(currentValues || {}); _i2 < _Object$keys2.length; _i2++) {
        p = _Object$keys2[_i2];
        v = currentValues[p];

        if (currentValues[p] !== originalValues[p]) {
          return true;
        }
      }

      return false;
    },
    actions: {
      edit: function edit() {
        if (this.isEditing) {
          return;
        }

        this._storeOriginalValues();

        return this.set('isEditing', true);
      },
      save: function save() {
        var _this = this;

        var model = this.content;

        var modified = this._hasChanged();

        if (model && modified) {
          return model.saveChanges().then(function () {
            return _this.set('isEditing', false);
          });
        } else {
          if (modified) {
            this.onSave();
          }

          return this.set('isEditing', false);
        }
      },
      cancel: function cancel() {
        this._revertOriginalValues();

        this.set('isEditing', false);
        return this.onCancel();
      }
    }
  });
  var _default = InlineEditorComponent;
  _exports.default = _default;
});