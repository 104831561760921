define("frontend/pods/components/call-lead-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AnimatedLead = Ember.Object.extend({
    lead: null,
    insertedAt: null,
    removedAt: null,
    isInserted: Ember.computed.bool('insertedAt'),
    isRemoved: Ember.computed.bool('removedAt'),
    isActive: Ember.computed('isInserted', 'isRemoved', function () {
      return this.isInserted && !this.isRemoved;
    }),
    _insert: function _insert() {
      if (!this.willInsert) {
        return console.log('attempted to insert when willInsert is false!');
      }

      this.setProperties({
        insertedAt: new Date(),
        willInsert: false,
        removedAt: null,
        willRemove: false
      });
      this.lead.set('isCurrentInCallList', true);
      return this._timer = null;
    },
    _remove: function _remove() {
      if (!this.willRemove) {
        return console.log('attempted to remove when willRemove is false!');
      }

      this.setProperties({
        removedAt: new Date(),
        willRemove: false,
        willInsert: false
      });
      this.lead.set('isCurrentInCallList', false);
      return this._timer = null;
    },
    _timer: null,
    cancelScheduledAction: function cancelScheduledAction() {
      if (this._timer) {
        Ember.run.cancel(this._timer);
        return this._timer = null;
      }
    },
    scheduleAction: function scheduleAction(fn, delay) {
      this.cancelScheduledAction();
      return this._timer = Ember.run.later(this, fn, delay);
    },
    scheduleInsertion: function scheduleInsertion(delay) {
      // if it is currently scheduled for removal, prevent it from occurring
      var isScheduledForRemoval = this.willRemove;

      if (isScheduledForRemoval) {
        this.cancelScheduledAction();
        this.set('willRemove', false);
      } // if it is already inserted and not removed, nothing to do


      var isCurrentlyInserted = this.isInserted && !this.isRemoved;

      if (isCurrentlyInserted) {
        return;
      } // if it is already scheduled for insertion, nothing to do


      var isScheduledForInsertion = this.willInsert;

      if (isScheduledForInsertion) {
        return;
      } // if it made it this far, it should be scheduled for insertion


      this.setProperties({
        willInsert: true,
        willRemove: false
      });
      return this.scheduleAction(this._insert, delay);
    },
    scheduleRemoval: function scheduleRemoval(delay) {
      // if it is currently scheduled for insertion, prevent it from occurring
      var isScheduledForInsertion = this.willInsert;

      if (isScheduledForInsertion) {
        this.cancelScheduledAction();
        this.set('willInsert', false);
      } // if it is already removed, nothing to do


      var isCurrentlyRemoved = this.isRemoved;

      if (isCurrentlyRemoved) {
        return;
      } // if it is not yet inserted, nothing to do


      var isInserted = this.isInserted;

      if (!isInserted) {
        return;
      } // if it is already scheduled for removal, nothing to do


      var isScheduledForRemoval = this.willRemove;

      if (isScheduledForRemoval) {
        return;
      } // if it made it this far, it should be scheduled for removal


      this.setProperties({
        willRemove: true,
        willInsert: false
      });
      return this.scheduleAction(this._remove, delay);
    }
  });
  var AnimatedLeads = Ember.ArrayProxy.extend({
    _insertCount: 0,
    _removeCount: 0,
    // remove leads older than 1 minute (rather than removing immediately so that animations work)
    expireRemovedItems: function expireRemovedItems() {
      var content = this.content;
      var now = new Date();
      var expirationTime = new Date(now.getTime() - 60000);
      var updatedContent = content.filter(function (l) {
        var removedAt = l.get('removedAt');
        var removed = removedAt && removedAt <= expirationTime;
        return !removed;
      });

      if (updatedContent.get('length') !== content.get('length')) {
        return this.set('content', updatedContent);
      }
    },
    nextInsertionAt: function nextInsertionAt() {
      this._insertCount += 1;
      return this._insertCount * 600;
    },
    nextRemovalAt: function nextRemovalAt() {
      this._removeCount += 1;
      return this._removeCount * 300;
    },
    resetAnimationStaggering: function resetAnimationStaggering() {
      this._insertCount = 0;
      return this._removeCount = 0;
    },
    addItem: function addItem(lead) {
      var item = AnimatedLead.create({
        lead: lead
      });
      this.addObject(item);
      return item;
    },
    scheduleInsertion: function scheduleInsertion(item) {
      return item.scheduleInsertion(this.nextInsertionAt());
    },
    scheduleRemoval: function scheduleRemoval(item) {
      return item.scheduleRemoval(this.nextRemovalAt());
    }
  });
  var CallLeadListComponent = Ember.Component.extend({
    callService: null,
    leadDisplay: 'all',
    displayingAllLeads: Ember.computed.equal('leadDisplay', 'all'),
    displayingActiveLeads: Ember.computed.equal('leadDisplay', 'active'),
    displayingCurrentLeads: Ember.computed.equal('leadDisplay', 'current'),
    leadList: Ember.computed.oneWay('callService.leadList'),
    allLeads: Ember.computed('leadList.leads', function () {
      return this.get('leadList.leads');
    }),
    activeLeads: Ember.computed.oneWay('callService.activeLeads'),
    hasLeads: Ember.computed.gt('allLeads.length', 0),
    leads: Ember.computed('leadDisplay', 'allLeads', 'activeLeads', 'currentDisplayLeads', function () {
      switch (this.leadDisplay) {
        case 'all':
          return this.allLeads;

        case 'active':
          return this.activeLeads;

        case 'current':
          return this.currentDisplayLeads;
      }
    }),
    upcomingLeads: Ember.computed.oneWay('callService.upcomingLeads'),
    currentLeads: Ember.computed('activeLeads.[]', 'upcomingLeads.[]', function () {
      var activeLeads = this.activeLeads || [];
      var upcomingLeads = this.upcomingLeads || [];
      var maxCurrentLeads = 8;

      if (activeLeads.get('length') >= maxCurrentLeads) {
        return activeLeads;
      } else {
        var result = activeLeads.slice(0, maxCurrentLeads);
        var inactiveUpcomingLeads = upcomingLeads.filter(function (l) {
          return result.indexOf(l) < 0;
        });
        result.addObjects(inactiveUpcomingLeads.slice(0, maxCurrentLeads - result.get('length')));
        return result;
      }
    }),
    currentDisplayLeads: Ember.computed('currentLeads.[]', 'activeLeads.[]', 'callService.status', 'callService.connectedLead', function () {
      // show the rolling list of active + upcoming leads when the user available
      var result;

      if (this.get('callService.isIdle')) {
        result = this.currentLeads; // show the leads actively being dialed when paused
      } else if (this.get('callService.isPaused')) {
        result = this.activeLeads; // show only the connected lead when connected
      } else if (this.get('callService.isOnCall')) {
        result = [this.get('callService.connectedLead')];
      } else {
        result = [];
      }

      return result.filter(function (a) {
        return !!a;
      });
    }),
    visibleAnimatedLeads: Ember.computed('animatedLeads.@each.isInserted', 'animatedLeads.@each.isRemoved', function () {
      return this.animatedLeads.filter(function (l) {
        return l.get('isInserted') && !l.get('isRemoved');
      });
    }),
    animatedLeads: Ember.computed(function () {
      return AnimatedLeads.create({
        content: []
      });
    }),
    _updateAnimatedLeads: function _updateAnimatedLeads() {
      var currentDisplayLeads = this.currentDisplayLeads; //console.log('current:', currentDisplayLeads.mapBy('name'))

      var animatedLeads = this.animatedLeads;
      animatedLeads.expireRemovedItems();
      animatedLeads.resetAnimationStaggering(); // mark leads no longer in the display list as removed

      animatedLeads.forEach(function (l) {
        if (currentDisplayLeads.indexOf(l.get('lead')) < 0) {
          return animatedLeads.scheduleRemoval(l);
        }
      }); // add leads not already in the list (or removed but not expired)

      return currentDisplayLeads.forEach(function (lead) {
        var l = animatedLeads.find(function (d) {
          return d.get('lead') === lead;
        });

        if (!l) {
          l = animatedLeads.addItem(lead);
        }

        return animatedLeads.scheduleInsertion(l);
      });
    },
    //console.log('animated: ', animatedLeads.get('length'), animatedLeads.filter((l) -> (l.get('isInserted') && !l.get('isRemoved') && !l.get('willRemove')) || l.get('willInsert')).mapBy('lead.name'))
    _updateAnimatedLeadsOnCurrentLeadsChange: Ember.observer('currentDisplayLeads.[]', function () {
      return Ember.run.throttle(this, this._updateAnimatedLeads, 500);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      return this.getProperties('currentLeads', 'currentDisplayLeads');
    },
    actions: {
      showAllLeads: function showAllLeads() {
        return this.set('leadDisplay', 'all');
      },
      showActiveLeads: function showActiveLeads() {
        return this.set('leadDisplay', 'active');
      },
      showCurrentLeads: function showCurrentLeads() {
        return this.set('leadDisplay', 'current');
      },
      refreshAllLeads: function refreshAllLeads() {
        var leadList = this.leadList;

        if (leadList) {
          return leadList.refreshLeads();
        }
      }
    }
  });
  var _default = CallLeadListComponent;
  _exports.default = _default;
});