define("frontend/pods/components/call-session/manager-live-view/list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    callSession: null,
    activity: null,
    tagName: '',
    callService: Ember.inject.service('call'),
    authentication: Ember.inject.service(),
    isCurrentUser: Ember.computed('callSession.user.id', 'authentication.activeUser.id', {
      get: function get() {
        return this.get('callSession.user.id') === this.get('authentication.activeUser.id');
      }
    }),
    canEavesdrop: Ember.computed('callService.isPaused', 'isCurrentUser', {
      get: function get() {
        return this.callService.isPaused && !this.isCurrentUser;
      }
    }),
    isEavesdroppingOnCallSession: Ember.computed('callSession.id', 'callService.callSession.eavesdroppingOnCallSession.id', {
      get: function get() {
        var callSessionId = this.get('callSession.id');
        var eavesdroppingOnCallSessionId = this.get('callService.callSession.eavesdroppingOnCallSession.id');
        return callSessionId && callSessionId === eavesdroppingOnCallSessionId;
      }
    }),
    isEavesdropping: Ember.computed.and('isEavesdroppingOnCallSession', 'callService.isEavesdropping'),
    isCoaching: Ember.computed.and('isEavesdroppingOnCallSession', 'callService.isCoaching'),
    humanStatus: Ember.computed('callSession.state', {
      get: function get() {
        var callSession = this.callSession;

        if (callSession) {
          if (callSession.isIdle) {
            return 'Team Dialing';
          } else if (callSession.isPaused) {
            return 'Paused';
          } else if (callSession.isOnCall) {
            return 'On a Call';
          } else if (callSession.isEavesdropping) {
            return 'Eavesdropping';
          } else if (callSession.isCoaching) {
            return 'Coaching';
          } else if (callSession.isOffline) {
            return 'Offline';
          } else {
            return 'Online';
          }
        }
      }
    }),
    statusClass: Ember.computed('callSession.state', {
      get: function get() {
        var callSession = this.callSession;

        if (callSession) {
          if (callSession.isIdle) {
            return 'primary';
          } else if (callSession.isPaused) {
            return 'warning';
          } else if (callSession.isOnCall) {
            return 'success';
          } else if (callSession.isEavesdropping || callSession.isCoaching) {
            return 'info';
          } else {
            return 'default';
          }
        }
      }
    }),
    eavesdrop: function eavesdrop() {
      this.callService.eavesdrop(this.get('callSession.user.id'));
    }
  });

  _exports.default = _default;
});