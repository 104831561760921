define("frontend/helpers/format-percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatPercent(params) {
    var value = params[0];

    if (value || value === 0) {
      return "".concat(Math.round(value * 100), "%");
    } else {
      return '';
    }
  });

  _exports.default = _default;
});