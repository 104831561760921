define("frontend/utils/time-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TimeFormat = {
    // minutes and seconds
    mmss: function mmss(seconds) {
      var t = TimeFormat._parse(seconds);

      var minutes = t.hours * 60 + t.minutes;
      seconds = TimeFormat._pad(t.seconds);
      return "".concat(t.sign).concat(minutes, ":").concat(seconds);
    },
    // hours and minutes
    hhmm: function hhmm(seconds) {
      var t = TimeFormat._parse(seconds);

      var minutes = t.minutes + Math.round(t.seconds / 60);
      minutes = TimeFormat._pad(minutes);
      return "".concat(t.sign).concat(t.hours, ":").concat(minutes);
    },
    // hours, minutes, and seconds
    hhmmss: function hhmmss(seconds) {
      var t = TimeFormat._parse(seconds);

      var minutes = TimeFormat._pad(t.minutes);

      seconds = TimeFormat._pad(t.seconds);
      return "".concat(t.sign).concat(t.hours, ":").concat(minutes, ":").concat(seconds);
    },
    _parse: function _parse(seconds) {
      var v = parseInt(seconds);

      if (isNaN(v)) {
        v = 0;
      }

      var result = {};
      result.sign = v < 0 ? '-' : '';
      v = Math.abs(v);
      result.seconds = Math.floor(v % 60);
      result.minutes = Math.floor(v / 60 % 60);
      result.hours = Math.floor(v / 60 / 60);
      return result;
    },
    _pad: function _pad(value) {
      if (value < 10) {
        return "0".concat(value);
      } else {
        return value;
      }
    }
  };
  var _default = TimeFormat;
  _exports.default = _default;
});