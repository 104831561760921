define("frontend/pods/components/infinite-scroll-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AKNpa60d",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/pods/components/infinite-scroll-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});