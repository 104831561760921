define("frontend/utils/phone-number-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPhoneNumber = formatPhoneNumber;
  var FORMATS = [{
    name: 'North America',
    regex: /^\+1\d{10}$/,
    formatter: function formatter(v) {
      return "(".concat(v.substr(2, 3), ") ").concat(v.substr(5, 3), "-").concat(v.substr(8, 4));
    }
  }];
  var FORMATS_COUNT = FORMATS.length;

  function formatPhoneNumber(value) {
    if (value) {
      var v = String(value).trim();

      if (v) {
        for (var i = 0; i < FORMATS_COUNT; i++) {
          if (v.match(FORMATS[i].regex)) {
            return FORMATS[i].formatter(v);
          }
        }
      }
    }

    return value;
  }
});