define("frontend/pods/outbound-voice-mail-recordings/new/route", ["exports", "frontend/pods/base/new/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var OutboundVoiceMailRecordingsNewRoute = _route.default.extend({
    model: function model() {
      return this.store.createRecord('outbound-voice-mail-recording', {
        user: this.authenticationService.activeUser
      });
    },
    afterSave: function afterSave() {
      return this.transitionTo('outbound-voice-mail-recordings.index');
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('outbound-voice-mail-recordings.index');
    }
  });

  var _default = OutboundVoiceMailRecordingsNewRoute;
  _exports.default = _default;
});