define("frontend/pods/components/dashboard-call-activity-item/component", ["exports", "frontend/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardCallActivityItemComponent = Ember.Component.extend({
    call: null,
    leadId: Ember.computed.readOnly('call.lead_id'),
    callerId: Ember.computed.readOnly('call.caller_id'),
    clientId: Ember.computed.readOnly('call.client_id'),
    startedAt: Ember.computed.readOnly('call.started_at'),
    joinedAt: Ember.computed.readOnly('call.joined_at'),
    dialResult: Ember.computed.readOnly('call.dial_result'),
    leadFirstName: Ember.computed.readOnly('call.lead_first_name'),
    leadLastName: Ember.computed.readOnly('call.lead_last_name'),
    callerFirstName: Ember.computed.readOnly('call.caller_first_name'),
    callerLastName: Ember.computed.readOnly('call.caller_last_name'),
    clientFirstName: Ember.computed.readOnly('call.client_first_name'),
    clientLastName: Ember.computed.readOnly('call.client_last_name'),
    voiceMailDeliveredAt: Ember.computed.readOnly('call.voice_mail_delivered_at'),
    status: Ember.computed.readOnly('call.status'),
    isConversation: Ember.computed.readOnly('call.is_conversation'),
    isAndyDial: Ember.computed.readOnly('call.is_andy_dial'),
    isPowerDial: Ember.computed.readOnly('call.is_power_dial'),
    isNonAgentDial: Ember.computed.or('isPowerDial', 'isAndyDial'),
    isAgentDial: Ember.computed.not('isNonAgentDial'),
    leadName: Ember.computed('leadFirstName', 'leadLastName', function () {
      return "".concat(this.leadFirstName, " ").concat(this.leadLastName);
    }),
    callerName: Ember.computed('isPowerDial', 'clientName', 'callerFirstName', 'callerLastName', function () {
      if (this.isPowerDial) {
        return this.clientName;
      } else {
        return _user.default.formatFriendlyName(this.callerFirstName, this.callerLastName);
      }
    }),
    clientName: Ember.computed('clientFirstName', 'clientLastName', function () {
      return "".concat(this.clientFirstName, " ").concat(this.clientLastName);
    }),
    conversationText: Ember.computed('voiceMailDeliveredAt', 'status', function () {
      if (this.voiceMailDeliveredAt) {
        return 'left a voice mail for';
      } else {
        switch (this.status) {
          case 'closed':
            return 'closed';

          case 'qualified':
            return 'qualified';

          case 'on hold':
            return 'put on hold';

          default:
            return 'had a conversation with';
        }
      }
    }),
    _initializeProperties: Ember.on('init', function () {
      this.getProperties('callerId', 'clientId', 'joinedAt', 'dialResult');
      this.getProperties('leadFirstName', 'leadLastName', 'callerFirstName', 'callerLastName');
      this.getProperties('voiceMailDeliveredAt', 'status', 'dialResult');
      return this.getProperties('isPowerDial', 'isAgentDial', 'isConversation');
    })
  });
  var _default = DashboardCallActivityItemComponent;
  _exports.default = _default;
});