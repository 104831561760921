define("frontend/models/recording", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    owner: (0, _model.belongsTo)('user'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    trim: (0, _model.attr)('boolean'),
    state: null,
    isPlaying: Ember.computed.equal('state', 'playing'),
    isRecording: Ember.computed.equal('state', 'recording'),
    isBusy: Ember.computed.or('isPlaying', 'isRecording'),
    isStopped: Ember.computed.not('isBusy'),
    canPlay: Ember.computed.bool('isStopped'),
    canRecord: Ember.computed.bool('isStopped'),
    canStopPlaying: Ember.computed.bool('isPlaying'),
    canStopRecording: Ember.computed.bool('isRecording'),
    canStop: Ember.computed.bool('isBusy'),
    play: function play() {
      return this._executeServerAction('play');
    },
    stop: function stop() {
      if (this.isPlaying) {
        return this.stopPlaying();
      }

      if (this.isRecording) {
        return this.stopRecording();
      }
    },
    _executeServerAction: function _executeServerAction(action) {
      var adapter = this.store.adapterFor('application');
      return adapter.executeServerAction('recording', this.id, action);
    },
    stopPlaying: function stopPlaying() {
      return this._executeServerAction('stop');
    },
    record: function record() {
      return this._executeServerAction('record');
    },
    stopRecording: function stopRecording() {
      return this._executeServerAction('stop_recording');
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.getProperties('isPlaying', 'isRecording', 'isBusy', 'isStopped', 'canPlay', 'canRecord', 'canStopPlaying', 'canStopRecording');
    }
  });

  _exports.default = _default;
});