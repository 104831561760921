define("frontend/pods/components/item-controller/service-provider/component", ["exports", "frontend/utils/oauth-authentication"], function (_exports, _oauthAuthentication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    tagName: '',
    isAuthenticating: Ember.computed.readOnly('auth.isAuthenticating'),
    auth: Ember.computed('model', function () {
      var provider = this.provider;
      var providerId = this.providerId;
      var adapter = this.store.adapterFor('application');
      var headerData = adapter.get('headerData');
      return _oauthAuthentication.default.create({
        provider: provider,
        providerId: providerId,
        headerData: headerData,
        command: 'reauthenticate'
      });
    }),
    authenticate: function authenticate() {
      var _this = this;

      var auth = this.auth;
      return auth.authenticate().then(function () {
        return _this.model.reload();
      });
    }
  });

  _exports.default = _default;
});