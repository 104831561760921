define("frontend/pods/auth/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    requiresLogin: false,
    beforeModel: function beforeModel() {
      return this.authenticationService.clear();
    }
  });

  _exports.default = _default;
});