define("frontend/utils/hash-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.camelizeKeys = camelizeKeys;
  _exports.dasherizeKeys = dasherizeKeys;

  function camelizeObject(obj) {
    var result = {};
    var keys = Object.keys(obj);

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      result[Ember.String.camelize(key)] = camelizeKeys(obj[key]);
    }

    return result;
  }

  function camelizeKeys(value) {
    var type = Ember.typeOf(value);

    if (type === 'array') {
      return value.map(function (v) {
        return camelizeKeys(v);
      });
    } else if (type === 'object') {
      return camelizeObject(value);
    } else {
      return value;
    }
  }

  function dasherizeObject(obj) {
    var result = {};
    var keys = Object.keys(obj);

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      result[Ember.String.dasherize(key)] = dasherizeKeys(obj[key]);
    }

    return result;
  }

  function dasherizeKeys(value) {
    var type = Ember.typeOf(value);

    if (type === 'array') {
      return value.map(function (v) {
        return dasherizeKeys(v);
      });
    } else if (type === 'object') {
      return dasherizeObject(value);
    } else {
      return value;
    }
  }
});