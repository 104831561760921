define("frontend/pods/components/link-salesforce-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rAE6xBhC",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[24,0,\"btn btn-info\"],[4,[38,0],[[32,0],\"submit\"],null],[12],[2,\"Link Salesforce Account\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/link-salesforce-account/template.hbs"
    }
  });

  _exports.default = _default;
});