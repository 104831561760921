define("frontend/pods/components/partial/auth/reset-password/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ew1uL/Au",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[4,[38,1],[[32,0],[35,0]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"New Password\"],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"class\",\"type\",\"value\",\"placeholder\"],[\"form-control\",\"password\",[35,2,[\"password\"]],\"Enter your new password\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Confirm Password\"],[13],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"class\",\"type\",\"value\",\"placeholder\"],[\"form-control\",\"password\",[35,2,[\"passwordConfirmation\"]],\"Confirm your new password\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-primary btn-block\"],[14,4,\"submit\"],[12],[2,\"Submit\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onSubmit\",\"action\",\"model\",\"input\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/partial/auth/reset-password/form/template.hbs"
    }
  });

  _exports.default = _default;
});