define("frontend/pods/components/partial/outbound-voice-mail-recording/form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    tagName: ''
  });

  _exports.default = _default;
});