define("frontend/models/outbound-call", ["exports", "@ember-data/model", "frontend/models/lead"], function (_exports, _model, _lead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var OutboundCall = _model.default.extend((_Model$extend = {
    startedAt: (0, _model.attr)('date'),
    endedAt: (0, _model.attr)('date'),
    answeredAt: (0, _model.attr)('date'),
    joinedAt: (0, _model.attr)('date'),
    phone: (0, _model.attr)('string'),
    notes: (0, _model.attr)('string'),
    transferResult: (0, _model.attr)('string'),
    callQuality: (0, _model.attr)('string'),
    initialDestination: (0, _model.attr)('string'),
    finalDestination: (0, _model.attr)('string'),
    dialResult: (0, _model.attr)('string'),
    agentConversationResult: (0, _model.attr)('string'),
    dialNotes: (0, _model.attr)('string'),
    followUpScript: (0, _model.attr)('string'),
    voiceMailDeliveredAt: (0, _model.attr)('date'),
    agentReleasedAt: (0, _model.attr)('date'),
    clientReleasedAt: (0, _model.attr)('date'),
    status: (0, _model.attr)('string'),
    leadStatusName: (0, _model.attr)('string'),
    reason: (0, _model.attr)('string'),
    onHoldUntil: (0, _model.attr)('date'),
    appointmentDate: (0, _model.attr)('date'),
    agentFlagged: (0, _model.attr)('boolean'),
    isConversation: (0, _model.attr)('boolean'),
    twoPartyState: (0, _model.attr)('boolean'),
    masterStatus: (0, _model.attr)('string'),
    masterErrorMessage: (0, _model.attr)('string'),
    clientRecording: (0, _model.attr)('string'),
    agentRecording: (0, _model.attr)('string'),
    gongAccountUrls: (0, _model.attr)(),
    gongAccountUrl: Ember.computed.readOnly('gongAccountUrls.firstObject'),
    execvisionAccountRecordings: (0, _model.attr)('boolean'),
    autoDetectResult: (0, _model.attr)('string'),
    autoDetectTime: (0, _model.attr)('number'),
    dialerLog: (0, _model.attr)(),
    externalDispositionId: (0, _model.attr)('string'),
    callSession: (0, _model.belongsTo)('callSession'),
    clientCallSession: (0, _model.belongsTo)('callSession'),
    lead: (0, _model.belongsTo)('lead'),
    leadList: (0, _model.belongsTo)('lead-list'),
    client: (0, _model.belongsTo)('user'),
    caller: (0, _model.belongsTo)('user'),
    isPowerDial: (0, _model.attr)('boolean'),
    isAndyDial: (0, _model.attr)('boolean'),
    // these properties are only used here in the client app -- the call state is pushed by the messages server
    callState: Ember.computed.alias('lead.callState'),
    isActive: Ember.computed('callState', function () {
      var callState = this.callState;
      return callState === 'dialing' || callState === 'connecting' || callState === 'connected' || callState === 'disconnecting';
    }),
    isOpen: Ember.computed.equal('status', 'open'),
    isQualified: Ember.computed.equal('status', 'qualified'),
    isClosed: Ember.computed.equal('status', 'closed'),
    isOnHold: Ember.computed.bool('onHoldUntil'),
    wasTransferred: Ember.computed.bool('joinedAt'),
    didClientDial: Ember.computed('caller.id', 'client.id', {
      get: function get() {
        var callerId = this.get('caller.id');
        var clientId = this.get('client.id');
        return callerId && callerId === clientId;
      }
    }),
    didAgentDial: Ember.computed.not('didClientDial'),
    didLeaveVoicemail: Ember.computed.bool('voiceMailDeliveredAt'),
    conversationStartedAt: Ember.computed('startedAt', 'joinedAt', 'didClientDial', function () {
      if (this.didClientDial) {
        return this.startedAt;
      } else {
        return this.joinedAt;
      }
    }),
    callerEndedAt: Ember.computed('agentReleasedAt', 'joinedAt', 'endedAt', function () {
      return this.agentReleasedAt || this.joinedAt || this.endedAt;
    })
  }, _defineProperty(_Model$extend, "isOpen", Ember.computed.equal('status', 'open')), _defineProperty(_Model$extend, "reasonValues", Ember.computed('status', function () {
    var status = this.status;

    if (status) {
      return OutboundCall.reasonValues[status];
    }
  })), _defineProperty(_Model$extend, "clientRecordingSrc", Ember.computed('clientRecording', 'id', function () {
    //"https://s3.amazonaws.com/monsterconnect-development-files/call_session_4_client.mp3"
    var recording = this.clientRecording;

    if (!recording) {
      return;
    }

    var url = "/api/v1/outbound_calls/".concat(this.id, "/client_recording");
    var queryString = this.store.adapterFor('application').get('headerDataQueryString');
    return url + '?' + queryString;
  })), _defineProperty(_Model$extend, "agentRecordingSrc", Ember.computed('agentRecording', 'id', function () {
    var recording = this.agentRecording;

    if (!recording) {
      return;
    }

    var url = "/api/v1/outbound_calls/".concat(this.id, "/agent_recording");
    var queryString = this.store.adapterFor('application').get('headerDataQueryString');
    return url + '?' + queryString;
  })), _defineProperty(_Model$extend, "clientRecordingStartedAt", Ember.computed('clientRecording', 'answeredAt', 'joinedAt', function () {
    if (this.clientRecording) {
      return this.joinedAt || this.answeredAt;
    }
  })), _defineProperty(_Model$extend, "clientRecordingEndedAt", Ember.computed('clientRecording', 'endedAt', function () {
    if (this.clientRecording) {
      return this.endedAt;
    }
  })), _defineProperty(_Model$extend, "clientRecordingDuration", Ember.computed('clientRecordingStartedAt', 'clientRecordingEndedAt', function () {
    var s = this.clientRecordingStartedAt;
    var e = this.clientRecordingEndedAt;

    if (e && s) {
      return Math.round((e - s) / 1000);
    }
  })), _defineProperty(_Model$extend, "agentRecordingStartedAt", Ember.computed('agentRecording', 'answeredAt', function () {
    if (this.agentRecording) {
      return this.answeredAt;
    }
  })), _defineProperty(_Model$extend, "agentRecordingEndedAt", Ember.computed('agentRecording', 'joinedAt', 'endedAt', function () {
    if (this.agentRecording) {
      return this.joinedAt || this.endedAt;
    }
  })), _defineProperty(_Model$extend, "agentRecordingDuration", Ember.computed('agentRecordingStartedAt', 'agentRecordingEndedAt', function () {
    var s = this.agentRecordingStartedAt;
    var e = this.agentRecordingEndedAt;

    if (e && s) {
      return Math.round((e - s) / 1000);
    }
  })), _defineProperty(_Model$extend, "displayStatus", Ember.computed('masterStatus', 'leadStatusName', 'humanStatus', function () {
    return this.masterStatus || this.leadStatusName || this.humanStatus;
  })), _defineProperty(_Model$extend, "humanStatus", Ember.computed('status', function () {
    return this._getHumanText(OutboundCall.statusValues, this.status);
  })), _defineProperty(_Model$extend, "humanTransferResult", Ember.computed('transferResult', function () {
    return this._getHumanText(OutboundCall.transferResultValues, this.transferResult);
  })), _defineProperty(_Model$extend, "humanCallQuality", Ember.computed('callQuality', function () {
    return this._getHumanText(OutboundCall.callQualityValues, this.callQuality);
  })), _defineProperty(_Model$extend, "humanInitialDestination", Ember.computed('initialDestination', function () {
    return this._getHumanText(OutboundCall.initialDestinationValues, this.initialDestination);
  })), _defineProperty(_Model$extend, "humanFinalDestination", Ember.computed('finalDestination', function () {
    return this._getHumanText(OutboundCall.finalDestinationValues, this.finalDestination);
  })), _defineProperty(_Model$extend, "humanAgentConversationResult", Ember.computed('agentConversationResult', function () {
    return this._getHumanText(OutboundCall.agentConversationResultValues, this.agentConversationResult);
  })), _defineProperty(_Model$extend, "humanDialResult", Ember.computed('dialResult', 'voiceMailDeliveredAt', function () {
    if (this.voiceMailDeliveredAt) {
      return 'Left voicemail';
    } else {
      return this._getHumanText(OutboundCall.dialResultValues, this.dialResult);
    }
  })), _defineProperty(_Model$extend, "humanReason", Ember.computed('reasonValues', 'reason', function () {
    var array = this.reasonValues || [];
    return this._getHumanText(array, this.reason);
  })), _defineProperty(_Model$extend, "_getHumanText", function _getHumanText(array, value) {
    var item = array.find(function (i) {
      return i.value === value;
    });

    if (item) {
      return item.text;
    }
  }), _Model$extend));

  OutboundCall.transferResultValues = [{
    value: "acceptable",
    text: "acceptable"
  }, {
    value: "not_connected",
    text: "I did not get connected"
  }, {
    value: "wrong_person",
    text: "I was connected to the wrong person"
  }, {
    value: "bad_handoff",
    text: "The handoff was awkward"
  }, {
    value: "gatekeeper",
    text: "Gatekeeper"
  }, {
    value: "other",
    text: "Other"
  }];
  OutboundCall.callQualityValues = [{
    value: "acceptable",
    text: "acceptable"
  }, {
    value: "bad_connection",
    text: "Difficult to hear"
  }, {
    value: "call_dropped",
    text: "The call was dropped"
  }, {
    value: "echo",
    text: "There was an echo"
  }, {
    value: "other",
    text: "Other"
  }];
  OutboundCall.initialDestinationValues = [{
    value: "phone_tree",
    text: "Phone tree"
  }, {
    value: "gatekeeper",
    text: "Gatekeeper"
  }, {
    value: "direct_line",
    text: "Direct line"
  }, {
    value: "other",
    text: "Other"
  }];
  OutboundCall.finalDestinationValues = [{
    value: "lead",
    text: "Lead"
  }, {
    value: "voicemail",
    text: "Voicemail"
  }, {
    value: "gatekeeper",
    text: "Gatekeeper"
  }, {
    value: "phone_tree",
    text: "Phone tree"
  }];
  OutboundCall.dialResultValues = [{
    value: "lead",
    text: "Reached lead"
  }, {
    value: "voicemail",
    text: "Reached voicemail"
  }, {
    value: "gatekeeper",
    text: "Reached gatekeeper"
  }, {
    value: "client_unavailable",
    text: "Client became unavailable"
  }, {
    value: "wrong_number",
    text: "Wrong number"
  }, {
    value: "no_answer",
    text: "No answer"
  }, {
    value: "busy_signal",
    text: "Busy signal"
  }, {
    value: "phone_tree",
    text: "Unable to navigate phone tree"
  }, {
    value: "other",
    text: "Other"
  }];
  OutboundCall.agentConversationResultValues = [{
    value: "left_company",
    text: "No longer with company"
  }, {
    value: "not_at_company",
    text: "Not at the company"
  }, {
    value: "out_of_office",
    text: "Out of the office"
  }, {
    value: "on_vacation",
    text: "On vacation"
  }, {
    value: "not_interested",
    text: "Not interested"
  }, {
    value: "remove_from_list",
    text: "Remove from list"
  }, {
    value: "wrong_number",
    text: "Wrong phone number"
  }, {
    value: "referral",
    text: "Referral"
  }, {
    value: "other",
    text: "Other"
  }];
  OutboundCall.statusValues = _lead.default.statusValues;
  OutboundCall.reasonValues = _lead.default.reasonValues;
  var _default = OutboundCall;
  _exports.default = _default;
});