define("frontend/pods/components/item-controller/lead-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    callState: Ember.computed.alias('model.callState'),
    title: Ember.computed.alias('model.title'),
    companyName: Ember.computed.alias('model.companyName'),
    callService: Ember.inject.service('call'),
    isAgent: Ember.computed.alias('callService.authentication.isAgent'),
    dial: function dial() {
      var lead = this.model;
      return this.callService.dialLead(lead);
    }
  });

  _exports.default = _default;
});