define("frontend/pods/auth/forgot-password/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    requiresLogin: false,
    model: function model() {
      return this.store.createRecord('forgot-password');
    },
    actions: {
      submit: function submit() {
        var _this = this;

        var model = this.get('controller.model');
        return model.save().then(function () {
          return _this.transitionTo('auth.forgot-password.submitted');
        }).then(null, function () {});
      }
    }
  });

  _exports.default = _default;
});