define("frontend/pods/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ROORdEec",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"progress-bar progress-bar-primary\"],[15,5,[34,0]],[12],[2,\"\\n\\t\"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"barStyle\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});