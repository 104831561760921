define("frontend/pods/components/geo-heat-map/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _googleInstallPromise = null;
  var GeoHeatMapComponent = Ember.Component.extend({
    google: Ember.computed(function () {
      return (// using $.getScript() does not load it properly -- gotta go the old fashioned way
        _googleInstallPromise || (_googleInstallPromise = new Ember.RSVP.Promise(function (resolve, reject) {
          return $.getScript("https://www.google.com/jsapi").done(function () {
            return window.google.load("visualization", "1.1", {
              packages: ["geochart"],
              callback: function callback() {
                return resolve(window.google);
              }
            });
          });
        }))
      );
    }),
    chartOptions: {
      region: 'US',
      colorAxis: {
        colors: ['#dbe9f5', '#286295']
      },
      backgroundColor: '#9fc7e9',
      datalessRegionColor: '#eee',
      defaultColor: '#f5f5f5',
      resolution: 'provinces'
    },
    // a hash of two-letter state abbreviations and values
    data: {
      'US-IN': 23,
      'US-CA': 94,
      'US-MI': 32,
      'US-KY': 6,
      'US-TN': 0
    },
    stateTitle: 'State',
    valueTitle: 'Value',
    dataTable: Ember.computed('data', function () {
      var result = [];
      result.push([this.stateTitle, this.valueTitle]);
      var data = this.data;

      for (var _i = 0, _Object$keys = Object.keys(data || {}); _i < _Object$keys.length; _i++) {
        var key = _Object$keys[_i];
        var value = data[key];
        result.push([key, value]);
      }

      return result;
    }),
    _renderChart: Ember.observer('dataTable', 'chartOptions', function () {
      var _this = this;

      return this.google.then(function (google) {
        var dataTable = _this.dataTable;
        var options = _this.chartOptions;
        var element = _this.element;

        if (!dataTable || !options || !element) {
          return;
        }

        _this._destroyChart();

        var hasData = dataTable.get('length') > 1;
        var data = google.visualization.arrayToDataTable(dataTable);
        var chart = new google.visualization.GeoChart(element);
        chart.draw(data, options);

        _this.set('_chart', chart);

        return chart;
      });
    }),
    _destroyChart: function _destroyChart() {
      var chart = this._chart;

      if (chart) {
        chart.clearChart();
      }

      return this.set('_chart', null);
    },
    _renderOnInsert: Ember.on('didInsertElement', function () {
      return Ember.run.scheduleOnce('afterRender', this, this._renderChart);
    }),
    _destroyOnRemove: Ember.on('willDestroyElement', function () {
      return this._destroyChart();
    })
  });
  var _default = GeoHeatMapComponent;
  _exports.default = _default;
});