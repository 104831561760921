define("frontend/pods/components/view/ui-wizard/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['wizard'],
    // allow the steps to be referenced by index or name -- changing one changes the other
    step: 1,
    stepName: null,
    stepViews: Ember.computed('childViews.[]', function () {
      return this.childViews.filter(function (v) {
        return v instanceof StepView;
      });
    }),
    stepNames: Ember.computed('stepViews', function () {
      return this.stepViews.getEach('name');
    }),
    // synchronize step when stepName changes
    _stepNameDidChange: Ember.observer('stepName', function () {
      var stepName = this.stepName;
      var index = this.stepNames.indexOf(stepName) + 1;

      if (index > 0) {
        return this.set('step', index);
      }
    }),
    // synchronize stepName when step changes
    _stepDidChange: Ember.observer('step', function () {
      var step = this.step;
      return this.set('stepName', this.stepNames.objectAt(step - 1));
    }),
    stepCount: Ember.computed.oneWay('stepViews.length')
  });

  _exports.default = _default;
  var StepView = Ember.Component.extend({
    classNames: ['wizard-step'],
    wizard: null,
    name: null,
    _getWizard: Ember.on('didInsertElement', function () {
      return this.set('wizard', this.nearestOfType(UiWizard.View));
    }),
    step: Ember.computed('wizard.stepViews', function () {
      var stepViews = this.get('wizard.stepViews') || [];
      return stepViews.indexOf(this) + 1;
    }),
    isActive: Ember.computed('step', 'wizard.step', function () {
      return this.step === this.get('wizard.step');
    }),
    isShowing: Ember.computed.bool('isActive'),
    classNameBindings: ['isShowing::hidden']
  });
});