define("frontend/pods/components/ui/dropdown-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BBspCkwu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"isOpen\",\"button\",\"menu\",\"header\",\"divider\",\"menu-item\",\"custom-menu-item\"],[[35,1],[30,[36,0],[\"ui/dropdown-button/button-component\"],null],[30,[36,0],[\"ui/dropdown-button/menu-component\"],null],[30,[36,0],[\"ui/dropdown-button/header-component\"],null],[30,[36,0],[\"ui/dropdown-button/divider-component\"],null],[30,[36,0],[\"ui/dropdown-button/menu-item\"],null],[30,[36,0],[\"ui/dropdown-button/custom-menu-item\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"isOpen\",\"hash\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui/dropdown-button/template.hbs"
    }
  });

  _exports.default = _default;
});