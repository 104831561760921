define("frontend/pods/outbound-voice-mail-recordings/outbound-voice-mail-recording/edit/route", ["exports", "frontend/mixins/modal", "frontend/pods/base/edit/route"], function (_exports, _modal, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var OutboundVoiceMailRecordingEditRoute = _route.default.extend(_modal.default, {
    afterSave: function afterSave() {
      return this.transitionTo('outbound-voice-mail-recordings.index');
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('outbound-voice-mail-recordings.index');
    },
    actions: {
      deleteModel: function deleteModel() {
        var _this = this;

        return this.modal.confirm('Are you sure you want to delete this message?').then(function (result) {
          if (result) {
            return _this.get('controller.model').destroyRecord().then(function () {
              return _this.transitionTo('outbound-voice-mail-recordings.index');
            });
          }
        });
      }
    }
  });

  var _default = OutboundVoiceMailRecordingEditRoute;
  _exports.default = _default;
});