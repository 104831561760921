define("frontend/pods/components/organization-dashboard-dials/component", ["exports", "frontend/models/lead", "frontend/models/organization", "frontend/models/outbound-call"], function (_exports, _lead, _organization, _outboundCall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationDashboardDialsComponent = Ember.Component.extend({
    organization: null,
    dashboard: null
  });
  var _default = OrganizationDashboardDialsComponent;
  _exports.default = _default;
});