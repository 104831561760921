define("frontend/pods/components/ui-spark-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UiSparkChartComponent = Ember.Component.extend({
    tagName: 'div',
    classNames: ['sparkline'],
    type: 'bar',
    // line, pie
    width: null,
    height: null,
    barWidth: 10,
    barSpacing: 8,
    zeroAxis: false,
    chartRangeMin: 0,
    defaultColor: '#4193d0',
    content: null,
    valuePath: 'value',
    labelPath: 'label',
    colorPath: 'color',
    values: Ember.computed('content', 'valuePath', function () {
      var path = this.valuePath;
      return (this.content || []).map(function (i) {
        return Ember.get(i, path);
      });
    }),
    labels: Ember.computed('content', 'labelPath', function () {
      var path = this.labelPath;
      return (this.content || []).map(function (i) {
        return Ember.get(i, path);
      });
    }),
    colors: Ember.computed('content', 'colorPath', 'defaultColor', function () {
      var path = this.colorPath;
      var defaultColor = this.defaultColor;
      return (this.content || []).map(function (i) {
        return Ember.get(i, path) || defaultColor;
      });
    }),
    _isInserted: false,
    _renderChart: function _renderChart() {
      var values = this.values || [];
      var options = this._chartOptions;

      if (this._isInserted) {
        return this.$().sparkline(values, options);
      }
    },
    _chartOptions: Ember.computed('width', 'height', 'type', 'colors', function () {
      var result = this.getProperties('width', 'height', 'type', 'barWidth', 'barSpacing', 'zeroAxis', 'chartRangeMin');
      result.colorMap = this.colors || ['#4193d0'];
      return result;
    }),
    _setIsInsertedOnInsert: Ember.on('didInsertElement', function () {
      return this.set('_isInserted', true);
    }),
    _setIsInsertedOnRemoval: Ember.on('willDestroyElement', function () {
      return this.set('_isInserted', false);
    }),
    _autoRenderOnChanges: Ember.observer('values', '_chartOptions', '_isInserted', function () {
      return this._renderChart();
    }),
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('values', '_chartOptions', '_isInserted');
    })
  });
  var _default = UiSparkChartComponent;
  _exports.default = _default;
});