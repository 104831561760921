define("frontend/models/phone-number-lookup", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    phoneNumber: (0, _model.attr)('string'),
    carrierType: (0, _model.attr)('string'),
    carrierName: (0, _model.attr)('string'),
    ownerType: (0, _model.attr)('string'),
    ownerName: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date')
  });

  _exports.default = _default;
});