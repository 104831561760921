define("frontend/pods/components/view/ui-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2mghG1iZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"title\"]]],null,[[\"default\"],[{\"statements\":[[10,\"h5\"],[14,0,\"strong innerAll border-bottom margin-none bg-gray\"],[12],[1,[32,0,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"innerAll\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/view/ui-box/template.hbs"
    }
  });

  _exports.default = _default;
});