define("frontend/pods/lead-phone-numbers/lead-phone-number/route", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadPhoneNumberRoute = Ember.Route.extend(_modal.default, {
    model: function model(params) {
      return this.store.findRecord('lead-phone-number', params.lead__phone_number_id, {
        backgroundReload: true
      });
    }
  });
  var _default = LeadPhoneNumberRoute;
  _exports.default = _default;
});