define("frontend/pods/components/soft-phone/button-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    softphone: Ember.inject.service(),
    tagName: '',
    connect: function connect() {
      this.softphone.connect();
    },
    disconnect: function disconnect() {
      this.softphone.disconnect();
    }
  });

  _exports.default = _default;
});