define("frontend/models/outbound-voice-mail-recording", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    user: (0, _model.belongsTo)('user'),
    status: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean', {
      default: false
    }),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    recording: (0, _model.belongsTo)('recording'),
    hasRecording: Ember.computed.bool('recordingId')
  });

  _exports.default = _default;
});