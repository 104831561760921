define("frontend/pods/components/lead-form/component", ["exports", "frontend/models/lead"], function (_exports, _lead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    statusValues: _lead.default.statusValues,
    actions: {
      statusChanged: function statusChanged(status, masterStatus, leadStatusName) {
        return this.model.setProperties({
          status: status,
          masterStatus: masterStatus,
          leadStatusName: leadStatusName
        });
      }
    }
  });

  _exports.default = _default;
});