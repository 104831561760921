define("frontend/pods/components/soft-phone/dial-pad/dial-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yelm/jrh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[\"letters\"],[[\"from\"],[\"frontend/pods/components/soft-phone/dial-pad/dial-button/styles\"]]]]]],[12],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"     \\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[[30,[36,1],[\"number\"],[[\"from\"],[\"frontend/pods/components/soft-phone/dial-pad/dial-button/styles\"]]]]]],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"letters\",\"local-class\",\"if\",\"digit\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/soft-phone/dial-pad/dial-button/template.hbs"
    }
  });

  _exports.default = _default;
});