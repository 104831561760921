define("frontend/pods/components/service-provider-fields-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ggEv/vRe",
    "block": "{\"symbols\":[\"type\",\"field\"],\"statements\":[[1,[30,[36,6],null,[[\"model\"],[[35,5]]]]],[2,\"\\n\\n\"],[10,\"h4\"],[12],[2,\"Field Display\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n  Set the order in which the fields appear in the lead's About box.\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"col-md-6 col-sm-12\"],[12],[2,\"\\n      \"],[10,\"h5\"],[12],[1,[32,1,[\"type\"]]],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"field-list\"],[12],[2,\"\\n        \"],[10,\"table\"],[14,0,\"table table-bordered table-primary table-padded\"],[12],[2,\"\\n          \"],[10,\"thead\"],[12],[2,\"\\n            \"],[10,\"th\"],[15,0,[31,[[30,[36,2],[\"header\"],[[\"from\"],[\"frontend/pods/components/service-provider-fields-edit/styles\"]]]]]],[12],[2,\"Field Name\"],[13],[2,\"\\n            \"],[10,\"th\"],[15,0,[31,[\"text-center \",[30,[36,2],[\"header\"],[[\"from\"],[\"frontend/pods/components/service-provider-fields-edit/styles\"]]]]]],[12],[2,\"Visible\"],[13],[2,\"\\n          \"],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,1,[\"fields\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],null,[[\"fieldType\",\"field\",\"fields\",\"onChange\"],[[32,1,[\"type\"]],[32,2],[32,1,[\"fields\"]],[30,[36,0],[[32,0],\"fieldsChanged\"],null]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"service-provider-fields-edit-row\",\"local-class\",\"-track-array\",\"each\",\"serviceProvider\",\"error-list\",\"availableFieldsByType\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/service-provider-fields-edit/template.hbs"
    }
  });

  _exports.default = _default;
});