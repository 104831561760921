define("frontend/pods/data-sources/data-source/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A1KzD4J5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"innerAll\"],[12],[2,\"\\n\"],[6,[37,7],null,[[\"title\"],[\"Edit Data Source\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,3],null,[[\"model\"],[[35,2]]]]],[2,\"\\n\\t\\t\"],[10,\"form\"],[12],[2,\"\\n\"],[6,[37,4],null,[[\"title\"],[\"DataSource Name\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,1],null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[35,0],\"form-control\",\"Enter the data source name\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,6],null,[[\"text\",\"isWaiting\",\"onClick\",\"icon\",\"isPrimary\"],[\"Save Changes\",[35,2,[\"isSaving\"]],[30,[36,5],[[32,0],\"save\"],null],\"save\",\"true\"]]]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,6],null,[[\"text\",\"onClick\"],[\"Cancel\",[30,[36,5],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"name\",\"input\",\"model\",\"error-list\",\"ui-form-field\",\"action\",\"ui-button\",\"ui-box\"]}",
    "meta": {
      "moduleName": "frontend/pods/data-sources/data-source/edit/template.hbs"
    }
  });

  _exports.default = _default;
});