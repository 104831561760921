define("frontend/pods/components/call-session-dial-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var CallSessionDialChartComponent = Ember.Component.extend({
    callSession: null,
    outboundCalls: null,
    width: null,
    height: null,
    startedAt: Ember.computed.readOnly('callSession.startedAt'),
    endedAt: Ember.computed.readOnly('callSession.endedAt'),
    _timestampToSeconds: function _timestampToSeconds(time, startTime) {
      if (time && startTime) {
        return Math.round((time - startTime) / 1000);
      }
    },
    outboundCallsByTimestamp: Ember.computed('startedAt', 'endedAt', 'outboundCalls.@each.startedAt', 'outboundCalls.@each.endedAt', function () {
      var _this = this;

      var outboundCalls = this.outboundCalls;
      var startedAt = this.startedAt; // determine the changes at each timestamp

      var times = [];
      var startTimes = outboundCalls.map(function (call) {
        return _this._timestampToSeconds(call.get('startedAt'), startedAt);
      });

      for (var _i = 0, _Array$from = Array.from(startTimes); _i < _Array$from.length; _i++) {
        var startTime = _Array$from[_i];

        if (!Ember.isEmpty(startTime)) {
          times.push([startTime, 1]);
        }
      }

      var endTimes = outboundCalls.map(function (call) {
        return _this._timestampToSeconds(call.get('callerEndedAt'), startedAt);
      });

      for (var _i2 = 0, _Array$from2 = Array.from(endTimes); _i2 < _Array$from2.length; _i2++) {
        var endTime = _Array$from2[_i2];

        if (!Ember.isEmpty(endTime)) {
          times.push([endTime, -1]);
        }
      } // convert the array to a hash of actual values (key = ISO timestamp, value = count)


      times = times.sort(function (a, b) {
        return a[0] - b[0];
      });
      var lastValue = 0;
      var result = {};

      for (var _i3 = 0, _Array$from3 = Array.from(times); _i3 < _Array$from3.length; _i3++) {
        var timestamp = _Array$from3[_i3];

        var _Array$from4 = Array.from(timestamp),
            _Array$from5 = _slicedToArray(_Array$from4, 2),
            time = _Array$from5[0],
            change = _Array$from5[1];

        lastValue += change;
        result[time] = lastValue;
      } // add the start and end points


      var endedAt = this.endedAt;

      if (startedAt) {
        if (!result[0]) {
          result[0] = 0;
        }
      }

      if (endedAt) {
        var name;

        if (!result[name = this._timestampToSeconds(endedAt, startedAt)]) {
          result[name] = lastValue;
        }
      }

      return result;
    }),
    conversations: Ember.computed.filterBy('outboundCalls', 'isConversation', true),
    conversationRegions: Ember.computed('startedAt', 'conversations.@each.conversationStartedAt', 'conversations.@each.endedAt', function () {
      var _this2 = this;

      if (this.get('callSession.user.isAgent')) {
        return [];
      }

      var startedAt = this.startedAt;
      return this.conversations.map(function (call) {
        var conversationStartedAt = _this2._timestampToSeconds(call.get('conversationStartedAt'), startedAt);

        var endedAt = _this2._timestampToSeconds(call.get('endedAt'), startedAt);

        return {
          axis: 'x',
          start: conversationStartedAt,
          end: endedAt,
          class: 'conversation'
        };
      });
    }),
    chartData: Ember.computed('outboundCallsByTimestamp', 'conversationRegions', function () {
      var startTime, time;
      var calls = this.outboundCallsByTimestamp; // ensure a sorted list since the values are getting destructured into two different arrays

      var times = [];

      for (var _i4 = 0, _Object$keys = Object.keys(calls || {}); _i4 < _Object$keys.length; _i4++) {
        time = _Object$keys[_i4];
        times.push(parseInt(time));
      } // haha, javascript sorts all array values by string by default


      times = times.sort(function (a, b) {
        return a - b;
      }); // the chart has time series capabilities, but not with step or area charts --
      // therefore the X axis should be set to the number of seconds,
      // and the Y axis is the number of dials

      var seconds = [];
      var values = [];
      var yAxisMax = 4;
      var ticks = [];

      if (!Ember.isEmpty(times)) {
        for (var _i5 = 0, _Array$from6 = Array.from(times); _i5 < _Array$from6.length; _i5++) {
          time = _Array$from6[_i5];
          seconds.push(time);
          values.push(calls[time]);
        }

        startTime = this.startedAt;
        yAxisMax = Math.max(Math.max.apply(Math, values), 4);
        var duration = seconds[seconds.length - 1] || 0;
        ticks = [0, 1, 2, 3, 4, 5].map(function (t) {
          return Math.round(duration * t / 6);
        });
      } // add the label as the first item in the array


      seconds.unshift('time');
      values.unshift('dials'); // create a function to format the value (in seconds) to time

      var formatSecondsFn = function formatSecondsFn(value, format) {
        var d = new Date(startTime);
        d.setSeconds(d.getSeconds() + value);
        var result = d3.time.format(format)(d); // strip off the leading 0 on the hour if necessary

        if (result[0] === '0') {
          result = result.substr(1);
        }

        return result;
      };

      return {
        data: {
          x: 'time',
          type: 'area-step',
          columns: [seconds, values]
        },
        line: {
          step: {
            type: 'step-after'
          }
        },
        size: {
          width: this.width || undefined,
          height: this.height || undefined
        },
        legend: {
          show: false
        },
        regions: this.conversationRegions,
        tooltip: {
          format: {
            title: function title(x) {
              return formatSecondsFn(x, '%I:%M:%S');
            }
          }
        },
        axis: {
          x: {
            tick: {
              values: ticks,
              format: function format(x) {
                return formatSecondsFn(x, '%I:%M');
              }
            }
          },
          y: {
            min: 0,
            max: yAxisMax,
            tick: {
              values: __range__(0, yAxisMax, true)
            }
          }
        }
      };
    })
  });
  var _default = CallSessionDialChartComponent;
  _exports.default = _default;

  function __range__(left, right, inclusive) {
    var range = [];
    var ascending = left < right;
    var end = !inclusive ? right : ascending ? right + 1 : right - 1;

    for (var i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
      range.push(i);
    }

    return range;
  }
});