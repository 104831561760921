define("frontend/pods/components/ui/hide-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    applicationState: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.applicationState.hideSidebar();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.applicationState.showSidebar();
    }
  });

  _exports.default = _default;
});