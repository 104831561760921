define("frontend/pods/data-sources/data-source/edit/route", ["exports", "frontend/pods/base/edit/route", "frontend/mixins/additional-models"], function (_exports, _route, _additionalModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var DataSourceEditRoute = _route.default.extend(_additionalModels.default, {
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      return this.additionalModelsFor('data-source').then(function () {
        var dataSourceController = _this.controllerFor('data-source');

        return controller.setProperties(dataSourceController.getProperties('leadLists', 'leadList'));
      });
    },
    updateLeadList: function updateLeadList() {
      var leadList = this.get('controller.leadList');

      if (!leadList) {
        return Ember.RSVP.resolve();
      }

      leadList.set('name', this.get('controller.model.name'));
      return leadList.save();
    },
    afterSave: function afterSave() {
      var _this2 = this;

      return this.updateLeadList().then(function () {
        return _this2.transitionTo('data-sources.data-source', _this2.get('controller.model'));
      }).then(null, function () {});
    },
    afterCancel: function afterCancel() {
      var leadList = this.get('controller.leadList');

      if (leadList) {
        leadList.rollbackAttributes();
      }

      return this.transitionTo('data-sources.data-source', this.get('controller.model'));
    }
  });

  var _default = DataSourceEditRoute;
  _exports.default = _default;
});