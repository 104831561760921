define("frontend/pods/components/phone-number/lookup-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dl8CYg5Y",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[15,0,[31,[[30,[36,3],[[35,2],\" \",[35,1]],null],\" \",[30,[36,0],[\"icon\"],[[\"from\"],[\"frontend/pods/components/phone-number/lookup-icon/styles\"]]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"iconClass\",\"class\",\"concat\",\"canLookup\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/phone-number/lookup-icon/template.hbs"
    }
  });

  _exports.default = _default;
});