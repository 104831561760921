define("frontend/pods/components/chat-conversations/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ChatConversationsComponent = Ember.Component.extend({
    chatService: Ember.inject.service('chat')
  });
  var _default = ChatConversationsComponent;
  _exports.default = _default;
});