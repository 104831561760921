define("frontend/pods/components/gravatar-image/component", ["exports", "frontend/utils/md5", "frontend/mixins/in-viewport"], function (_exports, _md, _inViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GravatarImageComponent = Ember.Component.extend(_inViewport.default, {
    tagName: 'img',
    size: 200,
    email: '',
    attributeBindings: ['src', 'width', 'height'],
    width: Ember.computed.oneWay('size'),
    height: Ember.computed.oneWay('size'),
    // use a standard blank gravatar prior to the element coming into view,
    // then convert it to the real gravatar once it comes into view
    src: Ember.computed('everInViewport', 'gravatarUrl', 'preloadGravatarUrl', function () {
      if (this.everInViewport) {
        return this.gravatarUrl;
      } else {
        return this.preloadGravatarUrl;
      }
    }),
    gravatarUrl: Ember.computed('email', 'size', function () {
      var email = this.email || "";
      var size = this.size; // the d parameter is the default image ("mystery man")

      return "https://www.gravatar.com/avatar/".concat((0, _md.default)(email), "?s=").concat(size, "&d=mm");
    }),
    preloadGravatarUrl: Ember.computed('size', function () {
      var size = this.size; // the d parameter is the default image ("mystery man")

      return "https://www.gravatar.com/avatar/".concat((0, _md.default)('null'), "?s=").concat(size, "&d=mm");
    })
  });
  var _default = GravatarImageComponent;
  _exports.default = _default;
});