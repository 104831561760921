define("frontend/pods/components/chat-conversation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Wd2Czr1",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[4,[38,5],[[32,0],\"markRead\"],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"chat-conversation-header\"],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"chat-conversation-name\"],[4,[38,5],[[32,0],\"toggleWindow\"],null],[12],[10,\"i\"],[14,0,\"fa fa-minus\"],[12],[13],[2,\" \"],[6,[37,7],null,[[\"title\"],[[35,6,[\"company_name\"]]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-building-o\"],[12],[13]],\"parameters\":[]}]]],[2,\" \"],[1,[35,6,[\"first_name\"]]],[2,\" \"],[1,[35,6,[\"last_name\"]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"chat-conversation-unread\"],[12],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-fw fa-envelope\"],[12],[13]],\"parameters\":[]}]]],[13],[13],[2,\"\\n    \"],[11,\"div\"],[24,0,\"chat-conversation-close\"],[4,[38,5],[[32,0],\"endConversation\",[35,6]],null],[12],[2,\"×\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,9],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"messages\"],[[35,0]]]]],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"chatService\",\"to\"],[[35,3],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"messages\",\"chat-messages\",\"userId\",\"chatService\",\"chat-input\",\"action\",\"conversation\",\"tool-tip\",\"unread\",\"if\",\"windowOpen\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/chat-conversation/template.hbs"
    }
  });

  _exports.default = _default;
});