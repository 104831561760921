define("frontend/pods/organizations/organization/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dhvTXYQH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-separator col-unscrollable col-separator-last\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-table\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll border-bottom\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h4\"],[14,0,\"margin-none\"],[12],[2,\"Edit Organization: \"],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\\t\\t\\t\"],[6,[37,1],null,[[\"route\",\"model\"],[\"organizations.organization.gong\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"Gong Setup\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[6,[37,1],null,[[\"route\",\"model\"],[\"organizations.organization.execvision\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"Execvision Setup\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[6,[37,1],null,[[\"route\",\"model\"],[\"organizations.organization.dynamics\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"MS Dynamics Setup\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll inner-2x\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],null,[[\"model\",\"externalDispositions\"],[[35,0],[35,0,[\"serviceProvider\",\"callDispositions\"]]]]]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],null,[[\"organization\",\"value\"],[[35,0],[35,3]]]]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,7],null,[[\"icon\",\"text\",\"isWaiting\",\"isPrimary\",\"onClick\"],[\"save\",\"Save Changes\",[35,0,[\"isSaving\"]],\"true\",[30,[36,6],[[32,0],[30,[36,5],[\"save\"],null]],null]]]]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,7],null,[[\"text\",\"onClick\"],[\"Cancel\",[30,[36,6],[[32,0],[30,[36,5],[\"cancel\"],null]],null]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"link-to\",\"partial/organization/form\",\"editableServiceApplicationOrganizations\",\"organization-service-applications-editor\",\"route-action\",\"action\",\"ui-button\"]}",
    "meta": {
      "moduleName": "frontend/pods/organizations/organization/edit/template.hbs"
    }
  });

  _exports.default = _default;
});