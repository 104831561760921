define("frontend/pods/components/agent/agent-console/current-call/actions/states/ended/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ended": "_ended_1srvf1",
    "container": "_container_1srvf1",
    "box": "_box_1srvf1",
    "agent-flagged": "_agent-flagged_1srvf1"
  };
  _exports.default = _default;
});