define("frontend/initializers/synchronize-data-sources-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;

  function initialize(app) {
    app.inject('route', 'synchronizeDataSourcesService', 'service:synchronize-data-sources');
  }

  ;
});