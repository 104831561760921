define("frontend/pods/components/ui-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y67zE+/o",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n\\t\"],[1,[34,4]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"i\"],[15,0,[34,0]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[1,[34,3]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"iconClass\",\"icon\",\"if\",\"text\",\"waitText\",\"isWaiting\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui-button/template.hbs"
    }
  });

  _exports.default = _default;
});