define("frontend/pods/components/view/paginated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "toHUbR2l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"isLoadingIndicatorVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"paginated-view-loading\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\" \"],[1,[32,0,[\"loadingText\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/view/paginated/template.hbs"
    }
  });

  _exports.default = _default;
});