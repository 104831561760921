define("frontend/services/synchronize-data-sources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    container: null,
    minutes: 5,
    authentication: Ember.inject.service(),
    loggedIn: Ember.computed.readOnly('authentication.loggedIn'),
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('loggedIn');
    }),
    store: Ember.inject.service(),
    synchronize: function synchronize() {
      var _this = this;

      if (this.isDestroyed) {
        return;
      }

      if (!this.loggedIn) {
        return;
      }

      console.log('synchronizing data sources');
      var adapter = this.store.adapterFor('application');
      return adapter.executeServerAction('data-source', null, 'synchronize').then(function () {
        return _this.queue();
      }).then(null, function () {
        return _this.queue();
      });
    },
    queue: function queue() {
      var delay = this.minutes * 60 * 1000;
      return Ember.run.debounce(this, this.synchronize, delay);
    },
    _queueOnInitialize: Ember.on('init', function () {
      return this.queue();
    }),
    _queueOnLogin: Ember.observer('loggedIn', function () {
      if (this.loggedIn) {
        return this.queue();
      }
    })
  });

  _exports.default = _default;
});