define("frontend/pods/lead-lists/lead-list/edit/route", ["exports", "frontend/pods/base/edit/route", "frontend/mixins/additional-models"], function (_exports, _route, _additionalModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LeadListEditRoute = _route.default.extend(_additionalModels.default, {
    additionalModels: function additionalModels() {
      return {
        dataSources: this.store.findAll('data-source'),
        hourOptions: [{
          label: "1 hour",
          value: 1
        }, {
          label: "2 hours",
          value: 2
        }, {
          label: "3 hours",
          value: 3
        }, {
          label: "4 hours",
          value: 4
        }, {
          label: "5 hours",
          value: 5
        }, {
          label: "1 day",
          value: 24
        }, {
          label: "2 days",
          value: 48
        }, {
          label: "3 days",
          value: 72
        }, {
          label: "4 days",
          value: 96
        }, {
          label: "5 days",
          value: 120
        }, {
          label: "1 week",
          value: 168
        }, {
          label: "2 weeks",
          value: 336
        }, {
          label: "1 month",
          value: 744
        }]
      };
    },
    afterSave: function afterSave() {
      return this.transitionTo('lead-lists.lead-list.index', this.get('controller.model'));
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('lead-lists.index');
    }
  });

  var _default = LeadListEditRoute;
  _exports.default = _default;
});