define("frontend/utils/time-updater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    timer: null,
    interval: 1000,
    updates: null,
    hasUpdates: Ember.computed.bool('updates.length'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('updates', []);
    },
    register: function register(component, property) {
      var updates = this.updates;

      if (updates.find(function (item) {
        return item.component === component && item.property === property;
      })) {
        return;
      }

      return updates.addObject({
        component: component,
        property: property
      });
    },
    unregister: function unregister(component, property) {
      var updates = this.updates;
      updates = updates.reject(function (item) {
        return item.component === component && item.property === property;
      });
      return this.set('updates', updates);
    },
    _createTimer: function _createTimer() {
      var _this = this;

      if (this.timer) {
        return;
      }

      if (!this.hasUpdates) {
        return;
      }

      var interval = this.interval;

      var fn = function fn() {
        return Ember.run(function () {
          return _this._performUpdates();
        });
      };

      return this.set('timer', setInterval(fn, interval));
    },
    _destroyTimer: function _destroyTimer() {
      var timer = this.timer;

      if (timer) {
        clearInterval(timer);
      }

      return this.set('timer', null);
    },
    _resetTimer: function _resetTimer() {
      this._destroyTimer();

      return this._createTimer();
    },
    // this should observe hasUpdates, but it does not work (https://github.com/emberjs/ember.js/issues/4368)
    _hasUpdatesChanged: Ember.observer('updates.length', function () {
      if (this.hasUpdates) {
        return this._createTimer();
      } else {
        return this._destroyTimer();
      }
    }),
    _intervalChanged: Ember.observer('interval', function () {
      return this._resetTimer();
    }),
    _performUpdates: function _performUpdates() {
      var time = new Date().getTime();
      return Array.from(this.updates).map(function (item) {
        return item.component.set(item.property, time);
      });
    }
  });

  _exports.default = _default;
});