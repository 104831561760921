define("frontend/pods/components/chat-messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "unrlcG7J",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[10,\"ul\"],[14,0,\"chat-conversation-messages\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[12],[10,\"b\"],[12],[1,[32,1,[\"first_name\"]]],[2,\":\"],[13],[2,\" \"],[1,[32,1,[\"text\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[10,\"li\"],[12],[2,\" \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"messages\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/chat-messages/template.hbs"
    }
  });

  _exports.default = _default;
});