define("frontend/pods/components/ui/dropdown-button/menu-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // The panel is the window that drops down
  var _default = Ember.Component.extend({
    position: 'left',
    tagName: '',
    positionClass: Ember.computed('position', {
      get: function get() {
        return "pull-".concat(this.position);
      }
    }),
    toggle: function toggle() {
      if (!this.isDestroyed) {
        var element = this.element;

        if (element) {
          element.$().dropdown('toggle');
        }
      }
    }
  });

  _exports.default = _default;
});