define("frontend/pods/components/draggable-element/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    draggable: false,
    droppable: false,
    dragContent: null,
    onDragStart: function onDragStart() {},
    onDragLeave: function onDragLeave() {},
    onDragEnd: function onDragEnd() {},
    onDrop: function onDrop() {},
    attributeBindings: ['draggable'],
    dragStart: function dragStart(e) {
      e.dataTransfer.setData('text/data', this.dragContent);
      return this.onDragStart(e);
    },
    dragLeave: function dragLeave(e) {
      return this.onDragLeave(e);
    },
    dragOver: function dragOver(e) {
      e.preventDefault();
      return !!this.droppable;
    },
    dragEnd: function dragEnd(e) {
      return this.onDragEnd(e);
    },
    drop: function drop(e) {
      var data = e.dataTransfer.getData('text/data');
      return this.onDrop(e, data);
    }
  });

  _exports.default = _default;
});