define("frontend/pods/organizations/organization/flagged-records/flagged-record/route", ["exports", "frontend/pods/base/edit/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LeadEditFlaggedRecordRoute = _route.default.extend({
    afterSave: function afterSave() {
      return this.transitionTo('organizations.organization.flagged-records', this.modelFor('organizations.organization'));
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('organizations.organization.flagged-records', this.modelFor('organizations.organization'));
    }
  });

  var _default = LeadEditFlaggedRecordRoute;
  _exports.default = _default;
});