define("frontend/utils/attachments-list", ["exports", "legendary-file-upload/utils/attachments-list"], function (_exports, _attachmentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _attachmentsList.default;
    }
  });
});