define("frontend/pods/components/call-lead-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallLeadListItemComponent = Ember.Component.extend({
    callService: null,
    lead: null,
    isInserted: true,
    isRemoved: false,
    isCurrent: false,
    tagName: '',
    startHidden: false,
    _isRendered: false,
    selectorElementId: Ember.computed('lead.id', function () {
      return "leads-list-lead-".concat(this.get('lead.id'));
    }),
    _animateInsert: function _animateInsert() {
      if (!this._isRendered) {
        return;
      }

      var id = this.selectorElementId;
      var el = window.$("li#".concat(id, ":hidden"));

      if (el) {
        el.css({
          height: 'auto',
          display: 'block',
          opacity: 0
        });
        el.animate({
          opacity: 1
        });
      }
    },
    _animateRemove: function _animateRemove() {
      if (!this._isRendered) {
        return;
      }

      var id = this.selectorElementId;
      var el = window.$("li#".concat(id, ":visible"));

      if (el) {
        el.animate({
          opacity: 0,
          height: 0
        }, 300, function () {
          return el.hide();
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('startHidden', !this.isInserted || this.isRemoved);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._isRendered = true;
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._isRendered = false;
    },
    _initializeAnimation: function _initializeAnimation() {
      var isInserted = this.isInserted;
      var isRemoved = this.isRemoved;

      if (isInserted === this._lastIsInserted && isRemoved === this._lastIsRemoved) {
        return;
      }

      this._lastIsInserted = isInserted;
      this._isRemoved = this._lastIsRemoved;

      if (isRemoved) {
        this._animateRemove();
      } else if (isInserted) {
        this._animateInsert();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._initializeAnimation();
    },
    actions: {
      doNotCall: function doNotCall(lead) {
        if (this.isCurrent) {
          return;
        }

        return lead.updateAttributes({
          doNotCall: true
        });
      },
      disableDoNotCall: function disableDoNotCall(lead) {
        if (this.isCurrent) {
          return;
        }

        return lead.updateAttributes({
          doNotCall: false
        });
      }
    }
  });
  var _default = CallLeadListItemComponent;
  _exports.default = _default;
});