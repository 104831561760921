define("frontend/pods/components/agent/agent-console/widget/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZTJZ2wC",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"body\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/widget/body/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/widget/body/template.hbs"
    }
  });

  _exports.default = _default;
});