define("frontend/pods/lead-lists/index/controller", ["exports", "frontend/utils/paginated"], function (_exports, _paginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadListsIndexController = Ember.Controller.extend({
    isClient: Ember.computed.equal('authenticationService.activeRole', 'client'),
    actions: {
      nextPage: function nextPage() {
        return this.model.nextPage();
      }
    }
  });
  var _default = LeadListsIndexController;
  _exports.default = _default;
});