define("frontend/pods/components/call-lead-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallLeadCardComponent = Ember.Component.extend({
    callService: null,
    // the lead is not necessarily just the connected lead, or else it will disappear immediately upon disconnect --
    // instead, the lead should be set upon connection but then set to null when the user is finished with him
    lead: null,
    connectedLead: Ember.computed.oneWay('callService.connectedLead'),
    isExecutingCommand: Ember.computed.oneWay('callService.isExecutingCommand'),
    canExecuteCommand: Ember.computed.not('isExecutingCommand'),
    isAgent: Ember.computed.alias('callService.authentication.isAgent'),
    hasConnectedLead: Ember.computed.bool('connectedLead'),
    isConnected: Ember.computed.oneWay('callService.isConnected'),
    canDropVoiceMail: Ember.computed.and('canExecuteCommand', 'isConnected'),
    canHangup: Ember.computed.and('canExecuteCommand', 'isConnected'),
    canUserTransfer: Ember.computed.and('hasConnectedLead', 'callService.canUserTransfer'),
    canTransfer: Ember.computed.and('canExecuteCommand', 'callService.canTransfer'),
    didTransfer: Ember.computed.bool('outboundCall.joinedAt'),
    canDial: Ember.computed.and('canExecuteCommand', 'callService.canDial'),
    _selectLeadUponConnection: Ember.observer('connectedLead', function () {
      var connectedLead = this.connectedLead;

      if (connectedLead) {
        return this.set('lead', connectedLead);
      }
    }),
    canRedial: Ember.computed('canExecuteCommand', 'canDial', 'lead', function () {
      return this.canExecuteCommand && this.canDial && !!this.lead;
    }),
    canDeselectLead: Ember.computed('lead', 'connectedLead', function () {
      return !!this.lead && !this.connectedLead;
    }),
    canSave: Ember.computed('lead', 'outboundCall', function () {
      return !!this.lead && !!this.outboundCall;
    }),
    deselectLead: function deselectLead() {
      if (this.canDeselectLead) {
        return this.setProperties({
          lead: null,
          outboundCall: null
        });
      }
    },
    // store the outbound call upon connection so that it can be referred to after hanging up
    outboundCall: null,
    connectedOutboundCall: Ember.computed.oneWay('callService.outboundCall'),
    _selectOutboundCallUponConnection: Ember.observer('connectedOutboundCall', function () {
      var call = this.connectedOutboundCall;

      if (call) {
        return this.set('outboundCall', call);
      }
    }),
    canSetStatus: Ember.computed.not('isAgent'),
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('connectedLead', 'hasConnectedLead', 'isConnected', 'canUserTransfer', 'canTransfer', 'connectedOutboundCall', 'outboundCall');
    }),
    actions: {
      callMe: function callMe() {
        return this.callService.callMe();
      },
      resume: function resume() {
        return this.callService.resume();
      },
      pause: function pause() {
        return this.callService.pause();
      },
      dialNext: function dialNext() {
        return this.callService.dialNext();
      },
      hangup: function hangup() {
        return this.callService.disconnect();
      },
      detectVoiceMail: function detectVoiceMail() {
        return this.callService.detectVoiceMail();
      },
      dropVoiceMail: function dropVoiceMail() {
        return this.callService.dropVoiceMail();
      },
      transfer: function transfer() {
        return this.callService.transfer();
      },
      getInboundCredentials: function getInboundCredentials() {
        return this.callService.getInboundCredentials();
      },
      redial: function redial() {
        // agents cannot dial any random lead via dialLead(), but they can redial() --
        // however, redial() will redial the lead the user last dialed, not a transfer,
        // so clients should dial back the last lead, regardless of who dialed it
        if (this.isAgent) {
          return this.callService.redial();
        } else {
          return this.callService.dialLead(this.lead);
        }
      },
      save: function save() {
        var _this = this;

        return this.outboundCall.saveChanges().then(function () {
          return _this.lead.saveChangesFromOutboundCall(_this.outboundCall, _this.isAgent, _this.isConnected);
        }).then(function () {
          return _this.callService.releaseOutboundCall(_this.outboundCall).catch(function () {// the release of the call failed, likely because the client attempted
            // to release the call prior to the call ending -- it's okay, just silently ignore
          });
        }).then(function () {
          return _this.deselectLead();
        });
      }
    }
  });
  var _default = CallLeadCardComponent;
  _exports.default = _default;
});