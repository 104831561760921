define("frontend/pods/components/auto-url/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AutoUrlComponent = Ember.Component.extend({
    value: null,
    tagName: 'span',
    target: null,
    _urlRegex: /^https?:\/\/.+$/i,
    _noProtocolRegex: /^www\..+$/i,
    url: Ember.computed('value', function () {
      var v = (this.value || '').toString();

      if (this._urlRegex.test(v)) {
        return v;
      }

      if (this._noProtocolRegex.test(v)) {
        return "http://".concat(v);
      }

      return null;
    }),
    isUrl: Ember.computed.bool('url')
  });
  var _default = AutoUrlComponent;
  _exports.default = _default;
});