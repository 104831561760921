define("frontend/pods/components/organization-phone-number/list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "statistics": "_statistics_1i6112",
    "phone-numbers-table": "_phone-numbers-table_1i6112",
    "loading": "_loading_1i6112"
  };
  _exports.default = _default;
});