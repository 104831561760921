define("frontend/pods/components/agent/agent-console/states/offline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OUV8LNpH",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"offline\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/offline/styles\"]]]]]],[17,1],[12],[2,\"\\n\\n  \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"title\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/offline/styles\"]]]]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"call\",\"status\"]],\"initializing\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Here we go...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Ready to get started?\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"btn btn-lg btn-success\"],[4,[38,4],[[32,0],[30,[36,3],[[32,0,[\"connect\"]]],null]],null],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,5],[[32,0,[\"connect\",\"isRunning\"]],[30,[36,1],[[32,0,[\"call\",\"status\"]],\"initializing\"],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-fw fa-spinner fa-spin\"],[12],[13],[2,\" Starting session...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-fw fa-play-circle\"],[12],[13],[2,\" Start a session\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"eq\",\"if\",\"perform\",\"action\",\"or\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/states/offline/template.hbs"
    }
  });

  _exports.default = _default;
});