define("frontend/pods/components/agent/agent-console/states/idle/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "idle": "_idle_5ndmh8",
    "description": "_description_5ndmh8",
    "duration": "_duration_5ndmh8",
    "time": "_time_5ndmh8",
    "actions": "_actions_5ndmh8"
  };
  _exports.default = _default;
});