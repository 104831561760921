define("frontend/pods/components/phone-number/lookup-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    tagName: '',
    authentication: Ember.inject.service(),
    store: Ember.inject.service(),
    isAdmin: Ember.computed.readOnly('authentication.isAdmin'),
    canLookup: Ember.computed.readOnly('isAdmin'),
    model: Ember.computed('value', 'canLookup', {
      get: function get() {
        if (this.value && this.canLookup) {
          var result = this.store.peekRecord('phone-number-lookup', this.value);

          if (!result) {
            result = this.store.findRecord('phone-number-lookup', this.value);
          }

          return result;
        }
      }
    }),
    iconClass: Ember.computed('model.carrierType', {
      get: function get() {
        var carrierType = this.get('model.carrierType');

        if (carrierType === 'mobile') {
          return 'fa fa-fw fa-mobile';
        } else if (carrierType === 'landline') {
          return 'fa fa-fw fa-phone-square';
        } else if (carrierType === 'voip') {
          return 'fa fa-fw fa-server';
        } else {
          return 'fa fa-fw fa-phone';
        }
      }
    }),
    tooltip: Ember.computed('model.carrierType', {
      get: function get() {
        var carrierType = this.get('model.carrierType');

        if (carrierType === 'mobile') {
          return 'Confirmed Mobile #';
        } else if (carrierType === 'landline') {
          return 'Confirmed Landline #';
        } else if (carrierType === 'voip') {
          return 'Confirmed VoIP #';
        } else {
          return '';
        }
      }
    })
  });

  _exports.default = _default;
});