define("frontend/pods/components/soft-phone/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    softphone: Ember.inject.service(),
    tagName: '',
    digits: Ember.computed('service.digits.[]', {
      get: function get() {
        return (this.softphone.digits || []).join('');
      }
    })
  });

  _exports.default = _default;
});