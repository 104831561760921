define("frontend/pods/components/paginated-lead-list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lead": "_lead_ncwymq",
    "name": "_name_ncwymq"
  };
  _exports.default = _default;
});