define("frontend/services/alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Alert = Ember.Object.extend({
    type: null,
    message: null,
    dismissIn: 10,
    // 10 seconds by default
    persistent: false,
    hasCloseButton: true,
    isDismissed: false,
    route: null
  });

  var _default = Ember.Service.extend({
    alerts: Ember.computed(function () {
      return [];
    }),
    info: function info(message, options) {
      if (options == null) {
        options = {};
      }

      return this._createAlert('primary', message, options);
    },
    warning: function warning(message, options) {
      if (options == null) {
        options = {};
      }

      return this._createAlert('warning', message, options);
    },
    success: function success(message, options) {
      if (options == null) {
        options = {};
      }

      return this._createAlert('success', message, options);
    },
    danger: function danger(message, options) {
      if (options == null) {
        options = {};
      }

      return this._createAlert('danger', message, options);
    },
    error: function error(message, options) {
      if (options == null) {
        options = {};
      }

      return this._createAlert('danger', message, options);
    },
    _createAlert: function _createAlert(type, message, options) {
      if (options == null) {
        options = {};
      }

      options.type = type;
      options.message = message;
      var alert = Alert.create(options);
      return this.alerts.pushObject(alert);
    },
    dismiss: function dismiss(alert) {
      this.alerts.removeObject(alert);
      return alert.set('isDismissed', true);
    }
  });

  _exports.default = _default;
});