define("frontend/pods/components/lead-status-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H3RoOq+I",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"classNames\",\"value\",\"content\",\"optionLabelPath\"],[\"form-control\",[35,1],[35,0],\"content.text\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"form-control\"],[12],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"options\",\"selectedValue\",\"ui-select\",\"isReady\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/lead-status-select/template.hbs"
    }
  });

  _exports.default = _default;
});