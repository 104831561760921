define("frontend/pods/components/agent/agent-console/current-call/actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4U0h7An",
    "block": "{\"symbols\":[\"componentName\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"agent/agent-console/mute-state-banner\",[[16,0,[31,[[30,[36,0],[\"mute-banner\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/styles\"]]]]]]],[[],[]],null],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],[[\"use\",\"duration\"],[[35,3],200]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[32,1]],[[\"states\",\"events\",\"onTrigger\"],[[32,0,[\"states\"]],[32,0,[\"events\"]],[30,[36,1],[[32,0],[32,0,[\"trigger\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,6],[[32,0,[\"actionStateObject\",\"notes\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"agent/agent-console/notes\",[[16,0,[31,[[30,[36,0],[\"notes\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/styles\"]]]]]]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"action\",\"component\",\"transition\",\"componentName\",\"animated-value\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/current-call/actions/template.hbs"
    }
  });

  _exports.default = _default;
});