define("frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/unknown-person/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UeEM64BS",
    "block": "{\"symbols\":[\"@states\",\"@events\",\"@onTrigger\"],\"statements\":[[8,\"agent/agent-console/current-call/actions/states/other\",[],[[\"@states\",\"@events\",\"@onTrigger\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/unknown-person/template.hbs"
    }
  });

  _exports.default = _default;
});