define("frontend/pods/components/tag-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1e6mbkjV",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"span\"],[14,0,\"label label-info\"],[12],[2,\"\\n\\t\\t\"],[11,\"a\"],[24,0,\"tag-remove\"],[24,6,\"\"],[4,[38,0],[[32,0],\"remove\",[32,1]],null],[12],[2,\"×\"],[13],[2,\"\\n\\t\\t\"],[1,[32,1]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[1,[30,[36,6],null,[[\"placeholder\",\"value\",\"insert-newline\",\"focus-out\"],[[35,5],[35,4],\"add\",\"add\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"-track-array\",\"each\",\"newTag\",\"placeholderText\",\"input\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/tag-editor/template.hbs"
    }
  });

  _exports.default = _default;
});