define("frontend/pods/organizations/organization/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationRoute = Ember.Route.extend({
    requiresLogin: true,
    // customize to allow client managers to see their own organization and clients to see their stats
    authorizeRole: function authorizeRole(transition) {
      var organizationId;
      var params = this.toTransitionParams(transition);
      console.log('transition: ', params, transition);
      var role = this.get('authenticationService.activeRole'); // allow admins

      if (role === 'admin') {
        return true;
      } // allow client managers for their own organization


      if (role === 'client_manager') {
        organizationId = this.get('authenticationService.activeUser.organizationId').toString();
        return params.organization_id === organizationId;
      }

      if (role === 'team_manager') {
        organizationId = this.get('authenticationService.activeUser.organizationId').toString();
        return params.organization_id === organizationId;
      }

      if (role === 'client') {
        organizationId = this.get('authenticationService.activeUser.organizationId').toString();
        return params.organization_id === organizationId;
      }

      return false;
    },
    model: function model(params) {
      return this.store.find('organization', params.organization_id);
    }
  });
  var _default = OrganizationRoute;
  _exports.default = _default;
});