define("frontend/pods/components/paginated-lead-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PaginatedLeadListComponent = Ember.Component.extend({
    content: null,
    callService: null,
    actions: {
      dial: function dial(lead) {
        var leadList = this.leadList;
        return this.callService.dialLead(lead, leadList);
      },
      doNotCall: function doNotCall(lead) {
        return lead.updateAttributes({
          doNotCall: true
        });
      },
      disableDoNotCall: function disableDoNotCall(lead) {
        return lead.updateAttributes({
          doNotCall: false
        });
      },
      nextPage: function nextPage() {
        return this.content.nextPage();
      }
    }
  });
  var _default = PaginatedLeadListComponent;
  _exports.default = _default;
});