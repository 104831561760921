define("frontend/models/service-provider", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ServiceProvider = _model.default.extend({
    provider: (0, _model.attr)('string'),
    providerId: (0, _model.attr)('string'),
    providerDisplayId: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    settings: (0, _model.attr)(),
    isAuthenticated: Ember.computed.equal('status', 'authenticated'),
    isUnauthenticated: Ember.computed.equal('status', 'unauthenticated'),
    isAuthenticationFailed: Ember.computed.equal('status', 'authentication_failed'),
    providerName: Ember.computed('provider', function () {
      return ServiceProvider.providers[this.provider];
    }),
    statusName: Ember.computed('status', function () {
      return ServiceProvider.statuses[this.status];
    }),
    leadStatuses: Ember.computed.readOnly('settings.lead_statuses'),
    leadStatusMap: Ember.computed.readOnly('settings.lead_status_map'),
    callDispositions: Ember.computed.readOnly('settings.call_dispositions'),
    getStatusFromMasterStatus: function getStatusFromMasterStatus(masterStatus) {
      var result;
      var map = this.leadStatusMap;

      if (map && map[masterStatus]) {
        result = map[masterStatus].status;
      }

      if (result) {
        return result;
      }

      var statuses = this.leadStatuses;
      var status = statuses.findBy('name', masterStatus);

      if (status) {
        return status.maps_to;
      }

      return 'open';
    }
  });

  ServiceProvider.reopenClass({
    statuses: {
      authenticated: 'authenticated',
      unauthenticated: 'unauthenticated',
      authentication_failed: 'authentication failed'
    },
    providers: {
      salesforce: 'Salesforce',
      azure_oauth2: 'Dynamics',
      hubspot: 'Hubspot',
      outreach: 'Outreach',
      salesloft: 'Salesloft',
      zoho: 'Zoho',
      pipedrive: 'Pipedrive'
    }
  });
  var _default = ServiceProvider;
  _exports.default = _default;
});