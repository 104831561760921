define("frontend/pods/components/agent/current-call/lead-info/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "lead": "_lead_1amg4m",
    "name": "_name_1amg4m",
    "title": "_title_1amg4m",
    "company": "_company_1amg4m",
    "phone": "_phone_1amg4m"
  };
  _exports.default = _default;
});