define("frontend/pods/components/ui/dropdown-button/header-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HeaderComponent = Ember.Component.extend({
    title: null,
    tagName: ''
  });
  HeaderComponent.reopenClass({
    positionalParams: ['title']
  });
  var _default = HeaderComponent;
  _exports.default = _default;
});