define("frontend/pods/components/ui/tabs-component/tab-header/component", ["exports", "frontend/pods/components/ui/tabs-component/tab-header/styles"], function (_exports, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    name: null,
    activeTabName: null,
    onActivate: function onActivate() {},
    classNames: ['tab-header'],
    classNameBindings: ['activeClass'],
    localClassNames: ['tab-header'],
    isActive: Ember.computed('name', 'activeTabName', {
      get: function get() {
        var name = this.get('name');

        if (!name) {
          return true;
        }

        var activePageName = this.get('activeTabName');
        return name === activePageName;
      }
    }),
    activeClass: Ember.computed('isActive', {
      get: function get() {
        if (this.get('isActive')) {
          return _styles.default['active'];
        }
      }
    }),
    click: function click() {
      this._super.apply(this, arguments);

      this.onActivate(this.get('name'));
    }
  });

  _exports.default = _default;
});