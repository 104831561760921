define("frontend/pods/components/detect-voice-mail-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DetectVoiceMailModalComponent = Ember.Component.extend({
    callService: Ember.inject.service('call'),
    router: Ember.inject.service(),
    isDetectingVoiceMail: Ember.computed.oneWay('callService.isDetectingVoiceMail'),
    isAgentRoute: Ember.computed('router.currentRouteName', {
      get: function get() {
        return this.router.currentRouteName === 'agent';
      }
    }),
    isShowingModal: Ember.computed('isDetectingVoiceMail', 'isAgentRoute', {
      get: function get() {
        return this.isDetectingVoiceMail && !this.isAgentRoute;
      }
    }),
    actions: {
      leaveVoicemail: function leaveVoicemail() {
        return this.callService.dropVoiceMail();
      },
      cancel: function cancel() {
        return this.callService.cancelVoiceMail();
      }
    }
  });
  var _default = DetectVoiceMailModalComponent;
  _exports.default = _default;
});