define("frontend/pods/components/soft-phone/button-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m2Fs9DE3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,6],[\"button-bar\"],[[\"from\"],[\"frontend/pods/components/soft-phone/button-bar/styles\"]]]]]],[12],[2,\"\\n\"],[6,[37,3],[[35,2,[\"isOnline\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,4]],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[4,[38,1],[[32,0],[35,5]],null],[12],[10,\"i\"],[14,0,\"fa fa-fw fa-power-off\"],[12],[13],[2,\" Disconnect\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2,[\"isOffline\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn btn-success\"],[4,[38,1],[[32,0],[35,0]],null],[12],[10,\"i\"],[14,0,\"fa fa-fw fa-power-off\"],[12],[13],[2,\" Connect\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"connect\",\"action\",\"softphone\",\"if\",\"soft-phone/mute-button\",\"disconnect\",\"local-class\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/soft-phone/button-bar/template.hbs"
    }
  });

  _exports.default = _default;
});