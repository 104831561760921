define("frontend/pods/components/system-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SunkWT90",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1,[\"hasCloseButton\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"close\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"dismiss\"],null],[12],[2,\"×\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[2,[35,1,[\"message\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"alert\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/system-alert/template.hbs"
    }
  });

  _exports.default = _default;
});