define("frontend/pods/components/oauth-authentication/component", ["exports", "frontend/utils/oauth-authentication"], function (_exports, _oauthAuthentication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OauthAuthenticationComponent = Ember.Component.extend({
    provider: null,
    providerDisplayName: null,
    providerId: null,
    serviceProviderId: null,
    providerDisplayId: null,
    model: null,
    headerData: null,
    command: null,
    onAuthenticated: function onAuthenticated() {},
    onError: function onError() {},
    onCancel: function onCancel() {},
    status: Ember.computed.oneWay('model.state'),
    isUnauthenticated: Ember.computed.oneWay('model.isUnauthenticated'),
    isAuthenticating: Ember.computed.oneWay('model.isAuthenticating'),
    isAuthenticated: Ember.computed.oneWay('model.isAuthenticated'),
    isCanceled: Ember.computed.oneWay('model.isCanceled'),
    isFailed: Ember.computed.oneWay('model.isFailed'),
    isError: Ember.computed.oneWay('model.isError'),
    didAuthenticateDifferentAccount: Ember.computed.oneWay('model.didAuthenticateDifferentAccount'),
    isComplete: Ember.computed.oneWay('model.isComplete'),
    _initializeProperties: Ember.on('init', function () {
      return this._initializeModel();
    }),
    _rejectPromiseOnDestroy: Ember.on('willDestroyElement', function () {
      var deferred = this.get('model.deferred');

      if (deferred) {
        return deferred.reject();
      }
    }),
    _initializeModel: function _initializeModel() {
      var model = _oauthAuthentication.default.create(this.getProperties('provider', 'providerId', 'headerData', 'command'));

      this.set('model', model);
      return model;
    },
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        if (this.isAuthenticating) {
          return this.get('model.promise');
        }

        var model = this._initializeModel();

        return model.authenticate().then(function () {
          return _this.onAuthenticated(model);
        }).catch(function (e) {
          return _this.onError({
            model: model,
            error: e
          });
        });
      },
      cancel: function cancel() {
        var model = this.model;

        if (model) {
          model.cancel();
        }

        return this.onCancel();
      }
    }
  });
  var _default = OauthAuthenticationComponent;
  _exports.default = _default;
});