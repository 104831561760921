define("frontend/pods/components/agent/agent-console/mute-state-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e9mamYRy",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,1],[\"alert text-center \",[30,[36,0],[[32,0,[\"isMuted\"]],\"alert-inverse text-success\",\"alert-danger animated pulse\"],null]],null],\" \",[30,[36,2],[[30,[36,1],[\"alert \",[30,[36,0],[[32,0,[\"isMuted\"]],\"muted\",\"unmuted\"],null]],null]],[[\"from\"],[\"frontend/pods/components/agent/agent-console/mute-state-banner/styles\"]]]]]],[17,1],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isMuted\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-fw fa-microphone-slash\"],[12],[13],[2,\"\\n    You are muted\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-fw fa-microphone\"],[12],[13],[2,\"\\n    YOU ARE UNMUTED\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"local-class\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/mute-state-banner/template.hbs"
    }
  });

  _exports.default = _default;
});