define("frontend/pods/components/ui/tabs-component/tab-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    name: null,
    activeTabName: null,
    isActive: Ember.computed('name', 'activeTabName', {
      get: function get() {
        var name = this.get('name');

        if (!name) {
          return true;
        }

        var activePageName = this.get('activeTabName');
        return name === activePageName;
      }
    }),
    isVisible: Ember.computed.readOnly('isActive')
  });

  _exports.default = _default;
});