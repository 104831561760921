define("frontend/pods/components/ui/dropdown-button/button-component/component", ["exports", "frontend/mixins/tool-tip"], function (_exports, _toolTip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_toolTip.default, {
    tagName: 'a',
    iconClass: null,
    title: null,
    caret: true,
    classNames: ['btn'],
    classNameBindings: ['caret:dropdown-toggle'],
    href: '',
    'data-toggle': 'dropdown',
    attributeBindings: ['data-toggle', 'href']
  });

  _exports.default = _default;
});