define("frontend/pods/components/call-lead-info/component", ["exports", "frontend/models/lead", "moment"], function (_exports, _lead, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallLeadInfoComponent = Ember.Component.extend({
    lead: null,
    wasAgentFlagged: Ember.computed('lead', function () {
      return this.get('lead.agentFlagged');
    }),
    statusDisplayValue: Ember.computed.readOnly('lead.displayStatus'),
    onHoldUntilDisplayValue: Ember.computed('lead.onHoldUntil', function () {
      var onHoldUntil = this.get('lead.onHoldUntil');

      if (onHoldUntil) {
        return (0, _moment.default)(onHoldUntil).format('LL');
      }

      return "(any time)";
    }),
    actions: {
      leadStatusChanged: function leadStatusChanged(status, masterStatus, leadStatusName) {
        return this.lead.setProperties({
          status: status,
          masterStatus: masterStatus,
          leadStatusName: leadStatusName
        });
      }
    }
  });
  var _default = CallLeadInfoComponent;
  _exports.default = _default;
});