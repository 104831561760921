define("frontend/models/lead-list", ["exports", "@ember-data/model", "frontend/utils/paginated"], function (_exports, _model, _paginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean', {
      default: false
    }),
    // `filter` is a keyword in active model serializers -- must use a differet term
    filterInfo: (0, _model.attr)(),
    filter: Ember.computed.alias('filterInfo'),
    //leads: DS.hasMany 'lead', async: true
    user: (0, _model.belongsTo)('user'),
    organization: (0, _model.belongsTo)('organization'),
    leadCount: (0, _model.attr)('number'),
    leadListOpenCount: (0, _model.attr)('number'),
    leadListConversationCount: (0, _model.attr)('number'),
    leadListAppointmentCount: (0, _model.attr)('number'),
    leadListFlaggedCount: (0, _model.attr)('number'),
    leadListDoNotCallCount: (0, _model.attr)('number'),
    leadListRemainingCount: (0, _model.attr)('number'),
    userId: (0, _model.attr)('string'),
    organizationId: (0, _model.attr)('number'),
    createdAt: (0, _model.attr)('date'),
    deletedAt: (0, _model.attr)('date'),
    dataSourceId: (0, _model.attr)('number'),
    lastDialed: (0, _model.attr)('date'),
    statsRefreshed: (0, _model.attr)('date'),
    refreshing: (0, _model.attr)('boolean', {
      default: false
    }),
    hoursBetweenDials: (0, _model.attr)('number'),
    hoursBetweenVoicemails: (0, _model.attr)('number'),
    maxNumberOfDials: (0, _model.attr)('number'),
    userName: (0, _model.attr)('string'),
    isFollowUpList: (0, _model.attr)('boolean'),
    isReferralList: (0, _model.attr)('boolean'),
    leads: Ember.computed('filter', {
      get: function get() {
        // return the existing list if the filter data has not changed (even though the filter object may have)
        var queryFilter;

        if (this.filter) {
          queryFilter = JSON.stringify(this.filter);
        }

        var lastQueryFilter = this._lastQueryFilter;
        var lastLeads = this._leads;

        if (lastLeads && queryFilter === lastQueryFilter) {
          return lastLeads;
        } // if it made it this far, the leads need to be requested


        var query = {
          lead_list_id: this.id,
          sort_by: "last_name,first_name"
        };
        query.filter = queryFilter;

        var result = _paginated.default.Array.find('lead', {
          store: this.store,
          pageSize: 50,
          query: query
        });

        this.set('_leads', result);
        this.set('_lastQueryFilter', queryFilter);
        return result;
      }
    }),
    refreshLeads: function refreshLeads() {
      this.set('_leads', null);
      return this.notifyPropertyChange('leads');
    },
    // just mark it as deleted since we still reuse it
    destroyRecord: function destroyRecord() {
      return this.markRecordAsDeleted();
    }
  });

  _exports.default = _default;
});