define("frontend/pods/components/recording-player/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S86UutVq",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,8]],[2,\"\\n\\n\"],[6,[37,10],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[[34,6],\" \",[30,[36,5],[\"pause\"],[[\"from\"],[\"frontend/pods/components/recording-player/styles\"]]]]]],[4,[38,0],[[32,0],\"pause\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-fw fa-pause\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[[34,6],\" \",[30,[36,5],[\"play\"],[[\"from\"],[\"frontend/pods/components/recording-player/styles\"]]]]]],[4,[38,0],[[32,0],\"play\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[34,7]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,10],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"min\",\"max\",\"value\",\"step\",\"onChange\",\"onMouseDown\",\"onMouseUp\"],[\"0\",[35,1,[\"duration\"]],[35,1,[\"position\"]],\"0.1\",[30,[36,0],[[32,0],\"positionChanged\"],null],[30,[36,0],[[32,0],\"sliderMouseDown\"],null],[30,[36,0],[[32,0],\"sliderMouseUp\"],null]]]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"label label-default\"],[12],[1,[34,3]],[2,\" / \"],[1,[34,4]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"audioService\",\"input-slider\",\"position\",\"durationDisplay\",\"local-class\",\"buttonClasses\",\"playIconClass\",\"title\",\"isPlaying\",\"if\",\"isLoaded\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/recording-player/template.hbs"
    }
  });

  _exports.default = _default;
});