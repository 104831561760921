define("frontend/pods/components/area-chart-over-time/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AreaChartOverTimeComponent = Ember.Component.extend({
    data: null,
    data2: null,
    valueTitle: null,
    valueTitle2: null,
    yaxis: null,
    _round: function _round(val) {
      if (val) {
        return Math.round(val * 10) / 10;
      } else {
        return 0;
      }
    },
    dialsOverTimeChartData: Ember.computed('data', 'dashboard.params', function () {
      var count, d, date, dateFormat, t, ticks, time, v;
      var dialsOverTime = this.get('data.items') || [];
      var data2OverTime = this.get('data2.items') || [];
      var interval = this.get('data.interval');
      var isDayInterval = interval === 'day' || interval === 'week' || interval === 'month';
      var interval2 = this.get('data2.interval');
      var isDayInterval2 = interval2 === 'day' || interval === 'week' || interval === 'month';
      var dialsHash = {};

      for (var _i = 0, _Array$from = Array.from(dialsOverTime); _i < _Array$from.length; _i++) {
        d = _Array$from[_i];
        // parse the ISO format
        time = Date.parse(d.time); // the time parsing assumes UTC, which is great for times but not for just dates,
        // since it assumes midnight UTC.  In this case, add the timezone offset
        // to make it midnight local time.

        if (isDayInterval) {
          date = new Date(d.time);
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
          time = date.valueOf();
        }

        dialsHash[time] = d.count;
      }

      var data2Hash = {};

      for (var _i2 = 0, _Array$from2 = Array.from(data2OverTime); _i2 < _Array$from2.length; _i2++) {
        d = _Array$from2[_i2];
        // parse the ISO format
        time = Date.parse(d.time); // the time parsing assumes UTC, which is great for times but not for just dates,
        // since it assumes midnight UTC.  In this case, add the timezone offset
        // to make it midnight local time.

        if (isDayInterval) {
          date = new Date(d.time);
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
          time = date.valueOf();
        }

        data2Hash[time] = d.count;
      } // insert a zero value before and after each date if necessary


      var intervalFn = 'Date';
      var intervalAmount = 1;

      switch (interval) {
        case 'hour':
          intervalFn = 'Hours';
          break;

        case 'week':
          intervalFn = 'Date';
          intervalAmount = 7;
          break;

        case 'month':
          intervalFn = 'Month';
          break;
      }

      var times = [];

      for (var _i3 = 0, _Object$keys = Object.keys(dialsHash || {}); _i3 < _Object$keys.length; _i3++) {
        t = _Object$keys[_i3];
        v = dialsHash[t];
        times.push(parseInt(t));
      }

      times = times.sort();

      for (var _i4 = 0, _Array$from3 = Array.from(times); _i4 < _Array$from3.length; _i4++) {
        t = _Array$from3[_i4];

        if (dialsHash[t] !== 0) {
          var name, name1;
          var dateBefore = new Date(t);
          dateBefore["set".concat(intervalFn)](dateBefore["get".concat(intervalFn)]() - intervalAmount);

          if (!dialsHash[name = dateBefore.valueOf()]) {
            dialsHash[name] = 0;
          }

          var dateAfter = new Date(t);
          dateAfter["set".concat(intervalFn)](dateAfter["get".concat(intervalFn)]() + intervalAmount);

          if (!dialsHash[name1 = dateAfter.valueOf()]) {
            dialsHash[name1] = 0;
          }
        }
      }

      var times2 = [];

      for (var _i5 = 0, _Object$keys2 = Object.keys(dialsHash || {}); _i5 < _Object$keys2.length; _i5++) {
        t = _Object$keys2[_i5];
        v = dialsHash[t];
        times2.push(parseInt(t));
      }

      times2 = times2.sort();

      for (var _i6 = 0, _Array$from4 = Array.from(times2); _i6 < _Array$from4.length; _i6++) {
        t = _Array$from4[_i6];

        if (!data2Hash[t]) {
          var name2;
          date = new Date(t);

          if (!data2Hash[name2 = date.valueOf()]) {
            data2Hash[name2] = 0;
          }
        }
      }

      var augmented = [];

      for (var _i7 = 0, _Object$keys3 = Object.keys(dialsHash || {}); _i7 < _Object$keys3.length; _i7++) {
        time = _Object$keys3[_i7];
        count = dialsHash[time];
        augmented.push({
          time: parseInt(time),
          count: count
        });
      }

      augmented = augmented.sortBy('time');
      var augmented2 = [];

      for (var _i8 = 0, _Object$keys4 = Object.keys(data2Hash || {}); _i8 < _Object$keys4.length; _i8++) {
        time = _Object$keys4[_i8];
        count = data2Hash[time];
        augmented2.push({
          time: parseInt(time),
          count: count
        });
      }

      augmented2 = augmented2.sortBy('time'); // remove the "bookend" zeroes that were added in the previous step

      augmented.shift();
      augmented.pop();
      augmented2.shift();
      augmented2.pop();
      times = augmented.map(function (i) {
        return i.time;
      });
      var values = augmented.map(function (i) {
        return i.count;
      });
      times2 = augmented2.map(function (i) {
        return i.time;
      });
      var values2 = augmented2.map(function (i) {
        return i.count;
      });
      var startTime = this.get('dashboard.params.start_time');

      if (startTime) {
        startTime = Date.parse(startTime);
      }

      var endTime = this.get('dashboard.params.end_time');
      endTime = endTime ? Date.parse(endTime) : new Date().valueOf();
      var s = startTime || times[0];

      if (s) {
        var duration = endTime - s; // equal intervals, rounded to the nearest second

        var roundTo = 1000 * 60;
        ticks = [0, 1, 2, 3, 4, 5].map(function (t) {
          return Math.round((s + duration * t / 6) / roundTo) * roundTo;
        });
      } else {
        ticks = [];
      } // add the label as the first item in the array


      times.unshift('Time');
      values.unshift(this.valueTitle);
      times2.unshift('Time');
      values2.unshift(this.valueTitle2);

      switch (interval) {
        case 'hour':
          dateFormat = '%I:%M';
          break;

        case 'month':
          dateFormat = '%b';
          break;

        default:
          dateFormat = '%b %d';
      } // create a function to format the value (in seconds) to time


      var formatDateFn = function formatDateFn(value, format) {
        d = new Date(value);
        return d3.time.format(format)(d);
      };

      return {
        data: {
          x: 'Time',
          type: 'area',
          columns: [times, values, values2]
        },
        legend: {
          show: true
        },
        tooltip: {
          format: {
            title: function title(x) {
              return formatDateFn(x, dateFormat);
            }
          }
        },
        axis: {
          x: {
            label: {
              text: 'Date',
              position: 'outer-center'
            },
            min: startTime,
            tick: {
              values: ticks,
              format: function format(x) {
                return formatDateFn(x, dateFormat);
              }
            }
          },
          y: {
            label: {
              text: this.yaxis,
              position: 'outer-middle'
            }
          }
        }
      };
    })
  });
  var _default = AreaChartOverTimeComponent;
  _exports.default = _default;
});