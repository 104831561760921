define("frontend/utils/file-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    url: null,
    file: null,
    fieldNamespace: null,
    fieldName: 'file',
    method: 'POST',
    headerData: null,
    progress: 0,
    isUploading: false,
    // JSON object indicating the additional fields to specify --
    // the easy way to specify this is to bind it to model.getProperties(...)
    fields: null,
    upload: function upload() {
      this.set('isUploading', true);
      var self = this;
      this.set('progress', 0);

      var formData = this._getFormData();

      var settings = {
        url: this.url,
        type: this.method || 'POST',
        data: this._getFormData(),
        contentType: false,
        processData: false,
        xhr: function xhr() {
          var xhr = window.$.ajaxSettings.xhr();

          xhr.upload.onprogress = function (e) {
            return self.set('progress', e.loaded / e.total * 100);
          };

          return xhr;
        }
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        settings.success = function (data) {
          return Ember.run(function () {
            self.set('isUploading', false);
            return resolve(data);
          });
        };

        settings.error = function (jqXHR) {
          return Ember.run(function () {
            self.set('isUploading', false);
            return reject(jqXHR);
          });
        };

        console.log(settings);
        return window.$.ajax(settings);
      }).then(function () {
        return console.log('success');
      }).catch(function (e) {
        return console.log(e);
      });
    },
    _getFormData: function _getFormData() {
      var field, value;
      var result = new FormData();
      var fields = this.fields;

      if (fields) {
        for (var _i = 0, _Object$keys = Object.keys(fields || {}); _i < _Object$keys.length; _i++) {
          field = _Object$keys[_i];
          value = fields[field];
          result.append(this._getNamespacedField(field), value);
        }
      }

      result.append(this._getNamespacedField(this.fieldName), this.file);
      var headerData = this.headerData;

      if (headerData) {
        for (var _i2 = 0, _Object$keys2 = Object.keys(headerData || {}); _i2 < _Object$keys2.length; _i2++) {
          field = _Object$keys2[_i2];
          value = headerData[field];
          result.append(field, value);
        }
      }

      return result;
    },
    _getNamespacedField: function _getNamespacedField(fieldName) {
      var namespace = this.fieldNamespace;

      if (namespace) {
        return "".concat(namespace, "[").concat(fieldName, "]");
      } else {
        return fieldName;
      }
    }
  });

  _exports.default = _default;
});