define("frontend/pods/components/view/ui-select-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    optionDisplayCount: 10,
    placeholder: null,
    buttonClassName: null,
    // for multi-select
    values: null,
    _rendered: false,
    _refreshUI: function _refreshUI() {
      this.$().selectpicker('refresh');
      var controlElement = this.$().next('.bootstrap-select'); // the CSS classes are copied from the <select> and <option> tags to the new picker --
      // the ember tags must be removed for it to work

      controlElement.removeClass('ember-view ember-select');
      controlElement.find('li a').removeClass('ember-view');

      if (this.buttonClassName) {
        return controlElement.find('button').removeClass('btn-default').addClass(this.buttonClassName);
      }
    },
    _initializeUI: function _initializeUI() {
      var _this = this;

      var options = {};

      if (this.optionDisplayCount) {
        options.size = this.optionDisplayCount;
      }

      options.noneSelectedText = this.placeholder || '';
      this.$().selectpicker(options);

      if (this.values) {
        this.$().selectpicker('val', this.values);
      }

      this.$().on('change', function () {
        if (_this.multiple) {
          return _this._multiValuesChanged();
        }
      });
      return this._refreshUI();
    },
    _refreshAfterRender: function _refreshAfterRender() {
      var _this2 = this;

      // wait until the <select> options have changed to update the
      return Ember.run.scheduleOnce('afterRender', this, function () {
        return _this2._refreshUI();
      });
    },
    _destroyUI: Ember.on('willDestroyElement', function () {
      this.$().selectpicker('destroy');
      return this.set('_rendered', false);
    }),
    _contentChanged: Ember.observer('content.[]', 'optionValuePath', 'optionLabelPath', function () {
      return this._refreshAfterRender();
    }),
    _onInsert: Ember.on('didInsertElement', function () {
      this._initializeUI();

      return this.set('_rendered', true);
    }),
    _multiValuesChanged: function _multiValuesChanged() {
      var currentValues = this.values;
      var newValues = this.$().selectpicker('val');

      if (!this._areArraysEqual(currentValues, newValues)) {
        return this.set('values', newValues);
      }
    },
    _updateControlOnValuesChange: Ember.observer('values', function () {
      if (!this._rendered) {
        return;
      }

      var values = this.values;

      if (!this._areArraysEqual(values, this.$().selectpicker('val'))) {
        return this.$().selectpicker('val', values);
      }
    }),
    _areArraysEqual: function _areArraysEqual(a1, a2) {
      if (!a1) {
        a1 = [];
      }

      if (!a2) {
        a2 = [];
      }

      if (a1.get('length') !== a2.get('length')) {
        return false;
      }

      if (a1.find(function (item, index) {
        return a2[index] !== item;
      })) {
        return false;
      }

      return true;
    },
    _initializeObservedProperties: function _initializeObservedProperties() {
      return this.getProperties('values');
    }
  });

  _exports.default = _default;
});