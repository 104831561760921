define("frontend/pods/components/service-provider-fields-edit-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jhtEjW4z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-bars fa-fw pointer\"],[12],[13],[2,\"\\n  \"],[1,[35,0,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"text-center\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"checked\",\"onChange\"],[[35,0,[\"visible\"]],[30,[36,1],[[32,0],\"visibilityChanged\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"field\",\"action\",\"ui/toggle-switch\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/service-provider-fields-edit-row/template.hbs"
    }
  });

  _exports.default = _default;
});