define("frontend/mixins/nice-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NiceScrollMixin = Ember.Mixin.create({
    _niceScrollSelector: null,

    get _niceScrollElement() {
      var selector = this._niceScrollSelector;

      if (selector) {
        return this.$().find(selector);
      } else {
        return this.$();
      }
    },

    _niceScrollOptions: {
      horizrailenabled: false,
      zindex: 2,
      cursorborder: "none",
      cursorborderradius: "0",
      cursorcolor: "#496cad"
    },
    _initializeNiceScroll: Ember.on('didInsertElement', function () {
      var el = this._niceScrollElement;
      el.niceScroll(this._niceScrollOptions).show();
      return el.css('overflow-y', 'auto');
    }),
    _destroyNiceScroll: Ember.on('willDestroyElement', function () {
      return this._niceScrollElement.niceScroll().remove();
    }),
    _resetNiceScroll: function _resetNiceScroll() {
      this._destroyNiceScroll();

      return this._initializeNiceScroll();
    }
  });
  var _default = NiceScrollMixin;
  _exports.default = _default;
});