define("frontend/pods/live/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pbt55IT2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"manager-live-view\"],[[\"from\"],[\"frontend/pods/live/manager/styles\"]]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"innerAll heading-buttons border-bottom\"],[12],[2,\"\\n\\t\\t\"],[10,\"h4\"],[14,0,\"margin-none\"],[12],[2,\"Manager Live View\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n  \"],[1,[34,1]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"call-session/manager-live-view\"]}",
    "meta": {
      "moduleName": "frontend/pods/live/manager/template.hbs"
    }
  });

  _exports.default = _default;
});