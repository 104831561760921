define("frontend/pods/leads/lead/edit/route", ["exports", "frontend/pods/base/edit/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LeadEditRoute = _route.default.extend({
    afterSave: function afterSave() {
      return this.transitionTo('leads.lead.index', this.get('controller.model'));
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('leads.lead.index', this.get('controller.model'));
    }
  });

  var _default = LeadEditRoute;
  _exports.default = _default;
});