define("frontend/pods/components/tag-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TagEditorComponent = Ember.Component.extend({
    tagName: 'div',
    classNames: ['tag-editor'],
    placeholder: null,
    value: null,
    isEmpty: Ember.computed.equal('value.length', 0),
    placeholderText: Ember.computed('placeholder', 'isEmpty', function () {
      if (this.isEmpty) {
        return this.placeholder;
      }
    }),
    valueString: Ember.computed('value.[]', {
      get: function get() {
        return (this.value || []).join(',');
      },
      set: function set(key, value) {
        this.set('value', this._parseTags(value));
        return (this.value || []).join(',');
      }
    }),
    newTag: null,
    focus: function focus() {
      return this.$('input').focus();
    },
    click: function click() {
      return this.focus();
    },
    _initializeProperties: Ember.on('init', function () {
      if (!this.value) {
        return this.set('value', []);
      }
    }),
    _parseTags: function _parseTags(tags) {
      var t = tags || '';
      var a = t.toString().split(',');
      a = a.map(function (v) {
        return v.trim();
      });
      return a.rejectBy('length', 0);
    },
    actions: {
      add: function add() {
        var newTag = this.newTag;
        this.value.addObjects(this._parseTags(newTag));
        return this.set('newTag', '');
      },
      remove: function remove(tag) {
        return this.value.removeObject(tag);
      }
    }
  });
  var _default = TagEditorComponent;
  _exports.default = _default;
});