define("frontend/pods/components/ui/dropdown-button/button-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NFKSyEgF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[34,1]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"iconClass\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui/dropdown-button/button-component/template.hbs"
    }
  });

  _exports.default = _default;
});