define("frontend/pods/components/organization-phone-number/cnam/list/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "statistics": "_statistics_1j9uxs",
    "cnams-table": "_cnams-table_1j9uxs",
    "loading": "_loading_1j9uxs",
    "new-record": "_new-record_1j9uxs"
  };
  _exports.default = _default;
});