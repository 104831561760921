define("frontend/pods/components/lead-status-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CocpiiMT",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[30,[36,1],[\"label label-lead-status \",[35,0]],null]],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"labelClass\",\"concat\",\"text\",\"isShowing\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/lead-status-label/template.hbs"
    }
  });

  _exports.default = _default;
});