define("frontend/pods/components/organization-crm-integrations-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cuWgyg/S",
    "block": "{\"symbols\":[\"s\"],\"statements\":[[10,\"table\"],[14,0,\"table table-vertical-center\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"CRM Integrations\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Actions\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn btn-danger\"],[4,[38,0],[[32,0],\"removeCrmIntegration\",[32,1]],null],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-ban\"],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"class\",\"content\",\"optionLabelPath\",\"value\",\"allowClear\",\"placeholder\"],[\"form-control\",[35,5],\"content.name\",[35,4],true,\"<select an integration>\"]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-success\"],[4,[38,0],[[32,0],\"addCrmIntegration\"],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"-track-array\",\"each\",\"newCrmIntegration\",\"crmIntegrations\",\"ui-select\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/organization-crm-integrations-editor/template.hbs"
    }
  });

  _exports.default = _default;
});