define("frontend/pods/components/service-provider/advanced-settings/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_1ah77o",
    "icon": "_icon_1ah77o",
    "field": "_field_1ah77o",
    "type": "_type_1ah77o",
    "use": "_use_1ah77o",
    "actions": "_actions_1ah77o",
    "add": "_add_1ah77o"
  };
  _exports.default = _default;
});