define("frontend/pods/service-providers/service-provider/controller", ["exports", "frontend/utils/oauth-authentication"], function (_exports, _oauthAuthentication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ServiceProviderController = Ember.Controller.extend({
    isAuthenticating: Ember.computed.oneWay('auth.isAuthenticating'),
    auth: Ember.computed('model', function () {
      var provider = this.provider;
      var providerId = this.providerId;
      var adapter = this.store.adapterFor('application');
      var headerData = adapter.get('headerData');
      return _oauthAuthentication.default.create({
        provider: provider,
        providerId: providerId,
        headerData: headerData,
        command: 'reauthenticate'
      });
    }),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var auth = this.auth;
        return auth.authenticate().then(function () {
          return _this.model.reload();
        });
      }
    }
  });
  var _default = ServiceProviderController;
  _exports.default = _default;
});