define("frontend/pods/components/call-bottom-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WUpnPr4k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,6,\"\"],[12],[10,\"i\"],[14,0,\"fa fa-fw fa-phone\"],[12],[13],[13],[10,\"span\"],[14,0,\"badge badge-info\"],[12],[1,[34,0]],[13],[2,\"  \\n\"],[10,\"a\"],[14,6,\"\"],[12],[10,\"i\"],[14,0,\"fa fa-fw fa-comments\"],[12],[13],[13],[10,\"span\"],[14,0,\"badge badge-success\"],[12],[1,[34,1]],[13],[2,\"  \\n\"],[10,\"a\"],[14,6,\"\"],[12],[10,\"i\"],[14,0,\"fa fa-fw fa-envelope\"],[12],[13],[13],[10,\"span\"],[14,0,\"badge badge-success\"],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"callCount\",\"conversationCount\",\"voiceMailCount\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/call-bottom-bar/template.hbs"
    }
  });

  _exports.default = _default;
});