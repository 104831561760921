define("frontend/instance-initializers/initialize-softphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var softphone = appInstance.lookup('service:softphone');
    softphone.set('config', window.API_HOST.webphone);
    softphone.recover();
  }

  var _default = {
    name: 'initialize-softphone',
    initialize: initialize
  };
  _exports.default = _default;
});