define("frontend/pods/components/organization-phone-number/create-form/available-phone-number/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search": "_search_4r59ms",
    "results": "_results_4r59ms",
    "item": "_item_4r59ms",
    "phone-number": "_phone-number_4r59ms",
    "actions": "_actions_4r59ms"
  };
  _exports.default = _default;
});