define("frontend/pods/components/auto-url/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jhHKYQ5+",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"a\"],[15,6,[34,1]],[15,\"target\",[34,2]],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"url\",\"target\",\"isUrl\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/auto-url/template.hbs"
    }
  });

  _exports.default = _default;
});