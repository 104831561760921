define("frontend/pods/organizations/organization/statuses/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uIQkjDDV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-separator col-unscrollable col-separator-last\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-table\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll border-bottom\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[6,[37,1],null,[[\"route\",\"model\"],[\"organizations.organization.dispositions\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"Edit Dispositions\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[6,[37,1],null,[[\"route\",\"model\"],[\"organizations.organization.dynamics\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"Edit MS Dynamics Field Mappings\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[10,\"h4\"],[14,0,\"margin-none\"],[12],[2,\"Organization Statuses: \"],[1,[34,5]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll inner-2x\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,6],null,[[\"organization\"],[[35,0]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"link-to\",\"displayDispositions\",\"if\",\"isDynamicsProvider\",\"name\",\"lead-status-editor\"]}",
    "meta": {
      "moduleName": "frontend/pods/organizations/organization/statuses/template.hbs"
    }
  });

  _exports.default = _default;
});