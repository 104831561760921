define("frontend/helpers/plural", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function plural(params) {
    var value = params[0];
    var oneText = params[1];
    var manyText = params[2];
    return value === 1 ? oneText : manyText;
  });

  _exports.default = _default;
});