define("frontend/pods/components/team-editor/component", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TeamEditorComponent = Ember.Component.extend(_modal.default, {
    organization: null,
    store: null,
    teams: Ember.computed.readOnly('organization.teams'),
    displayTeams: Ember.computed('teams.[]', 'modelsToAdd.[]', 'modelsToRemove.[]', 'editingTeams.[]', 'newTeam', function () {
      var teams = this.teams;
      var modelsToAdd = this.modelsToAdd;
      var modelsToRemove = this.modelsToRemove;
      var result = [];
      result.addObjects(teams);
      result.addObjects(modelsToAdd);
      result.removeObjects(modelsToRemove);
      result.removeObject(this.newTeam);
      var editingTeams = this.editingTeams;
      return result.map(function (t) {
        return {
          team: t,
          isEditing: editingTeams.includes(t)
        };
      });
    }),
    modelsToAdd: Ember.computed(function () {
      return [];
    }),
    modelsToRemove: Ember.computed(function () {
      return [];
    }),
    newTeamName: null,
    newTeamAgentScript: null,
    newTeam: null,
    editingTeams: Ember.computed(function () {
      return [];
    }),
    actions: {
      add: function add() {
        var _this = this;

        if (this.newTeam) {
          this.newTeam.deleteRecord();
        }

        var newTeam = this.get('organization.store').createRecord('team', {
          organization: this.organization,
          name: this.newTeamName,
          agentScript: this.newTeamAgentScript
        });
        this.set('newTeam', newTeam);
        return newTeam.save().then(function () {
          _this.modelsToAdd.addObject(newTeam);

          return _this.setProperties({
            newTeam: null,
            newTeamName: null,
            newTeamAgentScript: null
          });
        }).catch(function () {});
      },
      remove: function remove(team) {
        var _this2 = this;

        return this.modal.confirm('Are you sure you want to remove this team?').then(function (result) {
          if (result) {
            return team.destroyRecord().then(function () {
              return _this2.modelsToRemove.addObject(team);
            });
          }
        });
      },
      edit: function edit(team) {
        return this.editingTeams.addObject(team);
      },
      cancel: function cancel(team) {
        return this.editingTeams.removeObject(team);
      },
      save: function save(team) {
        var _this3 = this;

        return team.save().then(function () {
          return _this3.editingTeams.removeObject(team);
        }).catch(function () {});
      }
    }
  });
  var _default = TeamEditorComponent;
  _exports.default = _default;
});