define("frontend/pods/components/ui/dropdown-button/menu-component/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "panel": "_panel_6s7e5w"
  };
  _exports.default = _default;
});