define("frontend/pods/components/ui/tabs-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "93SqR8Ho",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"tab-headers \",[30,[36,0],[\"headers\"],[[\"from\"],[\"frontend/pods/components/ui/tabs-component/styles\"]]]]]],[12],[2,\"\\n  \"],[18,1,[[30,[36,4],null,[[\"headers\",\"header\"],[true,[30,[36,3],[\"ui/tabs-component/tab-header\"],[[\"activeTabName\",\"onActivate\"],[[35,2],[30,[36,1],[[32,0],\"activateTab\"],null]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"tab-content \",[30,[36,0],[\"content\"],[[\"from\"],[\"frontend/pods/components/ui/tabs-component/styles\"]]]]]],[12],[2,\"\\n  \"],[18,1,[[30,[36,4],null,[[\"content\"],[[30,[36,3],[\"ui/tabs-component/tab-content\"],[[\"activeTabName\"],[[35,2]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"action\",\"activeTabName\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui/tabs-component/template.hbs"
    }
  });

  _exports.default = _default;
});