define("frontend/pods/components/view/ui-switch/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: false,
    onChange: null,
    classNames: ['make-switch'],
    onText: null,
    offText: null,
    onIcon: null,
    offIcon: null,
    onClass: 'primary',
    offClass: 'default',
    size: null,
    onLabel: Ember.computed('onText', 'onIcon', function () {
      var onIcon = this.onIcon || "";
      onIcon = onIcon ? "<i class='fa fa-".concat(onIcon, "'></i>") : "";
      var onText = this.onText || "";
      var result = "".concat(onIcon, " ").concat(onText).trim();

      if (result.length === 0) {
        result = "ON";
      }

      return result;
    }),
    offLabel: Ember.computed('offText', 'offIcon', function () {
      var offIcon = this.offIcon || "";
      offIcon = offIcon ? "<i class='fa fa-".concat(offIcon, "'></i>") : "";
      var offText = this.offText || "";
      var result = "".concat(offIcon, " ").concat(offText).trim();

      if (result.length === 0) {
        result = "OFF";
      }

      return result;
    }),
    _setup: Ember.on('didInsertElement', function () {
      var _this = this;

      var el = this.$();
      el.bootstrapSwitch();

      this._setSwitchState();

      el.bootstrapSwitch('setOnLabel', this.onLabel);
      el.bootstrapSwitch('setOffLabel', this.offLabel);

      if (this.onClass) {
        el.bootstrapSwitch('setOnClass', this.onClass);
      }

      if (this.offClass) {
        el.bootstrapSwitch('setOffClass', this.offClass);
      }

      if (this.size) {
        el.bootstrapSwitch('setSizeClass', "switch-".concat(this.size));
      }

      return el.on('change', function (e) {
        var value = _this._getSwitchState();

        if (_this.onChange) {
          _this.onChange(value);
        } else {
          return _this.set('value', value);
        }
      });
    }),
    _destroy: Ember.on('willDestroyElement', function () {
      return this.$().bootstrapSwitch('destroy');
    }),
    _valueChanged: Ember.observer('value', function () {
      return this._setSwitchState();
    }),
    _getSwitchState: function _getSwitchState() {
      return this.$().bootstrapSwitch('status');
    },
    _setSwitchState: function _setSwitchState() {
      var value = this.value;

      if (this._getSwitchState() !== value) {
        return this.$().bootstrapSwitch('setState', value);
      }
    }
  });

  _exports.default = _default;
});