define("frontend/pods/components/service-provider/advanced-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5wouzU/W",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\\n\"],[10,\"form\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"title\"],[\"Throttle List Fetching (minutes)\"]],[[\"default\"],[{\"statements\":[[2,\"  \\t\\t\"],[1,[30,[36,1],null,[[\"type\",\"class\",\"value\"],[\"number\",\"form-control\",[35,0,[\"settings\",\"fetch_throttle_minutes\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"serviceProvider\",\"input\",\"ui-form-field\",\"error-list\",\"isSalesforceProvider\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/service-provider/advanced-settings/template.hbs"
    }
  });

  _exports.default = _default;
});