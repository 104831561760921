define("frontend/services/data-notifications", ["exports", "frontend/utils/web-socket"], function (_exports, _webSocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    authentication: Ember.inject.service(),
    webSocket: Ember.inject.service(),
    channel: Ember.computed('authentication.userId', function () {
      var userId = this.get('authentication.userId');

      if (userId) {
        return "/users/".concat(userId, "/data");
      }
    }),
    _subscription: null,
    subscribe: function subscribe() {
      var _this = this;

      var channel = this.channel;

      if (!channel) {
        return;
      }

      if (this._subscription) {
        return;
      }

      return this.set('_subscription', _webSocket.default.Subscription.create({
        client: this.webSocket,
        channel: channel,
        onNewMessage: function onNewMessage(message) {
          return _this._modelUpdated(message);
        }
      }));
    },
    unsubscribe: function unsubscribe() {
      if (!this._subscription) {
        return;
      }

      this._subscription.unsubscribe();

      return this.set('_subscription', null);
    },

    /*
    This version is better than the other and more The Ember Way...
    but it currently does not work because the "state" property is trying to be set,
    which is reserved in Ember Data.  The "state" property should be changed to a different name.
    _modelUpdated: (message) ->
      console.log "data notification!", message
      return unless message.event == 'model_updated'
      modelType = message.type
      delete message.type
      message.data.id = message.id if message.id
      store = @get('store')
      store.pushPayload(modelType, store.normalize(modelType, message.data))
    */
    _modelUpdated: function _modelUpdated(message) {
      console.log("data notification!", message);

      if (message.event !== 'model_updated') {
        return;
      }

      var store = this.store;
      var m = store.peekRecord(message.type, message.id);

      if (!m) {
        return;
      }

      var d = {};
      delete message.data.id; // camelize the property names

      for (var _i = 0, _Object$keys = Object.keys(message.data || {}); _i < _Object$keys.length; _i++) {
        var key = _Object$keys[_i];
        d[key.toString().camelize()] = message.data[key];
      } // do a find to ensure the record is fully loaded


      return store.find(message.type, message.id).then(function (loadedMessage) {
        return loadedMessage.setProperties(d);
      });
    },
    _channelChanged: Ember.observer('channel', function () {
      if (this._subscription) {
        this.set('channel', this.channel);
      }
    })
  });

  _exports.default = _default;
});