define("frontend/pods/components/service-provider-fields-edit-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ServiceProviderFieldsEditRowComponent = Ember.Component.extend({
    field: null,
    fields: null,
    fieldType: null,
    onChange: function onChange() {},
    tagName: 'tr',
    draggable: true,
    attributeBindings: ['draggable'],
    isFieldVisible: Ember.computed.readOnly('field.visible'),
    classNameBindings: ['isFieldVisible::field-hidden'],
    triggerChange: function triggerChange() {
      return this.onChange(this.fieldType, this.fields);
    },
    dragStart: function dragStart(e) {
      var data = {
        fieldName: this.get('field.name'),
        elementId: this.elementId
      };
      return e.dataTransfer.setData('text/data', JSON.stringify(data));
    },
    dragOver: function dragOver(e) {
      e.preventDefault();
      return true;
    },
    drop: function drop(e) {
      var data = JSON.parse(e.dataTransfer.getData('text/data'));
      var draggedFieldName = data.fieldName;
      var fieldName = this.get('field.name');
      var fields = this.fields;
      var draggedItem = fields.findBy('name', draggedFieldName);
      var droppedItem = fields.findBy('name', fieldName);
      var draggedIndex = fields.indexOf(draggedItem);
      var droppedIndex = fields.indexOf(droppedItem);

      if (draggedIndex > -1) {
        fields.splice(draggedIndex, 1);
      }

      fields.splice(droppedIndex, 0, draggedItem);
      var droppedElement = this.$();
      var draggedElement = window.$('#' + data.elementId);

      if (draggedIndex > droppedIndex) {
        draggedElement.insertBefore(droppedElement);
      } else {
        draggedElement.insertAfter(droppedElement);
      }

      return this.triggerChange();
    },
    actions: {
      visibilityChanged: function visibilityChanged(visible) {
        this.set('field.visible', visible);
        return this.triggerChange();
      }
    }
  });
  var _default = ServiceProviderFieldsEditRowComponent;
  _exports.default = _default;
});