define("frontend/helpers/format-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function formatNumber(params) {
    var value = params[0];

    if (value || value === 0) {
      return value.toLocaleString();
    } else {
      return '';
    }
  });

  _exports.default = _default;
});