define("frontend/pods/components/organization-crm-integrations-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationCrmIntegrationsEditorComponent = Ember.Component.extend({
    organization: null,
    value: null,
    store: Ember.computed.readOnly('organization.store'),
    crmIntegrations: Ember.computed('store', function () {
      var store = this.store;

      if (!store) {
        return;
      }

      return store.findAll('crm-integration');
    }),
    newCrmIntegration: null,
    actions: {
      addCrmIntegration: function addCrmIntegration() {
        var crmIntegration = this.newCrmIntegration;

        if (crmIntegration) {
          this.value.addObject(crmIntegration);
          var o = this.store.createRecord('crm-integration-organization', {
            organization: this.organization,
            crmIntegration: this.newCrmIntegration
          });
          o.save();
          return this.set('newCrmIntegration', null);
        }
      },
      removeCrmIntegration: function removeCrmIntegration(integrationToRemove) {
        this.store.queryRecord('crm-integration-organization', {
          organization_id: this.organization.id,
          crm_integration_id: integrationToRemove.get('id')
        }).then(function (crmIntegrationOrganization) {
          crmIntegrationOrganization.destroyRecord();
        });
        return this.value.removeObject(integrationToRemove);
      }
    }
  });
  var _default = OrganizationCrmIntegrationsEditorComponent;
  _exports.default = _default;
});