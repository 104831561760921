define("frontend/pods/components/organization-dashboard-users/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vl2WzFqz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row innerAll\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"widget\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"widget-head\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"heading\"],[12],[2,\"Usage by Rep\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"widget-body\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"info\"],[[35,0]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"widget\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"widget-head\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"heading\"],[12],[2,\"Appt Ratio by Rep\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"widget-body\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"info\"],[[35,2]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"widget\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"widget-head\"],[12],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"heading\"],[12],[2,\"Appt Conversion Rate by Rep\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"widget-body\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"info\"],[[35,3]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"usageByUserChartData\",\"c3-chart\",\"activityByUserChartData\",\"conversionRateByUserChartData\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/organization-dashboard-users/template.hbs"
    }
  });

  _exports.default = _default;
});