define("frontend/pods/components/soft-phone/control-panel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "bar": "_bar_1i15va",
    "dialpad-button": "_dialpad-button_1i15va",
    "dialpad-icon": "_dialpad-icon_1i15va",
    "dialpad": "_dialpad_1i15va",
    "digits": "_digits_1i15va"
  };
  _exports.default = _default;
});