define("frontend/pods/components/soft-phone/mute-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    softphone: Ember.inject.service(),
    tagName: '',
    mute: function mute() {
      this.softphone.mute();
    },
    unmute: function unmute() {
      this.softphone.unmute();
    },
    toggleMute: function toggleMute() {
      if (this.softphone.isMuted) {
        this.softphone.unmute();
      } else {
        this.softphone.mute();
      }
    }
  });

  _exports.default = _default;
});