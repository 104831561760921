define("frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/lead/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "gatekeeper-lead": "_gatekeeper-lead_1og0ao",
    "actions": "_actions_1og0ao"
  };
  _exports.default = _default;
});