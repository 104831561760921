define("frontend/models/call-session", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CALL_SESSION_STATUSES = void 0;
  var CALL_SESSION_STATUSES = {
    offline: {
      name: 'offline',
      title: 'Offline',
      color: 'inverse'
    },
    initializing: {
      name: 'initializing',
      title: 'Initializing...',
      color: 'inverse'
    },
    idle: {
      name: 'idle',
      title: 'Available',
      color: 'info'
    },
    dialing: {
      name: 'dialing',
      title: 'Dialing',
      color: 'success'
    },
    connecting: {
      name: 'connecting',
      title: 'Connecting',
      color: 'success'
    },
    connected: {
      name: 'connected',
      title: 'Connected',
      color: 'success'
    },
    disconnecting: {
      name: 'disconnecting',
      title: 'Disconnecting',
      color: 'success'
    },
    paused: {
      name: 'paused',
      title: 'Paused',
      color: 'warning'
    },
    eavesdropping: {
      name: 'eavesdropping',
      title: 'Eavesdropping',
      color: 'success'
    },
    coaching: {
      name: 'coaching',
      title: 'Coaching',
      color: 'success'
    }
  };
  _exports.CALL_SESSION_STATUSES = CALL_SESSION_STATUSES;

  var _default = _model.default.extend({
    user: (0, _model.belongsTo)('user'),
    startedAt: (0, _model.attr)('date'),
    endedAt: (0, _model.attr)('date'),
    state: (0, _model.attr)('string'),
    stateChangedAt: (0, _model.attr)('date'),
    dialCount: (0, _model.attr)('number'),
    idleTime: (0, _model.attr)('number'),
    pausedTime: (0, _model.attr)('number'),
    connectedTime: (0, _model.attr)('number'),
    eavesdroppingOnCallSession: (0, _model.belongsTo)('call-session', {
      inverse: null,
      async: false
    }),
    isInitializing: Ember.computed.equal('state', 'initializing'),
    isIdle: Ember.computed.equal('state', 'idle'),
    isDialing: Ember.computed.equal('state', 'dialing'),
    isConnecting: Ember.computed.equal('state', 'connecting'),
    isConnected: Ember.computed.equal('state', 'connected'),
    isDisconnecting: Ember.computed.equal('state', 'disconnecting'),
    isPaused: Ember.computed.equal('state', 'paused'),
    isEavesdropping: Ember.computed.equal('state', 'eavesdropping'),
    isCoaching: Ember.computed.equal('state', 'coaching'),
    isOnCall: Ember.computed.or('isDialing', 'isConnected', 'isConnecting'),
    isOnline: Ember.computed.or('isInitializing', 'isIdle', 'isDialing', 'isConnected', 'isConnecting', 'isDisconnecting', 'isPaused', 'isEavesdropping', 'isCoaching'),
    isOffline: Ember.computed.not('isOnline'),
    canDial: Ember.computed.or('isIdle', 'isPaused'),
    init: function init() {
      this._super.apply(this, arguments);

      this.getProperties('isIdle', 'isDialing', 'isConnecting', 'isConnected', 'isDisconnecting', 'isPaused', 'isOnCall', 'isOnline', 'isOffline');
    }
  });

  _exports.default = _default;
});