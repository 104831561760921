define("frontend/pods/components/gong-editor/component", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GongEditorComponent = Ember.Component.extend(_modal.default, {
    organization: null,
    store: null,
    gongAccounts: Ember.computed.readOnly('organization.gongAccounts'),
    displayGongAccounts: Ember.computed('gongAccounts.[]', 'modelsToAdd.[]', 'modelsToRemove.[]', 'editingGongAccounts.[]', 'newGongAccount', function () {
      var gongAccounts = this.gongAccounts;
      var modelsToAdd = this.modelsToAdd;
      var modelsToRemove = this.modelsToRemove;
      var result = [];
      result.addObjects(gongAccounts);
      result.addObjects(modelsToAdd);
      result.removeObjects(modelsToRemove);
      result.removeObject(this.newGongAccount);
      var editingGongAccounts = this.editingGongAccounts;
      return result.map(function (g) {
        return {
          gong: g,
          isEditing: editingGongAccounts.includes(g)
        };
      });
    }),
    modelsToAdd: Ember.computed(function () {
      return [];
    }),
    modelsToRemove: Ember.computed(function () {
      return [];
    }),
    newGongAccessKey: null,
    newGongAccessKeySecret: null,
    newGongActive: true,
    newHistoricSyncLimit: null,
    newGongAccount: null,
    editingGongAccounts: Ember.computed(function () {
      return [];
    }),
    actions: {
      add: function add() {
        var _this = this;

        if (this.newGongAccount) {
          this.newGongAccount.deleteRecord();
        }

        var newGongAccount = this.get('organization.store').createRecord('gong-account', {
          organization: this.organization,
          accessKey: this.newGongAccessKey,
          accessKeySecret: this.newGongAccessKeySecret,
          historicSyncDaysLimit: this.newHistoricSyncLimit,
          active: this.newGongActive
        });
        this.set('newGongAccount', newGongAccount);
        return newGongAccount.save().then(function () {
          _this.modelsToAdd.addObject(newGongAccount);

          return _this.setProperties({
            newGongAccount: null,
            newGongAccessKey: null,
            newGongAccessKeySecret: null,
            newGongActive: true,
            newHistoricSyncLimit: null
          });
        }).catch(function () {});
      },
      remove: function remove(gong) {
        var _this2 = this;

        return this.modal.confirm('Are you sure you want to remove this Gong Access Key?').then(function (result) {
          if (result) {
            return gong.destroyRecord().then(function () {
              return _this2.modelsToRemove.addObject(gong);
            });
          }
        });
      },
      edit: function edit(gong) {
        return this.editingGongAccounts.addObject(gong);
      },
      cancel: function cancel(gong) {
        return this.editingGongAccounts.removeObject(gong);
      },
      save: function save(gong) {
        var _this3 = this;

        return gong.save().then(function () {
          return _this3.editingGongAccounts.removeObject(gong);
        }).catch(function () {});
      },
      activeChanged: function activeChanged(active, gong) {
        return gong.set('active', active);
      }
    }
  });
  var _default = GongEditorComponent;
  _exports.default = _default;
});