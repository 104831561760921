define("frontend/pods/components/view/infinite-scroll/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u1972oAk",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"renderedContent\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,2],[[32,0,[\"hasMoreContent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"infinite-scroll-view-indicator\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/view/infinite-scroll/template.hbs"
    }
  });

  _exports.default = _default;
});