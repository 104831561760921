define("frontend/pods/components/bubble-chart-by-hour/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BubbleChartByHourComponent = Ember.Component.extend({
    data: null,
    data2: null,
    valueTitle: null,
    _round: function _round(val) {
      if (val) {
        return Math.round(val * 10) / 10;
      } else {
        return 0;
      }
    },
    dialsByHourChartData: Ember.computed('data', 'data2', 'dashboard.params', function () {
      var counts, day, dials, hour, hourOfWeek;
      var dialsByHour = this.data || {};
      var connectionsByHour = this.data2 || {};
      var valueTitle = this.valueTitle; // Convert to local time - this assumes all records are in the current Daylight Savings period.
      // Not ideal, but the only way to handle it otherwise is to have all data in the client
      // so the local time conversion can be accurate.  This is close enough for now.

      var timezoneOffset = new Date().getTimezoneOffset();
      var hoursInWeek = 7 * 24;
      var offsetDials = {};

      for (var _i = 0, _Object$keys = Object.keys(dialsByHour || {}); _i < _Object$keys.length; _i++) {
        hourOfWeek = _Object$keys[_i];
        dials = dialsByHour[hourOfWeek];
        var offsetHour = hourOfWeek - timezoneOffset / 60; // wrap it around to the end of the week if it winds up at the beginning

        if (offsetHour < 0) {
          offsetHour = hoursInWeek + offsetHour;
        } else if (offsetHour > hoursInWeek) {
          offsetHour = offsetHour - hoursInWeek;
        }

        offsetDials[offsetHour] = {
          dials: dials,
          connections: connectionsByHour[hourOfWeek] || 0
        };
      } // format: day[hour] = count


      var days = {};

      for (var _i2 = 0, _Object$keys2 = Object.keys(offsetDials || {}); _i2 < _Object$keys2.length; _i2++) {
        hourOfWeek = _Object$keys2[_i2];
        counts = offsetDials[hourOfWeek];
        day = Math.floor(hourOfWeek / 24);
        hour = hourOfWeek % 24;

        if (!days[day]) {
          days[day] = {};
        }

        days[day][hour] = counts;
      }

      var x = ['day'];
      var y = ['hour'];
      counts = [];

      for (var _i3 = 0, _Array$from = Array.from(Object.keys(days).map(function (i) {
        return parseInt(i);
      })); _i3 < _Array$from.length; _i3++) {
        var d = _Array$from[_i3];

        for (var _i4 = 0, _Array$from2 = Array.from(Object.keys(days[d]).map(function (i) {
          return parseInt(i);
        })); _i4 < _Array$from2.length; _i4++) {
          var h = _Array$from2[_i4];
          x.push(d);
          y.push(h);
          counts.push(days[d][h]);
        }
      }

      var maxDialsRateByHour = Math.max.apply(this, counts.map(function (i) {
        return i.dials;
      }));
      var maxConnectionRateByHour = Math.max.apply(this, counts.map(function (i) {
        return i.connections / i.dials;
      }));
      var colorScale = valueTitle === "Appointments" ? d3.scale.linear().range(['#d62728', '#bcbd22', '#2ca02c']).domain([0, maxDialsRateByHour / 2, maxConnectionRateByHour]) : d3.scale.linear().range(['#d62728', '#bcbd22', '#2ca02c']).domain([0, maxConnectionRateByHour / 2, maxDialsRateByHour]);
      var result = {
        //interaction:
        //  enabled: false
        data: {
          type: 'scatter',
          columns: [x, y],
          xs: {
            'hour': 'day'
          },
          color: function color(_color, d) {
            // set the color based on the conversion rate
            if (valueTitle === "Appointments") {
              return colorScale(counts[d.index].dials / counts[d.index].connections);
            } else {
              return colorScale(counts[d.index].connections / counts[d.index].dials);
            }
          }
        },
        point: {
          r: function r(d) {
            // size the bubble by the number of dials
            return counts[d.index].dials / maxDialsRateByHour * 20;
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          contents: function contents(d, defaultTitleFormat, defaultValueFormat, color) {
            d = d[0];
            day = defaultTitleFormat(d.x);
            hour = defaultValueFormat(d.value);
            var title = "".concat(day, " @ ").concat(hour);
            var toolTipType = valueTitle;
            dials = counts[d.index].dials;
            var connections = counts[d.index].connections;
            var d2C = connections === 0 ? dials : Math.round(Math.floor(dials / connections));
            var d2C_denominator = connections === 0 ? 0 : 1;
            var conversionRate = Math.round(Math.floor(connections / dials * 1000) / 10);
            var conversionRate2 = Math.round(Math.floor(dials / connections * 1000) / 10);
            result = "<table class='c3-tooltip'>";
            result += "<tr><th colspan='2'>" + title + "</th></tr>";

            if (toolTipType !== "Appointments") {
              result += "<tr><td class='name'>" + toolTipType + "<td class='value'>" + dials + "</td></tr>";
            }

            if (toolTipType === "Dials") {
              result += "<tr><td class='name'>D2C<td class='value'>" + "".concat(d2C, ":").concat(d2C_denominator) + "</td></tr>";
            }

            if (toolTipType === "Dials") {
              result += "<tr><td class='name'>Conv Conversion Rate<td class='value'>" + "".concat(conversionRate, "%") + "</td></tr>";
            }

            if (toolTipType === "Conversations") {
              result += "<tr><td class='name'>Appointments<td class='value'>" + connections + "</td></tr>";
            }

            if (toolTipType === "Conversations") {
              result += "<tr><td class='name'>Appt Conversion Rate<td class='value'>" + "".concat(conversionRate, "%") + "</td></tr>";
            }

            if (toolTipType === "Appointments") {
              result += "<tr><td class='name'>Conversations<td class='value'>" + connections + "</td></tr>";
            }

            if (toolTipType === "Appointments") {
              result += "<tr><td class='name'>Appointments<td class='value'>" + dials + "</td></tr>";
            }

            if (toolTipType === "Appointments") {
              result += "<tr><td class='name'>Appt Conversion Rate<td class='value'>" + "".concat(conversionRate2, "%") + "</td></tr>";
            }

            result += "</table>";
            return result;
          }
        },
        axis: {
          x: {
            padding: 0.2,
            tick: {
              fit: false,
              values: [0, 1, 2, 3, 4, 5, 6],
              format: function format(d) {
                return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][d];
              }
            }
          },
          y: {
            tick: {
              fit: true,
              values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
              format: function format(d) {
                if (d === 0) {
                  return "12a";
                } else if (d < 12) {
                  return "".concat(d, "a");
                } else if (d === 12) {
                  return "12p";
                } else {
                  return "".concat(d - 12, "p");
                }
              }
            }
          }
        }
      };
      return result;
    })
  });
  var _default = BubbleChartByHourComponent;
  _exports.default = _default;
});