define("frontend/pods/components/ui/tabs-component/tab-header/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "tab-header": "_tab-header_zs3sue",
    "active": "_active_zs3sue"
  };
  _exports.default = _default;
});