define("frontend/pods/data-sources/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DataSourcesController = Ember.Controller.extend({
    sortProperties: ['name'],
    filterText: '',
    filteredDataSources: Ember.computed('model.@each.isNew', 'filterText', function () {
      var filterText = this.filterText || '';
      return this.model.filter(function (c) {
        // do not show "new" items
        if (c.get('isNew')) {
          return false;
        } // show all saved items if there is no filter


        if (filterText.length === 0) {
          return true;
        } // perform the filter check


        var name = c.get('name');
        return name && name.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
      });
    })
  });
  var _default = DataSourcesController;
  _exports.default = _default;
});