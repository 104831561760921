define("frontend/pods/components/input-dial-mode/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputDialModeComponent = Ember.Component.extend({
    value: null,
    onChange: function onChange() {},
    selected: Ember.computed('value', {
      get: function get() {
        var value = this.value;

        if (value) {
          return value.toString().split(',');
        } else {
          return [];
        }
      },
      set: function set(key, value) {
        this.onChange((value || []).join(','));
        return value;
      }
    }),
    dialModes: [{
      value: 'power_dial',
      title: 'Power Dial'
    }, {
      value: 'team_dial',
      title: 'Team Dial'
    }, {
      value: 'andy_dial',
      title: 'Andi Dial'
    }]
  });
  var _default = InputDialModeComponent;
  _exports.default = _default;
});