define("frontend/pods/call-sessions/call-session/index/route", ["exports", "frontend/mixins/additional-models"], function (_exports, _additionalModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallSessionIndexRoute = Ember.Route.extend(_additionalModels.default, {
    additionalModels: function additionalModels(model) {
      if (model) {
        return {
          outboundCalls: this.store.query('outbound-call', {
            call_session_id: model.get('id')
          })
        };
      }
    }
  });
  var _default = CallSessionIndexRoute;
  _exports.default = _default;
});