define("frontend/pods/components/input-slider/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputSliderComponent = Ember.Component.extend({
    tagName: 'input',
    type: 'range',
    step: 1,
    min: 0,
    max: 10,
    value: 0,
    onChange: function onChange() {},
    onMouseDown: function onMouseDown() {},
    onMouseUp: function onMouseUp() {},
    attributeBindings: ['type', 'step', 'min', 'max', 'value'],
    _hookEvents: Ember.on('didInsertElement', function () {
      var _this = this;

      var el = this.$();
      el.on('change', function () {
        return Ember.run(function () {
          return _this.onChange(_this.element.value);
        });
      });
      el.on('mousedown', function () {
        return Ember.run(function () {
          return _this.onMouseDown();
        });
      });
      return el.on('mouseup', function () {
        return Ember.run(function () {
          return _this.onMouseUp();
        });
      });
    })
  });
  var _default = InputSliderComponent;
  _exports.default = _default;
});