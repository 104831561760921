define("frontend/pods/components/agent/agent-console/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "agent-console": "_agent-console_odtbe4",
    "container": "_container_odtbe4"
  };
  _exports.default = _default;
});