define("frontend/pods/components/infinite-scroll-indicator/component", ["exports", "frontend/mixins/in-viewport"], function (_exports, _inViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InfiniteScrollIndicatorComponent = Ember.Component.extend(_inViewport.default, {
    isComplete: false,
    onShow: function onShow() {},
    // Usually infinite scrolling is related to listing a number of items --
    // when the number of items changes (such as new content was added), it
    // is likely that this indicator's position changed even if scrolling did not occur.
    // As a result, watch for changes to the number of items and check its position when it changes.
    itemCount: 0,
    classNames: ['infinite-scroll-indicator'],
    isShowing: Ember.computed.not('isComplete'),
    classNameBindings: ['isShowing::hidden'],
    _inViewportChanged: Ember.observer('inViewport', function () {
      var p = this.getProperties('inViewport', 'isComplete');

      if (p.inViewport && !p.isComplete) {
        return this.onShow();
      }
    }),
    _itemCountChanged: Ember.observer('itemCount', function () {
      return Ember.run.scheduleOnce('afterRender', this, this._checkScroll);
    }),
    _initializeLocalProperties: Ember.on('init', function () {
      return this.getProperties('itemCount');
    })
  });
  var _default = InfiniteScrollIndicatorComponent;
  _exports.default = _default;
});