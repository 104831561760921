define("frontend/helpers/date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function date(params, hash) {
    var d = params[0] || new Date();
    var format = hash.format || 'LL';
    return (0, _moment.default)(d).format(format);
  });

  _exports.default = _default;
});