define("frontend/utils/form-submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var iframeCount = 0;

  var _default = Ember.Object.extend({
    url: null,
    method: 'GET',
    target: null,
    data: null,
    headerData: null,
    execute: function execute() {
      var url = this.url;
      var method = this.method;
      var target = this.target;
      var data = this.data || {};
      var headerData = this.headerData;

      if (headerData) {
        data = Ember.copy(data, true);
        Ember.assign(data, headerData);
      }

      iframeCount++;
      var iframeName = "iframe-form-submit-".concat(iframeCount);
      target = target || iframeName;
      var body = $('body');
      var iframe = $('<iframe src="about:blank"></iframe>').attr('name', iframeName).hide().appendTo(body);
      var form = $('<form></form>').attr('method', method).attr('action', url).attr('target', target).appendTo(body);

      this._addDataFields(form, data);

      return form.submit();
    },
    _addDataFields: function _addDataFields(form, data) {
      var _this = this;

      var parentKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      return function () {
        var result = [];

        for (var _i = 0, _Object$keys = Object.keys(data || {}); _i < _Object$keys.length; _i++) {
          var key = _Object$keys[_i];
          var value = data[key];
          var name = parentKey ? "".concat(parentKey, "[").concat(key, "]") : key;

          if (Ember.typeOf(value) === 'object') {
            result.push(_this._addDataFields(form, value, name));
          } else {
            result.push(_this._addDataField(form, name, value));
          }
        }

        return result;
      }();
    },
    _addDataField: function _addDataField(form, name, value) {
      if (!Ember.isEmpty(value)) {
        return form.append($('<input type="hidden" />').attr('name', name).attr('value', value));
      }
    }
  });

  _exports.default = _default;
});