define("frontend/services/audio-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    src: null,
    elementId: '#audio-player',
    isPlaying: false,
    duration: null,
    element: Ember.computed('elementId', function () {
      if (this._element) {
        this._unhookEvents(this._element);
      }

      this._element = window.$(this.elementId)[0];

      if (this._element) {
        this._hookEvents(this._element);
      }

      return this._element;
    }),
    position: Ember.computed('_position', {
      get: function get() {
        return this._position || 0;
      },
      set: function set(key, value) {
        this.set('_position', value);
        var wasPlaying = this.isPlaying;

        if (wasPlaying) {
          this.pause();
        }

        var element = this.element;

        if (element) {
          element.currentTime = value;
        }

        if (wasPlaying) {
          this.play();
        }

        return value || 0;
      }
    }),
    play: function play(src, options) {
      if (options == null) {
        options = {};
      }

      var element = this.element;

      if (src) {
        this.set('src', src);
      }

      this.set('duration', options.duration);
      src = this.src; // only update the element when the source changes --
      // if not, it will reload it and play from the beginning every time

      if (src !== this._lastSrc) {
        element.src = src;
        this.set('_lastSrc', src);
        element.currentTime = 0;
      }

      return element.play();
    },
    pause: function pause() {
      return this.element.pause();
    },
    seek: function seek(seconds) {
      return this.element.currentTime = seconds;
    },
    _hookEvents: function _hookEvents(element) {
      if (!element) {
        return;
      }

      element.addEventListener('play', this.__audioPlayed || (this.__audioPlayed = function () {
        return this._audioPlayed.apply(this, arguments);
      }.bind(this)));
      element.addEventListener('pause', this.__audioPaused || (this.__audioPaused = function () {
        return this._audioPaused.apply(this, arguments);
      }.bind(this)));
      element.addEventListener('ended', this.__audioEnded || (this.__audioEnded = function () {
        return this._audioEnded.apply(this, arguments);
      }.bind(this)));
      element.addEventListener('timeupdate', this.__audioPositionChanged || (this.__audioPositionChanged = function () {
        return this._audioPositionChanged.apply(this, arguments);
      }.bind(this)));
      return element.addEventListener('durationchange', this.__audioDurationChanged || (this.__audioDurationChanged = function () {
        return this._audioDurationChanged.apply(this, arguments);
      }.bind(this)));
    },
    _unhookEvents: function _unhookEvents(element) {
      if (!element) {
        return;
      }

      if (this.__audioPlayed) {
        element.removeEventListener('play', this.__audioPlayed);
      }

      if (this.__audioPaused) {
        element.removeEventListener('pause', this.__audioPaused);
      }

      if (this.__audioEnded) {
        element.removeEventListener('ended', this.__audioEnded);
      }

      if (this.__audioPositionChanged) {
        element.removeEventListener('timeupdate', this.__audioPositionChanged);
      }

      if (this.__audioDurationChanged) {
        return element.removeEventListener('durationchange', this.__audioDurationChanged);
      }
    },
    _audioPlayed: function _audioPlayed() {
      return this.set('isPlaying', true);
    },
    _audioPaused: function _audioPaused() {
      return this.set('isPlaying', false);
    },
    _audioEnded: function _audioEnded() {
      return this.set('_position', this.duration || 0);
    },
    _audioPositionChanged: function _audioPositionChanged() {
      // the timing can vary from system to system -- never let it exceed the duration
      var position = Math.min(this.element.currentTime, this.duration || 0);
      return this.set('_position', position);
    },
    _audioDurationChanged: function _audioDurationChanged() {
      if (!this.duration) {
        return this.set('duration', this.element.duration);
      }
    }
  });

  _exports.default = _default;
});