define("frontend/pods/components/call-voicemail-select/component", ["exports", "frontend/pods/components/dropdown-button/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CallVoicemailSelectComponent = _component.default.extend({
    callService: null,
    isShowing: true,
    classNameBindings: ['isShowing::hidden'],
    store: Ember.computed.oneWay('callService.store'),
    recordings: Ember.computed('store', function () {
      return this.store.findAll('outbound_voice_mail_recording');
    }),
    recording: Ember.computed('recordings.@each.active', function () {
      return this.recordings.findBy('active', true);
    }),
    content: Ember.computed.oneWay('recordings'),
    value: Ember.computed('recording.id', {
      get: function get() {
        return this.get('recording.id');
      },
      set: function set(key, value) {
        if (this.get('recording.id') !== value) {
          if (value) {
            this._activateRecording(this.store.peekRecord('outbound_voice_mail_recording', value), true);
          } else {
            this._activateRecording(this.recording, false);
          }
        }

        return this.get('recording.id');
      }
    }),
    _activateRecording: function _activateRecording(recording, active) {
      var _this = this;

      if (!recording) {
        return;
      }

      recording.set('active', active);
      return recording.save().then(function () {
        return _this.store.findAll('outbound_voice_mail_recording');
      });
    },
    select: function select(item) {
      this.set('value', item && item.id);
    }
  });

  var _default = CallVoicemailSelectComponent;
  _exports.default = _default;
});