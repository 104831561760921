define("frontend/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "32mLQMju",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,5,\"width: 100%; text-align: center; padding-top: 50px\"],[14,0,\"animated fadeInUp\"],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin fa-4x\"],[14,5,\"color: #999\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});