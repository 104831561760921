define("frontend/pods/components/ui-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    content: null,
    value: null,
    optionLabelPath: null,
    optionValuePath: null,
    placeholder: null,
    searchPlaceholder: '(search for an item)',
    allowClear: false,
    multiple: false,
    onChange: null,
    onSearch: null,
    tagName: '',
    searchEnabled: Ember.computed.bool('onSearch'),
    optionsFor: function optionsFor(content) {
      var optionLabelPath = this.optionLabelPath && (/^content\.(.+)$/.exec(this.optionLabelPath) || [])[1];
      var optionValuePath = this.optionValuePath && (/^content\.(.+)$/.exec(this.optionValuePath) || [])[1];
      return (content || []).map(function (item) {
        var label = optionLabelPath ? Ember.get(item, optionLabelPath) : item;
        var value = optionValuePath ? Ember.get(item, optionValuePath) : item;
        return {
          label: label,
          value: value
        };
      });
    },
    options: Ember.computed('content.[]', 'optionLabelPath', 'optionValuePath', {
      get: function get() {
        return this.optionsFor(this.content);
      }
    }),
    selectedOption: Ember.computed('options.[]', 'value', {
      get: function get() {
        var value = this.value;

        if (value) {
          if (this.multiple) {
            return this.options.filter(function (o) {
              return value.includes(o.value);
            });
          } else {
            return this.options.findBy('value', value);
          }
        }
      }
    }),
    selectionChanged: function selectionChanged(selected) {
      var value;

      if (this.multiple) {
        value = selected && selected.mapBy('value');
      } else {
        value = selected && selected.value;
      }

      if (this.onChange) {
        this.onChange(value);
      } else {
        this.set('value', value);
      }
    },
    search: function search(value) {
      if (this.onSearch) {
        var searchResults = this.onSearch(value) || [];
        return this.optionsFor(searchResults);
      }
    }
  });

  _exports.default = _default;
});