define("frontend/pods/lead-phone-numbers/lead-phone-number/edit/controller", ["exports", "frontend/pods/lead-phone-numbers/lead-phone-number/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LeadPhoneNumberEditController = _controller.default.extend();

  var _default = LeadPhoneNumberEditController;
  _exports.default = _default;
});