define("frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transfer-failed/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "transfer-failed": "_transfer-failed_13b8jw",
    "container": "_container_13b8jw",
    "actions": "_actions_13b8jw"
  };
  _exports.default = _default;
});