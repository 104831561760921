define("frontend/pods/components/view/paginated/component", ["exports", "frontend/utils/paginated"], function (_exports, _paginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['paginated-view'],
    content: null,
    scrollElement: null,
    threshold: 0.1,
    loadingText: 'Loading...',
    showLoadingIndicator: true,
    isLoadingIndicatorVisible: Ember.computed('showLoadingIndicator', 'content.isBusy', function () {
      return this.showLoadingIndicator === true && this.get('content.isBusy');
    }),
    _hookScrolling: Ember.on('didInsertElement', function () {
      this._initializeScrollEl();

      var scrollEl = this._scrollEventEl;
      var fn = this._hookFunction;
      return scrollEl.on('scroll', fn);
    }),
    _unhookScrolling: Ember.on('willDestroyElement', function () {
      var scrollEl = this._scrollEventEl;
      var fn = this._hookFunction;
      return scrollEl.off('scroll', fn);
    }),
    _scrollEventEl: null,
    _scrollCalcEl: null,
    _initializeScrollEl: function _initializeScrollEl() {
      var scrollEventEl = null;
      var scrollCalcEl = null;
      var scrollElement = this.scrollElement;

      if (scrollElement) {
        if (scrollElement === 'body') {
          scrollEventEl = window.$(window);
          scrollCalcEl = window.$('body');
        } else {
          scrollEventEl = window.$(scrollElement);
          scrollCalcEl = scrollEventEl;
        }
      } else {
        scrollEventEl = window.$();
        scrollCalcEl = scrollEventEl;
      }

      this.set('_scrollEventEl', scrollEventEl);
      return this.set('_scrollCalcEl', scrollCalcEl);
    },
    _hookFunction: Ember.computed(function () {
      var _this = this;

      return function () {
        return Ember.run.throttle(_this, _this._checkScroll, 100);
      };
    }),
    _checkScroll: function _checkScroll() {
      var scrollEl = this._scrollCalcEl;

      if (!scrollEl.length) {
        return;
      }

      var scrollTop = scrollEl.scrollTop();
      var scrollHeight = scrollEl[0].scrollHeight;
      var height = scrollEl.height();
      var threshold = this.threshold;

      if ((scrollHeight - (scrollTop + height)) / scrollHeight <= threshold) {
        return this._infiniteScroll();
      }
    },
    _infiniteScroll: function _infiniteScroll() {
      var content = this.content;

      if (!content || content.constructor !== _paginated.default.Array) {
        return;
      }

      if (this.content.get('isBusy') || this.content.get('isComplete')) {
        return;
      }

      return this.content.nextPage();
    }
  });

  _exports.default = _default;
});