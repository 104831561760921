define("frontend/pods/components/soft-phone/digits-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    softphone: Ember.inject.service(),
    size: 16,
    tagName: '',
    digits: Ember.computed('softphone.digits.[]', {
      get: function get() {
        return (this.softphone.digits || []).join('').slice(this.size * -1);
      }
    })
  });

  _exports.default = _default;
});