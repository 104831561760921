define("frontend/pods/components/display-phone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x47Qde9O",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,1]],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\", \"],[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"i\"],[14,0,\"fa fa-warning text-warning\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"additionalInfo\",\"phoneText\",\"displayAdditionalInfo\",\"if\",\"isValid\",\"unless\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/display-phone/template.hbs"
    }
  });

  _exports.default = _default;
});