define("frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/lead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jjQ6wv7x",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"gatekeeper-lead\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/lead/styles\"]]]]]],[12],[2,\"\\n\\n  \"],[8,\"agent/current-call/say-something\",[],[[\"@text\"],[\"One moment please...\"]],null],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"actions\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/lead/styles\"]]]]]],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-lg btn-success\"],[4,[38,1],[\"click\",[32,0,[\"transfer\"]]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-fw fa-random\"],[12],[13],[2,\" Transfer\\n    \"],[13],[2,\"\\n\\n    \"],[11,\"button\"],[24,0,\"btn btn-inverse\"],[4,[38,1],[\"click\",[32,0,[\"back\"]]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-fw fa-chevron-left\"],[12],[13],[2,\" Back\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"on\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/lead/template.hbs"
    }
  });

  _exports.default = _default;
});