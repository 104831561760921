define("frontend/pods/data-sources/data-source/route", ["exports", "frontend/mixins/modal", "frontend/mixins/additional-models"], function (_exports, _modal, _additionalModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DataSourceRoute = Ember.Route.extend(_additionalModels.default, _modal.default, {
    requiresLogin: true,
    additionalModels: function additionalModels() {
      return {
        leadLists: this.store.findAll('lead-list')
      };
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      return this.additionalModelsPromise.then(function () {
        return _this.setLeadList(controller, model);
      });
    },
    setLeadList: function setLeadList(controller, model) {
      var leadLists = controller.leadLists;
      var leadList = leadLists.findBy('name', model.get('name'));
      return controller.set('leadList', leadList);
    },
    actions: {
      deleteModel: function deleteModel() {
        var _this2 = this;

        return this.modal.confirm('Are you sure you want to delete this data source?').then(function (result) {
          if (result) {
            var model = _this2.get('controller.model');

            model.rollbackAttributes();
            return model.destroyRecord().then(function () {
              var leadList = _this2.get('controller.leadList');

              if (leadList) {
                return leadList.destroyRecord();
              }
            }).then(function () {
              return _this2.transitionTo('data-sources');
            });
          }
        });
      }
    }
  });
  var _default = DataSourceRoute;
  _exports.default = _default;
});