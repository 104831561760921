define("frontend/pods/components/view/call-control-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    callService: Ember.inject.service('call'),
    leadList: Ember.computed.readOnly('callService.leadList'),
    leads: Ember.computed.readOnly('callService.leads'),
    // This resizing is necessary because the leads list should expand to consume all available height,
    // but it cannot be done only through CSS if the top and/or bottom can vary.  It makes sense to do it
    // here in the parent control.
    resizeLeadsList: function resizeLeadsList() {
      var list = this.$().find('ul.leads-list');
      var parent = this.$().find('.sidebar-scrolling-region');

      if (list.length && parent.length) {
        var height = parent[0].offsetHeight - list[0].offsetTop;

        if (height < 0) {
          height = 0;
        }

        return list[0].style.height = "".concat(height, "px");
      }
    },
    _hookResize: Ember.on('didInsertElement', function () {
      var _this = this;

      var fn = function fn() {
        return _this.resizeLeadsList();
      };

      return this.set('resizeInterval', setInterval(fn, 1000));
    }),
    _unhookResize: Ember.on('willDestroyElement', function () {
      return clearInterval(this.resizeInterval);
    })
  });

  _exports.default = _default;
});