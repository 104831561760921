define("frontend/pods/components/ui-spark-legend/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "If25VeQt",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,3],[[35,0,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"ul\"],[14,0,\"list-unstyled\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"innerAll half\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"i\"],[14,0,\"fa fa-fw fa-square text-info\"],[15,5,[32,1,[\"style\"]]],[12],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"strong\"],[12],[1,[32,1,[\"value\"]]],[13],[2,\"\\n\\t\\t\\t\\t\"],[1,[32,1,[\"label\"]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"viewContent\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui-spark-legend/template.hbs"
    }
  });

  _exports.default = _default;
});