define("frontend/pods/components/view/call-status/component", ["exports", "frontend/mixins/animation"], function (_exports, _animation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_animation.default, {
    tagName: 'span',
    classNames: ['call-status', 'label'],
    classNameBindings: ['labelClass'],
    templateName: 'call/status',
    callSession: null,
    status: Ember.computed.alias('callSession.state'),
    labelClass: Ember.computed('status', function () {
      switch (this.status) {
        case 'idle':
          return 'label-info';

        case 'connected':
          return 'label-success';

        case 'wrapping_up':
          return 'label-warning';

        case 'not_accepting_calls':
          return 'label-error';

        default:
          return 'label-inverse';
      }
    }),
    statusText: Ember.computed('status', 'callSession.isOnline', function () {
      var _this = this;

      var onlineText = this.get('callSession.isOnline') ? 'Online' : 'Offline';

      var state = function () {
        switch (_this.status) {
          case 'idle':
            return 'Accepting Calls';

          case 'connected':
            return 'On a Call';

          case 'wrapping_up':
            return 'Wrapping Up';

          case 'not_accepting_calls':
            return 'Not Accepting Calls';

          default:
            return 'Disconnected';
        }
      }();

      return "".concat(onlineText, " : ").concat(state);
    }),
    _animateOnChange: Ember.observer('statusText', function () {
      return this.pulse();
    })
  });

  _exports.default = _default;
});