define("frontend/pods/components/agent/agent-console/current-call/actions/states/voicemail/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "reached-voicemail": "_reached-voicemail_emdti4",
    "container": "_container_emdti4",
    "title": "_title_emdti4",
    "actions": "_actions_emdti4",
    "body": "_body_emdti4"
  };
  _exports.default = _default;
});