define("frontend/pods/organizations/organization/edit/route", ["exports", "frontend/pods/base/edit/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var OrganizationEditRoute = _route.default.extend({
    requiresLogin: true,
    authorizedRoles: ['admin'],
    saveModel: function saveModel() {
      var _this = this;

      var model = this.get('controller.model');
      var serviceApplicationOrganizations = this.get('controller.serviceApplicationOrganizations');
      var existingServices = serviceApplicationOrganizations.mapBy('serviceApplication');
      var adjustedServices = this.get('controller.editableServiceApplicationOrganizations');
      return model.save().then(function () {
        var newServices = adjustedServices.filter(function (s) {
          return !existingServices.includes(s);
        });
        var removedServices = existingServices.filter(function (s) {
          return !adjustedServices.includes(s);
        });
        var removedPromises = removedServices.map(function (s) {
          var o = serviceApplicationOrganizations.findBy('serviceApplication', s);
          return o.destroyRecord();
        });
        return Ember.RSVP.Promise.all(removedPromises).then(function () {
          var newPromises = newServices.map(function (s) {
            var o = _this.store.createRecord('service-application-organization', {
              organization: model,
              serviceApplication: s
            });

            return o.save();
          });
          return Ember.RSVP.Promise.all(newPromises);
        });
      });
    },
    afterSave: function afterSave() {
      return this.transitionTo('organizations.organization.index', this.get('controller.model'));
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('organizations.organization.index', this.get('controller.model'));
    }
  });

  var _default = OrganizationEditRoute;
  _exports.default = _default;
});