define("frontend/pods/components/agent/current-call/client-info/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "widget-body": "_widget-body_tcptek",
    "client-info": "_client-info_tcptek",
    "name-container": "_name-container_tcptek",
    "name": "_name_tcptek",
    "status": "_status_tcptek",
    "company": "_company_tcptek",
    "notes": "_notes_tcptek",
    "title": "_title_tcptek",
    "body": "_body_tcptek"
  };
  _exports.default = _default;
});