define("frontend/pods/lead-phone-numbers/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadPhoneNumbersRoute = Ember.Route.extend({
    requiresLogin: true
  });
  var _default = LeadPhoneNumbersRoute;
  _exports.default = _default;
});