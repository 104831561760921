define("frontend/services/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    confirm: function confirm(text) {
      return new Promise(function (resolve, reject) {
        return bootbox.confirm(text, function (result) {
          return resolve(result);
        });
      });
    },
    alert: function alert(text) {
      return new Promise(function (resolve, reject) {
        return bootbox.alert(text, function () {
          return resolve();
        });
      });
    }
  });

  _exports.default = _default;
});