define("frontend/controllers/users/user/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    hasImpersonatePermission: Ember.computed.alias('authenticationService.canImpersonate'),
    isAdmin: Ember.computed.equal('authenticationService.activeRole', 'admin'),
    isClientManager: Ember.computed.equal('authenticationService.activeRole', 'client_manager'),
    isTeamManager: Ember.computed.equal('authenticationService.activeRole', 'team_manager'),
    canUserBeImpersonated: Ember.computed.not('isAdmin'),
    canImpersonate: Ember.computed.and('hasImpersonatePermission', 'canUserBeImpersonated'),
    canEdit: Ember.computed.or('isAdmin', 'isClientManager', 'isTeamManager')
  });

  _exports.default = _default;
});