define("frontend/pods/data-sources/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X3/dHvTw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row row-app\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-separator\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"row row-app\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-lg-12\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-separator col-separator-first box\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "frontend/pods/data-sources/template.hbs"
    }
  });

  _exports.default = _default;
});