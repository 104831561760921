define("frontend/pods/components/dashboard-filters/component", ["exports", "frontend/models/outbound-call"], function (_exports, _outboundCall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardFiltersComponent = Ember.Component.extend({
    startDate: null,
    endDate: null,
    userIds: null,
    users: null,
    statuses: null,
    leadStatuses: null,
    leadLists: null,
    teams: null,
    dialResults: null,
    agentConversationResults: null,
    session: null,
    isClient: null,
    clientEmail: null,
    leadListIds: null,
    onSubmit: function onSubmit() {},
    canSelectUser: Ember.computed.notEmpty('users'),
    sessionOptions: [{
      value: 'false',
      text: 'Team Dial'
    }, {
      value: 'true',
      text: 'Power Dial'
    }],
    dialResultOptions: _outboundCall.default.dialResultValues,
    agentConversationResultOptions: _outboundCall.default.agentConversationResultValues,
    actions: {
      submit: function submit() {
        return this.onSubmit(this.getProperties('startDate', 'endDate', 'userIds', 'statuses', 'leadListIds', 'teamIds', 'dialResults', 'agentConversationResults', 'session'));
      }
    }
  });
  var _default = DashboardFiltersComponent;
  _exports.default = _default;
});