define("frontend/pods/components/file-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FileFieldComponent = Ember.TextField.extend({
    type: 'file',
    file: null,
    change: function change(e) {
      var input = e.target;
      var files = input.files || [];
      return this.set('file', files[0]);
    }
  });
  var _default = FileFieldComponent;
  _exports.default = _default;
});