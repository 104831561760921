define("frontend/pods/components/tool-tip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ToolTipComponent = Ember.Component.extend({
    tagName: 'span',
    title: 'foobar',
    placement: 'left',
    containerElement: 'body',
    didInsertElement: function didInsertElement() {
      var p = this.getProperties('title', 'placement', 'containerElement');
      return this.$().tooltip(p);
    },
    willDestroyElement: function willDestroyElement() {
      return this.$().tooltip('destroy');
    }
  });
  var _default = ToolTipComponent;
  _exports.default = _default;
});