define("frontend/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationState: Ember.inject.service(),
    authentication: Ember.inject.service(),
    logoUrl: Ember.computed.readOnly('authentication.activeUser.organization.logoUrl')
  });

  _exports.default = _default;
});