define("frontend/pods/components/ui-spark-legend/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UiSparkLegendComponent = Ember.Component.extend({
    content: null,
    defaultColor: '#4193d0',
    valuePath: 'value',
    labelPath: 'label',
    colorPath: 'color',
    viewContent: Ember.computed('content.[]', 'valuePath', 'labelPath', 'colorPath', 'defaultColor', function () {
      var content = this.content || [];
      var valuePath = this.valuePath;
      var labelPath = this.labelPath;
      var colorPath = this.colorPath;
      var defaultColor = this.defaultColor;
      return content.map(function (item) {
        var result = {};
        result.label = Ember.get(item, labelPath);
        result.value = Ember.get(item, valuePath);
        result.color = Ember.get(item, colorPath) || defaultColor;
        result.style = "color: ".concat(result.color);
        return result;
      });
    })
  });
  var _default = UiSparkLegendComponent;
  _exports.default = _default;
});