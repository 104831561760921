define("frontend/adapters/outbound-call", ["exports", "frontend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    transfer: function transfer(id) {
      return this.executeServerAction('outbound-call', id, 'transfer');
    },
    end: function end(id) {
      return this.executeServerAction('outbound-call', id, 'end');
    }
  });

  _exports.default = _default;
});