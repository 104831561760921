define("frontend/pods/components/ellipses-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uLg3OsJq",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[34,0]],[2,\"\\n\\t\\t\"],[11,\"a\"],[4,[38,2],[[32,0],\"collapse\"],null],[12],[10,\"i\"],[14,0,\"fa fa-toggle-left\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[1,[34,1]],[2,\"\\n\\t\\t\"],[11,\"a\"],[4,[38,2],[[32,0],\"expand\"],null],[12],[10,\"i\"],[14,0,\"fa fa-toggle-right\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"text\",\"ellipsesText\",\"action\",\"expanded\",\"if\",\"needsEllipses\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ellipses-text/template.hbs"
    }
  });

  _exports.default = _default;
});