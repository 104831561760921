define("frontend/pods/components/ui/dropdown-button/header-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8ecmfiY2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h6\"],[14,0,\"dropdown-header\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui/dropdown-button/header-component/template.hbs"
    }
  });

  _exports.default = _default;
});