define("frontend/pods/components/tag-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7nPekgcX",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"span\"],[14,0,\"label label-info\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"content\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/tag-list/template.hbs"
    }
  });

  _exports.default = _default;
});