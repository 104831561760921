define("frontend/pods/components/ui/tabs-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeTabName: null,
    actions: {
      activateTab: function activateTab(name) {
        this.set('activeTabName', name);
      }
    }
  });

  _exports.default = _default;
});