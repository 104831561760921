define("frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transferred/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d4nRadyL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"transferred\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transferred/styles\"]]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"title\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transferred/styles\"]]]]]],[12],[2,\"🎉 Transferred! 🙌\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transferred/template.hbs"
    }
  });

  _exports.default = _default;
});