define("frontend/pods/user-profile/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UserIndexController = Ember.Controller.extend({
    dialLeadTimes: [{
      value: 0,
      text: '12am'
    }, {
      value: 1 * 60,
      text: '1am'
    }, {
      value: 2 * 60,
      text: '2am'
    }, {
      value: 3 * 60,
      text: '3am'
    }, {
      value: 4 * 60,
      text: '4am'
    }, {
      value: 5 * 60,
      text: '5am'
    }, {
      value: 6 * 60,
      text: '6am'
    }, {
      value: 7 * 60,
      text: '7am'
    }, {
      value: 8 * 60,
      text: '8am'
    }, {
      value: 9 * 60,
      text: '9am'
    }, {
      value: 10 * 60,
      text: '10am'
    }, {
      value: 11 * 60,
      text: '11am'
    }, {
      value: 12 * 60,
      text: '12pm'
    }, {
      value: 13 * 60,
      text: '1pm'
    }, {
      value: 14 * 60,
      text: '2pm'
    }, {
      value: 15 * 60,
      text: '3pm'
    }, {
      value: 16 * 60,
      text: '4pm'
    }, {
      value: 17 * 60,
      text: '5pm'
    }, {
      value: 18 * 60,
      text: '6pm'
    }, {
      value: 19 * 60,
      text: '7pm'
    }, {
      value: 20 * 60,
      text: '8pm'
    }, {
      value: 21 * 60,
      text: '9pm'
    }, {
      value: 22 * 60,
      text: '10pm'
    }, {
      value: 23 * 60,
      text: '11pm'
    }, {
      value: 24 * 60,
      text: '12am'
    }],
    actions: {
      randomizeCallerIdChanged: function randomizeCallerIdChanged(value) {
        return this.set('model.randomizeCallerId', value);
      }
    }
  });
  var _default = UserIndexController;
  _exports.default = _default;
});