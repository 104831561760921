define("frontend/models/lead-status", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    statusType: (0, _model.attr)('string'),
    sortOrder: (0, _model.attr)('number'),
    isTransferDefault: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isImportDefault: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    organization: (0, _model.belongsTo)('organization')
  });

  _exports.default = _default;
});