define("frontend/pods/user-profile/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UserProfileRoute = Ember.Route.extend({
    requiresLogin: true,
    model: function model(params) {
      var id = this.get('authenticationService.activeUserId');
      return this.store.find('user', id);
    },
    actions: {
      save: function save() {
        var _this = this;

        return this.get('controller.model').save().then(function () {
          return _this.alertsService.success('Your changes have been saved.');
        }).then(null, function () {});
      }
    }
  });
  var _default = UserProfileRoute;
  _exports.default = _default;
});