define("frontend/pods/components/view/call-control-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2MVFG5aR",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"callService\"],[[35,0]]]]],[2,\"\\n\"],[1,[30,[36,3],null,[[\"callService\"],[[35,0]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"sidebar-scrolling-region\"],[12],[2,\"\\n  \"],[1,[34,4]],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"callService\"],[[35,0]]]]],[2,\"\\n\\n\"],[6,[37,6],[[35,0,[\"authentication\",\"isAgent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"callService\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[1,[30,[36,7],null,[[\"callService\"],[[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"callService\",\"call-lead-list\",\"call-status\",\"call-buttons\",\"soft-phone/control-panel\",\"call-lead-card\",\"unless\",\"call-bottom-bar\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/view/call-control-panel/template.hbs"
    }
  });

  _exports.default = _default;
});