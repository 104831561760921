define("frontend/pods/components/agent/agent-console/current-call/actions/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "mute-banner": "_mute-banner_r0x9on",
    "notes": "_notes_r0x9on"
  };
  _exports.default = _default;
});