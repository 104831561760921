define("frontend/pods/organizations/organization/flagged-phone-numbers/controller", ["exports", "moment", "frontend/utils/paginated"], function (_exports, _moment, _paginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationFlaggedPhoneNumbersController = Ember.Controller.extend({
    canEditUsers: Ember.computed.equal('authenticationService.activeRole', 'admin'),
    canShowUsers: Ember.computed.equal('authenticationService.activeRole', 'admin'),
    organizationController: Ember.inject.controller('organizations.organization'),
    filters: Ember.computed.readOnly('organizationController.filters'),
    dashboard: Ember.computed.readOnly('organizationController.dashboard'),
    filteredUsers: Ember.computed.readOnly('organizationController.filteredUsers'),
    start: Ember.computed.readOnly('organizationController.start'),
    list: Ember.computed.readOnly('organizationController.list'),
    useStartTime: Ember.computed.readOnly('organizationController.skip'),
    numbers: Ember.computed('model.id', 'filters', 'start', 'list', 'useStartTime', function () {
      var filters = this.filters || {
        startDate: (0, _moment.default)(this.start).startOf('day').toDate(),
        leadListIds: [this.list]
      } || {
        startDate: (0, _moment.default)().startOf('day').toDate()
      };
      var query = {
        organization_id: this.get('model.id'),
        use_start_time: this.useStartTime
      };

      if (filters.startDate) {
        query.start_time = filters.startDate.toISOString();
      }

      if (filters.endDate) {
        query.end_time = filters.endDate.toISOString();
      }

      query.user_id = this.get('authenticationService.activeUserId');

      if (filters.userIds) {
        query.user_ids = filters.userIds;
      }

      if (filters.statuses) {
        query.statuses = filters.statuses;
      }

      if (filters.leadListIds) {
        query.lead_list_ids = filters.leadListIds;
      }

      if (filters.teamIds) {
        query.team_ids = filters.teamIds;
      }

      if (filters.session) {
        query.session = filters.session;
      }

      if (filters.dialResults) {
        query.dial_results = filters.dialResults;
      }

      if (filters.agentConversationResults) {
        query.agent_conversation_results = filters.agentConversationResults;
      }

      query.flagged = true;
      return _paginated.default.Array.find('lead-phone-number', {
        store: this.store,
        pageSize: 100,
        query: query
      });
    }),
    actions: {
      nextPage: function nextPage() {
        return this.numbers.nextPage();
      }
    }
  });
  var _default = OrganizationFlaggedPhoneNumbersController;
  _exports.default = _default;
});