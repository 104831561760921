define("frontend/pods/auth/reset-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FxZG6llG",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"title\"],[\"Password Reset\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"placeholder text-center\"],[12],[10,\"i\"],[14,0,\"fa fa-key\"],[12],[13],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"panel panel-default col-sm-6 col-sm-offset-3\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,1],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[12],[2,\"Reset your password by entering a new password below.\"],[13],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],null,[[\"model\",\"onSubmit\"],[[35,0],[30,[36,3],[[32,0],[30,[36,2],[\"submit\"],null]],null]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"error-list\",\"route-action\",\"action\",\"partial/auth/reset-password/form\",\"view/unauthenticated-box\"]}",
    "meta": {
      "moduleName": "frontend/pods/auth/reset-password/template.hbs"
    }
  });

  _exports.default = _default;
});