define("frontend/pods/call-sessions/index/route", ["exports", "frontend/mixins/additional-models"], function (_exports, _additionalModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallSessionsIndexRoute = Ember.Route.extend(_additionalModels.default, {
    model: function model(params) {
      // perform the query only if the query params have been specified
      // or if the default filters have been set (by the controller) --
      // it's kinda weird that the controller sets the default query params
      // when the route must refresh the data, but this is definitely an edge case,
      // where the params must be calculated at the controller level too
      var areParamsInitialized = params.dateFilter;

      if (areParamsInitialized) {
        var filter = {};

        if (params.start) {
          filter.start = params.start;
        }

        if (params.end) {
          filter.end = params.end;
        }

        if (params.userId) {
          filter.user_id = params.userId;
        }

        return this.store.query('call-session', filter);
      }
    },
    queryParams: {
      start: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      },
      userId: {
        refreshModel: true
      }
    },
    additionalModels: function additionalModels() {
      if (this.get('authenticationService.isAdmin')) {
        return {
          users: this.store.findAll('user')
        };
      } else {
        return {
          users: []
        };
      }
    }
  });
  var _default = CallSessionsIndexRoute;
  _exports.default = _default;
});