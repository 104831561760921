define("frontend/pods/components/lead-status-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var LeadStatusSelectComponent = Ember.Component.extend((_EmberComponent$exte = {
    lead: null,
    outboundCall: null,
    onChange: null,
    format: 'select'
  }, _defineProperty(_EmberComponent$exte, "onChange", function onChange() {}), _defineProperty(_EmberComponent$exte, "model", Ember.computed('lead', 'outboundCall', function () {
    return this.outboundCall || this.lead;
  })), _defineProperty(_EmberComponent$exte, "status", Ember.computed.readOnly('model.status')), _defineProperty(_EmberComponent$exte, "masterStatus", Ember.computed.readOnly('model.masterStatus')), _defineProperty(_EmberComponent$exte, "leadStatusName", Ember.computed.readOnly('model.leadStatusName')), _defineProperty(_EmberComponent$exte, "isReady", false), _defineProperty(_EmberComponent$exte, "serviceProvider", Ember.computed.readOnly('lead.serviceProvider')), _defineProperty(_EmberComponent$exte, "selectedValue", null), _defineProperty(_EmberComponent$exte, "_initializeSelectedValue", function _initializeSelectedValue() {
    var selectedValue;
    var options = this.options;

    if (options) {
      if (this.masterStatus) {
        selectedValue = options.findBy('masterStatus', this.masterStatus);
      } else if (this.leadStatusName) {
        selectedValue = options.findBy('leadStatusName', this.leadStatusName);
      } else {
        selectedValue = options.findBy('status', this.status);
      }
    } else {
      selectedValue = null;
    }

    return this.set('selectedValue', selectedValue);
  }), _defineProperty(_EmberComponent$exte, "_selectedValueChanged", Ember.observer('selectedValue', function () {
    var selectedValue = this.selectedValue;

    if (selectedValue) {
      var statusChanged = selectedValue.status !== this.status;
      var masterStatusChanged = selectedValue.masterStatus !== this.masterStatus;
      var leadStatusNameChanged = selectedValue.leadStatusName !== this.leadStatusName;

      if (statusChanged || masterStatusChanged || leadStatusNameChanged) {
        return this.onChange(selectedValue.status, selectedValue.masterStatus, selectedValue.leadStatusName);
      }
    }
  })), _defineProperty(_EmberComponent$exte, "options", null), _defineProperty(_EmberComponent$exte, "_setOptions", Ember.observer('lead', function () {
    var _this = this;

    this.set('isReady', false);
    return this._getOptions().then(function (options) {
      if (!_this.isDestroyed) {
        _this.setProperties({
          options: options,
          isReady: true
        });

        return _this._initializeSelectedValue();
      }
    });
  })), _defineProperty(_EmberComponent$exte, "_getOptions", function _getOptions() {
    var _this2 = this;

    return this._getServiceProviderStatusOptions().then(function (result) {
      return result || _this2._getOrganizationStatusOptions();
    }).then(function (result) {
      return result || _this2.defaultOptions;
    });
  }), _defineProperty(_EmberComponent$exte, "_getServiceProviderStatusOptions", function _getServiceProviderStatusOptions() {
    var _this3 = this;

    var type = this.get('lead.masterObjectType');
    return Ember.RSVP.resolve().then(function () {
      if (!type) {
        return;
      }

      return _this3.get('lead.serviceProvider').then(function (serviceProvider) {
        if (!serviceProvider) {
          return;
        }

        var statuses = serviceProvider.get("settings.lead_statuses.".concat(type));

        if (!statuses) {
          return;
        }

        var statusMap = serviceProvider.get("settings.lead_status_map.".concat(type)) || {};
        return statuses.map(function (s) {
          var status = statusMap[s.name] ? statusMap[s.name].status : s.maps_to;
          return {
            id: s.name,
            text: s.name,
            masterStatus: s.name,
            leadStatusName: null,
            status: status
          };
        });
      });
    });
  }), _defineProperty(_EmberComponent$exte, "_getOrganization", function _getOrganization() {
    var _this4 = this;

    return new Ember.RSVP.Promise(function (resolve, reject) {
      var user = _this4.get('lead.user');

      if (!user) {
        return resolve(null);
      }

      var organizationId = user.get('organizationId');

      if (!organizationId) {
        return resolve(null);
      }

      return _this4.get('lead.store').find('organization', organizationId).then(resolve).catch(reject);
    });
  }), _defineProperty(_EmberComponent$exte, "_getOrganizationStatusOptions", function _getOrganizationStatusOptions() {
    return this._getOrganization().then(function (organization) {
      if (!organization) {
        return;
      }

      var leadStatuses = organization.get('leadStatuses');

      if (Ember.isEmpty(leadStatuses)) {
        return;
      }

      return leadStatuses.sortBy('sortOrder').map(function (s) {
        return {
          id: s.get('id'),
          text: s.get('name'),
          masterStatus: null,
          leadStatusName: s.get('name'),
          status: s.get('statusType')
        };
      });
    });
  }), _defineProperty(_EmberComponent$exte, "defaultOptions", [{
    id: 'open',
    text: 'Open',
    masterStatus: null,
    leadStatusName: null,
    status: 'open'
  }, {
    id: 'qualified',
    text: 'Qualified',
    masterStatus: null,
    leadStatusName: null,
    status: 'qualified'
  }, {
    id: 'closed',
    text: 'Closed',
    masterStatus: null,
    leadStatusName: null,
    status: 'closed'
  }]), _defineProperty(_EmberComponent$exte, "_initializeProperties", Ember.on('init', function () {
    this.getProperties('lead', 'outboundCall', 'model', 'selectedValue');
    return this._setOptions();
  })), _EmberComponent$exte));
  var _default = LeadStatusSelectComponent;
  _exports.default = _default;
});