define("frontend/models/organization-phone-number-cnam-record", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    provider: (0, _model.attr)('string'),
    cnam: (0, _model.attr)('string'),
    approvedAt: (0, _model.attr)('date'),
    rejectedAt: (0, _model.attr)('date'),
    rejectionMessage: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    organization: (0, _model.belongsTo)('organization')
  });

  _exports.default = _default;
});