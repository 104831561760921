define("frontend/pods/components/call-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallButtonsComponent = Ember.Component.extend({
    callService: null,
    isAgent: Ember.computed.readOnly('callService.authentication.isAgent'),
    isClient: Ember.computed.not('isAgent'),
    hasLeadList: Ember.computed.bool('callService.leadList'),
    isExecutingCommand: Ember.computed.readOnly('callService.isExecutingCommand'),
    canExecuteCommand: Ember.computed.not('isExecutingCommand'),
    canPowerOn: Ember.computed.readOnly('callService.isOffline'),
    isPoweredOn: Ember.computed.readOnly('callService.isOnline'),
    canPlay: Ember.computed.and('canExecuteCommand', 'callService.isPaused'),
    canEject: Ember.computed.and('canExecuteCommand', 'callService.isConnected'),
    canHaveFastForward: Ember.computed.readOnly('isClient'),
    canFastForward: Ember.computed.and('canExecuteCommand', 'isClient', 'callService.isPaused'),
    isEavesdropping: Ember.computed.readOnly('callService.isEavesdropping'),
    isCoaching: Ember.computed.readOnly('callService.isCoaching'),
    canStop: Ember.computed('canExecuteCommand', 'callService.isIdle', 'callService.isEavesdropping', 'callService.isCoaching', {
      get: function get() {
        return this.canExecuteCommand && (this.callService.isIdle || this.callService.isEavesdropping || this.callService.isCoaching);
      }
    }),
    isNotOnCall: Ember.computed.not('callService.isOnCall'),
    isOffCall: Ember.computed.and('isPoweredOn', 'isNotOnCall'),
    canPowerOff: Ember.computed.and('canExecuteCommand', 'isOffCall'),
    canBeep: Ember.computed.and('canExecuteCommand', 'isOffCall'),
    canHaveLeadLists: Ember.computed.readOnly('isClient'),
    canSelectLeadList: Ember.computed.and('canHaveLeadLists', 'isNotOnCall'),
    canHaveVoicemail: Ember.computed.not('callService.authentication.isAgent'),
    canSelectVoicemail: Ember.computed.and('canHaveVoicemail', 'isNotOnCall'),
    actions: {
      powerOn: function powerOn() {
        this.callService.callMe();
      },
      powerOff: function powerOff() {
        this.callService.hangup();
      },
      play: function play() {
        if (this.isAgent) {
          this.callService.resume();
        } else {
          this.callService.dialNext();
        }
      },
      fastForward: function fastForward() {
        this.callService.resume();
      },
      stop: function stop() {
        this.callService.pause();
      },
      eject: function eject() {
        this.callService.disconnect();
      },
      beep: function beep() {
        this.callService.beep();
      }
    }
  });
  var _default = CallButtonsComponent;
  _exports.default = _default;
});