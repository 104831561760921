define("frontend/pods/components/soft-phone/control-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    softphone: Ember.inject.service(),
    tagName: '',
    isShowingDialpad: false,
    toggleDialpad: function toggleDialpad() {
      this.set('isShowingDialpad', !this.isShowingDialpad);
    }
  });

  _exports.default = _default;
});