define("frontend/pods/components/agent/current-call/lead-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FEH91RwM",
    "block": "{\"symbols\":[\"widget\",\"&attrs\"],\"statements\":[[8,\"agent/agent-console/widget\",[[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"heading\"]],[],[[\"@title\"],[\"Lead\"]],null],[2,\"\\n  \"],[8,[32,1,[\"body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"lead\"],[[\"from\"],[\"frontend/pods/components/agent/current-call/lead-info/styles\"]]]]]],[12],[2,\"\\n      \"],[10,\"h1\"],[15,0,[31,[[30,[36,0],[\"name\"],[[\"from\"],[\"frontend/pods/components/agent/current-call/lead-info/styles\"]]]]]],[12],[1,[35,1,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,2],[[35,1,[\"title\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"h2\"],[15,0,[31,[[30,[36,0],[\"title\"],[[\"from\"],[\"frontend/pods/components/agent/current-call/lead-info/styles\"]]]]]],[12],[1,[35,1,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,1,[\"companyName\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"h2\"],[15,0,[31,[[30,[36,0],[\"company\"],[[\"from\"],[\"frontend/pods/components/agent/current-call/lead-info/styles\"]]]]]],[12],[1,[35,1,[\"companyName\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"h4\"],[15,0,[31,[[30,[36,0],[\"phone\"],[[\"from\"],[\"frontend/pods/components/agent/current-call/lead-info/styles\"]]]]]],[12],[1,[35,1,[\"phone\"]]],[2,\" \"],[10,\"span\"],[14,0,\"label label-primary\"],[12],[1,[35,1,[\"phoneAdditionalInfo\"]]],[13],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"lead\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/current-call/lead-info/template.hbs"
    }
  });

  _exports.default = _default;
});