define("frontend/models/lead-phone-number", ["exports", "@ember-data/model", "frontend/models/lead"], function (_exports, _model, _lead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LeadPhoneNumber = _model.default.extend({
    leadId: (0, _model.attr)('number'),
    rawPhoneNumber: (0, _model.attr)('string'),
    formattedPhoneNumber: (0, _model.attr)('string'),
    phoneValid: (0, _model.attr)('boolean'),
    phoneAdditionalInfo: (0, _model.attr)('string'),
    phoneType: (0, _model.attr)('string'),
    dialingPriority: (0, _model.attr)('number'),
    lastDialedAt: (0, _model.attr)('date'),
    deletedAt: (0, _model.attr)('date'),
    agentFlaggedAt: (0, _model.attr)('date'),
    userMarkedDoNotCallAt: (0, _model.attr)('date'),
    leadMasterId: (0, _model.attr)('string'),
    phoneMasterId: (0, _model.attr)('string'),
    agentFlagged: (0, _model.attr)('boolean'),
    doNotCall: (0, _model.attr)('boolean'),
    lead: (0, _model.belongsTo)('lead')
  });

  var _default = LeadPhoneNumber;
  _exports.default = _default;
});