define("frontend/pods/components/data-source-field-mapping-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    headers: null,
    fieldMapping: null,
    fieldName: null,
    allowClear: true,
    placeholder: '< select a field >',
    value: null,
    tagName: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._initializeValue();
    },
    _initializeValue: function _initializeValue() {
      var p = this.getProperties('fieldMapping', 'fieldName');

      if (p.fieldMapping && p.fieldName) {
        this.set('value', p.fieldMapping[p.fieldName]);
      } else {
        this.set('value', null);
      }
    },
    _updateFieldMapping: Ember.observer('value', function () {
      var p = this.getProperties('fieldMapping', 'fieldName', 'value');

      if (p.fieldMapping && p.fieldName) {
        p.fieldMapping[p.fieldName] = p.value;
      }
    })
  });

  _exports.default = _default;
});