define("frontend/routes/users/user/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    actions: {
      impersonate: function impersonate() {
        var userId = this.get('controller.model.id');
        return this.authenticationService.impersonate(userId);
      },
      invite: function invite() {
        var _this = this;

        var userId = this.get('controller.model.id');
        var adapter = this.store.adapterFor('application');
        return adapter.executeServerAction('user', userId, 'invite').then(function () {
          return _this.alertsService.success("The invitation has been sent.");
        });
      }
    }
  });

  _exports.default = _default;
});