define("frontend/models/linked-in-profile", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    hasProfile: (0, _model.attr)('boolean'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    location: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    industry: (0, _model.attr)('string'),
    summary: (0, _model.attr)('string'),
    picture: (0, _model.attr)('string'),
    name: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.firstName;
      var lastName = this.lastName;
      return "".concat(firstName, " ").concat(lastName).trim();
    }),
    place: Ember.computed('location', 'country', function () {
      var location = this.location;
      var country = this.country;

      if (location && country) {
        return "".concat(location, ", ").concat(country).trim();
      } else {
        return "".concat(location, " ").concat(country).trim();
      }
    })
  });

  _exports.default = _default;
});