define("frontend/pods/components/service-provider/map-phone-numbers/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serviceProvider: null,
    serviceProviderExtendedInfo: null,
    onChange: function onChange() {},
    tagName: '',
    availableFields: Ember.computed.readOnly('serviceProviderExtendedInfo.availableFields'),
    fields: Ember.computed('serviceProvider.settings', function () {
      if (!this.get('serviceProvider.settings')) {
        this.set('serviceProvider.settings', {});
      }

      var settings = this.get('serviceProvider.settings');

      if (!settings.fields) {
        settings.fields = {};
      }

      return settings.fields;
    }),
    fieldNames: Ember.computed('fields', {
      get: function get() {
        var fields = this.fields;
        var result = [];
        Object.keys(fields).forEach(function (type) {
          fields[type].forEach(function (f) {
            return result.push(f);
          });
        });
        return result.uniq().sort();
      }
    }),
    defaultMapping: Ember.computed.readOnly('serviceProviderExtendedInfo.defaultPhoneNumberFieldMapping'),
    settingsMapping: Ember.computed.readOnly('serviceProvider.settings.phone_number_field_mapping'),
    mapping: Ember.computed('defaultMapping', 'settingsMapping', {
      get: function get() {
        return this.settingsMapping || this.defaultMapping || [];
      }
    }),
    items: Ember.computed('mapping', {
      get: function get() {
        return this.mapping.map(function (item, index) {
          return Ember.Object.create({
            fieldName: item.field_name,
            type: item.type,
            use: item.use,
            index: index
          });
        });
      }
    }),
    itemChanged: function itemChanged(item) {
      this.doChange();
    },
    add: function add() {
      var index = this.items.length;
      var item = Ember.Object.create({
        fieldName: null,
        type: null,
        use: true,
        index: index
      });
      this.items.addObject(item);
      this.doChange();
    },
    deleteItem: function deleteItem(item) {
      this.items.removeObject(item);
      this.doChange();
    },
    moveItem: function moveItem(from, to) {
      var item = this.items.objectAt(from);
      this.items.removeObject(item);

      if (from > to) {
        this.items.insertAt(to, item);
      } else {
        this.items.insertAt(to, item);
      }

      this.items.forEach(function (item, i) {
        return item.set('index', i);
      });
      this.doChange();
    },
    doChange: function doChange() {
      var data = this.items.map(function (item) {
        return {
          field_name: item.fieldName,
          type: item.type,
          use: item.use
        };
      });
      this.onChange(data);
    }
  });

  _exports.default = _default;
});