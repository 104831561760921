define("frontend/pods/components/lead-status-label/component", ["exports", "frontend/models/lead"], function (_exports, _lead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadStatusLabelComponent = Ember.Component.extend({
    tagName: '',
    model: null,
    lead: Ember.computed.alias('model'),
    masterStatus: Ember.computed.oneWay('model.masterStatus'),
    leadStatusName: Ember.computed.oneWay('model.leadStatusName'),
    status: Ember.computed.oneWay('model.status'),
    isOnHold: Ember.computed.oneWay('model.isOnHold'),
    onHoldUntil: Ember.computed.oneWay('model.onHoldUntil'),
    labelClass: Ember.computed('status', 'isOnHold', function () {
      if (this.isOnHold) {
        return 'label-warning';
      } else {
        switch (this.status) {
          case 'open':
            return 'label-primary';

          case 'qualified':
            return 'label-success';

          case 'closed':
            return 'label-default';
        }
      }
    }),
    statusText: Ember.computed('status', 'masterStatus', 'leadStatusName', function () {
      var masterStatus = this.masterStatus;

      if (masterStatus) {
        return masterStatus;
      }

      var leadStatusName = this.leadStatusName;

      if (leadStatusName) {
        return leadStatusName;
      }

      var status = this.status;

      var s = _lead.default.statusValues.findBy('value', status);

      if (s) {
        return s.text;
      }
    }),
    text: Ember.computed('statusText', 'onHoldText', function () {
      var statusText = this.statusText || '';
      var onHoldText = this.onHoldText || '';
      return onHoldText || statusText;
    }),
    onHoldText: Ember.computed('isOnHold', 'onHoldUntil', function () {
      if (!this.isOnHold) {
        return;
      }

      var date = this.onHoldUntil;

      if (!date) {
        return;
      }

      return "On Hold until ".concat(date.toLocaleDateString());
    }),
    isShowing: Ember.computed.or('statusText', 'onHoldText')
  });
  var _default = LeadStatusLabelComponent;
  _exports.default = _default;
});