define("frontend/pods/components/input-integrations/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputIntegrationsComponent = Ember.Component.extend({
    value: null,
    onChange: function onChange() {},
    selected: Ember.computed('value', {
      get: function get() {
        var value = this.value;

        if (value) {
          return value.toString().split(',');
        } else {
          return [];
        }
      },
      set: function set(key, value) {
        this.onChange((value || []).join(','));
        return value;
      }
    }),
    integrations: [{
      value: 'csv',
      title: 'CSV'
    }, {
      value: 'salesforce',
      title: 'Salesforce'
    }, {
      value: 'hubspot',
      title: 'Hubspot'
    }, {
      value: 'outreach',
      title: 'Outreach'
    }, {
      value: 'salesloft',
      title: 'Salesloft'
    }, {
      value: 'dynamics',
      title: 'Dynamics'
    }, {
      value: 'zoho',
      title: 'Zoho'
    }, {
      value: 'pipedrive',
      title: 'Pipedrive'
    }]
  });
  var _default = InputIntegrationsComponent;
  _exports.default = _default;
});