define("frontend/pods/auth/reset-password/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    requiresLogin: false,
    model: function model(params) {
      return this.store.createRecord('password-reset', {
        token: params.token
      });
    },
    store: Ember.inject.service(),
    actions: {
      submit: function submit() {
        var _this = this;

        var model = this.get('controller.model');
        return model.save().then(function () {
          var session = model.get('session');
          return _this.authenticationService.login(session);
        }).then(function () {
          return _this.send('redirectAfterLogin');
        }).then(null, function () {});
      }
    }
  });

  _exports.default = _default;
});