define("frontend/models/execvision-account", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    sftpHostname: (0, _model.attr)('string'),
    username: (0, _model.attr)('string'),
    privateKeyPath: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean'),
    historicSyncDaysLimit: (0, _model.attr)('number'),
    organization: (0, _model.belongsTo)('organization')
  });

  _exports.default = _default;
});