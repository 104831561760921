define("frontend/routes/users/user/edit", ["exports", "frontend/pods/base/edit/route", "frontend/mixins/additional-models"], function (_exports, _route, _additionalModels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_additionalModels.default, {
    additionalModels: function additionalModels(model) {
      return {
        organizations: this.store.findAll('organization')
      };
    },
    afterSave: function afterSave() {
      return this.transitionTo('users.user.index', this.get('controller.model'));
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('users.user.index', this.get('controller.model'));
    }
  });

  _exports.default = _default;
});