define("frontend/pods/components/soft-phone/dial-pad/dial-button/component", ["exports", "frontend/pods/components/soft-phone/dial-pad/dial-button/styles"], function (_exports, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    letters: null,
    digit: null,
    onClicked: function onClicked() {},
    tagName: 'button',
    classNames: ['btn'],
    localClassNames: ['dialpad-button'],
    attributeBindings: ['disabled'],
    softphone: Ember.inject.service(),
    isEnabled: Ember.computed.readOnly('softphone.isOnline'),
    disabled: Ember.computed.not('isEnabled'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._flash = function (digit) {
        if (digit === _this.digit) {
          _this.flash();
        }
      };

      this.softphone.on('dtmf', this._flash);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.softphone.off('dtmf', this._flash);
    },
    flash: function flash() {
      var _this2 = this;

      var removeAnimationClass = function removeAnimationClass() {
        if (_this2.element && !_this2.isDestroyed) {
          _this2.element.classList.remove(_styles.default.flash);
        }
      };

      this.element.addEventListener('animationend', removeAnimationClass, {
        once: true
      });
      this.element.classList.add(_styles.default.flash);
    },
    click: function click() {
      this.softphone.dtmf(this.digit);
      this.onClicked(this.digit);
    }
  });

  _exports.default = _default;
});