define("frontend/pods/components/input-country-codes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputCountryCodesComponent = Ember.Component.extend({
    value: null,
    onChange: function onChange() {},
    selected: Ember.computed('value', {
      get: function get() {
        var value = this.value;

        if (value) {
          return value.toString().split(',');
        } else {
          return [];
        }
      },
      set: function set(key, value) {
        this.onChange((value || []).join(','));
        return value;
      }
    }),
    // Great resource for international calling: https://www.howtocallabroad.com
    countryCodes: [{
      value: '1',
      title: 'US and Canada (+1)'
    }, {
      value: '54',
      title: 'Argentina (+54)'
    }, {
      value: '43',
      title: 'Austria (+43)'
    }, {
      value: '32',
      title: 'Belgium (+32)'
    }, {
      value: '55',
      title: 'Brazil (+55)'
    }, {
      value: '591',
      title: 'Bolivia (+591)'
    }, {
      value: '56',
      title: 'Chile (+56)'
    }, {
      value: '57',
      title: 'Colombia (+57)'
    }, {
      value: '45',
      title: 'Denmark (+45)'
    }, {
      value: '33',
      title: 'France (+33)'
    }, {
      value: '49',
      title: 'Germany (+49)'
    }, {
      value: '30',
      title: 'Greece (+30)'
    }, {
      value: '91',
      title: 'India (+91)'
    }, {
      value: '353',
      title: 'Ireland (+353)'
    }, {
      value: '39',
      title: 'Italy (+39)'
    }, {
      value: '52',
      title: 'Mexico (+52)'
    }, {
      value: '31',
      title: 'Netherlands (+31)'
    }, {
      value: '595',
      title: 'Paraguay (+595)'
    }, {
      value: '51',
      title: 'Peru (+51)'
    }, {
      value: '351',
      title: 'Portugal (+351)'
    }, {
      value: '34',
      title: 'Spain (+34)'
    }, {
      value: '46',
      title: 'Sweden (+46)'
    }, {
      value: '90',
      title: 'Turkey (+90)'
    }, {
      value: '971',
      title: 'United Arab Emirates (+971)'
    }, {
      value: '44',
      title: 'United Kingdom (+44)'
    }, {
      value: '598',
      title: 'Uruguay (+598)'
    }]
  });
  var _default = InputCountryCodesComponent;
  _exports.default = _default;
});