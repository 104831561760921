define("frontend/pods/lead-lists/route", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadListsRoute = Ember.Route.extend(_modal.default, {
    requiresLogin: true,
    actions: {
      stats: function stats(model) {
        var list;
        model.set('refreshing', true);
        var adapter = this.store.adapterFor('application');
        return list = adapter.executeServerAction('lead_lists', null, model.id).then(function (result) {
          model.set('leadListOpenCount', result.lead_list.lead_list_open_count);
          model.set('leadListFlaggedCount', result.lead_list.lead_list_flagged_count);
          model.set('leadListDoNotCallCount', result.lead_list.lead_list_do_not_call_count);
          model.set('leadListConversationCount', result.lead_list.lead_list_conversation_count);
          model.set('leadListAppointmentCount', result.lead_list.lead_list_appointment_count);
          model.set('leadListRemainingCount', result.lead_list.lead_list_remaining_count);
          model.set('statsRefreshed', new Date());
          return model.set('refreshing', false);
        });
      }
    }
  });
  var _default = LeadListsRoute;
  _exports.default = _default;
});