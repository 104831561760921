define("frontend/pods/greeting-recordings/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GreetingRecordingsIndexController = Ember.Controller.extend({
    sortedModels: Ember.computed('model.@each.name', {
      get: function get() {
        var models = this.get('model') || [];
        return models.sortBy('name');
      }
    })
  });
  var _default = GreetingRecordingsIndexController;
  _exports.default = _default;
});