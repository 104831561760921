define("frontend/mixins/additional-models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AdditionalModelsMixin = Ember.Mixin.create({
    // override to return a hash of properties / models to set on the controller
    additionalModels: function additionalModels(model, transition, queryParams) {},
    // returns a promise that will resolve once all additional models have resolved
    initializeAdditionalModels: function initializeAdditionalModels(model, transition, queryParams) {
      var models = this.additionalModels(model, transition, queryParams);

      if (models) {
        var promise = Ember.RSVP.hash(models);
        this.set('additionalModelsPromise', promise);
        return promise;
      }
    },
    // copies the resolved properties onto the controller
    setupControllerAdditionalModels: function setupControllerAdditionalModels(controller) {
      var modelsPromise = this.additionalModelsPromise;

      if (modelsPromise) {
        return modelsPromise.then(function (m) {
          return controller.setProperties(m);
        });
      }
    },
    // hook to initialize the additional models -- blocks until all models have been resolved
    afterModel: function afterModel(model, transition, queryParams) {
      return this.initializeAdditionalModels(model, transition, queryParams);
    },
    // hook to copy the models onto the controller -- occurs after all models have been resolved
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      return this.setupControllerAdditionalModels(controller);
    },
    // copies the additional models from the specified route (usually a parent route) --
    // call this in setupController()
    copyAdditionalModels: function copyAdditionalModels(controller, fromRouteName) {
      for (var _len = arguments.length, propertyNames = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        propertyNames[_key - 2] = arguments[_key];
      }

      var fromRoute = this.container.lookup("route:".concat(fromRouteName));
      var fromController = this.controllerFor(fromRouteName);
      var promise = fromRoute.get('additionalModelsPromise') || Ember.RSVP.resolve();
      return promise.then(function () {
        return controller.setProperties(fromController.getProperties(propertyNames));
      });
    },
    additionalModelsFor: function additionalModelsFor(routeName) {
      var route = this.container.lookup("route:".concat(routeName));
      return route.additionalModelsPromise || Ember.RSVP.resolve();
    }
  });
  var _default = AdditionalModelsMixin;
  _exports.default = _default;
});