define("frontend/pods/components/ui-wizard/wizard-step/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    name: null,
    currentStep: null,
    tagName: '',
    isCurrentStep: Ember.computed('name', 'currentStep', {
      get: function get() {
        return this.name === this.currentStep;
      }
    })
  });

  _exports.default = _default;
});