define("frontend/mixins/in-viewport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InViewportMixin = Ember.Mixin.create({
    // toggles based on whether or not the element is currently in the viewport
    inViewport: false,
    // toggles the first time the element has been in the viewport
    everInViewport: false,
    _scrollElement: null,
    // yanked from jquery UI
    _getScrollParent: function _getScrollParent() {
      var el = this.$();
      var position = el.css('position');
      var excludeStaticParent = position === 'absolute';
      var scrollParent = el.parents().filter(function () {
        var parent = window.$(this);

        if (excludeStaticParent && parent.css('position') === 'static') {
          return false;
        }

        return /(auto|scroll)/.test(parent.css('overflow') + parent.css('overflow-y') + parent.css('overflow-x'));
      }).eq(0);

      if (position === 'fixed' || !scrollParent.length) {
        return window.$(el.ownerDocument || document);
      } else {
        return scrollParent;
      }
    },
    _hookScrolling: Ember.on('didInsertElement', function () {
      var scrollElement = this._getScrollParent();

      if (!scrollElement.length) {
        console.warn("no scrolling element has been found for the infinite scroll indicator");
        return;
      }

      this.set('_scrollElement', scrollElement);
      var fn = this._hookFunction;
      scrollElement.on('scroll', fn);
      return this._checkScroll();
    }),
    _unhookScrolling: Ember.on('willDestroyElement', function () {
      var scrollElement = this._scrollElement;
      var fn = this._hookFunction;

      if (scrollElement) {
        return scrollElement.off('scroll', fn);
      }
    }),
    _hookFunction: Ember.computed(function () {
      var _this = this;

      return function () {
        return Ember.run.debounce(_this, _this._checkScroll, 100);
      };
    }),
    _checkScroll: function _checkScroll() {
      var contentHeight, viewportHeight;
      var scrollElement = this._scrollElement;

      if (!scrollElement.length) {
        return;
      }

      var scrollTop = scrollElement.scrollTop();

      if (scrollElement[0] === window.document) {
        contentHeight = scrollElement.height();
        viewportHeight = window.$(window).height();
      } else {
        contentHeight = scrollElement[0].scrollHeight;
        viewportHeight = scrollElement.height();
      }

      var el = this.$();

      if (!el) {
        return;
      }

      var elementTop = el.offset().top;
      var elementHeight = el.height();
      var wasInViewport = this.inViewport;
      var isInViewport = elementTop <= scrollTop + viewportHeight && elementTop + elementHeight >= scrollTop;

      if (isInViewport !== wasInViewport) {
        this.toggleProperty('inViewport');
      }

      if (isInViewport && !this.everInViewport) {
        return this.toggleProperty('everInViewport');
      }
    },
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('inViewport', 'everInViewport');
    })
  });
  var _default = InViewportMixin;
  _exports.default = _default;
});