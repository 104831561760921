define("frontend/templates/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XkkrzPbx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-separator col-unscrollable col-separator-last\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-table\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll border-bottom\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h4\"],[14,0,\"margin-none\"],[12],[2,\"New User\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll inner-2x\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"user-form\",[],[[\"@model\",\"@organizations\",\"@teams\"],[[34,0],[34,1],[34,2]]],null],[2,\"\\n\\t\\t\\t\"],[8,\"ui-button\",[],[[\"@icon\",\"@text\",\"@isWaiting\",\"@isPrimary\",\"@onClick\"],[\"save\",\"Save Changes\",[34,0,[\"isSaving\"]],\"true\",[30,[36,4],[[32,0],[30,[36,3],[\"save\"],null]],null]]],null],[2,\"\\n\\t\\t\\t\"],[8,\"ui-button\",[],[[\"@text\",\"@onClick\"],[\"Cancel\",[30,[36,4],[[32,0],[30,[36,3],[\"cancel\"],null]],null]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"organizations\",\"teams\",\"route-action\",\"action\"]}",
    "meta": {
      "moduleName": "frontend/templates/users/new.hbs"
    }
  });

  _exports.default = _default;
});