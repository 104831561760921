define("frontend/pods/outbound-call/controller", ["exports", "frontend/models/outbound-call", "frontend/models/lead"], function (_exports, _outboundCall, _lead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OutboundCallController = Ember.Controller.extend({
    isEditing: false,
    connectedText: Ember.computed('reasonText', 'dialResultText', 'voiceMailDeliveredAt', function () {
      if (this.voiceMailDeliveredAt) {
        return "Left voicemail";
      } else {
        return this.reasonText || this.dialResultText || 'Call attempted';
      }
    }),
    dialResultText: Ember.computed('dialResult', function () {
      var result = this.dialResult;

      if (!result) {
        return;
      }

      var resultValue = _outboundCall.default.dialResultValues.findBy('value', result);

      if (resultValue) {
        return resultValue.text;
      } else {
        return result;
      }
    }),
    reasonValues: Ember.computed('status', function () {
      var status = this.status;

      if (status) {
        return _lead.default.reasonValues[status];
      }
    }),
    reasonText: Ember.computed('dialResult', function () {
      var reason = this.reason;

      if (!reason) {
        return;
      }

      var reasonValues = this.reasonValues;

      if (!reasonValues) {
        return;
      }

      var resultValue = reasonValues.findBy('value', reason);

      if (resultValue) {
        return resultValue.text;
      } else {
        return reason;
      }
    }),
    canHearClientRecording: Ember.computed.and('clientRecording', 'authenticationService.canHearClientRecordings'),
    canHearAgentRecording: Ember.computed.and('agentRecording', 'authenticationService.canHearAgentRecordings'),
    actions: {
      edit: function edit() {
        // disable editing until we find it necessary (the form needs to be updated)
        // @set('isEditing', true) if @get('canEdit')
        return this.set('isEditing', true);
      },
      save: function save() {
        var _this = this;

        return this.model.saveChanges().then(function () {
          return _this.set('isEditing', false);
        });
      },
      cancel: function cancel() {
        this.model.rollbackAttributes();
        return this.set('isEditing', false);
      }
    }
  });
  var _default = OutboundCallController;
  _exports.default = _default;
});