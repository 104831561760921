define("frontend/pods/components/mail-to/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MailToComponent = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href'],
    email: null,
    href: Ember.computed('email', function () {
      return "mailto:".concat(this.email);
    }),
    bubbles: false
  });
  var _default = MailToComponent;
  _exports.default = _default;
});