define("frontend/pods/components/ui/toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ovMcckXv",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,3],[[30,[36,2],[\"ui-toggle-switch \",[30,[36,1],[[35,0],\"checked\"],null]],null]],[[\"from\"],[\"frontend/pods/components/ui/toggle-switch/styles\"]]]]]],[4,[38,5],[[32,0],[35,4]],null],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,3],[\"ball\"],[[\"from\"],[\"frontend/pods/components/ui/toggle-switch/styles\"]]]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"checked\",\"if\",\"concat\",\"local-class\",\"toggle\",\"action\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui/toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});