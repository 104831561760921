define("frontend/initializers/add-jquery-to-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    Ember.Component.reopen({
      $: function $(selector) {
        if (this.element) {
          return selector ? jQuery(selector, this.element) : jQuery(this.element);
        }
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});