define("frontend/helpers/time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function time(params, hash) {
    var d = params[0] || new Date();
    var format = hash.format || 'h:mm:ss a';
    return moment(d).format(format);
  });

  _exports.default = _default;
});