define("frontend/pods/greeting-recordings/greeting-recording/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GreetingRecordingRoute = Ember.Route.extend({
    requiresLogin: true,
    model: function model(params) {
      return this.store.find('greeting-recording', params.greeting_recording_id);
    }
  });
  var _default = GreetingRecordingRoute;
  _exports.default = _default;
});