define("frontend/pods/lead-phone-numbers/lead-phone-number/edit/route", ["exports", "frontend/pods/base/edit/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LeadPhoneNumberEditRoute = _route.default.extend({
    afterSave: function afterSave() {
      return this.transitionTo('leads.lead.index', this.modelFor('leads.lead'));
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('leads.lead.index', this.modelFor('leads.lead'));
    }
  });

  var _default = LeadPhoneNumberEditRoute;
  _exports.default = _default;
});