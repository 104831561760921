define("frontend/pods/components/agent/agent-console/states/idle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MXpyLqxF",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"idle\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/idle/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"description\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/idle/styles\"]]]]]],[12],[2,\"Please wait... the next call will arrive shortly.\"],[13],[2,\"\\n  \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"duration\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/idle/styles\"]]]]]],[12],[2,\"\\n    You have been waiting for:\\n    \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[\"time\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/idle/styles\"]]]]]],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"startTime\"],[[32,0,[\"call\",\"statusUpdatedAt\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"actions\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/idle/styles\"]]]]]],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[16,\"disabled\",[32,0,[\"pause\",\"isRunning\"]]],[4,[38,3],[\"click\",[30,[36,2],[[32,0,[\"pause\"]]],null]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-fw fa-pause\"],[12],[13],[2,\" Take a pit stop\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"time-diff\",\"perform\",\"on\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/states/idle/template.hbs"
    }
  });

  _exports.default = _default;
});