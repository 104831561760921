define("frontend/pods/components/system-alerts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oN6IJGTa",
    "block": "{\"symbols\":[\"alert\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"alerts\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"alert\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"system-alert\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/system-alerts/template.hbs"
    }
  });

  _exports.default = _default;
});