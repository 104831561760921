define("frontend/pods/components/call-outbound-call-details/component", ["exports", "frontend/models/outbound-call"], function (_exports, _outboundCall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallOutboundCallDetailsComponent = Ember.Component.extend({
    outboundCall: null,
    lead: Ember.computed.oneWay('outboundCall.lead'),
    isEditing: false,
    callService: Ember.inject.service('call'),
    isOutreachProvider: Ember.computed.equal('outboundCall.lead.serviceProvider.provider', 'outreach'),
    isTransient: Ember.computed.or('outboundCall.isLoading', 'outboundCall.isReloading', 'outboundCall.isSaving'),
    didUserDial: Ember.computed('outboundCall.caller.id', 'callService.authentication.userId', function () {
      return this.get('outboundCall.caller.id') === this.get('callService.authentication.userId');
    }),
    isLeadOwner: Ember.computed('outboundCall.client.id', 'callService.authentication.userId', function () {
      return this.get('outboundCall.client.id') === this.get('callService.authentication.userId');
    }),
    isAgent: Ember.computed.alias('callService.authentication.isAgent'),
    didTransfer: Ember.computed.bool('outboundCall.joinedAt'),
    didConnect: Ember.computed.or('didTransfer', 'didUserDial'),
    wasTransferAcceptable: Ember.computed('outboundCall.transferResult', {
      get: function get() {
        if (!this.outboundCall) {
          return true;
        }

        var transferResult = this.get('outboundCall.transferResult');
        return transferResult === 'acceptable' || Ember.isEmpty(transferResult);
      },
      set: function set(key, value) {
        if (!this.outboundCall) {
          return true;
        }

        this.set('outboundCall.transferResult', !!value ? 'acceptable' : 'not_connected');
        var transferResult = this.get('outboundCall.transferResult');
        return transferResult === 'acceptable' || Ember.isEmpty(transferResult);
      }
    }),
    unacceptableTransferResult: Ember.computed('outboundCall.transferResult', {
      get: function get() {
        if (!this.outboundCall) {
          return;
        }

        var transferResult = this.get('outboundCall.transferResult');

        if (transferResult !== 'acceptable') {
          return transferResult;
        }
      },
      set: function set(key, value) {
        if (!this.outboundCall) {
          return;
        }

        this.set('outboundCall.transferResult', value);
        var transferResult = this.get('outboundCall.transferResult');

        if (transferResult !== 'acceptable') {
          return transferResult;
        }
      }
    }),
    unacceptableTransferOptions: Ember.computed(function () {
      return _outboundCall.default.transferResultValues.reject(function (item) {
        return item.value === "acceptable";
      });
    }),
    wasCallQualityAcceptable: Ember.computed('outboundCall.callQuality', {
      get: function get() {
        if (!this.outboundCall) {
          return true;
        }

        var callQuality = this.get('outboundCall.callQuality');
        return callQuality === 'acceptable' || Ember.isEmpty(callQuality);
      },
      set: function set(key, value) {
        if (!this.outboundCall) {
          return true;
        }

        this.set('outboundCall.callQuality', !!value ? 'acceptable' : 'bad_connection');
        var callQuality = this.get('outboundCall.callQuality');
        return callQuality === 'acceptable' || Ember.isEmpty(callQuality);
      }
    }),
    unacceptableCallQualityResult: Ember.computed('outboundCall.callQuality', {
      get: function get() {
        if (!this.outboundCall) {
          return;
        }

        var callQuality = this.get('outboundCall.callQuality');

        if (callQuality !== 'acceptable') {
          return callQuality;
        }
      },
      set: function set(key, value) {
        if (!this.outboundCall) {
          return;
        }

        this.set('outboundCall.callQuality', value);
        var callQuality = this.get('outboundCall.callQuality');

        if (callQuality !== 'acceptable') {
          return callQuality;
        }
      }
    }),
    unacceptableCallQualityOptions: Ember.computed(function () {
      return _outboundCall.default.callQualityValues.reject(function (item) {
        return item.value === "acceptable";
      });
    }),
    initialDestinationValues: Ember.computed(function () {
      return _outboundCall.default.initialDestinationValues;
    }),
    dialResultValues: Ember.computed(function () {
      return _outboundCall.default.dialResultValues;
    }),
    agentConversationResultValues: Ember.computed(function () {
      return _outboundCall.default.agentConversationResultValues;
    }),
    statusValues: Ember.computed(function () {
      return _outboundCall.default.statusValues;
    }),
    reasonValues: Ember.computed.oneWay('outboundCall.reasonValues'),
    canSetStatus: Ember.computed.not('isAgent'),
    canSetConversationResult: Ember.computed('isAgent', 'didTransfer', 'outboundCall.dialResult', function () {
      if (!this.isAgent) {
        return false;
      }

      if (this.didTransfer) {
        return false;
      }

      var dialResult = this.get('outboundCall.dialResult');
      return dialResult === 'lead' || dialResult === 'gatekeeper';
    }),
    _enableEditingOnNewCall: Ember.observer('outboundCall', function () {
      console.log('outbound call changed');

      if (this.outboundCall) {
        return this.set('isEditing', true);
      }
    }),
    _initializeProperties: Ember.on('init', function () {
      this.getProperties('outboundCall', 'isEditing');
      return this._enableEditingOnNewCall();
    }),
    actions: {
      flagCall: function flagCall() {
        return this.set('outboundCall.agentFlagged', true);
      },
      unflagCall: function unflagCall() {
        return this.set('outboundCall.agentFlagged', false);
      },
      statusChanged: function statusChanged(status, masterStatus, leadStatusName) {
        return this.outboundCall.setProperties({
          status: status,
          masterStatus: masterStatus,
          leadStatusName: leadStatusName
        });
      }
    }
  });
  var _default = CallOutboundCallDetailsComponent;
  _exports.default = _default;
});