define("frontend/pods/service-providers/service-provider/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ServiceProviderEditController = Ember.Controller.extend({
    serviceProvider: Ember.computed.readOnly('model.serviceProvider'),
    serviceProviderExtendedInfo: Ember.computed.readOnly('model.serviceProviderExtendedInfo'),
    isSalesforceProvider: Ember.computed.equal('serviceProvider.provider', 'salesforce'),
    isHubspotProvider: Ember.computed.equal('serviceProvider.provider', 'hubspot'),
    isDynamicsProvider: Ember.computed.equal('serviceProvider.provider', 'azure_oauth2'),
    isZohoProvider: Ember.computed.equal('serviceProvider.provider', 'zoho'),
    isOutreachProvider: Ember.computed.equal('serviceProvider.provider', 'outreach'),
    isSalesloftProvider: Ember.computed.equal('serviceProvider.provider', 'salesloft'),
    isPipedriveProvider: Ember.computed.equal('serviceProvider.provider', 'pipedrive'),
    canEditLeadStatuses: Ember.computed.bool('isSalesforceProvider'),
    canEditFieldDisplay: Ember.computed.or('isSalesforceProvider', 'isHubspotProvider', 'isDynamicsProvider', 'isZohoProvider', 'isOutreachProvider', 'isSalesloftProvider', 'isPipedriveProvider'),
    canEditAdvancedSettings: Ember.computed.bool('isSalesforceProvider'),
    actions: {
      leadStatusMapChanged: function leadStatusMapChanged(changes) {
        // buffer the changes in via data-down-actions-up so it doesn't re-render every time
        this.set('modifiedLeadStatusMap', changes);
      },
      phoneNumberMappingChanged: function phoneNumberMappingChanged(changes) {
        this.set('modifiedPhoneNumberMapping', changes);
      },
      saveBufferedChanges: function saveBufferedChanges() {
        if (this.modifiedLeadStatusMap) {
          this.set('serviceProvider.settings.lead_status_map', this.modifiedLeadStatusMap);
        }

        if (this.modifiedPhoneNumberMapping) {
          this.set('serviceProvider.settings.phone_number_field_mapping', this.modifiedPhoneNumberMapping);
        } // bubble up to the route


        return this.send('save');
      }
    }
  });
  var _default = ServiceProviderEditController;
  _exports.default = _default;
});