define("frontend/pods/leads/lead/route", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadRoute = Ember.Route.extend(_modal.default, {
    model: function model(params) {
      return this.store.findRecord('lead', params.lead_id, {
        backgroundReload: true
      });
    },
    actions: {
      deleteModel: function deleteModel() {
        var _this = this;

        return this.modal.confirm('Are you sure you want to delete this lead?').then(function (result) {
          if (result) {
            var model = _this.get('controller.model');

            model.rollbackAttributes();
            return model.destroyRecord().then(function () {
              return _this.transitionTo('leads');
            });
          }
        });
      }
    }
  });
  var _default = LeadRoute;
  _exports.default = _default;
});