define("frontend/pods/leads/lead/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadIndexRoute = Ember.Route.extend();
  var _default = LeadIndexRoute;
  _exports.default = _default;
});