define("frontend/mixins/animation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Uses the animate.css from https://daneden.me/animate/
  // Just add the mixin to a view and call the animate methods
  var AnimationMixin = Ember.Mixin.create({
    flash: function flash() {
      return this.animate('flash');
    },
    bounce: function bounce() {
      return this.animate('bounce');
    },
    shake: function shake() {
      return this.animate('shake');
    },
    tada: function tada() {
      return this.animate('tada');
    },
    swing: function swing() {
      return this.animate('swing');
    },
    wobble: function wobble() {
      return this.animate('wobble');
    },
    pulse: function pulse() {
      return this.animate('pulse');
    },
    flip: function flip() {
      return this.animate('flip');
    },
    animate: function animate(effect) {
      var classes = "animated ".concat(effect);
      var el = this.$();
      el.addClass(classes);
      return setTimeout(function () {
        return el.removeClass(classes);
      }, 2000);
    }
  });
  var _default = AnimationMixin;
  _exports.default = _default;
});