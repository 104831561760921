define("frontend/pods/outbound-voice-mail-recordings/route", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OutboundVoiceMailRecordingsRoute = Ember.Route.extend(_modal.default, {
    requiresLogin: true,
    model: function model() {
      var a;
      return a = this.store.findAll('outbound-voice-mail-recording');
    },
    actions: {
      deleteModel: function deleteModel(recording) {
        return this.modal.confirm('Are you sure you want to delete this message?').then(function (result) {
          if (result) {
            return recording.destroyRecord();
          }
        });
      },
      toggleRecording: function toggleRecording(recording) {
        var _this = this;

        var active = !recording.get('active');
        recording.set('active', active);
        return recording.save().then(function () {
          // refresh the recording list to show the previously active recording as inactive
          return _this.store.findAll('outbound-voice-mail-recording');
        });
      }
    }
  });
  var _default = OutboundVoiceMailRecordingsRoute;
  _exports.default = _default;
});