define("frontend/pods/components/dropdown-button/component", ["exports", "frontend/mixins/click-elsewhere", "frontend/mixins/tool-tip"], function (_exports, _clickElsewhere, _toolTip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DropdownButtonComponent = Ember.Component.extend(_clickElsewhere.default, {
    content: null,
    classNameBindings: [':dropdown-button-component', 'isMenuAlignedRight:menu-align-right:menu-align-left'],
    tooltip: null,
    value: null,
    allowNone: false,
    isEnabled: true,
    menuVisible: Ember.computed.alias('menuView.isVisible'),
    menuAlign: 'left',
    isMenuAlignedRight: Ember.computed.equal('menuAlign', 'right'),
    toggleMenu: function toggleMenu() {
      return this.set('menuVisible', !this.menuVisible);
    },
    onClickElsewhere: function onClickElsewhere() {
      if (this.menuVisible) {
        return this.set('menuVisible', false);
      }
    }
  });
  var _default = DropdownButtonComponent;
  _exports.default = _default;
});