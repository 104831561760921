define("frontend/pods/components/ui/dropdown-button/menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FpXlQ642",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"a\"],[16,0,[31,[[30,[36,2],[\"dropdown-item \",[35,1]],null],\" \",[30,[36,0],[\"a\"],[[\"from\"],[\"frontend/pods/components/ui/dropdown-button/menu-item/styles\"]]]]]],[24,6,\"\"],[4,[38,4],[[32,0],[35,3]],null],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"class\",\"concat\",\"onclick\",\"action\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui/dropdown-button/menu-item/template.hbs"
    }
  });

  _exports.default = _default;
});