define("frontend/pods/components/date-picker-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "80YJQ/W4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],null,[[\"classNames\",\"content\",\"optionLabelPath\",\"optionValuePath\",\"placeholder\",\"value\"],[\"form-control\",[35,1],\"content.text\",\"content.value\",\"(select a range)\",[35,0]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,5],null,[[\"date\",\"placeholder\",\"disabled\"],[[35,4],\"(select a start date)\",[35,3]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,5],null,[[\"date\",\"placeholder\",\"disabled\"],[[35,6],\"(select an end date)\",[35,3]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"optionValue\",\"options\",\"ui-select\",\"disableCustom\",\"startDate\",\"date-picker\",\"internalEndDate\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/date-picker-group/template.hbs"
    }
  });

  _exports.default = _default;
});