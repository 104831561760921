define("frontend/pods/organizations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationsRoute = Ember.Route.extend({
    requiresLogin: true,
    authorizedRoles: ['admin', 'client_manager', 'client', 'team_manager']
  });
  var _default = OrganizationsRoute;
  _exports.default = _default;
});