define("frontend/pods/components/ui/toggle-switch/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    checked: false,
    onChange: function onChange() {},
    tagName: '',
    toggle: function toggle() {
      this.onChange(!this.checked);
    }
  });

  _exports.default = _default;
});