define("frontend/pods/components/ellipses-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EllipsesTextComponent = Ember.Component.extend({
    text: null,
    length: 100,
    ellipses: '...',
    expanded: false,
    tagName: 'span',
    classNames: ['ellipses'],
    ellipsesText: Ember.computed('text', 'length', 'ellipses', function () {
      var text = this.text || '';
      var length = this.length;
      var ellipses = this.ellipses || '';
      return text.slice(0, length) + ellipses;
    }),
    needsEllipses: Ember.computed('text', 'length', 'ellipses', function () {
      var text = this.text || '';
      var length = this.length;
      var ellipses = this.ellipses || '';
      return text.length > length + ellipses.length;
    }),
    actions: {
      expand: function expand() {
        return this.set('expanded', true);
      },
      collapse: function collapse() {
        return this.set('expanded', false);
      }
    }
  });
  var _default = EllipsesTextComponent;
  _exports.default = _default;
});