define("frontend/pods/organizations/new/route", ["exports", "frontend/pods/base/new/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var OrganizationsNewRoute = _route.default.extend({
    requiresLogin: true,
    authorizedRoles: ['admin'],
    model: function model() {
      return this.store.createRecord('organization');
    },
    saveModel: function saveModel() {
      var _this = this;

      var model = this.get('controller.model');
      var addedServices = this.get('controller.editableServiceApplicationOrganizations');
      return model.save().then(function () {
        var addedPromises = addedServices.map(function (s) {
          var o = _this.store.createRecord('service-application-organization', {
            organization: model,
            serviceApplication: s
          });

          return o.save();
        });
        return Ember.RSVP.Promise.all(addedPromises);
      });
    },
    afterSave: function afterSave() {
      return this.transitionTo('organizations.index');
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('organizations.index');
    }
  });

  var _default = OrganizationsNewRoute;
  _exports.default = _default;
});