define("frontend/pods/outbound-voice-mail-recordings/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OutboundVoiceMailRecordingsIndexRoute = Ember.Route.extend({
    model: function model() {
      return this.modelFor('outbound-voice-mail-recordings');
    }
  });
  var _default = OutboundVoiceMailRecordingsIndexRoute;
  _exports.default = _default;
});