define("frontend/pods/components/dials-per-hour/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DialsPerHourComponent = Ember.Component.extend({
    startTime: null,
    dialCount: null,
    dialsPerHour: 0,
    // Initial value
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.refreshComponent(); // Initial call

      this.refreshInterval = setInterval(function () {
        return _this.refreshComponent();
      }, 60000); // Refresh every minute
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.refreshInterval); // Clear the interval when the component is destroyed
    },
    refreshComponent: function refreshComponent() {
      var startTime = this.get('startTime');
      var dialCount = this.get('dialCount');

      if (!startTime || dialCount === null || dialCount <= 0) {
        this.set('dialsPerHour', 0);
        return;
      }

      var now = new Date();
      var elapsed_time = now - startTime;
      var elapsed_hours = elapsed_time / (1000 * 60 * 60);

      if (elapsed_hours < 0.001) {
        this.set('dialsPerHour', dialCount);
        return;
      }

      var dials_per_hour = dialCount / elapsed_hours;

      if (!isNaN(dials_per_hour)) {
        if (dials_per_hour < 1) {
          this.set('dialsPerHour', dials_per_hour.toFixed(1)); // Display with one decimal place

          console.log('dials_per_hour', dials_per_hour);
        } else {
          this.set('dialsPerHour', dials_per_hour);
          console.log('dials_per_hour', dials_per_hour);
        }
      } else {
        this.set('dialsPerHour', 0);
        console.log('dials_per_hour', dials_per_hour);
      }
    }
  });
  var _default = DialsPerHourComponent;
  _exports.default = _default;
});