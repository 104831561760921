define("frontend/pods/components/error-list/component", ["exports", "@ember-data/adapter/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ErrorListComponent = Ember.Component.extend({
    model: null,
    showFieldNames: true,
    errors: null,
    anyErrors: Ember.computed.gt('errorMessages.length', 0),
    oneError: Ember.computed.equal('errorMessages.length', 1),
    useModelErrors: Ember.computed.bool('model'),
    modelErrorMessages: Ember.computed('model.errors.[]', function () {
      var _this = this;

      var errors = this.get('model.errors') || [];
      return errors.map(function (e) {
        return _this._formatErrorMessage(e.attribute, e.message);
      });
    }),
    errorMessages: Ember.computed('useModelErrors', 'modelErrorMessages.[]', 'errors', function () {
      var k, result, v;

      if (this.useModelErrors) {
        return this.modelErrorMessages;
      }

      var errors = this.errors;

      if (Ember.isEmpty(errors)) {
        return [];
      }

      if (Ember.typeOf(errors) === 'string') {
        return errors;
      }

      if (Ember.typeOf(errors) === 'array') {
        return errors;
      }

      if (errors instanceof _error.InvalidError) {
        var e = errors.errors; // sometimes the errors property can be double nested (if not using a model)

        if (e.errors && Ember.typeOf(e.errors) === 'object') {
          e = e.errors;
        }

        result = [];

        for (k in e) {
          v = e[k];
          result.push(this._formatErrorMessage(k, v));
        }

        return result;
      }

      if (Ember.typeOf(errors) === 'object') {
        result = [];

        for (k in errors) {
          v = errors[k];
          result.push(this._formatErrorMessage(k, v));
        }

        return result;
      }

      return ['Unidentified error class'];
    }),
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('useModelErrors', 'modelErrorMessages');
    }),
    _formatErrorMessage: function _formatErrorMessage(fieldName, message) {
      if (this.showFieldNames) {
        var f = Ember.String.classify(fieldName || '').replace(/([a-z])([A-Z])/g, function (m) {
          return m[0] + ' ' + m[1];
        });
        return "".concat(f, " ").concat(message);
      } else {
        return message;
      }
    },
    tagName: 'div',
    classNames: ['validation-errors']
  });
  var _default = ErrorListComponent;
  _exports.default = _default;
});