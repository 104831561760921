define("frontend/utils/oauth-authentication", ["exports", "frontend/utils/form-submit"], function (_exports, _formSubmit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    provider: null,
    providerDisplayName: null,
    providerId: null,
    providerDisplayId: null,
    serviceProviderId: null,
    headerData: null,
    command: null,
    // valid states: null (unauthenticated), authenticating, authenticated, canceled, failed
    state: null,
    response: null,
    isUnauthenticated: Ember.computed.not('state'),
    isAuthenticating: Ember.computed.equal('state', 'authenticating'),
    isAuthenticated: Ember.computed.equal('state', 'authenticated'),
    isCanceled: Ember.computed.equal('state', 'canceled'),
    isFailed: Ember.computed.equal('state', 'failed'),
    isError: Ember.computed.equal('state', 'error'),
    didAuthenticateDifferentAccount: Ember.computed.equal('state', 'incorrect_account_authenticated'),
    isComplete: Ember.computed.or('isAuthenticated', 'isCanceled', 'isFailed', 'isError', 'didAuthenticateDifferentAccount'),
    localStorageKey: Ember.computed('provider', function () {
      return "oauth.".concat(this.provider);
    }),
    _getLocalStorageData: function _getLocalStorageData() {
      var localStorageKey = this.localStorageKey;
      return localStorage[localStorageKey];
    },
    _setLocalStorageData: function _setLocalStorageData(value) {
      var localStorageKey = this.localStorageKey;

      if (value) {
        localStorage[localStorageKey] = value;
      } else {
        delete localStorage[localStorageKey];
      }

      return localStorage[localStorageKey];
    },
    _submitAuthenticationForm: function _submitAuthenticationForm() {
      var url = "/oauth/".concat(this.provider);
      var data = Ember.assign({}, this.headerData || {});

      if (this.providerId) {
        data.provider_id = this.providerId;
      }

      if (this.command) {
        data.command = this.command;
      }

      var form = _formSubmit.default.create({
        url: url,
        method: 'post',
        target: '_blank',
        data: data
      });

      return form.execute();
    },
    _becameComplete: Ember.observer('isComplete', function () {
      if (!this.isComplete) {
        return;
      }

      if (this.isAuthenticated) {
        return this.deferred.resolve(this.response);
      } else {
        return this.deferred.reject(this.response);
      }
    }),
    deferred: null,
    promise: null,
    // public version that initializes the promise
    authenticate: function authenticate() {
      var _this = this;

      if (this.isAuthenticating) {
        return this.promise;
      } // create a new promise


      var deferred = Ember.RSVP.defer();
      var promise = deferred.promise;
      this.set('deferred', deferred);
      this.set('promise', promise);

      this._setLocalStorageData(null);

      this.set('state', 'authenticating');

      this._submitAuthenticationForm(); // checks the local storage state for a change, presumably written by the callback


      var fnCheck = function fnCheck() {
        console.log('checking state');

        if (!_this.isAuthenticating) {
          return;
        }

        var data = _this._getLocalStorageData();

        if (data) {
          data = JSON.parse(data);
          console.log('authentication response: ', data); // the local storage data was written, presumably by the callback -- update the data

          return _this.setProperties({
            response: data,
            state: data.status,
            providerDisplayId: data.provider_display_id,
            serviceProviderId: data.service_provider_id
          });
        } else {
          // the local storage state has not yet been written -- try again later
          return fnScheduleCheck();
        }
      }; // schedules the local storage check every second


      var fnScheduleCheck = function fnScheduleCheck() {
        return Ember.run.later(this, fnCheck, 1000);
      }; // perform the initial check


      fnCheck(); // return the promise

      return promise;
    },
    cancel: function cancel() {
      if (this.isAuthenticating) {
        return this.set('state', 'canceled');
      }
    },
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('isUnauthenticated', 'isAuthenticating', 'isAuthenticated', 'isCanceled', 'isFailed', 'isComplete', 'didAuthenticateDifferentAccount');
    })
  });

  _exports.default = _default;
});