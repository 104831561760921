define("frontend/pods/components/dashboard-call-activity-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d0MTPC/p",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"date\"],[12],[1,[30,[36,9],[[35,8]],null]],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"glyphicons activity-icon conversation\"],[12],[10,\"i\"],[12],[13],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"ellipses\"],[12],[2,\"\\n    \"],[10,\"a\"],[12],[1,[34,6]],[13],[2,\"\\n    \"],[1,[34,7]],[2,\"\\n    \"],[6,[37,5],null,[[\"route\",\"model\"],[\"leads.lead\",[35,4]]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"glyphicons activity-icon phone_alt\"],[12],[10,\"i\"],[12],[13],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"ellipses\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"a\"],[12],[2,\"Andi\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"a\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    dialed\\n    \"],[6,[37,5],null,[[\"route\",\"model\"],[\"leads.lead\",[35,4]]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n    for\\n    \"],[10,\"a\"],[12],[1,[34,6]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"leadName\",\"callerName\",\"isAndyDial\",\"if\",\"leadId\",\"link-to\",\"clientName\",\"conversationText\",\"startedAt\",\"moment-from-now\",\"isConversation\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/dashboard-call-activity-item/template.hbs"
    }
  });

  _exports.default = _default;
});