define("frontend/pods/components/ui/dropdown-button/menu-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    onclick: function onclick() {}
  });

  _exports.default = _default;
});