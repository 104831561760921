define("frontend/pods/components/agent/agent-console/states/dialing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SxZjfTDt",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"dialing\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/dialing/styles\"]]]]]],[17,1],[12],[2,\"\\n\\n  \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"title\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/dialing/styles\"]]]]]],[12],[2,\"\\n    Dialing... \"],[10,\"i\"],[14,0,\"fa fa-fw fa-spinner fa-spin\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/states/dialing/template.hbs"
    }
  });

  _exports.default = _default;
});