define("frontend/pods/components/ui-switch-component/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UiSwitchComponent = Ember.Component.extend({
    classNames: ['make-switch', 'ui-switch'],
    classNameBindings: ['sizeClass'],
    // not necessary, included in the toggle action to provide a context for the value
    content: null,
    value: false,
    onToggle: function onToggle() {},
    onText: null,
    offText: null,
    onIcon: null,
    offIcon: null,
    onClass: 'primary',
    offClass: 'default',
    size: null,
    onLabel: Ember.computed('onText', 'onIcon', function () {
      var onIcon = this.onIcon || "";
      onIcon = onIcon ? "<i class='fa fa-".concat(onIcon, "'></i>") : "";
      var onText = this.onText || "";
      var result = "".concat(onIcon, " ").concat(onText).trim();

      if (result.length === 0) {
        result = "ON";
      }

      return result;
    }),
    offLabel: Ember.computed('offText', 'offIcon', function () {
      var offIcon = this.offIcon || "";
      offIcon = offIcon ? "<i class='fa fa-".concat(offIcon, "'></i>") : "";
      var offText = this.offText || "";
      var result = "".concat(offIcon, " ").concat(offText).trim();

      if (result.length === 0) {
        result = "OFF";
      }

      return result;
    }),
    sizeClass: Ember.computed('size', function () {
      var size = this.size;

      if (size) {
        return "ui-switch-".concat(size);
      }
    }),
    _setup: Ember.on('didInsertElement', function () {
      var _this = this;

      var el = this.$();
      el.bootstrapSwitch();

      this._setSwitchState();

      el.bootstrapSwitch('setOnLabel', this.onLabel);
      el.bootstrapSwitch('setOffLabel', this.offLabel);

      if (this.onClass) {
        el.bootstrapSwitch('setOnClass', this.onClass);
      }

      if (this.offClass) {
        el.bootstrapSwitch('setOffClass', this.offClass);
      }

      if (this.size) {
        el.bootstrapSwitch('setSizeClass', "switch-".concat(this.size));
      }

      return el.on('change', function (e) {
        return _this._change(e);
      });
    }),
    _destroy: Ember.on('willDestroyElement', function () {
      return this.$().bootstrapSwitch('destroy');
    }),
    _valueChanged: Ember.observer('value', function () {
      return this._setSwitchState();
    }),
    _getSwitchState: function _getSwitchState() {
      return this.$().bootstrapSwitch('status');
    },
    _setSwitchState: function _setSwitchState() {
      var value = this.value;

      if (this._getSwitchState() !== value) {
        return this.$().bootstrapSwitch('setState', value);
      }
    },
    _change: function _change() {
      // the change event will fire if the state is set through the control or programmatically --
      // only fire the toggle event when the user manipulates the control
      var state = this._getSwitchState();

      if (state !== this.value) {
        return this.onToggle(state, this.content);
      }
    },
    _initializeProperties: function _initializeProperties() {
      return this.getProperties('value');
    }
  });
  var _default = UiSwitchComponent;
  _exports.default = _default;
});