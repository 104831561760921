define("frontend/services/service-provider-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    minutes: 5,
    invalidProviders: null,
    store: Ember.inject.service(),
    authentication: Ember.inject.service(),
    alerts: Ember.inject.service(),
    loggedIn: Ember.computed.readOnly('authentication.loggedIn'),
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('invalidProviders', 'loggedIn');
    }),
    _invalidProvidersChanged: Ember.observer('invalidProviders.length', function () {
      if (this.get('invalidProviders.length') > 0) {
        return this.displayAlert();
      }
    }),
    _queueOnInit: Ember.on('init', function () {
      return this.queueValidation();
    }),
    _queueOnLogin: Ember.observer('loggedIn', function () {
      if (this.loggedIn) {
        return this.queueValidation();
      }
    }),
    queueValidation: function queueValidation() {
      return Ember.run.debounce(this, this.perform, this.minutes * 60 * 1000);
    },
    perform: function perform() {
      var _this = this;

      if (this.isDestroyed) {
        return;
      }

      if (!this.loggedIn) {
        return;
      }

      return this.validate().then(function () {
        return _this.queueValidation();
      }, function () {
        return _this.queueValidation();
      });
    },
    //isPageActive: ->
    //  window.document.hasFocus()
    validate: function validate() {
      //return Ember.RSVP.resolve() unless @isPageActive()
      var providers = this.store.query('service-provider', {
        status: 'authentication_failed'
      });
      this.set('invalidProviders', providers);
      return providers;
    },
    alert: null,
    displayAlert: function displayAlert() {
      var alert = this.alert;

      if (alert && !alert.get('isDismissed')) {
        return;
      }

      var count = this.get('invalidProviders.length');

      if (count === 0) {
        return;
      }

      var options = {
        persistent: true,
        route: 'service-providers'
      };
      var message = "<strong>Uh oh! ".concat(count, " of your service providers could not authenticate.</strong> Click this message to begin re-authenticating.");
      alert = this.alerts.warning(message, options);
      return this.set('alert', alert);
    }
  });

  _exports.default = _default;
});