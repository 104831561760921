define("frontend/utils/attachment", ["exports", "legendary-file-upload/utils/attachment"], function (_exports, _attachment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _attachment.default;
    }
  });
});