define("frontend/pods/leads/lead/index/controller", ["exports", "frontend/pods/leads/lead/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var LeadIndexController = _controller.default.extend({
    serviceProvider: Ember.computed.readOnly('model.serviceProvider.content'),
    isAgent: Ember.computed.alias('callService.authentication.isAgent'),
    fieldList: Ember.computed('serviceProvider.settings.fields', 'model.masterObjectType', function () {
      var fieldType = this.get('model.masterObjectType');

      if (!fieldType) {
        return;
      }

      var fields = this.get('serviceProvider.settings.fields');

      if (!fields) {
        return;
      }

      var result = fields[fieldType];

      if (Ember.isEmpty(result)) {
        return;
      }

      return result;
    }),
    // easy iteration of the full record properties
    fullRecordProperties: Ember.computed('model.fullRecord', 'fieldList', function () {
      var fullRecord = this.get('model.fullRecord') || {};
      var fieldList = this.fieldList || Object.keys(fullRecord);
      var result = [];

      for (var _i = 0, _Array$from = Array.from(fieldList); _i < _Array$from.length; _i++) {
        var f = _Array$from[_i];
        var value = fullRecord[f];

        if (!Ember.isEmpty(value)) {
          result.push({
            key: f,
            value: value
          });
        }
      }

      return result;
    }),
    processLeadInfo: function processLeadInfo(obj, fieldList) {
      var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      var html = "<div class=\"object-container\" style=\"margin-left: ".concat(level * 20, "px;\">");
      var urlPattern = /^https:\/\/[^\s]+$/;

      var _iterator = _createForOfIteratorHelper(fieldList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var key = _step.value;
          var value = obj[key];

          if (!Ember.isEmpty(value)) {
            if (_typeof(value) === 'object' && value !== null) {
              html += "<div style=\"margin-bottom:4px;\"><strong>".concat(!isNaN(key) ? "Entry ".concat(key + 1) : "".concat(key), ":</strong>").concat(this.processLeadInfo(value, Object.keys(value), level + 1), "</div>");
            } else {
              if (urlPattern.test(value)) {
                value = "<a href=\"".concat(value, "\" target=\"_blank\">").concat(value, "</a>");
              }

              html += "<div style=\"margin-bottom:4px;\"><strong>".concat(!isNaN(key) ? "Entry ".concat(key + 1) : "".concat(key), ":</strong> ").concat(value, "</div>");
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      html += '</div>';
      return html;
    },
    processedLeadInfo: Ember.computed('model.fullRecord', 'fieldList', function () {
      var fullRecord = this.get('model.fullRecord') || {};
      var fieldList = this.fieldList || Object.keys(fullRecord);
      return Ember.String.htmlSafe(this.processLeadInfo(fullRecord, fieldList));
    })
  });

  var _default = LeadIndexController;
  _exports.default = _default;
});