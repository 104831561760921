define("frontend/pods/components/recording-controller/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PYXoWZsO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,7],null,[[\"canPlay\",\"canRecord\",\"canStop\",\"play\",\"record\",\"stop\"],[[35,6],[35,5],[35,4],[30,[36,1],[[32,0],[35,3]],null],[30,[36,1],[[32,0],[35,2]],null],[30,[36,1],[[32,0],[35,0]],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"stop\",\"action\",\"record\",\"play\",\"canStop\",\"canRecord\",\"canPlay\",\"hash\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/recording-controller/template.hbs"
    }
  });

  _exports.default = _default;
});