define("frontend/pods/organizations/organization/flagged-records/flagged-record/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "152dztM8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-separator col-unscrollable col-separator-last\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-table\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll border-bottom\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h4\"],[14,0,\"margin-none\"],[12],[2,\"Edit Lead: \"],[1,[35,0,[\"name\"]]],[2,\" \"],[6,[37,2],[[35,0,[\"masterUrl\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\" \"],[10,\"span\"],[14,0,\"innerL pull-right\"],[12],[10,\"a\"],[15,6,[34,0,[\"masterUrl\"]]],[14,\"target\",\"_blank\"],[14,0,\"btn btn-xs btn-info\"],[12],[10,\"i\"],[14,0,\"fa fa-user\"],[12],[13],[13],[13],[2,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]},{\"statements\":[[2,\" \"],[10,\"span\"],[14,0,\"innerL pull-right\"],[12],[6,[37,1],null,[[\"target\",\"route\",\"model\"],[\"_blank\",\"leads.lead\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-user\"],[12],[13]],\"parameters\":[]}]]],[13]],\"parameters\":[]}]]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll inner-2x\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,3],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,6],null,[[\"icon\",\"text\",\"isWaiting\",\"isPrimary\",\"onClick\"],[\"save\",\"Save Changes\",[35,0,[\"isSaving\"]],\"true\",[30,[36,5],[[32,0],[30,[36,4],[\"save\"],null]],null]]]]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,6],null,[[\"text\",\"onClick\"],[\"Cancel\",[30,[36,5],[[32,0],[30,[36,4],[\"cancel\"],null]],null]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"link-to\",\"if\",\"lead-form\",\"route-action\",\"action\",\"ui-button\"]}",
    "meta": {
      "moduleName": "frontend/pods/organizations/organization/flagged-records/flagged-record/template.hbs"
    }
  });

  _exports.default = _default;
});