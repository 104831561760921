define("frontend/pods/components/organization-dashboard-user-row/component", ["exports", "frontend/utils/time-format"], function (_exports, _timeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationDashboardUserRowComponent = Ember.Component.extend({
    tagName: 'tr',
    user: null,
    dashboard: null,
    canEditUser: null,
    isAdmin: null,
    chatService: Ember.inject.service('chat'),
    userDashboardItem: Ember.computed('user', 'dashboard', function () {
      var dashboard = this.get('dashboard.usageByUser') || [];
      var userId = this.get('user.id').toString();
      return dashboard.find(function (i) {
        return i.user_id.toString() === userId;
      });
    }),
    dials: Ember.computed.readOnly('userDashboardItem.dials'),
    connections: Ember.computed.readOnly('userDashboardItem.connections'),
    appointments: Ember.computed.readOnly('userDashboardItem.appointments'),
    voicemails: Ember.computed.readOnly('userDashboardItem.voicemails'),
    conversionRate: Ember.computed('connections', 'appointments', function () {
      var p = this.getProperties('connections', 'appointments');

      if (!p.appointments) {
        return 0;
      }

      return this._round(p.appointments / p.connections * 100);
    }),
    avgWaitTime: Ember.computed('connections', 'idleTime', function () {
      var wait;
      var p = this.getProperties('connections', 'idleTime');

      if (!(p.connections > 0)) {
        wait = p.idleTime;
      }

      wait = this._round(p.idleTime / p.connections);
      return _timeFormat.default.hhmmss(wait);
    }),
    dialsPerConversation: Ember.computed('dials', 'connections', function () {
      var d2c;
      var p = this.getProperties('dials', 'connections');

      if (!(p.connections > 0)) {
        d2c = 0;
      }

      if (p.connections > 0) {
        d2c = this._round(p.dials / p.connections);
      }

      return d2c;
    }),
    _round: function _round(val) {
      if (val) {
        return Math.round(val * 10) / 10;
      } else {
        return 0;
      }
    },
    pausedTime: Ember.computed.readOnly('userDashboardItem.paused_time'),
    idleTime: Ember.computed.readOnly('userDashboardItem.idle_time'),
    connectedTime: Ember.computed.readOnly('userDashboardItem.connected_time'),
    totalTime: Ember.computed('pausedTime', 'idleTime', 'connectedTime', function () {
      var p = this.getProperties('pausedTime', 'idleTime', 'connectedTime');
      var totalTime = p.pausedTime + p.idleTime + p.connectedTime;

      if (!isNaN(totalTime)) {
        return _timeFormat.default.hhmmss(totalTime);
      }
    })
  });
  var _default = OrganizationDashboardUserRowComponent;
  _exports.default = _default;
});