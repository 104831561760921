define("frontend/pods/organizations/organization/statuses/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationStatusesController = Ember.Controller.extend({
    isDynamicsProvider: Ember.computed.equal('model.serviceProvider.provider', 'azure_oauth2'),
    isOutreachProvider: Ember.computed.equal('model.serviceProvider.provider', 'outreach'),
    isHubspotProvider: Ember.computed.equal('model.serviceProvider.provider', 'hubspot'),
    isSalesloftProvider: Ember.computed.equal('model.serviceProvider.provider', 'salesloft'),
    displayDispositions: Ember.computed.or('isOutreachProvider', 'isHubspotProvider', 'isSalesloftProvider')
  });
  var _default = OrganizationStatusesController;
  _exports.default = _default;
});