define("frontend/pods/data-sources/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tAXS7tnj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"innerAll\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"jumbotron\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h1\"],[12],[2,\"Get Started with a Source\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[12],[2,\"Chances are you already have your list of contacts somewhere else: in a CRM application, in a file, etc.  Rather than entering them all manually, simply import them using a data source.  When integrating with a CRM application, your data will always stay in sync!\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-primary btn-large\",\"data-sources.new\"]],[[\"default\"],[{\"statements\":[[2,\"Let's create my first data source \"],[10,\"i\"],[14,0,\"fa fa-arrow-circle-right\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n\\t\\t  \"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"length\",\"unless\"]}",
    "meta": {
      "moduleName": "frontend/pods/data-sources/index/template.hbs"
    }
  });

  _exports.default = _default;
});