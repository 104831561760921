define("frontend/pods/components/call-status/component", ["exports", "frontend/mixins/animation"], function (_exports, _animation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallStatusComponent = Ember.Component.extend(_animation.default, {
    classNames: ['call-status', 'label'],
    classNameBindings: ['labelClass'],
    // bug in this version of Ember: "view" is necessary here but not if defined externally
    //template: Ember.Handlebars.compile 'aa{{view.statusText}}bb'
    callService: null,
    status: Ember.computed.oneWay('callService.status'),
    isOnline: Ember.computed.oneWay('callService.isOnline'),
    startedAt: Ember.computed.oneWay('callService.callSession.startedAt'),
    showTimer: Ember.computed.and('isOnline', 'startedAt'),
    labelClass: Ember.computed('status', function () {
      switch (this.status) {
        case 'idle':
          return 'label-info';

        case 'dialing':
        case 'connecting':
        case 'connected':
        case 'disconnecting':
          return 'label-success';

        case 'paused':
          return 'label-warning';

        case 'eavesdropping':
          return 'label-success';

        case 'coaching':
          return 'label-success';

        default:
          return 'label-inverse';
      }
    }),
    statusText: Ember.computed('status', 'isOnline', function () {
      if (!this.isOnline) {
        return 'Offline';
      }

      switch (this.status) {
        case 'initializing':
          return 'Initializing...';

        case 'idle':
          return 'Available';

        case 'dialing':
          return 'Dialing';

        case 'connecting':
        case 'connected':
          return 'On a Call';

        case 'disconnecting':
          return 'Disconnecting';

        case 'paused':
          return 'Idle';

        case 'eavesdropping':
          return 'Eavesdropping';

        case 'coaching':
          return 'Coaching';

        default:
          return "Unknown (".concat(this.status, ")");
      }
    }),
    _animateOnChange: Ember.observer('statusText', function () {
      return this.pulse();
    })
  });
  var _default = CallStatusComponent;
  _exports.default = _default;
});