define("frontend/pods/lead-lists/lead-list/route", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadListRoute = Ember.Route.extend(_modal.default, {
    model: function model(params) {
      return this.store.find('lead-list', params.lead_list_id);
    },
    actions: {
      deleteModel: function deleteModel() {
        var _this = this;

        return this.modal.confirm('Are you sure you want to delete this list?').then(function (result) {
          if (result) {
            var model = _this.get('controller.model');

            model.rollbackAttributes();
            return model.destroyRecord().then(function () {
              return _this.transitionTo('lead-lists');
            });
          }
        });
      }
    }
  });
  var _default = LeadListRoute;
  _exports.default = _default;
});