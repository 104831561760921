define("frontend/pods/organizations/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationsIndexRoute = Ember.Route.extend({
    requiresLogin: true,
    authorizedRoles: ['admin'],
    model: function model() {
      return this.store.findAll('organization');
    }
  });
  var _default = OrganizationsIndexRoute;
  _exports.default = _default;
});