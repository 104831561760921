define("frontend/components/file-upload-input", ["exports", "legendary-file-upload/components/file-upload-input"], function (_exports, _fileUploadInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fileUploadInput.default;
    }
  });
});