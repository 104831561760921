define("frontend/pods/components/soft-phone/dial-pad/dial-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "dialpad-button": "_dialpad-button_18x9ih",
    "letters": "_letters_18x9ih",
    "number": "_number_18x9ih",
    "flash": "_flash_18x9ih"
  };
  _exports.default = _default;
});