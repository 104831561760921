define("frontend/utils/web-socket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WebSocket = {}; // Manages a single subscription

  WebSocket.Subscription = Ember.Object.extend({
    // the web socket client (typically service:web-socket)
    client: null,
    // the subscription channel
    channel: null,
    // callback when a new message occurs
    onNewMessage: function onNewMessage() {},
    init: function init() {
      this._super.apply(this, arguments);

      return this.subscribe();
    },
    willDestroy: function willDestroy() {
      this.unsubscribe();
      return this._super.apply(this, arguments);
    },
    subscribe: function subscribe() {
      var _this = this;

      // store the callback to reference it on unsubscribe
      this._messageReceived = function (message) {
        return Ember.run(function () {
          return _this.onNewMessage(message);
        });
      };

      var channel = this.channel;
      var client = this.client;

      if (channel) {
        client.subscribe(channel, this._messageReceived);
      }

      return this.set('_subscribedChannel', channel);
    },
    unsubscribe: function unsubscribe() {
      var channel = this._subscribedChannel;
      var client = this.client;

      if (channel) {
        client.unsubscribe(channel, this._messageReceived);
      }

      return this.set('_subscribedChannel', null);
    },
    // if the channel changes while subscribed, unsubscribe from the old one and re-subscribe to the new one
    _channelChanged: Ember.observer('channel', function () {
      var channel = this._subscribedChannel;

      if (!channel) {
        return;
      }

      this.unsubscribe();
      return this.subscribe();
    })
  });
  var _default = WebSocket;
  _exports.default = _default;
});