define("frontend/pods/organizations/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationsNewController = Ember.Controller.extend({
    editableServiceApplicationOrganizations: Ember.computed('model', function () {
      return [];
    })
  });
  var _default = OrganizationsNewController;
  _exports.default = _default;
});