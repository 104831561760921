define("frontend/pods/components/agent/agent-console/states/paused/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "paused": "_paused_45vd6m",
    "description": "_description_45vd6m",
    "counter": "_counter_45vd6m",
    "not-ready": "_not-ready_45vd6m",
    "duration": "_duration_45vd6m",
    "time": "_time_45vd6m",
    "actions": "_actions_45vd6m"
  };
  _exports.default = _default;
});