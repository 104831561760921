define("frontend/pods/components/soft-phone/button-bar/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button-bar": "_button-bar_1gdomw"
  };
  _exports.default = _default;
});