define("frontend/serializers/call-session", ["exports", "frontend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // the backend / serializer format does not handle self-referenced model relationship well
    // (in this case, a call session can eavesdrop on another call session) --
    // the serializer uses a different name key to ensure there is no confusion
    // between the primary response record(s) and the relationship records.
    // Therefore we just need to tell the frontend how to map that key.
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      if (key === 'eavesdropping_on_call_sessions') {
        return 'call-session';
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});