define("frontend/pods/components/data-source-field-mapping-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "45jDkK+J",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"classNames\",\"value\",\"content\",\"allowClear\",\"placeholder\"],[\"form-control campaign-field-mapping-select\",[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"allowClear\",\"headers\",\"value\",\"ui-select\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/data-source-field-mapping-select/template.hbs"
    }
  });

  _exports.default = _default;
});