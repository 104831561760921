define("frontend/pods/components/external-crm-api-key-editor/component", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExternalCrmApiKeyEditorComponent = Ember.Component.extend(_modal.default, {
    organization: null,
    store: null,
    externalCrmApiKeys: Ember.computed.readOnly('organization.externalCrmApiKeys'),
    displayExternalCrmApiKeys: Ember.computed('externalCrmApiKeys.[]', 'modelsToAdd.[]', 'modelsToRemove.[]', 'editingExternalCrmApiKeys.[]', 'newExternalCrmApiKey', function () {
      var externalCrmApiKeys = this.externalCrmApiKeys;
      var modelsToAdd = this.modelsToAdd;
      var modelsToRemove = this.modelsToRemove;
      var result = [];
      result.addObjects(externalCrmApiKeys);
      result.addObjects(modelsToAdd);
      result.removeObjects(modelsToRemove);
      result.removeObject(this.newExternalCrmApiKey);
      var editingExternalCrmApiKeys = this.editingExternalCrmApiKeys;
      return result.map(function (g) {
        return {
          externalCrmApiKey: e,
          isEditing: editingExternalCrmApiKeys.includes(e)
        };
      });
    }),
    modelsToAdd: Ember.computed(function () {
      return [];
    }),
    modelsToRemove: Ember.computed(function () {
      return [];
    }),
    newAccessId: null,
    newSecretKey: null,
    newActive: true,
    newType: null,
    newUrlPrefix: null,
    newExternalCrmApiKey: null,
    editingExternalCrmApiKey: Ember.computed(function () {
      return [];
    }),
    actions: {
      add: function add() {
        var _this = this;

        if (this.newExternalCrmApiKey) {
          this.newExternalCrmApiKey.deleteRecord();
        }

        var newExternalCrmApiKey = this.get('organization.store').createRecord('external-crm-api-key', {
          organization: this.organization,
          accessKey: this.newAccessId,
          accessKeySecret: this.newSecretKey,
          active: newActive,
          type: this.newType,
          urlPrefix: this.newUrlPrefix
        });
        this.set('newExternalCrmApiKey', newExternalCrmApiKey);
        return newExternalCrmApiKey.save().then(function () {
          _this.modelsToAdd.addObject(newExternalCrmApiKey);

          return _this.setProperties({
            newExternalCrmApiKey: null,
            newAccessId: null,
            newSecretKey: null,
            newType: null,
            newUrlPrefix: null,
            newActive: true
          });
        }).catch(function () {});
      },
      remove: function remove(externalCrmApiKey) {
        var _this2 = this;

        return this.modal.confirm('Are you sure you want to remove this External CRM API Key?').then(function (result) {
          if (result) {
            return externalCrmApiKey.destroyRecord().then(function () {
              return _this2.modelsToRemove.addObject(externalCrmApiKey);
            });
          }
        });
      },
      edit: function edit(externalCrmApiKey) {
        return this.editingExternalCrmApiKeys.addObject(externalCrmApiKey);
      },
      cancel: function cancel(externalCrmApiKey) {
        return this.editingExternalCrmApiKeys.removeObject(externalCrmApiKey);
      },
      save: function save(externalCrmApiKey) {
        var _this3 = this;

        return externalCrmApiKey.save().then(function () {
          return _this3.editingExternalCrmApiKeys.removeObject(externalCrmApiKey);
        }).catch(function () {});
      },
      activeChanged: function activeChanged(active, externalCrmApiKey) {
        return externalCrmApiKey.set('active', active);
      }
    }
  });
  var _default = GongEditorComponent;
  _exports.default = _default;
});