define("frontend/pods/components/date-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DatePickerComponent = Ember.Component.extend({
    tagName: 'input',
    classNames: ['form-control'],
    attributeBindings: ['type', 'disabled', 'placeholder'],
    type: 'text',
    startDate: null,
    onChange: function onChange() {},
    date: null,
    _renderPicker: Ember.on('didInsertElement', function () {
      var _this = this;

      var date = this.date || new Date();
      this.$().datepicker({
        format: "dd MM yyyy",
        autoclose: true,
        todayBtn: 'linked',
        clearBtn: true,
        startDate: this.startDate
      });
      this.$().datepicker().on('changeDate', function (e) {
        return Ember.run(function () {
          if (Ember.isEmpty(_this.$().datepicker().val())) {
            _this.set('date', null);
          } else {
            _this.set('date', e.date);
          }

          return _this.onChange();
        });
      });
      return this._setPickerDate();
    }),
    _unrenderPicker: Ember.on('willDestroyElement', function () {
      return this.$().datepicker('remove');
    }),
    _updatePickerOnDateChange: Ember.observer('date', function () {
      return this._setPickerDate();
    }),
    _setPickerDate: function _setPickerDate() {
      var date = this.date;

      if (date) {
        this.$().datepicker('setDate', date);
      }

      if (!date) {
        return this.$().val('');
      }
    },
    _initializeProperties: Ember.on('init', function () {
      return this.date;
    })
  });
  var _default = DatePickerComponent;
  _exports.default = _default;
});