define("frontend/pods/leads/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadsRoute = Ember.Route.extend({
    requiresLogin: true
  });
  var _default = LeadsRoute;
  _exports.default = _default;
});