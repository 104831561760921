define("frontend/pods/service-providers/service-provider/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ServiceProviderRoute = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('service-provider', params.service_provider_id);
    }
  });
  var _default = ServiceProviderRoute;
  _exports.default = _default;
});