define("frontend/pods/components/soft-phone/control-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "niwKot4m",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,9,[\"isOnline\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"bar\"],[[\"from\"],[\"frontend/pods/components/soft-phone/control-panel/styles\"]]]]]],[12],[2,\"\\n    \"],[1,[34,4]],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"btn btn-small btn-inverse \",[30,[36,0],[\"dialpad-button\"],[[\"from\"],[\"frontend/pods/components/soft-phone/control-panel/styles\"]]]]]],[4,[38,6],[[32,0],[35,5]],null],[12],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"dialpad-icon\"],[[\"from\"],[\"frontend/pods/components/soft-phone/control-panel/styles\"]]]]]],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-ellipsis-v\"],[12],[13],[10,\"i\"],[14,0,\"fa fa-ellipsis-v\"],[12],[13],[10,\"i\"],[14,0,\"fa fa-ellipsis-v\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[30,[36,8],[[35,7],\"Hide Keypad\",\"Show Keypad\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"animated fadeInDown \",[30,[36,0],[\"dialpad\"],[[\"from\"],[\"frontend/pods/components/soft-phone/control-panel/styles\"]]]]]],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"class\"],[[30,[36,1],[[30,[36,0],[\"digits\"],[[\"from\"],[\"frontend/pods/components/soft-phone/control-panel/styles\"]]]],null]]]]],[2,\"\\n      \"],[1,[34,3]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"concat\",\"soft-phone/digits-bar\",\"soft-phone/dial-pad\",\"soft-phone/mute-button\",\"toggleDialpad\",\"action\",\"isShowingDialpad\",\"if\",\"softphone\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/soft-phone/control-panel/template.hbs"
    }
  });

  _exports.default = _default;
});