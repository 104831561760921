define("frontend/pods/components/call-bottom-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallBottomBarComponent = Ember.Component.extend({
    classNames: ["bottom-bar", "innerAll"],
    callService: null,
    userOutboundCalls: Ember.computed.alias('callService.userOutboundCalls'),
    agentOutboundCalls: Ember.computed.alias('callService.agentOutboundCalls'),
    outboundCalls: Ember.computed.alias('callService.outboundCalls'),
    agentTransferredCalls: Ember.computed.alias('callService.agentTransferredCalls'),
    voiceMailDroppedCalls: Ember.computed.alias('callService.voiceMailDroppedCalls'),
    isAgent: Ember.computed.alias('callService.authentication.isAgent'),
    callCount: Ember.computed('isAgent', 'userOutboundCalls.length', 'agentOutboundCalls.length', 'outboundCalls.length', function () {
      if (this.isAgent) {
        // for agents, the call count is the number of dials made
        return this.get('userOutboundCalls.length');
      } else {
        // for clients, the call count is the number of unique agent calls + user calls
        return this.get('outboundCalls.length');
      }
    }),
    conversationCount: Ember.computed('isAgent', 'agentTransferredCalls.length', 'userOutboundCalls.length', function () {
      if (this.isAgent) {
        // for agents, the number of transfers made
        return this.get('agentTransferredCalls.length');
      } else {
        // for clients, the number of calls the user has been connected to (manually or via an agent)
        return this.get('userOutboundCalls.length');
      }
    }),
    voiceMailCount: Ember.computed.alias('voiceMailDroppedCalls.length')
  });
  var _default = CallBottomBarComponent;
  _exports.default = _default;
});