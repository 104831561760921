define("frontend/pods/lead-lists/index/route", ["exports", "frontend/utils/paginated"], function (_exports, _paginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadListsIndexRoute = Ember.Route.extend({
    model: function model() {
      return _paginated.default.Array.find('lead-list', {
        store: this.store,
        pageSize: 25
      });
    }
  });
  var _default = LeadListsIndexRoute;
  _exports.default = _default;
});