define("frontend/pods/outbound-voice-mail-recordings/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pn/LOSZU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"col-separator col-unscrollable col-separator-last\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-table\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll border-bottom\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h4\"],[14,0,\"margin-none\"],[12],[2,\"New Outgoing Voice Mail Message\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"innerAll inner-2x\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],null,[[\"icon\",\"text\",\"isWaiting\",\"isPrimary\",\"onClick\"],[\"save\",\"Save Changes\",[35,0,[\"isSaving\"]],\"true\",[30,[36,3],[[32,0],[30,[36,2],[\"save\"],null]],null]]]]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],null,[[\"text\",\"onClick\"],[\"Cancel\",[30,[36,3],[[32,0],[30,[36,2],[\"cancel\"],null]],null]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"partial/outbound-voice-mail-recording/form\",\"route-action\",\"action\",\"ui-button\"]}",
    "meta": {
      "moduleName": "frontend/pods/outbound-voice-mail-recordings/new/template.hbs"
    }
  });

  _exports.default = _default;
});