define("frontend/services/chat", ["exports", "frontend/models/meta", "frontend/utils/web-socket"], function (_exports, _meta, _webSocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    messages: Ember.computed(function () {
      return [];
    }),
    conversations: Ember.computed(function () {
      return [];
    }),
    channel: Ember.computed(function () {
      return "/chat_inbox/".concat(this.userId);
    }),
    url: '/api/v1/chat_messages',
    _subscription: null,
    store: Ember.inject.service(),
    webSocket: Ember.inject.service(),
    session: Ember.inject.service('authentication'),
    meta: Ember.computed(function () {
      return _meta.default;
    }),
    apiVersion: Ember.computed.oneWay('meta.apiVersion'),
    sessionId: Ember.computed.oneWay('session.id'),
    userId: Ember.computed.oneWay('session.userId'),
    _initializeSubscription: Ember.observer('session.userId', function () {
      var loggedIn = this.get('session.userId');

      if (loggedIn) {
        return this.subscribe();
      } else {
        return this.unsubscribe();
      }
    }),
    _initializeSubscriptionOnInit: Ember.on('init', function () {
      return this._initializeSubscription();
    }),
    subscribe: function subscribe(channel) {
      var _this = this;

      if (this.channel === channel) {
        return;
      }

      channel = this.channel;
      this.unsubscribe();
      return this.set('_subscription', _webSocket.default.Subscription.create({
        client: this.webSocket,
        channel: channel,
        onNewMessage: function onNewMessage(message) {
          return _this._onNewMessage(message);
        }
      }));
    },
    unsubscribe: function unsubscribe() {
      if (!this._subscription) {
        return;
      }

      this._subscription.unsubscribe();

      return this.set('_subscription', null);
    },
    sendMessage: function sendMessage(message, to) {
      var options;
      var adapter = this.store.adapterFor('application');
      return adapter.executeServerAction('chat_messages', undefined, 'send_chat', options = {
        method: 'POST',
        data: {
          message: message,
          to_id: to
        }
      }).then(function () {});
    },
    startConversation: function startConversation(user) {
      if (!this._conversationExists(user.get('email'))) {
        var newConversation = {
          conversation: user.get('email'),
          email: user.get('email'),
          first_name: user.get('firstName'),
          last_name: user.get('lastName'),
          company_name: user.get('companyDisplayName'),
          user_id: user.get('id')
        };
        return this.conversations.pushObject(newConversation);
      }
    },
    endConversation: function endConversation(conversation) {
      return this.conversations.removeObject(conversation);
    },
    _onNewMessage: function _onNewMessage(payload) {
      // Add message to messages
      this.messages.pushObject(payload);

      if (!this._conversationExists(payload.conversation)) {
        this.conversations.pushObject({
          conversation: payload.conversation,
          user_id: payload.user_id,
          email: payload.email,
          first_name: payload.first_name,
          last_name: payload.last_name,
          company_name: payload.company_name
        });
      }

      return $('.chat-conversation-messages').scrollTop($('.chat-conversation-messages').prop('scrollHeight'));
    },
    _conversationExists: function _conversationExists(conversation) {
      var conversations = this.conversations.filter(function (item) {
        return item.conversation === conversation;
      });
      return conversations.length > 0;
    }
  });

  _exports.default = _default;
});