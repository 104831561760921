define("frontend/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fiK401dD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"layout-app\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"row error\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-4 col-md-offset-1 center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"center\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"img\"],[14,\"src\",\"../assets/images/error-icon-bucket.png \"],[14,0,\"error-icon\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-5 content center\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"h1\"],[14,0,\"strong\"],[12],[2,\"Location Not Found\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"h4\"],[14,0,\"innerB\"],[12],[2,\"Sorry, this page does not exist.\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});