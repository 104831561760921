define("frontend/pods/call-sessions/call-session/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CallSessionIndexController = Ember.Controller.extend({
    outboundCalls: null,
    conversations: Ember.computed.filterBy('outboundCalls', 'isConversation', true),
    voicemails: Ember.computed.filterBy('outboundCalls', 'didLeaveVoicemail'),
    averageTimeToLead: Ember.computed('model.idleTime', 'conversations.length', function () {
      var idleTime = this.get('model.idleTime');
      var callCount = this.get('conversations.length');

      if (idleTime && callCount) {
        return idleTime / callCount;
      }
    }),
    outboundCallSessions: Ember.computed('outboundCalls.@each.callSession', {
      get: function get() {
        return this.outboundCalls.mapBy('callSession');
      }
    }),
    agents: Ember.computed('outboundCallSessions.@each.user', function () {
      var callers = this.outboundCallSessions.mapBy('user').uniq();
      callers.removeObject(this.get('model.user'));
      return callers;
    }),
    agentCount: Ember.computed.readOnly('agents.length'),
    canHearClientRecording: Ember.computed.readOnly('authenticationService.canHearClientRecordings'),
    canHearAgentRecording: Ember.computed.readOnly('authenticationService.canHearAgentRecordings')
  });
  var _default = CallSessionIndexController;
  _exports.default = _default;
});