define("frontend/pods/components/agent/agent-console/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Eet8VaH",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"widget\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/widget/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,2],null,[[\"heading\",\"body\"],[[30,[36,1],[\"agent/agent-console/widget/heading\"],null],[30,[36,1],[\"agent/agent-console/widget/body\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/widget/template.hbs"
    }
  });

  _exports.default = _default;
});