define("frontend/pods/leads/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadsNewController = Ember.Controller.extend({
    queryParams: ['referredBy']
  });
  var _default = LeadsNewController;
  _exports.default = _default;
});