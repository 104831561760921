define("frontend/pods/components/agent/status-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sRit1gdc",
    "block": "{\"symbols\":[\"statusInfo\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[35,5]],[[\"use\",\"duration\"],[[35,4],300]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[30,[36,2],[\"bg-\",[32,1,[\"color\"]]],null],\" \",[30,[36,0],[\"status-bar\"],[[\"from\"],[\"frontend/pods/components/agent/status-bar/styles\"]]]]]],[12],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"status\"],[[\"from\"],[\"frontend/pods/components/agent/status-bar/styles\"]]]]]],[12],[2,\"\\n        \"],[1,[32,1,[\"title\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"call\",\"isOnline\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"time\"],[[\"from\"],[\"frontend/pods/components/agent/status-bar/styles\"]]]]]],[12],[2,\"\\n          \"],[1,[30,[36,1],null,[[\"startTime\"],[[32,0,[\"call\",\"statusUpdatedAt\"]]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"time-diff\",\"concat\",\"if\",\"crossFade\",\"statusInfo\",\"animated-value\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/status-bar/template.hbs"
    }
  });

  _exports.default = _default;
});