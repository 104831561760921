define("frontend/pods/components/agent/agent-console/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "feiN9IvQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[\"box\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/notes/styles\"]]]]]],[12],[2,\"\\n  \"],[10,\"form\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"title\"],[\"Dial Notes\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"class\",\"value\"],[\"form-control\",[32,0,[\"outboundCall\",\"notes\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"textarea\",\"local-class\",\"ui-form-field\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/notes/template.hbs"
    }
  });

  _exports.default = _default;
});