define("frontend/pods/components/agent/agent-console/current-call/actions/states/ivr/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ivr": "_ivr_11ryls",
    "container": "_container_11ryls",
    "dial-pad": "_dial-pad_11ryls",
    "actions": "_actions_11ryls",
    "unmute": "_unmute_11ryls"
  };
  _exports.default = _default;
});