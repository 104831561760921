define("frontend/pods/lead-phone-numbers/lead-phone-number/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadPhoneNumberController = Ember.Controller.extend({});
  var _default = LeadPhoneNumberController;
  _exports.default = _default;
});