define("frontend/pods/components/link-salesforce-account/component", ["exports", "frontend/utils/oauth-authentication"], function (_exports, _oauthAuthentication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LinkSalesforceAccountComponent = Ember.Component.extend({
    store: null,
    //template: Ember.Handlebars.compile '<button class="btn btn-info" {{action "submit"}}>Link Salesforce Account</button>'
    authentication: Ember.computed(function () {
      return _oauthAuthentication.default.create({
        provider: 'salesforce',
        providerDisplayName: 'Salesforce',
        store: this.store
      });
    }),
    authenticationState: Ember.computed.alias('authentication.state'),
    actions: {
      submit: function submit() {
        return this.authentication.authenticate().then(function () {
          return console.log('promise: authenticated!');
        }, function () {
          return console.log('promise: failed!');
        });
      }
    }
  });
  var _default = LinkSalesforceAccountComponent;
  _exports.default = _default;
});