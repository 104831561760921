define("frontend/pods/organizations/organization/users/controller", ["exports", "frontend/utils/time-format"], function (_exports, _timeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationUsersController = Ember.Controller.extend({
    canEditUsers: Ember.computed.readOnly('organizationController.canEditUsers'),
    isClient: Ember.computed.readOnly('organizationController.isClient'),
    isTeamManager: Ember.computed.readOnly('organizationController.isTeamManager'),
    isAdmin: Ember.computed.readOnly('organizationController.isAdmin'),
    organizationController: Ember.inject.controller('organizations.organization'),
    filters: Ember.computed.alias('organizationController.filters'),
    dashboard: Ember.computed.readOnly('organizationController.dashboard'),
    filteredUsers: Ember.computed.readOnly('organizationController.filteredUsers'),
    filteredTeamUsers: Ember.computed.readOnly('organizationController.filteredTeamUsers'),
    filteredUsersTeamManager: Ember.computed.readOnly('organizationController.filteredUsersTeamManager'),
    actions: {
      filterDashboard: function filterDashboard(filters) {
        this.set('filters', filters);
        this.set('skip', 'true');
        this.set('list', null);
        return this.set('datasource', null);
      },
      exportToCSV: function exportToCSV() {
        var _this = this;

        var headers = ['Name', 'Total Time', 'Dials', 'Voicemails', 'D2C', 'Connections', 'Appointments', 'Conversion Rate', 'Connected Time', 'Idle Time', 'Paused Time'];
        var rows = [];
        rows.push(headers.join(','));
        var users = [];

        if (this.get('isTeamManager')) {
          users = this.get('filteredUsersTeamManager');
        } else {
          users = this.get('filteredTeamUsers');
        }

        if (users.length === 0) {
          return;
        }

        users.forEach(function (user) {
          var _userDashboardItem$co, _userDashboardItem$id, _userDashboardItem$pa, _userDashboardItem$di, _userDashboardItem$vo, _userDashboardItem$co2, _userDashboardItem$ap;

          var dashboardUsageData = _this.get('dashboard.usageByUser') || [];
          var userId = user.get('id').toString();
          var userDashboardItem = dashboardUsageData.find(function (i) {
            return i.user_id.toString() === userId;
          });
          var name = user.get('name');
          var connectedTime = (_userDashboardItem$co = userDashboardItem === null || userDashboardItem === void 0 ? void 0 : userDashboardItem.connected_time) !== null && _userDashboardItem$co !== void 0 ? _userDashboardItem$co : 0;
          var idleTime = (_userDashboardItem$id = userDashboardItem === null || userDashboardItem === void 0 ? void 0 : userDashboardItem.idle_time) !== null && _userDashboardItem$id !== void 0 ? _userDashboardItem$id : 0;
          var pausedTime = (_userDashboardItem$pa = userDashboardItem === null || userDashboardItem === void 0 ? void 0 : userDashboardItem.paused_time) !== null && _userDashboardItem$pa !== void 0 ? _userDashboardItem$pa : 0;
          var totalTime = connectedTime + idleTime + pausedTime;
          var dials = (_userDashboardItem$di = userDashboardItem === null || userDashboardItem === void 0 ? void 0 : userDashboardItem.dials) !== null && _userDashboardItem$di !== void 0 ? _userDashboardItem$di : 0;
          var voicemails = (_userDashboardItem$vo = userDashboardItem === null || userDashboardItem === void 0 ? void 0 : userDashboardItem.voicemails) !== null && _userDashboardItem$vo !== void 0 ? _userDashboardItem$vo : 0;
          var connections = (_userDashboardItem$co2 = userDashboardItem === null || userDashboardItem === void 0 ? void 0 : userDashboardItem.connections) !== null && _userDashboardItem$co2 !== void 0 ? _userDashboardItem$co2 : 0;
          var d2c = 0;

          if (connections > 0) {
            d2c = Math.round(dials / connections * 10) / 10;
          }

          var appointments = (_userDashboardItem$ap = userDashboardItem === null || userDashboardItem === void 0 ? void 0 : userDashboardItem.appointments) !== null && _userDashboardItem$ap !== void 0 ? _userDashboardItem$ap : 0;
          var conversionRate = 0;

          if (appointments > 0) {
            conversionRate = Math.round(appointments / connections * 100) / 100;
          }

          var row = [name, _timeFormat.default.hhmmss(totalTime), dials, voicemails, d2c, connections, appointments, conversionRate, _timeFormat.default.hhmmss(connectedTime), _timeFormat.default.hhmmss(idleTime), _timeFormat.default.hhmmss(pausedTime)];
          return rows.push(row.join(','));
        });
        var csvContent = rows.join('\n');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', "data:text/csv;charset=utf-8,".concat(encodedUri));
        link.setAttribute('download', 'users.csv');
        document.body.appendChild(link);
        return link.click();
      }
    }
  });
  var _default = OrganizationUsersController;
  _exports.default = _default;
});