define("frontend/pods/components/agent/agent-console/states/dialing/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "dialing": "_dialing_tamdxk",
    "title": "_title_tamdxk"
  };
  _exports.default = _default;
});