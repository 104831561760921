define("frontend/pods/components/call-lead-list-select/component", ["exports", "frontend/pods/components/dropdown-button/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CallLeadListSelectComponent = _component.default.extend({
    callService: null,
    isShowing: true,
    classNameBindings: ['isShowing::hidden'],
    store: Ember.computed.readOnly('callService.store'),
    leadLists: Ember.computed.readOnly('callService.leadLists'),
    leadList: Ember.computed.readOnly('callService.leadList'),
    sortField: 'name',
    content: Ember.computed('leadLists.@each.name', 'sortField', {
      get: function get() {
        return this.leadLists.sortBy(this.sortField);
      }
    }),
    value: Ember.computed('leadList', {
      get: function get() {
        return this.get('leadList.id');
      },
      set: function set(key, value) {
        if (this.get('leadList.id') !== value) {
          this.set('leadList', this.store.peekRecord('lead-list', value));
        }

        return this.get('leadList.id');
      }
    }),
    activateLeadList: function activateLeadList(item) {
      this.callService.activateLeadList(item);
    }
  });

  var _default = CallLeadListSelectComponent;
  _exports.default = _default;
});