define("frontend/pods/components/view/ui-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["box-generic", "padding-none"],
    title: null
  });

  _exports.default = _default;
});