define("frontend/adapters/file-upload", ["exports", "frontend/adapters/application", "legendary-file-upload/mixins/adapters/file-upload"], function (_exports, _application, _fileUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_fileUpload.default);

  _exports.default = _default;
});