define("frontend/pods/organizations/organization/controller", ["exports", "moment", "frontend/models/outbound-call", "frontend/utils/file-downloader"], function (_exports, _moment, _outboundCall, _fileDownloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationController = Ember.Controller.extend({
    isAdmin: Ember.computed.equal('authenticationService.activeRole', 'admin'),
    isClientManager: Ember.computed.equal('authenticationService.activeRole', 'client_manager'),
    isTeamManager: Ember.computed.equal('authenticationService.activeRole', 'team_manager'),
    isClient: Ember.computed.equal('authenticationService.activeRole', 'client'),
    displayTeamsFilter: Ember.computed.or('isAdmin', 'isClientManager'),
    canEditOrganization: Ember.computed.bool('isAdmin'),
    // technically a client manager can edit the statuses, but currently hidden from the user
    canEditStatuses: Ember.computed.or('isAdmin', 'isClientManager'),
    canEditUsers: Ember.computed.or('isAdmin', 'isClientManager', 'isTeamManager'),
    canEditTeams: Ember.computed.or('isAdmin', 'isClientManager'),
    clientEmail: Ember.computed('authenticationService.activeUser.email', function () {
      return this.get('authenticationService.activeUser.email');
    }),
    canViewPhoneNumbers: Ember.computed('isAdmin', 'isClientManager', 'model.maxPhoneNumbers', function () {
      return (this.get('isAdmin') || this.get('isClientManager')) && !!this.get('model.maxPhoneNumbers');
    }),
    sortedUsers: Ember.computed('model.users.@each.lastName', 'model.users.@each.firstName', function () {
      return this.get('model.users').sortBy('lastName', 'firstName');
    }),
    sortedUsersTeamManager: Ember.computed('model.id', function () {
      return this.store.query('user', {
        organization_id: this.get('model.id')
      });
    }),
    sortedStatuses: Ember.computed('model.id', function () {
      var adapter = this.store.adapterFor('application');
      var organizationId = this.get('model.id');
      var result = [];
      adapter.executeServerAction('organization', organizationId, 'unique_lead_statuses').then(function (data) {
        return result.addObjects(data.unique_lead_statuses);
      });
      return result;
    }),
    sortedLeadLists: Ember.computed('model.id', function () {
      return this.store.query('lead-list', {
        organization_id: this.get('model.id')
      });
    }),
    filteredLeadLists: Ember.computed('model.id', function () {
      return this.store.findAll('lead-list');
    }),
    sortedTeams: Ember.computed('model.id', function () {
      return this.store.query('team', {
        organization_id: this.get('model.id')
      });
    }),
    filteredUsers: Ember.computed('sortedUsers', 'filters', function () {
      var filters = this.filters || {};
      var userIds = filters.userIds || [];
      var sortedUsers = this.sortedUsers;

      if (Ember.isEmpty(userIds)) {
        return sortedUsers.filterBy('isDisabled', false);
      }

      return sortedUsers.filter(function (u) {
        return userIds.includes(u.get('id'));
      });
    }),
    filteredTeamUsers: Ember.computed('filteredUsers', 'filters', function () {
      var filters = this.filters || {};
      var teamIds = filters.teamIds || [];
      var filteredUsers = this.filteredUsers;

      if (Ember.isEmpty(teamIds)) {
        return filteredUsers;
      }

      return filteredUsers.filter(function (u) {
        return teamIds.includes(u.get('teamId'));
      });
    }),
    filteredUsersTeamManager: Ember.computed('sortedUsersTeamManager', 'filters', function () {
      var filters = this.filters || {};
      var userIds = filters.userIds || [];
      var sortedUsers = this.sortedUsersTeamManager;

      if (Ember.isEmpty(userIds)) {
        return sortedUsers.filterBy('isDisabled', false);
      }

      return sortedUsers.filter(function (u) {
        return userIds.includes(u.get('id'));
      });
    }),
    filters: null,
    dashboard: Ember.computed.readOnly('_dashboardQuery.firstObject'),
    queryParams: ['list', 'start', 'datasource', 'skip'],
    _setList: Ember.observer('list', 'start', 'datasource', 'skip', function () {
      this.getProperties('list', 'start', 'datasource', 'skip');

      if (this.skip !== 'true') {
        this.setProperties({
          filters: null
        });
      }
    }),
    _dashboardQuery: Ember.computed('model', 'filters', 'list', 'start', function () {
      var model = this.model;

      if (!model) {
        return null;
      }

      var filters = this.filters || {
        startDate: (0, _moment.default)(this.start).startOf('day').toDate(),
        leadListIds: [this.list]
      } || {
        startDate: (0, _moment.default)().startOf('day').toDate()
      };
      var f = {};
      f.id = model.get('id');

      if (filters.startDate) {
        f.start_time = filters.startDate.toISOString();
      }

      if (filters.endDate) {
        f.end_time = filters.endDate.toISOString();
      }

      f.user_id = this.get('authenticationService.activeUserId');

      if (filters.userIds) {
        f.user_ids = filters.userIds;
      }

      if (filters.statuses) {
        f.statuses = filters.statuses;
      }

      if (filters.leadListIds) {
        f.lead_list_ids = filters.leadListIds;
      }

      if (filters.teamIds) {
        f.team_ids = filters.teamIds;
      }

      if (filters.session) {
        f.session = filters.session;
      }

      if (filters.dialResults) {
        f.dial_results = filters.dialResults;
      }

      if (filters.agentConversationResults) {
        f.agent_conversation_results = filters.agentConversationResults;
      }

      return this.store.query('organization-dashboard', f);
    }),
    router: Ember.inject.service(),
    isDashboardFilterShowing: Ember.computed('router.currentRouteName', {
      get: function get() {
        var subroute = this.router.currentRouteName.split('.').pop();
        return ['index', 'dials', 'conversations', 'appointments', 'users', 'flagged-phone-numbers', 'flagged-records'].includes(subroute);
      }
    }),
    dialsRemaining: Ember.computed('model.totalAllowedDials', 'dashboard', function () {
      var totalAllowedDials = this.get('model.totalAllowedDials') || 0;
      var dashboard = this.get('dashboard');
      var totalDials = 0;

      if (dashboard && totalAllowedDials && totalAllowedDials > 0) {
        dashboard.dialCountForMonth.items.forEach(function (item) {
          return totalDials += item.count;
        });
      }

      return totalAllowedDials - totalDials;
    }),
    actions: {
      filterDashboard: function filterDashboard(filters) {
        this.set('filters', filters);
        this.set('skip', 'true');
        this.set('list', null);
        return this.set('datasource', null);
      },
      export: function _export(filters) {
        var model = this.model;
        var id = model.get('id');
        filters = this.filters || {
          startDate: (0, _moment.default)(this.start).startOf('day').toDate(),
          leadListIds: [this.list]
        } || {
          startDate: (0, _moment.default)().startOf('day').toDate()
        };
        var f = {};

        if (filters.startDate) {
          f.start_time = filters.startDate.toISOString();
        }

        if (filters.endDate) {
          f.end_time = filters.endDate.toISOString();
        }

        f.user_id = this.get('authenticationService.activeUserId');

        if (filters.userIds) {
          f.user_ids = filters.userIds;
        }

        if (filters.statuses) {
          f.statuses = filters.statuses;
        }

        if (filters.leadListIds) {
          f.lead_list_ids = filters.leadListIds;
        }

        if (filters.teamIds) {
          f.team_ids = filters.teamIds;
        }

        if (filters.session) {
          f.session = filters.session;
        }

        if (filters.dialResults) {
          f.dial_results = filters.dialResults;
        }

        if (filters.agentConversationResults) {
          f.agent_conversation_results = filters.agentConversationResults;
        }

        var data = {
          id: id,
          outbound_call_analytic: f
        };
        var adapter = this.store.adapterFor('application');
        var url = "".concat(adapter.host, "/api/v1/outbound_call_analytics.csv");
        var headerData = this.store.adapterFor('application').get('headerData');

        var fileDownloader = _fileDownloader.default.create({
          url: url,
          method: 'post',
          data: data,
          headerData: headerData
        });

        return fileDownloader.execute();
      }
    }
  });
  var _default = OrganizationController;
  _exports.default = _default;
});