define("frontend/pods/components/execvision-editor/component", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExecvisionEditorComponent = Ember.Component.extend(_modal.default, {
    organization: null,
    store: null,
    execvisionAccounts: Ember.computed.readOnly('organization.execvisionAccounts'),
    displayExecvisionAccounts: Ember.computed('execvisionAccounts.[]', 'modelsToAdd.[]', 'modelsToRemove.[]', 'editingExecvisionAccounts.[]', 'newExecvisionAccount', function () {
      var execvisionAccounts = this.execvisionAccounts;
      var modelsToAdd = this.modelsToAdd;
      var modelsToRemove = this.modelsToRemove;
      var result = [];
      result.addObjects(execvisionAccounts);
      result.addObjects(modelsToAdd);
      result.removeObjects(modelsToRemove);
      result.removeObject(this.newExecvisionAccount);
      var editingExecvisionAccounts = this.editingExecvisionAccounts;
      return result.map(function (g) {
        return {
          execvision: g,
          isEditing: editingExecvisionAccounts.includes(g)
        };
      });
    }),
    modelsToAdd: Ember.computed(function () {
      return [];
    }),
    modelsToRemove: Ember.computed(function () {
      return [];
    }),
    newExecvisionSftpHostname: null,
    newExecvisionUsername: null,
    newExecvisionPrivateKeyPath: null,
    newExecvisionActive: true,
    newHistoricSyncLimit: null,
    newExecvisionAccount: null,
    editingExecvisionAccounts: Ember.computed(function () {
      return [];
    }),
    actions: {
      add: function add() {
        var _this = this;

        if (this.newExecvisionAccount) {
          this.newExecvisionAccount.deleteRecord();
        }

        var newExecvisionAccount = this.get('organization.store').createRecord('execvision-account', {
          organization: this.organization,
          sftpHostname: this.newExecvisionSftpHostname,
          privateKeyPath: this.newExecvisionPrivateKeyPath,
          username: this.newExecvisionUsername,
          historicSyncDaysLimit: this.newHistoricSyncLimit,
          active: this.newExecvisionActive
        });
        this.set('newExecvisionAccount', newExecvisionAccount);
        return newExecvisionAccount.save().then(function () {
          _this.modelsToAdd.addObject(newExecvisionAccount);

          return _this.setProperties({
            newExecvisionAccount: null,
            newExecvisionSftpHostname: null,
            newExecvisionPrivateKeyPath: null,
            newExecvisionUsername: null,
            newExecvisionActive: true,
            newHistoricSyncLimit: null
          });
        }).catch(function () {});
      },
      remove: function remove(execvision) {
        var _this2 = this;

        return this.modal.confirm('Are you sure you want to remove this Execvision Account?').then(function (result) {
          if (result) {
            return execvision.destroyRecord().then(function () {
              return _this2.modelsToRemove.addObject(execvision);
            });
          }
        });
      },
      edit: function edit(execvision) {
        return this.editingExecvisionAccounts.addObject(execvision);
      },
      cancel: function cancel(execvision) {
        return this.editingExecvisionAccounts.removeObject(execvision);
      },
      save: function save(execvision) {
        var _this3 = this;

        return execvision.save().then(function () {
          return _this3.editingExecvisionAccounts.removeObject(execvision);
        }).catch(function () {});
      },
      activeChanged: function activeChanged(active, execvision) {
        return execvision.set('active', active);
      }
    }
  });
  var _default = ExecvisionEditorComponent;
  _exports.default = _default;
});