define("frontend/pods/components/display-phone/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DisplayPhoneComponent = Ember.Component.extend({
    tagName: 'span',
    isValid: true,
    phone: null,
    additionalInfo: null,
    _regex: {
      us: /^\+1\d{10}$/
    },
    phoneText: Ember.computed('phone', 'isValid', function () {
      var phone = this.phone || '';

      if (!this.isValid) {
        return phone;
      }

      if (this._regex.us.test(phone)) {
        return "(".concat(phone.substr(2, 3), ") ").concat(phone.substr(5, 3), "-").concat(phone.substr(8, 4));
      } else {
        return phone;
      }
    }),
    displayAdditionalInfo: Ember.computed.and('isValid', 'additionalInfo')
  });
  var _default = DisplayPhoneComponent;
  _exports.default = _default;
});