define("frontend/models/organization-dashboard", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Model$extend;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _model.default.extend((_Model$extend = {
    metrics: (0, _model.attr)(),
    usageByUser: (0, _model.attr)(),
    conversionRateByUser: (0, _model.attr)(),
    statusesByDial: (0, _model.attr)(),
    callResultsByDial: (0, _model.attr)(),
    dialsByRegion: (0, _model.attr)(),
    dialsOverTime: (0, _model.attr)(),
    dialsByHour: (0, _model.attr)(),
    voicemailsOverTime: (0, _model.attr)(),
    connectionsByRegion: (0, _model.attr)(),
    connectionsOverTime: (0, _model.attr)(),
    connectionsByHour: (0, _model.attr)(),
    appointmentsByRegion: (0, _model.attr)(),
    appointmentsOverTime: (0, _model.attr)(),
    appointmentsByHour: (0, _model.attr)(),
    dialsPerConversation: (0, _model.attr)(),
    totalHours: (0, _model.attr)(),
    totalMinutes: (0, _model.attr)(),
    appointmentCount: (0, _model.attr)(),
    appointmentsPerHour: (0, _model.attr)(),
    conversionRate: (0, _model.attr)(),
    params: (0, _model.attr)(),
    organization: (0, _model.belongsTo)('organization'),
    dialCount: Ember.computed('metrics.dial_count', function () {
      return this.get('metrics.dial_count') || 0;
    }),
    connectionCount: Ember.computed('metrics.connection_count', function () {
      return this.get('metrics.connection_count') || 0;
    })
  }, _defineProperty(_Model$extend, "appointmentCount", Ember.computed('metrics.appointment_count', function () {
    return this.get('metrics.appointment_count') || 0;
  })), _defineProperty(_Model$extend, "voicemailCount", Ember.computed('metrics.voicemail_count', function () {
    return this.get('metrics.voicemail_count') || 0;
  })), _defineProperty(_Model$extend, "totalUserTime", Ember.computed('metrics.total_user_time', function () {
    return this.get('metrics.total_user_time') || 0;
  })), _defineProperty(_Model$extend, "dialCountForMonth", Ember.computed('metrics.dial_count_for_month', function () {
    return this.get('metrics.dial_count_for_month') || 0;
  })), _defineProperty(_Model$extend, "leadCount", Ember.computed('metrics.unique_lead_count', function () {
    return this.get('metrics.unique_lead_count') || 0;
  })), _defineProperty(_Model$extend, "totalHours", Ember.computed('totalUserTime', function () {
    return this._round(this.totalUserTime / 60 / 60);
  })), _defineProperty(_Model$extend, "totalMinutes", Ember.computed('totalUserTime', function () {
    return this._round(this.totalUserTime / 60);
  })), _defineProperty(_Model$extend, "connectionsPerHour", Ember.computed('connectionCount', 'totalHours', function () {
    var p = this.getProperties('connectionCount', 'totalHours');

    if (!p.totalHours) {
      return 0;
    }

    return this._round(p.connectionCount / p.totalHours);
  })), _defineProperty(_Model$extend, "_round", function _round(val) {
    if (val) {
      return Math.round(val * 10) / 10;
    } else {
      return 0;
    }
  }), _defineProperty(_Model$extend, "dialsPerHour", Ember.computed('dialCount', 'totalHours', function () {
    var p = this.getProperties('dialCount', 'totalHours');

    if (!p.totalHours) {
      return 0;
    }

    return this._round(p.dialCount / p.totalHours);
  })), _defineProperty(_Model$extend, "dialsPerConversation", Ember.computed('dialCount', 'connectionCount', function () {
    var p = this.getProperties('dialCount', 'connectionCount');

    if (!p.connectionCount) {
      return 0;
    }

    return this._round(p.dialCount / p.connectionCount);
  })), _defineProperty(_Model$extend, "appointmentsPerHour", Ember.computed('appointmentCount', 'totalHours', function () {
    var p = this.getProperties('appointmentCount', 'totalHours');

    if (!p.totalHours) {
      return 0;
    }

    return this._round(p.appointmentCount / p.totalHours);
  })), _defineProperty(_Model$extend, "conversionRate", Ember.computed('appointmentCount', 'connectionCount', function () {
    var p = this.getProperties('appointmentCount', 'connectionCount');

    if (!p.appointmentCount) {
      return 0;
    }

    return this._round(p.appointmentCount / p.connectionCount * 100);
  })), _Model$extend));

  _exports.default = _default;
});