define("frontend/models/lead-list-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadListSearch = {};
  LeadListSearch.Single = Ember.Object.extend({
    field: null,
    value: null,
    hash: Ember.computed('field', 'value', {
      get: function get() {
        return this.getProperties('field', 'value');
      }
    }),
    text: Ember.computed('field', 'value', {
      get: function get() {
        var p = this.getProperties('field', 'value');

        if (p.field && p.value) {
          return "".concat(p.field, ": ").concat(p.value);
        }
      }
    })
  });
  LeadListSearch.Multiple = Ember.Object.extend({
    match: 'all',
    isMatchAny: Ember.computed.equal('match', 'any'),
    isMatchAll: Ember.computed.not('isMatchAny'),
    hasCriteria: Ember.computed.gt('criteria.length', 0),
    hasMultipleCriteria: Ember.computed.gt('criteria.length', 1),
    criteria: Ember.computed({
      get: function get() {
        if (!this._criteria) {
          this.set('_criteria', []);
        }

        return this._criteria;
      },
      set: function set(key, value) {
        var result = [];

        if (Ember.isArray(value)) {
          for (var _i = 0, _Array$from = Array.from(value); _i < _Array$from.length; _i++) {
            var item = _Array$from[_i];
            result.addObject(LeadListSearch.innerLoad(item));
          }
        }

        this.set('_criteria', result);
        return this._criteria;
      }
    }),
    hash: Ember.computed('match', 'criteria.@each.value', {
      get: function get() {
        var result = this.getProperties('match', 'criteria');
        result.criteria = (result.criteria || []).mapBy('hash');
        return result;
      }
    })
  });

  LeadListSearch.load = function (data) {
    if (data == null) {
      data = {};
    }

    if (data.field) {
      return LeadListSearch.Multiple.create({
        match: 'all',
        criteria: [data]
      });
    } else {
      return LeadListSearch.Multiple.create(data);
    }
  };

  LeadListSearch.innerLoad = function (data) {
    if (data == null) {
      data = {};
    }

    if (data.match) {
      return LeadListSearch.Multiple.create(data);
    } else {
      return LeadListSearch.Single.create(data);
    }
  };

  var _default = LeadListSearch;
  _exports.default = _default;
});