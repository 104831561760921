define("frontend/pods/data-sources/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DataSourcesIndexRoute = Ember.Route.extend();
  var _default = DataSourcesIndexRoute;
  _exports.default = _default;
});