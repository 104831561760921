define("frontend/utils/file-downloader", ["exports", "frontend/utils/form-submit"], function (_exports, _formSubmit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: This file was created by bulk-decaffeinate.
  // Sanity-check the conversion and remove this comment.
  var FileDownloader = _formSubmit.default;
  var _default = FileDownloader;
  _exports.default = _default;
});