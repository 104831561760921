define("frontend/pods/components/ui/tabs-component/tab-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UvtYKncq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"isActive\"],[[35,0]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isActive\",\"hash\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui/tabs-component/tab-content/template.hbs"
    }
  });

  _exports.default = _default;
});