define("frontend/components/view-deprecated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span style="background-color: red; color: white">DEPRECATED VIEW "{{name}}"</span>
  
  */
  {
    id: "Wtmq5NOB",
    block: "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,5,\"background-color: red; color: white\"],[12],[2,\"DEPRECATED VIEW \\\"\"],[1,[34,0]],[2,\"\\\"\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"name\"]}",
    meta: {
      moduleName: "frontend/components/view-deprecated.hbs"
    }
  });

  var ViewDeprecatedComponent = Ember.Component.extend({
    name: null
  });
  ViewDeprecatedComponent.reopenClass({
    positionalParams: ['name']
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ViewDeprecatedComponent);

  _exports.default = _default;
});