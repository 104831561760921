define("frontend/pods/components/partial/outbound-voice-mail-recording/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AcSqgfEb",
    "block": "{\"symbols\":[\"r\"],\"statements\":[[1,[30,[36,6],null,[[\"model\"],[[35,2]]]]],[2,\"\\n\"],[10,\"form\"],[12],[2,\"\\n\"],[6,[37,7],null,[[\"title\"],[\"Name\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,5],null,[[\"type\",\"class\",\"value\"],[\"text\",\"form-control\",[35,2,[\"name\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],null,[[\"title\"],[\"Recording\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n\\n\"],[6,[37,4],null,[[\"recording\",\"onRecord\"],[[35,2,[\"recording\",\"content\"]],[30,[36,0],[[32,0],[30,[36,3],[[35,2,[\"recording\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,1],null,[[\"icon\",\"text\",\"isInverse\",\"onClick\",\"isEnabled\"],[\"play\",\"Play\",\"true\",[30,[36,0],[[32,0],[32,1,[\"play\"]]],null],[32,1,[\"canPlay\"]]]]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,[36,1],null,[[\"icon\",\"text\",\"isDanger\",\"onClick\",\"isEnabled\"],[\"circle\",\"Record\",\"true\",[30,[36,0],[[32,0],[32,1,[\"record\"]]],null],[32,1,[\"canRecord\"]]]]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,[36,1],null,[[\"icon\",\"text\",\"isInverse\",\"onClick\",\"isEnabled\"],[\"stop\",\"Stop\",\"true\",[30,[36,0],[[32,0],[32,1,[\"stop\"]]],null],[32,1,[\"canStop\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"ui-button\",\"model\",\"mut\",\"recording-controller\",\"input\",\"error-list\",\"ui-form-field\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/partial/outbound-voice-mail-recording/form/template.hbs"
    }
  });

  _exports.default = _default;
});