define("frontend/pods/components/lead-picture/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Q/9EWoA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"display-block-inline animated fadeInDown\"],[12],[2,\"\\n\"],[6,[37,4],[[35,1,[\"hasLinkedInProfile\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[35,1,[\"linkedInProfile\",\"picture\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"img\"],[15,\"src\",[34,1,[\"linkedInProfile\",\"picture\"]]],[15,5,[34,3]],[14,0,\"img-responsive img-rounded thumb animated fadeInDown\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,2],null,[[\"email\",\"size\",\"classNames\"],[[35,1,[\"email\"]],[35,0],\"img-responsive img-rounded thumb\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,2],null,[[\"email\",\"size\",\"classNames\"],[[35,1,[\"email\"]],[35,0],\"img-responsive img-rounded thumb\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"size\",\"lead\",\"gravatar-image\",\"style\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/lead-picture/template.hbs"
    }
  });

  _exports.default = _default;
});