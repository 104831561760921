define("frontend/models/meta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Meta = Ember.Object.extend({
    apiVersion: window.API_HOST.version,
    webSocketUrl: window.API_HOST.webSocketUrl
  });
  var metaInstance = Meta.create();
  var _default = metaInstance;
  _exports.default = _default;
});