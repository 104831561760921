define("frontend/pods/components/c3-chart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var C3ChartComponent = Ember.Component.extend({
    info: null,
    partialRefresh: false,
    _chart: null,
    _buildChart: function _buildChart() {
      var element = this.element;

      if (!element) {
        return;
      }

      if (!this.partialRefresh) {
        this._destroyChart();
      }

      var info = this.info;

      if (info) {
        info.bindto = element;
        var chart = this._chart;

        if (chart) {
          var loadData = {};

          if (info.data && info.data.columns) {
            loadData.columns = info.data.columns;
          }

          if (info.axis) {
            loadData.axes = info.axis;
          }

          return chart.load(loadData);
        } else {
          chart = c3.generate(info);
          return this.set('_chart', chart);
        }
      }
    },
    _clearChart: function _clearChart() {
      var chart = this._chart;

      if (chart) {
        chart.destroy();
        return this.set('_chart', null);
      }
    },
    _infoChanged: Ember.observer('info', function () {
      return Ember.run.scheduleOnce('afterRender', this, this._buildChart);
    }),
    _initializeChart: Ember.on('didInsertElement', function () {
      return this._buildChart();
    }),
    _destroyChart: Ember.on('willDestroyElement', function () {
      return this._clearChart();
    })
  });
  var _default = C3ChartComponent;
  _exports.default = _default;
});