define("frontend/pods/components/ui/toggle-switch/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-toggle-switch": "_ui-toggle-switch_1gno2h",
    "checked": "_checked_1gno2h",
    "ball": "_ball_1gno2h"
  };
  _exports.default = _default;
});