define("frontend/pods/base/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modelPath: 'controller.model',
    saveModel: function saveModel() {
      return this.get(this.modelPath).save();
    },
    rollbackModel: function rollbackModel() {
      var model = this.get(this.modelPath);
      model.rollbackAttributes();

      if (model.get('isNew')) {
        return model.deleteRecord();
      }
    },
    deactivate: function deactivate() {
      return this.rollbackModel();
    },
    afterSave: function afterSave() {},
    afterCancel: function afterCancel() {},
    actions: {
      save: function save() {
        var _this = this;

        return this.saveModel().then(function () {
          return _this.afterSave();
        }).then(null, function () {});
      },
      cancel: function cancel() {
        this.rollbackModel();
        return this.afterCancel();
      }
    }
  });

  _exports.default = _default;
});