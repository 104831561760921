define("frontend/pods/components/system-alert/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SystemAlertComponent = Ember.Component.extend({
    alertsService: Ember.inject.service('alerts'),
    alert: null,
    tagName: 'div',
    classNames: ['alert', 'system-alert'],
    classNameBindings: ['alertClass', 'hasRoute'],
    alertClass: Ember.computed('alert.type', function () {
      var t = this.get('alert.type');

      if (t) {
        return "alert-".concat(t);
      }
    }),
    hasRoute: Ember.computed.bool('alert.route'),
    _animateOnInsert: Ember.on('didInsertElement', function () {
      return this.$().slideDown('fast');
    }),
    _initializeDismissTimer: Ember.on('didInsertElement', function () {
      var _this = this;

      if (this.get('alert.persistent')) {
        return;
      }

      var fn = function fn() {
        return _this.dismiss();
      };

      var timeout = this.get('alert.dismissIn') * 1000;
      return this.set('_dismissTimer', Ember.run.later(fn, timeout));
    }),
    _clearDismissTimer: function _clearDismissTimer() {
      var dismissTimer = this._dismissTimer;

      if (dismissTimer) {
        return Ember.run.cancel(dismissTimer);
      }
    },
    dismiss: function dismiss() {
      var _this2 = this;

      if (this.isDismissed) {
        return;
      }

      this.set('isDismissed', true);

      this._clearDismissTimer();

      var alert = this.alert;
      return this.$().slideUp('fast', function () {
        return _this2.alertsService.dismiss(alert);
      });
    },
    click: function click() {
      if (this.isDismissed) {
        return;
      }

      var route = this.get('alert.route');

      if (route) {
        this.dismiss(); // this is pretty sucky and should be implemented better some other time

        return window.location.href = '/#/' + route;
      }
    },
    actions: {
      dismiss: function dismiss() {
        return this.dismiss();
      }
    }
  });
  var _default = SystemAlertComponent;
  _exports.default = _default;
});