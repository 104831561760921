define("frontend/mixins/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ToolTipMixin = Ember.Mixin.create({
    tooltip: null,
    tooltipPlacement: 'bottom',
    tooltipContainer: 'body',
    hasTooltip: Ember.computed.bool('tooltip'),
    _initializeTooltip: Ember.on('didInsertElement', function () {
      if (!this.hasTooltip) {
        return;
      }

      var p = this.getProperties('tooltip', 'tooltipPlacement', 'tooltipContainer');

      if (!p.tooltip) {
        return;
      }

      var options = {
        title: p.tooltip,
        placement: p.tooltipPlacement,
        container: p.tooltipContainer
      };
      return this._executeTooltip(options);
    }),
    _destroyTooltip: Ember.on('willDestroyElement', function () {
      if (this.hasTooltip) {
        return this._executeTooltip('destroy');
      }
    }),
    _showTooltip: function _showTooltip() {
      if (this.hasTooltip) {
        return this._executeTooltip('show');
      }
    },
    _hideTooltip: function _hideTooltip() {
      if (this.hasTooltip) {
        return this._executeTooltip('hide');
      }
    },
    _executeTooltip: function _executeTooltip(params) {
      return this.$().tooltip(params);
    }
  });
  var _default = ToolTipMixin;
  _exports.default = _default;
});