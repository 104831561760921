define("frontend/pods/organizations/organization/conversations/controller", ["exports", "moment", "frontend/utils/paginated", "frontend/utils/file-downloader"], function (_exports, _moment, _paginated, _fileDownloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationConversationsController = Ember.Controller.extend({
    canEditUsers: Ember.computed.equal('authenticationService.activeRole', 'admin'),
    canShowUsers: Ember.computed.equal('authenticationService.activeRole', 'admin'),
    organizationController: Ember.inject.controller('organizations.organization'),
    filters: Ember.computed.readOnly('organizationController.filters'),
    dashboard: Ember.computed.readOnly('organizationController.dashboard'),
    filteredUsers: Ember.computed.readOnly('organizationController.filteredUsers'),
    start: Ember.computed.readOnly('organizationController.start'),
    list: Ember.computed.readOnly('organizationController.list'),
    outboundCalls: Ember.computed('model.id', 'filters', 'list', 'start', function () {
      var filters = this.filters || {
        startDate: (0, _moment.default)(this.start).startOf('day').toDate(),
        leadListIds: [this.list]
      } || {
        startDate: (0, _moment.default)().startOf('day').toDate()
      };
      var query = {
        organization_id: this.get('model.id'),
        conversation: true
      };

      if (filters.startDate) {
        query.start_time = filters.startDate.toISOString();
      }

      if (filters.endDate) {
        query.end_time = filters.endDate.toISOString();
      }

      query.user_id = this.get('authenticationService.activeUserId');

      if (filters.userIds) {
        query.user_ids = filters.userIds;
      }

      if (filters.statuses) {
        query.statuses = filters.statuses;
      }

      if (filters.leadListIds) {
        query.lead_list_ids = filters.leadListIds;
      }

      if (filters.teamIds) {
        query.team_ids = filters.teamIds;
      }

      if (filters.session) {
        query.session = filters.session;
      }

      if (filters.dialResults) {
        query.dial_results = filters.dialResults;
      }

      if (filters.agentConversationResults) {
        query.agent_conversation_results = filters.agentConversationResults;
      }

      return _paginated.default.Array.find('outbound-call', {
        store: this.store,
        pageSize: 100,
        query: query
      });
    }),
    canHearClientRecordings: Ember.computed.readOnly('authenticationService.canHearClientRecordings'),
    canHearAgentRecordings: Ember.computed.readOnly('authenticationService.canHearAgentRecordings'),
    actions: {
      nextPage: function nextPage() {
        return this.outboundCalls.nextPage();
      },
      downloadRecording: function downloadRecording(url) {
        var headerData = this.store.adapterFor('application').get('headerData');

        var fileDownloader = _fileDownloader.default.create({
          url: url,
          method: 'get',
          data: {
            download_recording: true
          },
          headerData: headerData
        });

        return fileDownloader.execute();
      }
    }
  });
  var _default = OrganizationConversationsController;
  _exports.default = _default;
});