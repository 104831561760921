define("frontend/pods/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Talb48PO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"form\"],[14,\"multipart\",\"true\"],[12],[2,\"\\n\\t\"],[10,\"span\"],[15,0,[30,[36,4],[\"btn btn-success fileinput-button \",[30,[36,3],[[35,2],\"is-saving\"],null]],null]],[12],[2,\"\\n\\t\\t\"],[10,\"i\"],[14,0,\"glyphicon glyphicon-plus\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[12],[1,[34,5]],[13],[2,\"\\n\\t\\t\"],[10,\"input\"],[15,3,[34,6]],[14,4,\"file\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"filename\"],[12],[1,[34,7]],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"innerAll\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"progress\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"progress-bar progress-bar-primary\"],[15,5,[34,0]],[12],[2,\"\\n\\t\\t\\t\\t\\tuploading... \"],[10,\"strong\"],[12],[1,[34,1]],[2,\"% complete\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"progressStyle\",\"progress\",\"isSaving\",\"if\",\"concat\",\"buttonText\",\"fieldName\",\"filename\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});