define("frontend/utils/paginated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Paginated = {};
  Paginated.Array = Ember.ArrayProxy.extend({
    pageSize: 100,
    store: null,
    pages: null,
    pagesReceived: Ember.computed.oneWay('pages.length'),
    isBusy: Ember.computed.oneWay('currentPage.isPending'),
    // the computed property on "total" does not work, I think because "meta" is a POJO instead of an Ember object --
    // check back with a newer version of Ember
    //total: Ember.computed.oneWay 'currentPage.content.meta.total'
    total: null,
    // currentPage should work but does not consistently for the first page... can't figure out why...
    //currentPage: Ember.computed.oneWay 'pages.lastObject'
    currentPage: null,
    // determine completeness based on whether fewer records were returned than the page size --
    // even if the content is exactly the amount of a page, it will work because the next request will return zero --
    // this is more effective than comparing the records to the total because the data may have changed
    isComplete: Ember.computed('currentPage.content.length', 'pageSize', function () {
      var currentPageSize = this.get('currentPage.content.length');
      return currentPageSize != null && currentPageSize < this.pageSize;
    }),
    find: function find(type, query) {
      if (query == null) {
        query = {};
      }

      this.clear();
      this.set('type', type);
      this.set('query', query);
      return this.nextPage();
    },
    nextPage: function nextPage() {
      var _this = this;

      // return the most recent request if it is currently pending or if all records have been received
      if (this.isBusy || this.isComplete) {
        return this.currentPage;
      }

      var p = Object.assign({}, this.query);
      p.page = this.pagesReceived + 1;
      p.page_size = this.pageSize;
      var request = this.store.query(this.type, p);
      this.pages.addObject(request);
      this.set('currentPage', request);
      return request.then(function (results) {
        _this.addObjects(results);

        return _this.set('total', results.get('meta.total'));
      });
    },
    clear: function clear() {
      this._super.apply(this, arguments);

      this.set('pages', Ember.A([]));
      this.set('currentPage', null);
      return this.set('total', null);
    },
    reload: function reload() {
      return this.find(this.type, this.query);
    },
    refresh: function refresh() {
      return this.reload();
    },
    _initializeProperties: Ember.on('init', function () {
      if (!this.content) {
        this.set('content', Ember.A([]));
      }

      this.clear();
      return this.getProperties('pages', 'currentPage', 'pagesReceived', 'total', 'isComplete', 'isBusy');
    })
  });
  Paginated.Array.reopenClass({
    find: function find(type, options) {
      if (options == null) {
        options = {};
      }

      var _options = options,
          store = _options.store;

      if (!store) {
        throw 'No store specified for PaginatedArray#find';
      }

      var pageSize = options.pageSize || 100;
      var result = Paginated.Array.create({
        store: store,
        pageSize: pageSize
      });
      var query = options.query || {};
      result.find(type, query);
      return result;
    }
  });

  Paginated.hasMany = function (type, foreign_key, options) {
    if (options == null) {
      options = {};
    }

    var pageSize = options.pageSize || 100; // observe "data" to reload it when the data has changed (this is how the other attributes work)

    return Ember.computed('data', function (key, value) {
      var result = Paginated.Array.create({
        store: this.store,
        pageSize: pageSize
      });
      var params = Object.assign({}, options);
      params[foreign_key] = this.id;
      result.find(type, params);
      return result;
    });
  };

  var _default = Paginated;
  _exports.default = _default;
});