define("frontend/pods/components/service-provider-fields-edit/component", ["exports", "frontend/models/lead"], function (_exports, _lead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ServiceProviderFieldsEditComponent = Ember.Component.extend({
    serviceProvider: null,
    serviceProviderExtendedInfo: null,
    classNames: ['service-provider-fields-edit'],
    availableFields: Ember.computed.readOnly('serviceProviderExtendedInfo.availableFields'),
    fields: Ember.computed('serviceProvider.settings', function () {
      if (!this.get('serviceProvider.settings')) {
        this.set('serviceProvider.settings', {});
      }

      var settings = this.get('serviceProvider.settings');

      if (!settings.fields) {
        settings.fields = {};
      }

      return settings.fields;
    }),
    availableFieldsByType: Ember.computed('availableFields', 'fields', function () {
      var result = [];
      var availableFields = this.availableFields || {};
      var fieldSettings = this.fields;

      for (var _i = 0, _Object$keys = Object.keys(availableFields || {}); _i < _Object$keys.length; _i++) {
        var type = _Object$keys[_i];
        // if the settings have not been specified yet, copy the default
        var field;
        var fields = availableFields[type];

        if (!fieldSettings[type]) {
          fieldSettings[type] = fields.map(function (f) {
            return f;
          });
        }

        var settings = fieldSettings[type];
        var displayFields = []; // add the fields that will be displayed

        for (var _i2 = 0, _Array$from = Array.from(settings); _i2 < _Array$from.length; _i2++) {
          field = _Array$from[_i2];
          displayFields.push({
            name: field,
            visible: true
          });
        } // add the fields that will not be displayed


        for (var _i3 = 0, _Array$from2 = Array.from(fields); _i3 < _Array$from2.length; _i3++) {
          field = _Array$from2[_i3];

          if (!displayFields.findBy('name', field)) {
            displayFields.push({
              name: field,
              visible: false
            });
          }
        }

        result.push({
          type: type,
          fields: displayFields,
          settings: settings
        });
      }

      return result;
    }),
    updateSettings: function updateSettings(fieldType, fields) {
      var settings = this.get('serviceProvider.settings.fields');
      return settings[fieldType] = fields.filterBy('visible', true).mapBy('name');
    },
    actions: {
      fieldsChanged: function fieldsChanged(fieldType, fields) {
        return this.updateSettings(fieldType, fields);
      }
    }
  });
  var _default = ServiceProviderFieldsEditComponent;
  _exports.default = _default;
});