define("frontend/pods/current-activity/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    requiresLogin: true,
    authorizedRoles: ['admin'],
    model: function model(params) {
      return this.store.findAll('server-activity');
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      return this.controllerFor('current-activity').activate();
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      return this.controllerFor('current-activity').deactivate();
    }
  });

  _exports.default = _default;
});