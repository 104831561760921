define("frontend/pods/components/view/infinite-scroll/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['infinite-scroll-view'],
    content: null,
    pageSize: 100,
    page: 1,
    renderedContent: Ember.computed('content.[]', function () {
      // reset the page when content has changed
      this.set('page', 1);
      var p = this.getProperties('content', 'pageSize', 'page');
      return (p.content || []).slice(0, p.pageSize * p.page);
    }),
    _updateRenderedContent: Ember.observer('pageSize', 'page', function () {
      var p = this.getProperties('renderedContent', 'content', 'pageSize', 'page');
      var newCount = p.pageSize * p.page;
      var currentCount = p.renderedContent.get('length');

      if (!p.content) {
        p.content = [];
      }

      if (newCount > currentCount) {
        // add the objects to the current array instead of replacing the entire array to improve performance
        return p.renderedContent.pushObjects(p.content.slice(currentCount, newCount));
      } else if (newCount < currentCount) {
        // truncate the array
        return p.renderedContent.set('length', newCount);
      }
    }),
    hasMoreContent: Ember.computed('content.[]', 'renderedContent.[]', function () {
      return (this.get('content.length') || 0) > (this.get('renderedContent.length') || 0);
    }),
    _didScroll: function _didScroll() {
      // ensure it doesn't occur more than 100ms apart
      return Ember.run.throttle(this, '_checkScroll', 100);
    },
    _checkScroll: function _checkScroll() {
      if (!this.hasMoreContent) {
        return;
      }

      var el = this.$();
      var indicatorEl = this.$('> .infinite-scroll-view-indicator');
      var listTop = el.position().top;
      var listHeight = el.height();
      var listScrollTop = el.scrollTop();
      var elementTop = indicatorEl.position().top - listTop + listScrollTop;

      if (elementTop < listScrollTop + listHeight) {
        return this.set('page', this.page + 1);
      }
    },
    _setupScrollListener: Ember.on('didInsertElement', function () {
      var _this = this;

      this.__didScroll = function () {
        return _this._didScroll();
      };

      return this.$().on('scroll', this.__didScroll);
    }),
    _teardownScrollListener: Ember.on('willDestroyElement', function () {
      return this.$().off('scroll', this.__didScroll);
    }),
    _resetPageOnContentChange: Ember.observer('content', function () {
      return this.set('page', 1);
    })
  });

  _exports.default = _default;
});