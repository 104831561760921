define("frontend/models/lead", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Lead = _model.default.extend({
    _received: (0, _model.attr)('boolean'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    companyName: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    phoneAdditionalInfo: (0, _model.attr)('string'),
    phoneValid: (0, _model.attr)('boolean'),
    linkedInProfileUrl: (0, _model.attr)('string'),
    masterId: (0, _model.attr)('string'),
    masterUrl: (0, _model.attr)('string'),
    salesforceId: (0, _model.attr)('string'),
    lastDialedAt: (0, _model.attr)('date'),
    lastVoicemailedAt: (0, _model.attr)('date'),
    status: (0, _model.attr)('string'),
    leadStatusName: (0, _model.attr)('string'),
    reason: (0, _model.attr)('string'),
    doNotCall: (0, _model.attr)('boolean'),
    onHoldUntil: (0, _model.attr)('date'),
    agentFlagged: (0, _model.attr)('boolean'),
    fullRecord: (0, _model.attr)(),
    tags: (0, _model.attr)(),
    masterErrorMessage: (0, _model.attr)('string'),
    masterStatus: (0, _model.attr)('string'),
    masterObjectType: (0, _model.attr)('string'),
    serviceProviderId: (0, _model.attr)('string'),
    externalCrmApiKeyId: (0, _model.attr)('string'),
    agentScript: (0, _model.attr)('string'),
    dialCount: (0, _model.attr)('number'),
    flaggedDialResult: (0, _model.attr)('string'),
    flaggedAgentConversationResult: (0, _model.attr)('string'),
    lastCallNotes: (0, _model.attr)('string'),
    lastCallDialNotes: (0, _model.attr)('string'),
    followUpScript: (0, _model.attr)('string'),
    flaggedReason: (0, _model.attr)('string'),
    lastCallDialResult: (0, _model.attr)('string'),
    lastCallAgentConversationResult: (0, _model.attr)('string'),
    leadListNames: (0, _model.attr)('string'),
    followUpUser: (0, _model.belongsTo)('user'),
    referralUser: (0, _model.belongsTo)('user'),
    referredBy: (0, _model.attr)('number'),
    maintainMultipleNumberDialing: (0, _model.attr)('boolean'),
    dataSource: (0, _model.belongsTo)('dataSource'),
    user: (0, _model.belongsTo)('user'),
    phoneId: (0, _model.attr)('string'),
    primaryPhoneId: (0, _model.attr)('string'),
    mobilePhoneId: (0, _model.attr)('string'),
    otherPhoneId: (0, _model.attr)('string'),
    workPhoneId: (0, _model.attr)('string'),
    directPhoneId: (0, _model.attr)('string'),
    companyPhoneId: (0, _model.attr)('string'),
    homePhoneId: (0, _model.attr)('string'),
    // leadPhoneNumbers: hasMany('lead-phone-number'),
    // returns a promise!
    serviceProvider: Ember.computed('serviceProviderId', function () {
      var id = this.serviceProviderId;

      if (id) {
        return this.store.find('service-provider', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    leadPhonePhone: Ember.computed('phoneId', function () {
      var id = this.phoneId;

      if (id) {
        return this.store.find('lead-phone-number', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    primaryPhone: Ember.computed('primaryPhoneId', function () {
      var id = this.primaryPhoneId;

      if (id) {
        return this.store.find('lead-phone-number', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    mobilePhone: Ember.computed('mobilePhoneId', function () {
      var id = this.mobilePhoneId;

      if (id) {
        return this.store.find('lead-phone-number', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    otherPhone: Ember.computed('otherPhoneId', function () {
      var id = this.otherPhoneId;

      if (id) {
        return this.store.find('lead-phone-number', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    workPhone: Ember.computed('workPhoneId', function () {
      var id = this.workPhoneId;

      if (id) {
        return this.store.find('lead-phone-number', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    directPhone: Ember.computed('directPhoneId', function () {
      var id = this.directPhoneId;

      if (id) {
        return this.store.find('lead-phone-number', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    companyPhone: Ember.computed('companyPhoneId', function () {
      var id = this.companyPhoneId;

      if (id) {
        return this.store.find('lead-phone-number', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    homePhone: Ember.computed('homePhoneId', function () {
      var id = this.homePhoneId;

      if (id) {
        return this.store.find('lead-phone-number', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    externalCrmApiKey: Ember.computed('externalCrmApiKeyId', function () {
      var id = this.externalCrmApiKeyId;

      if (id) {
        return this.store.find('external-crm-api-key', id);
      }

      return Ember.RSVP.resolve(null);
    }),
    isMultiNumberDial: Ember.computed.equal('user.organization.multipleNumberDialing', true),
    isDynamicsProvider: Ember.computed.equal('serviceProvider.provider', 'azure_oauth2'),
    isHubspotProvider: Ember.computed.equal('serviceProvider.provider', 'hubspot'),
    isOutreachProvider: Ember.computed.equal('serviceProvider.provider', 'outreach'),
    isSalesloftProvider: Ember.computed.equal('serviceProvider.provider', 'salesloft'),
    isZohoProvider: Ember.computed.equal('serviceProvider.provider', 'zoho'),
    isPipedriveProvider: Ember.computed.equal('serviceProvider.provider', 'pipedrive'),
    isSharpspringLead: Ember.computed.equal('externalCrmApiKey.type', 'sharpspring'),
    noEmailPhoneCompanyName: Ember.computed.or('isOutreachProvider', 'isPipedriveProvider'),
    linkedInProfile: (0, _model.belongsTo)('linkedInProfile', {
      async: true
    }),
    hasSocialLinks: Ember.computed.or('linkedInProfileUrl', 'masterUrl'),
    hasLinkedInProfile: Ember.computed.bool('linkedInProfileUrl'),
    // typically this would be defined as a has many relationship, but with the current version of Ember Data,
    // new records are not automatically added to the array, even if the proper relationship is specified on the model --
    // use a filter instead, which will stay updated automatically --
    // this is required here because of the call status push notifications
    //outboundCalls: DS.hasMany 'outboundCall', async: true
    _allOutboundCalls: Ember.computed('outboundCallsPromise', {
      get: function get() {
        this.outboundCallsPromise;
        return this.store.peekAll('outbound-call');
      }
    }),
    _allOutboundCallLeads: Ember.computed('_allOutboundCalls.@each.lead', {
      get: function get() {
        return this._allOutboundCalls.mapBy('lead');
      }
    }),
    outboundCallsPromise: Ember.computed('id', {
      get: function get() {
        if (this.id) {
          return this.store.query('outbound-call', {
            lead_id: this.id
          });
        }
      }
    }),
    outboundCalls: Ember.computed('id', '_allOutboundCallLeads.@each.id', {
      get: function get() {
        var id = this.id;
        this.get('_allOutboundCallLeads');

        var result = this._allOutboundCalls.filterBy('lead.id', id).sortBy('startedAt');

        result.reverse();
        return result;
      }
    }),
    // _allLeadPhoneNumbers: computed('leadPhoneNumbersPromise', {
    //   get() {
    //     this.leadPhoneNumbersPromise;
    //     return this.store.peekAll('lead-phone-number');
    //   }
    // }),
    //
    // _allLeadPhoneNumberLeads: computed('_allLeadPhoneNumbers.@each.lead', {
    //   get() {
    //     return this._allLeadPhoneNumbers.mapBy('lead');
    //   }
    // }),
    //
    // leadPhoneNumbersPromise: computed('id', {
    //   get() {
    //     if (this.id) {
    //       return this.store.query('lead-phone-number', { lead_id: this.id });
    //     }
    //   }
    // }),
    //
    // leadPhoneNumbers: computed('id', '_allLeadPhoneNumberLeads.@each.id', {
    //   get() {
    //     const id = this.id;
    //     this.get('_allLeadPhoneNumberLeads');
    //     const result = this._allLeadPhoneNumberLeads.filterBy('lead.id', id).sortBy('dialingPriority');
    //     return result;
    //   }
    // }),
    name: Ember.computed('firstName', 'lastName', {
      get: function get() {
        var firstName = this.firstName || '';
        var lastName = this.lastName || '';
        return "".concat(firstName, " ").concat(lastName);
      }
    }),
    // does not take into account the fact that time keeps changing, though in theory it should
    canCall: Ember.computed('isOpen', 'doNotCall', {
      get: function get() {
        return this.isOpen && !this.doNotCall;
      }
    }),
    canCallNow: Ember.computed('canCall', 'onHoldUntil', function () {
      if (!this.canCall) {
        return false;
      }

      var onHoldUntil = this.onHoldUntil;

      if (onHoldUntil && onHoldUntil > new Date()) {
        return false;
      }

      return true;
    }),
    isOnHold: Ember.computed('canCall', 'canCallNow', function () {
      return this.canCall && !this.canCallNow;
    }),
    // only used here in the client app -- the call state is pushed by the messages server
    callState: null,
    isDialing: Ember.computed('callState', function () {
      return ["dialing", "connecting", "connected", "connected_to_client"].includes(this.callState);
    }),
    isOnline: Ember.computed.alias('isDialing'),
    init: function init() {
      this._super.apply(this, arguments);

      return this.getProperties('fullRecord', 'callState', 'isDialing');
    },
    role: Ember.computed('companyName', 'title', function () {
      var title = this.title;
      var company = this.companyName;

      if (title && company) {
        return "".concat(title, ", ").concat(company);
      } else {
        return title || company;
      }
    }),
    mailto: Ember.computed('email', function () {
      var email = this.email;

      if (email) {
        return "mailto:".concat(email);
      }
    }),
    statusText: Ember.computed('status', function () {
      var status = this.status;

      if (status) {
        var s = Lead.statusValues.findBy('value', status);

        if (s) {
          return s.text;
        }
      }

      return null;
    }),
    salutation: Ember.computed('title', 'companyName', function () {
      var title = this.title;
      var companyName = this.companyName;

      if (title && companyName) {
        return "".concat(title, ", ").concat(companyName);
      } else {
        return title || companyName;
      }
    }),
    displayStatus: Ember.computed('masterStatus', 'leadStatusName', 'statusText', function () {
      return this.masterStatus || this.leadStatusName || this.statusText;
    }),
    isOpen: Ember.computed.equal('status', 'open'),
    isQualified: Ember.computed.equal('status', 'qualified'),
    isClosed: Ember.computed.equal('status', 'closed'),
    reasonValues: Ember.computed('status', function () {
      var status = this.status;

      if (status) {
        return Lead.reasonValues[status];
      }
    }),
    saveChangesFromOutboundCall: function saveChangesFromOutboundCall(outboundCall, isAgent, isConnected) {
      var p = outboundCall.getProperties('masterStatus', 'leadStatusName', 'status', 'reason', 'onHoldUntil', 'agentFlagged', 'notes', 'dialNotes', 'dialResult', 'agentConversationResult', 'followUpScript', 'clientReleasedAt', 'joinedAt');
      var leadChanges = {};

      if (!isAgent) {
        leadChanges.masterStatus = p.masterStatus;
        leadChanges.leadStatusName = p.leadStatusName;
        leadChanges.status = p.status;
        leadChanges.reason = p.reason;
      }

      if (p.onHoldUntil) {
        leadChanges.onHoldUntil = p.onHoldUntil;
      }

      if (p.onHoldUntil) {
        leadChanges.maintainMultipleNumberDialing = false;
      }

      if (p.clientReleasedAt) {
        leadChanges.maintainMultipleNumberDialing = false;
      }

      if (p.joinedAt) {
        leadChanges.maintainMultipleNumberDialing = false;
      }

      if (p.followUpScript) {
        leadChanges.followUpScript = p.followUpScript;
      }

      if (p.agentFlagged) {
        leadChanges.agentFlagged = p.agentFlagged;
      }

      leadChanges.lastCallNotes = p.notes;
      leadChanges.lastCallDialNotes = p.dialNotes;
      leadChanges.lastCallDialResult = p.dialResult;
      leadChanges.lastCallAgentConversationResult = p.agentConversationResult;
      return this.updateAttributes(leadChanges);
    }
  });

  Lead.reopenClass({
    statusValues: [{
      value: 'open',
      text: 'Open'
    }, {
      value: 'qualified',
      text: 'Qualified'
    }, {
      value: 'closed',
      text: 'Closed'
    }],
    reasonValues: {
      closed: [{
        value: 'not_interested',
        text: 'Not interested'
      }, {
        value: 'using_competitor',
        text: 'Using competitor'
      }, {
        value: 'wrong_contact',
        text: 'Wrong point of contact'
      }, {
        value: 'invalid',
        text: 'Invalid'
      }, {
        value: 'other',
        text: 'Other'
      }]
    }
  });
  var _default = Lead;
  _exports.default = _default;
});