define("frontend/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Ember.Route.reopen({
    authenticationService: Ember.inject.service('authentication'),
    // set to true to force authentication before accessing this route
    requiresLogin: true,
    // specify the roles authorized to access this route
    authorizedRoles: ['admin', 'client', 'agent', 'client_manager', 'team_manager'],
    beforeModel: function beforeModel(transition) {
      console.log("transitioning to ".concat(this.routeName, " on our way to ").concat(transition.targetName));

      this._super(transition);

      if (this._checkLoginRedirect(transition)) {
        return false;
      }

      if (this._checkAuthorizedRoles(transition)) {
        return false;
      }

      return true;
    },
    _checkLoginRedirect: function _checkLoginRedirect(transition) {
      if (this.routeName !== 'application' && this.requiresLogin && !this.get('authenticationService.loggedIn')) {
        this.redirectToLogin(transition);
        return true;
      } else {
        return false;
      }
    },
    authorize: function authorize(transition) {
      // always allow routes that do not require authentication
      if (!this.requiresLogin) {
        return true;
      } // perform the authorizations -- return early if unauthorized


      if (!this.authorizeRole(transition)) {
        return false;
      } // if it made it this far, it is authorized -- return true


      return true;
    },
    authorizeRole: function authorizeRole(transition) {
      var role = this.get('authenticationService.activeRole');
      return this.authorizedRoles.includes(role);
    },
    _checkAuthorizedRoles: function _checkAuthorizedRoles(transition) {
      if (!this.authorize(transition)) {
        return this.transitionTo('unauthorized');
      }
    },
    redirectToLogin: function redirectToLogin(transition) {
      if (!transition || transition.targetName !== 'auth.login') {
        this.authenticationService.set('redirectAfterLogin', transition);
      }

      return this.transitionTo('auth.login');
    },
    toTransitionParams: function toTransitionParams(transition, routeName) {
      routeName = routeName || this.fullRouteName;
      var t = transition.to;

      while (t && t.name !== routeName) {
        t = t.parent;
      }

      return t && t.params || {};
    }
  });

  var _default = Ember.Route.extend({
    requiresLogin: false,
    // make sure the user is retrieved before entering any route
    beforeModel: function beforeModel(transition) {
      var _this = this;

      if (!this._super(transition)) {
        return false;
      }

      return this.authenticationService.initializeUser().then(function () {
        return _this.authenticationService.get('activeUser.organization');
      });
    },
    actions: {
      logout: function logout() {
        return this.authenticationService.logout();
      },
      redirectAfterLogin: function redirectAfterLogin() {
        var transition = this.get('authenticationService.redirectAfterLogin');

        if (transition) {
          this.set('authenticationService.redirectAfterLogin', null);
          return transition.retry();
        } else {
          return this.transitionTo('/');
        }
      },
      unimpersonate: function unimpersonate() {
        return this.authenticationService.impersonate(null);
      }
    }
  });

  _exports.default = _default;
});