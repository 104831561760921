define("frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/transferring-to-lead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6L2gpCOw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"gatekeeper-lead\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/transferring-to-lead/styles\"]]]]]],[12],[2,\"\\n\\n  \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"instructions\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/transferring-to-lead/styles\"]]]]]],[12],[2,\"Wait for the transfer to complete... now what?\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"actions\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/transferring-to-lead/styles\"]]]]]],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-block btn-lg btn-success\"],[4,[38,1],[\"click\",[32,0,[\"reachedLead\"]]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-fw fa-random\"],[12],[13],[2,\" Reached Lead!\\n    \"],[13],[2,\"\\n\\n    \"],[11,\"button\"],[24,0,\"btn btn-block btn-lg btn-info\"],[4,[38,1],[\"click\",[32,0,[\"reachedVoicemail\"]]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-fw fa-envelope-o\"],[12],[13],[2,\" Reached Voicemail\\n    \"],[13],[2,\"\\n\\n    \"],[11,\"button\"],[24,0,\"btn btn-block btn-inverse\"],[4,[38,1],[\"click\",[32,0,[\"back\"]]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-fw fa-question-circle\"],[12],[13],[2,\" Other\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"on\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/current-call/actions/states/gatekeeper/transferring-to-lead/template.hbs"
    }
  });

  _exports.default = _default;
});