define("frontend/pods/organizations/organization/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationIndexController = Ember.Controller.extend({
    organizationController: Ember.inject.controller('organizations.organization'),
    filters: Ember.computed.readOnly('organizationController.filters'),
    dashboard: Ember.computed.readOnly('organizationController.dashboard')
  });
  var _default = OrganizationIndexController;
  _exports.default = _default;
});