define("frontend/pods/auth/forgot-password/submitted/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BfIL5pBr",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],null,[[\"title\"],[\"Password Reset Submitted\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"placeholder text-center\"],[12],[10,\"i\"],[14,0,\"fa fa-key\"],[12],[13],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"panel panel-default col-sm-6 col-sm-offset-3\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"p\"],[12],[2,\"Thank you.  If you are registered with us, you will receive an e-mail with further instructions.\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"view/unauthenticated-box\"]}",
    "meta": {
      "moduleName": "frontend/pods/auth/forgot-password/submitted/template.hbs"
    }
  });

  _exports.default = _default;
});