define("frontend/pods/components/user-form/component", ["exports", "frontend/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UserFormComponent = Ember.Component.extend({
    model: null,
    organizations: null,
    direction: Ember.computed(function () {
      return false;
    }),
    authenticationService: Ember.inject.service('authentication'),
    store: Ember.computed.readOnly('model.store'),
    isAdmin: Ember.computed.readOnly('authenticationService.isAdmin'),
    isClientManager: Ember.computed.equal('authenticationService.activeRole', 'client_manager'),
    isTeamManager: Ember.computed.equal('authenticationService.activeRole', 'team_manager'),
    userRoles: _user.default.roles,
    clientManagerUserRoles: ['client', 'client_manager', 'team_manager'],
    teamManagerUserRoles: ['client'],
    teams: Ember.computed('model.organizationId', function () {
      var organizationId = this.get('model.organizationId');

      if (organizationId) {
        var updated_teams = this.store.query('team', {
          organization_id: organizationId
        });
        this.set('teams', updated_teams);
      } else {
        this.set('teams', []);
      }
    }),
    dialLeadTimes: [{
      value: 0,
      text: '12am'
    }, {
      value: 1 * 60,
      text: '1am'
    }, {
      value: 2 * 60,
      text: '2am'
    }, {
      value: 3 * 60,
      text: '3am'
    }, {
      value: 4 * 60,
      text: '4am'
    }, {
      value: 5 * 60,
      text: '5am'
    }, {
      value: 6 * 60,
      text: '6am'
    }, {
      value: 7 * 60,
      text: '7am'
    }, {
      value: 8 * 60,
      text: '8am'
    }, {
      value: 9 * 60,
      text: '9am'
    }, {
      value: 10 * 60,
      text: '10am'
    }, {
      value: 11 * 60,
      text: '11am'
    }, {
      value: 12 * 60,
      text: '12pm'
    }, {
      value: 13 * 60,
      text: '1pm'
    }, {
      value: 14 * 60,
      text: '2pm'
    }, {
      value: 15 * 60,
      text: '3pm'
    }, {
      value: 16 * 60,
      text: '4pm'
    }, {
      value: 17 * 60,
      text: '5pm'
    }, {
      value: 18 * 60,
      text: '6pm'
    }, {
      value: 19 * 60,
      text: '7pm'
    }, {
      value: 20 * 60,
      text: '8pm'
    }, {
      value: 21 * 60,
      text: '9pm'
    }, {
      value: 22 * 60,
      text: '10pm'
    }, {
      value: 23 * 60,
      text: '11pm'
    }, {
      value: 24 * 60,
      text: '12am'
    }],
    _updateTeamsList: Ember.observer('model.organizationId', 'refreshTeamList', function () {
      var organizationId = this.get('model.organizationId');

      if (organizationId) {
        var updated_teams = this.store.query('team', {
          organization_id: organizationId
        });
        this.set('teams', updated_teams);
      } else {
        this.set('teams', []);
      }
    }),
    actions: {
      callRecordingEnabledChanged: function callRecordingEnabledChanged(value) {
        return this.set('model.callRecordingEnabled', value);
      },
      inboundPhoneMatchChanged: function inboundPhoneMatchChanged(value) {
        return this.set('model.inboundPhoneMatch', value);
      },
      randomizeCallerIdChanged: function randomizeCallerIdChanged(value) {
        return this.set('model.randomizeCallerId', value);
      },
      availableCountryCodesChanged: function availableCountryCodesChanged(value) {
        return this.set('model.availableCountryCodes', value);
      },
      toggleRefreshTeamList: function toggleRefreshTeamList() {
        return this.toggleProperty('refreshTeamList');
      },
      organizationChanged: function organizationChanged(id) {
        return this.set('model.organization', id && this.get('organizations').findBy('id', id));
      }
    }
  });
  var _default = UserFormComponent;
  _exports.default = _default;
});