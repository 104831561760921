define("frontend/models/data-source", ["exports", "@ember-data/model", "frontend/utils/paginated"], function (_exports, _model, _paginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var DataSource = _model.default.extend({
    name: (0, _model.attr)('string'),
    type: (0, _model.attr)('string', {
      defaultValue: 'CsvDataSource'
    }),
    headers: (0, _model.attr)(),
    fieldMapping: (0, _model.attr)(),
    settings: (0, _model.attr)(),
    leadCount: (0, _model.attr)('number'),
    serviceProviderId: (0, _model.attr)('string'),
    externalCrmApiKeyId: (0, _model.attr)('string'),
    fileUploadId: (0, _model.attr)('string'),
    //leads: DS.hasMany 'lead', async: true
    user: (0, _model.belongsTo)('user'),
    leads: _paginated.default.hasMany('lead', 'data_source_id'),
    import: function _import() {
      var id = this.id;
      var adapter = this.store.adapterFor('application');
      return adapter.executeServerAction(DataSource, id, 'import');
    }
    /*_updateLeadCount: (function() {
      if (!this.get('isDeleted') && !this.get('isLoading')) { return this.set('leadCount', this.get('leads.total')); }
    }).observes('leads.total')*/

  });

  var _default = DataSource;
  _exports.default = _default;
});