define("frontend/routes/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    requiresLogin: true,
    authorizedRoles: ['admin', 'client_manager', 'team_manager'],
    model: function model(params) {
      return this.store.findAll('user');
    }
  });

  _exports.default = _default;
});