define("frontend/mixins/click-elsewhere", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClickElsewhereMixin = Ember.Mixin.create({
    //use this method hook to define your desired behavior
    onClickElsewhere: function onClickElsewhere() {},
    //bound version of our instance method
    clickHandler: Ember.computed(function () {
      return this.elsewhereHandler.bind(this);
    }),
    //logic for determining of a click has landed anywhere but our view
    elsewhereHandler: function elsewhereHandler(e) {
      var elementClicked = $(e.target).closest(this.element).length === 1;

      if (!elementClicked) {
        return this.onClickElsewhere(e);
      }
    },
    //attach event listener to window when view in DOM
    _attachElsewhereClickHandler: Ember.on('didInsertElement', function () {
      return $(window).on("click", this.clickHandler);
    }),
    //remove window event listener when view removed from DOM
    _detachElsewhereClickHandler: Ember.on('willDestroyElement', function () {
      return $(window).off("click", this.clickHandler);
    })
  });
  var _default = ClickElsewhereMixin;
  _exports.default = _default;
});