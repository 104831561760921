define("frontend/pods/service-providers/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ServiceProvidersRoute = Ember.Route.extend({
    requiresLogin: true,
    model: function model() {
      return this.store.findAll('service-provider');
    }
  });
  var _default = ServiceProvidersRoute;
  _exports.default = _default;
});