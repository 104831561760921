define("frontend/pods/organizations/organization/dials/controller", ["exports", "frontend/utils/paginated", "frontend/utils/file-downloader"], function (_exports, _paginated, _fileDownloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationDialsController = Ember.Controller.extend({
    canEditUsers: Ember.computed.equal('authenticationService.activeRole', 'admin'),
    canShowUsers: Ember.computed.equal('authenticationService.activeRole', 'admin'),
    organizationController: Ember.inject.controller('organizations.organization'),
    filters: Ember.computed.readOnly('organizationController.filters'),
    dashboard: Ember.computed.readOnly('organizationController.dashboard'),
    filteredUsers: Ember.computed.readOnly('organizationController.filteredUsers'),
    outboundCalls: Ember.computed('model.id', 'filters', 'list', function () {
      var filters = this.filters || {
        leadListIds: [this.list]
      };
      var query = {
        organization_id: this.get('model.id'),
        followup: true
      };
      query.user_id = this.get('authenticationService.activeUserId');

      if (filters.userIds) {
        query.user_ids = filters.userIds;
      }

      if (filters.statuses) {
        query.statuses = filters.statuses;
      }

      if (filters.leadListIds) {
        query.lead_list_ids = filters.leadListIds;
      }

      if (filters.teamIds) {
        query.team_ids = filters.teamIds;
      }

      if (filters.session) {
        query.session = filters.session;
      }

      if (filters.dialResults) {
        query.dial_results = filters.dialResults;
      }

      if (filters.agentConversationResults) {
        query.agent_conversation_results = filters.agentConversationResults;
      }

      return _paginated.default.Array.find('outbound-call', {
        store: this.store,
        pageSize: 100,
        query: query
      });
    }),
    actions: {
      nextPage: function nextPage() {
        return this.outboundCalls.nextPage();
      }
    }
  });
  var _default = OrganizationDialsController;
  _exports.default = _default;
});