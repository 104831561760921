define("frontend/pods/components/service-provider/map-phone-numbers/list-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "item": "_item_na5mzm",
    "icon": "_icon_na5mzm",
    "field": "_field_na5mzm",
    "type": "_type_na5mzm",
    "use": "_use_na5mzm",
    "actions": "_actions_na5mzm"
  };
  _exports.default = _default;
});