define("frontend/pods/organizations/index/controller", ["exports", "frontend/utils/paginated"], function (_exports, _paginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationsIndexController = Ember.Controller.extend({
    sortedOrganizations: Ember.computed('model', function () {
      return _paginated.default.Array.find('organization', {
        store: this.store,
        pageSize: 50
      });
    }),
    actions: {
      deactivate: function deactivate(model) {
        model.set('disabledAt', new Date());
        return model.save();
      },
      activate: function activate(model) {
        model.set('disabledAt', null);
        return model.save();
      },
      nextPage: function nextPage() {
        return this.sortedOrganizations.nextPage();
      }
    }
  });
  var _default = OrganizationsIndexController;
  _exports.default = _default;
});