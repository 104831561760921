define("frontend/pods/components/soft-phone/dial-pad/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onDtmf: function onDtmf() {},
    softphone: Ember.inject.service(),
    clicked: function clicked(digit) {
      this.onDtmf(digit);
    },
    keyDown: function keyDown(e) {
      var digit = this.digitFromKeyDownEvent(e);

      if (digit) {
        this.softphone.dtmf(digit);
        this.onDtmf(digit);
      }
    },
    digitFromKeyDownEvent: function digitFromKeyDownEvent(e) {
      switch (e.keyCode) {
        case 48:
          return '0';

        case 49:
          return '1';

        case 50:
          return '2';

        case 51:
          return e.shiftKey ? '#' : '3';

        case 52:
          return '4';

        case 53:
          return '5';

        case 54:
          return '6';

        case 55:
          return '7';

        case 56:
          return e.shiftKey ? '*' : '8';

        case 57:
          return '9';
      }
    }
  });

  _exports.default = _default;
});