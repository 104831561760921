define("frontend/pods/components/partial/layouts/top-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authenticationService: Ember.inject.service('authentication'),
    organizationId: Ember.computed.readOnly('authenticationService.activeUser.organizationId'),
    isClientManager: Ember.computed.readOnly('authenticationService.isClientManager'),
    isTeamManager: Ember.computed.readOnly('authenticationService.isTeamManager'),
    isClient: Ember.computed.readOnly('authenticationService.isClient'),
    isAdmin: Ember.computed.readOnly('authenticationService.isAdmin'),
    isAgent: Ember.computed.readOnly('authenticationService.isAgent')
  });

  _exports.default = _default;
});