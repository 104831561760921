define("frontend/initializers/audio-player-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;

  function initialize(app) {
    app.inject('route', 'audioPlayerService', 'service:audio-player');
  }

  ;
});