define("frontend/pods/components/view/unauthenticated-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nZvNVb7I",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row row-app\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-separator col-unscrollable box\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-table\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"h4\"],[14,0,\"innerAll margin-none border-bottom text-center\"],[12],[1,[32,0,[\"title\"]]],[13],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-table-row\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-app col-unscrollable\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-app\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"login\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"innerAll\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"img\"],[14,0,\"border-none\"],[14,\"src\",\"/images/murray.png\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-md-8\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[18,1,null],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "frontend/pods/components/view/unauthenticated-box/template.hbs"
    }
  });

  _exports.default = _default;
});