define("frontend/pods/data-sources/new/route", ["exports", "frontend/pods/base/new/route", "frontend/mixins/additional-models", "frontend/mixins/modal"], function (_exports, _route, _additionalModels, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var DataSourcesNewRoute = _route.default.extend(_additionalModels.default, {
    model: function model() {
      return this.store.createRecord('data-source');
    },
    additionalModels: function additionalModels() {
      return {
        user: this.store.find('user', this.get('authenticationService.activeUserId'))
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      return controller.initializeWizard();
    },
    afterSave: function afterSave() {
      return this.transitionTo('data-sources.data-source.index', this.get('controller.model'));
    },
    afterCancel: function afterCancel() {
      return this.transitionTo('data-sources.index');
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (!this.get('controller.isComplete')) {
          if (window.confirm('Are you sure you want to leave this page and discard your changes?')) {
            return this.get('controller.model').destroyRecord();
          } else {
            return transition.abort();
          }
        }
      }
    }
  });

  var _default = DataSourcesNewRoute;
  _exports.default = _default;
});