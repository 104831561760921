define("frontend/pods/components/recording-player/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "recording-player": "_recording-player_r76nfu",
    "pause": "_pause_r76nfu",
    "play": "_play_r76nfu"
  };
  _exports.default = _default;
});