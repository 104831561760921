define("frontend/pods/components/time-duration/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TimeDurationComponent = Ember.Component.extend({
    tagName: '',
    // specify either a start and end time, or the number of seconds
    startTime: null,
    endTime: null,
    seconds: null,
    isNumeric: Ember.computed('seconds', function () {
      return isFinite(this.seconds);
    }),
    isValid: Ember.computed.readOnly('isNumeric'),
    _recalculate: Ember.observer('startTime', 'endTime', function () {
      var startTime = this.startTime;
      var endTime = this.endTime;

      if (startTime && endTime) {
        return this.set('seconds', (endTime - startTime) / 1000);
      }
    }),
    durationString: Ember.computed('seconds', function () {
      var durationSeconds = this.seconds;

      if (!isFinite(durationSeconds)) {
        return null;
      }

      var negative = durationSeconds < 0 ? '-' : '';
      var totalSeconds = Math.abs(durationSeconds);
      var hours = Math.floor(totalSeconds / 60 / 60);
      var minutes = Math.floor(totalSeconds / 60 % 60);
      var seconds = Math.floor(totalSeconds % 60);

      if (seconds < 10) {
        seconds = "0".concat(seconds);
      }

      if (hours > 0 && minutes < 10) {
        minutes = "0".concat(minutes);
      }

      hours = hours > 0 ? "".concat(hours, ":") : "";
      return "".concat(negative).concat(hours).concat(minutes, ":").concat(seconds);
    }),
    _initializeProperties: Ember.on('init', function () {
      return this._recalculate();
    })
  });
  var _default = TimeDurationComponent;
  _exports.default = _default;
});