define("frontend/pods/components/date-picker-group/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function areDatesEqual(a, b) {
    if (a && b && !!a.getTime && !!b.getTime && a.getTime() === b.getTime()) {
      return true;
    } else if (!a && !b) {
      return true;
    } else {
      return false;
    }
  }

  var DatePickerGroupComponent = Ember.Component.extend({
    startDate: null,
    endDate: null,
    futureEndDates: false,
    enableCustom: false,
    disableCustom: Ember.computed.not('enableCustom'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!areDatesEqual(this.endDate, this.internalEndDate)) {
        this.set('internalEndDate', this.endDate);
      }
    },
    internalEndDate: null,
    _updateEndDate: Ember.observer('internalEndDate', function () {
      var internalEndDate = this.internalEndDate;

      if (internalEndDate === null) {
        return;
      }

      var endDate = this.endDate;
      var adjustedEndDate = (0, _moment.default)(internalEndDate).endOf('day').toDate();

      if (!areDatesEqual(endDate, adjustedEndDate)) {
        this.set('endDate', adjustedEndDate);
      }
    }),
    options: [{
      value: 'today',
      text: 'Today'
    }, {
      value: 'yesterday',
      text: 'Yesterday'
    }, {
      value: 'thisWeek',
      text: 'This Week'
    }, {
      value: 'lastWeek',
      text: 'Last Week'
    }, {
      value: 'thisMonth',
      text: 'This Month'
    }, {
      value: 'lastMonth',
      text: 'Last Month'
    }, {
      value: 'custom',
      text: 'Custom Date Range'
    }],
    optionValue: 'today',
    _executeOptionValueAction: Ember.observer('optionValue', function () {
      var _this = this;

      if (!this.__executeOptionValueAction) {
        this.__executeOptionValueAction = function () {
          var v = _this.optionValue;

          if (v) {
            return _this[v]();
          }
        };
      }

      Ember.run.scheduleOnce('afterRender', this.__executeOptionValueAction);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this._executeOptionValueAction();
    },
    today: function today() {
      var endDate;
      var startDate = (0, _moment.default)().startOf('day').toDate();

      if (this.futureEndDates) {
        endDate = (0, _moment.default)().endOf('day').toDate();
      }

      return this.setProperties({
        startDate: startDate,
        endDate: endDate,
        enableCustom: false
      });
    },
    yesterday: function yesterday() {
      var startDate = (0, _moment.default)().subtract('days', 1).startOf('day').toDate();
      var endDate = (0, _moment.default)().subtract('days', 1).endOf('day').toDate();
      return this.setProperties({
        startDate: startDate,
        endDate: endDate,
        enableCustom: false
      });
    },
    thisWeek: function thisWeek() {
      var endDate;
      var startDate = (0, _moment.default)().startOf('week').toDate();

      if (this.futureEndDates) {
        endDate = (0, _moment.default)().endOf('week').toDate();
      }

      return this.setProperties({
        startDate: startDate,
        endDate: endDate,
        enableCustom: false
      });
    },
    lastWeek: function lastWeek() {
      var startDate = (0, _moment.default)().subtract('weeks', 1).startOf('week').toDate();
      var endDate = (0, _moment.default)().subtract('weeks', 1).endOf('week').toDate();
      return this.setProperties({
        startDate: startDate,
        endDate: endDate,
        enableCustom: false
      });
    },
    thisMonth: function thisMonth() {
      var endDate;
      var startDate = (0, _moment.default)().startOf('month').toDate();

      if (this.futureEndDates) {
        endDate = (0, _moment.default)().endOf('month').toDate();
      }

      return this.setProperties({
        startDate: startDate,
        endDate: endDate,
        enableCustom: false
      });
    },
    lastMonth: function lastMonth() {
      var startDate = (0, _moment.default)().subtract('months', 1).startOf('month').toDate();
      var endDate = (0, _moment.default)().subtract('months', 1).endOf('month').toDate();
      return this.setProperties({
        startDate: startDate,
        endDate: endDate,
        enableCustom: false
      });
    },
    custom: function custom() {
      return this.set('enableCustom', true);
    }
  });
  var _default = DatePickerGroupComponent;
  _exports.default = _default;
});