define("frontend/pods/components/ui-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3TAHr2+B",
    "block": "{\"symbols\":[\"item\",\"item\"],\"statements\":[[6,[37,16],[[35,15]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,14],null,[[\"triggerClass\",\"options\",\"selected\",\"placeholder\",\"searchPlaceholder\",\"allowClear\",\"closeOnSelect\",\"onChange\",\"searchEnabled\",\"search\"],[[30,[36,12],[[35,11],\" \",[35,10]],null],[35,9],[35,8],[35,7],[35,6],[35,5],false,[30,[36,2],[[32,0],[35,4]],null],[35,3],[30,[36,2],[[32,0],[35,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"item\"],[[\"from\"],[\"frontend/pods/components/ui-select/styles\"]]]]]],[12],[2,\"\\n      \"],[1,[32,2,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,13],null,[[\"triggerClass\",\"options\",\"selected\",\"placeholder\",\"searchPlaceholder\",\"allowClear\",\"onChange\",\"searchEnabled\",\"search\"],[[30,[36,12],[[35,11],\" \",[35,10]],null],[35,9],[35,8],[35,7],[35,6],[35,5],[30,[36,2],[[32,0],[35,4]],null],[35,3],[30,[36,2],[[32,0],[35,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"item\"],[[\"from\"],[\"frontend/pods/components/ui-select/styles\"]]]]]],[12],[2,\"\\n      \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"search\",\"action\",\"searchEnabled\",\"selectionChanged\",\"allowClear\",\"searchPlaceholder\",\"placeholder\",\"selectedOption\",\"options\",\"class\",\"classNames\",\"concat\",\"power-select\",\"power-select-multiple\",\"multiple\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui-select/template.hbs"
    }
  });

  _exports.default = _default;
});