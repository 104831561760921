define("frontend/pods/components/system-alerts/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SystemAlertsComponent = Ember.Component.extend({
    // the alerts service (must be specified)
    alertsService: Ember.inject.service('alerts'),
    alerts: Ember.computed.readOnly('alertsService.alerts')
  });
  var _default = SystemAlertsComponent;
  _exports.default = _default;
});