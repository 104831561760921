define("frontend/pods/components/organization-dashboard-user-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1nJt5fIz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[12],[10,\"i\"],[14,0,\"fa fa-user\"],[12],[13],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[6,[37,1],null,[[\"route\",\"model\"],[\"users.user\",[35,0]]],[[\"default\"],[{\"statements\":[[1,[35,0,[\"name\"]]]],\"parameters\":[]}]]],[2,\" \"],[1,[35,0,[\"teamName\"]]],[6,[37,3],[[35,0,[\"isUserTeamManager\"]]],null,[[\"default\"],[{\"statements\":[[2,\" Team Manager\"]],\"parameters\":[]}]]],[6,[37,3],[[35,0,[\"isUserClientManager\"]]],null,[[\"default\"],[{\"statements\":[[2,\" Client Manager\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[34,4]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[34,5]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[34,6]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[34,7]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[34,8]],[13],[2,\"\\n\"],[6,[37,3],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[34,10]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[34,11]],[2,\"%\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,12]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[14,0,\"text-right\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"btn-group btn-group-xs\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"route\",\"model\"],[\"btn btn-default\",\"users.user.edit\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"user\",\"link-to\",\"avgWaitTime\",\"if\",\"totalTime\",\"dials\",\"voicemails\",\"dialsPerConversation\",\"connections\",\"isAdmin\",\"appointments\",\"conversionRate\",\"canEditUser\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/organization-dashboard-user-row/template.hbs"
    }
  });

  _exports.default = _default;
});