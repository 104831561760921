define("frontend/pods/components/agent/agent-console/states/connected/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QZfpvUB3",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"connected\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/connected/styles\"]]]]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"top\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/connected/styles\"]]]]]],[12],[2,\"\\n    \"],[8,\"agent/current-call/lead-info\",[[16,0,[31,[[30,[36,0],[\"agent-info\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/connected/styles\"]]]]]]],[[],[]],null],[2,\"\\n    \"],[8,\"agent/current-call/client-info\",[[16,0,[31,[[30,[36,0],[\"client-info\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/connected/styles\"]]]]]]],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"agent/agent-console/current-call/actions\",[[16,0,[31,[[30,[36,0],[\"actions\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/states/connected/styles\"]]]]]]],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/states/connected/template.hbs"
    }
  });

  _exports.default = _default;
});