define("frontend/pods/auth/forgot-password/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AsNXxU8k",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"title\"],[\"Password Reset\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"placeholder text-center\"],[12],[10,\"i\"],[14,0,\"fa fa-key\"],[12],[13],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"panel panel-default col-sm-6 col-sm-offset-3\"],[12],[2,\"\\n  \\t\"],[1,[30,[36,1],null,[[\"model\",\"showFieldNames\"],[[35,0],false]]]],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n\\t    \"],[11,\"form\"],[4,[38,2],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"label\"],[12],[2,\"Enter your email address to reset your password\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,[36,3],null,[[\"value\",\"class\",\"placeholder\"],[[35,0,[\"email\"]],\"form-control\",\"Your Email Address\"]]]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[10,\"button\"],[14,0,\"btn btn-primary btn-block\"],[14,4,\"submit\"],[12],[2,\"Submit\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"error-list\",\"action\",\"input\",\"view/unauthenticated-box\"]}",
    "meta": {
      "moduleName": "frontend/pods/auth/forgot-password/index/template.hbs"
    }
  });

  _exports.default = _default;
});