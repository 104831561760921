define("frontend/pods/components/soft-phone/audio-element/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zHChGLdu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"audio\"],[15,1,[34,0,[\"audioElementId\"]]],[14,\"autoplay\",\"\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"softphone\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/soft-phone/audio-element/template.hbs"
    }
  });

  _exports.default = _default;
});