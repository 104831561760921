define("frontend/models/user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var User = _model.default.extend({
    username: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    companyName: (0, _model.attr)('string'),
    companyDisplayName: (0, _model.attr)('string'),
    role: (0, _model.attr)('string'),
    callerIdNumber: (0, _model.attr)('string'),
    randomizeCallerId: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    phone: (0, _model.attr)('string'),
    salesforceDomain: (0, _model.attr)('string'),
    inboundPhone: (0, _model.attr)('string'),
    inboundPin: (0, _model.attr)('string'),
    inboundPhoneMatch: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    callRecordingEnabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    dialLeadsStartingAtMinute: (0, _model.attr)('number'),
    dialLeadsUntilMinute: (0, _model.attr)('number'),
    availableCountryCodes: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    lastLoggedInAt: (0, _model.attr)('date'),
    disabledAt: (0, _model.attr)('date'),
    teamId: (0, _model.attr)('string'),
    teamName: (0, _model.attr)('string'),
    team: (0, _model.attr)('string'),
    agentScript: (0, _model.attr)('string'),
    organization: (0, _model.belongsTo)('organization'),
    organizationId: Ember.computed.readOnly('organization.id'),
    name: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.firstName || '';
      var lastName = this.lastName || '';
      return "".concat(firstName, " ").concat(lastName);
    }),
    friendlyName: Ember.computed('firstName', 'lastName', function () {
      return User.formatFriendlyName(this.firstName, this.lastName);
    }),
    displayCompanyName: Ember.computed('companyName', 'organization.name', {
      get: function get() {
        return this.companyName || this.get('organization.name');
      }
    }),
    isDisabled: Ember.computed.bool('disabledAt'),
    isUserClientManager: Ember.computed.equal('role', 'client_manager'),
    isUserTeamManager: Ember.computed.equal('role', 'team_manager')
  });

  User.roles = ['client', 'agent', 'admin', 'client_manager', 'team_manager'];

  User.formatFriendlyName = function (first, last) {
    var firstName = first || '';
    var lastInitial = (last || '')[0] || '';

    if (lastInitial) {
      lastInitial += '.';
    }

    return "".concat(firstName, " ").concat(lastInitial);
  };

  var _default = User;
  _exports.default = _default;
});