define("frontend/pods/organizations/organization/users/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationUsersRoute = Ember.Route.extend({
    model: function model() {
      return this.modelFor('organizations.organization');
    }
  });
  var _default = OrganizationUsersRoute;
  _exports.default = _default;
});