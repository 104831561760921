define("frontend/pods/organizations/organization/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationEditController = Ember.Controller.extend({
    serviceApplicationOrganizations: Ember.computed('model', function () {
      var organization = this.model;

      if (!organization) {
        return;
      }

      return this.store.query('service-application-organization', {
        organization_id: organization.get('id')
      });
    }),
    editableServiceApplicationOrganizations: Ember.computed('serviceApplicationOrganizations.[]', function () {
      var serviceApplicationOrganizations = this.serviceApplicationOrganizations || [];
      return serviceApplicationOrganizations.mapBy('serviceApplication');
    }),
    crmIntegrationOrganizations: Ember.computed('model', function () {
      var organization = this.model;

      if (!organization) {
        return;
      }

      return this.store.query('crm-integration-organization', {
        organization_id: organization.get('id')
      });
    }),
    editableCrmIntegrationOrganizations: Ember.computed('crmIntegrationOrganizations.[]', function () {
      var crmIntegrationOrganizations = this.crmIntegrationOrganizations || [];
      return crmIntegrationOrganizations.mapBy('crmIntegration');
    })
  });
  var _default = OrganizationEditController;
  _exports.default = _default;
});