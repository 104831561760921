define("frontend/adapters/application", ["exports", "@ember-data/adapter/rest", "@ember-data/model", "@ember-data/adapter/error", "frontend/models/meta", "ember-inflector"], function (_exports, _rest, _model, _error, _meta, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    host: window.API_HOST.host,
    namespace: 'api/v1',
    meta: _meta.default,
    session: Ember.inject.service('authentication'),
    apiVersion: Ember.computed.oneWay('meta.apiVersion'),
    sessionId: Ember.computed.oneWay('session.id'),
    pathForType: function pathForType(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));
    },
    // used when posting data via form submit or similar, outside of the adapter
    headerData: Ember.computed('apiVersion', 'sessionId', function () {
      return {
        api_key: this.sessionId,
        api_version: this.apiVersion
      };
    }),
    headers: Ember.computed('apiVersion', 'sessionId', function () {
      return {
        'X-API-Version': this.apiVersion,
        'Authorization': "Token token=\"".concat(this.sessionId, "\"")
      };
    }),
    headerDataQueryString: Ember.computed('headerData', function () {
      var headerData = this.headerData;

      if (!headerData) {
        return "";
      }

      return this.queryStringFor(headerData);
    }),
    queryStringFor: function queryStringFor() {
      var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var params = [];

      for (var _i = 0, _Object$keys = Object.keys(hash || {}); _i < _Object$keys.length; _i++) {
        var k = _Object$keys[_i];
        var v = hash[k];
        params.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }

      return params.join("&");
    },
    // executes non-RESTful actions on the server
    executeServerAction: function executeServerAction(type, id, action, options) {
      if (options == null) {
        options = {};
      }

      var method = options.method || 'GET';
      var url = this.buildURL(type, id) + '/' + action;
      return this.ajax(url, method, options);
    },
    checkForVersionError: function checkForVersionError(status, headers, payload) {
      if (this._updatingVersion) {
        return;
      }

      if (status !== 422) {
        return;
      }

      var errors = payload && payload.errors;

      if (errors && Ember.typeOf(errors) === 'object' && errors.api_version) {
        window.bootbox.dialog({
          title: "Software Update",
          message: '<h4 style="margin-bottom: 20px">Good news, we just released a new version!</h4> <p>One moment while we deliver our latest and greatest to you... <i class="fa fa-spinner fa-spin"></i></p>',
          closeButton: false
        });
        window.setTimeout(function () {
          return window.location.reload(true);
        }, 5000);
        return this._updatingVersion = true;
      }
    },
    ajaxError: function ajaxError(jqXHR) {
      this.checkForVersionError(jqXHR);
      return this._super(jqXHR);
    },
    updateAttributes: function updateAttributes(type, model, attributes) {
      var id = model.get('id');
      var url = this.buildURL(type, id);
      var data = {};
      var t = Ember.String.underscore(type);
      data[t] = {};

      for (var key in attributes) {
        var value = attributes[key];
        data[t][Ember.String.underscore(key)] = value;
      }

      return this.ajax(url, 'PUT', {
        data: data
      }).then(function (result) {
        model.rollbackAttributes();
        model.store.pushPayload(type, result);
        return model;
      });
    },
    // use the REST API to delete it and then reload it, assuming it has a deletedAt property
    markRecordAsDeleted: function markRecordAsDeleted(type, model) {
      var id = model.get('id');
      var url = this.buildURL(type, id);
      return this.ajax(url, 'DELETE').then(function () {
        return model.reload();
      });
    },
    // override to handle the error message normalization (which should happen here but is not)
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (this.isInvalid(status, headers, payload)) {
        this.checkForVersionError(status, headers, payload);
        var normalizedErrors = this.store.serializerFor('application').extractErrors(this.store, null, payload);
        payload = {
          errors: normalizedErrors
        };
      }

      return this._super(status, headers, payload, requestData);
    },
    findHasMany: function findHasMany(store, snapshot, url, relationship) {
      debugger;
      var id = snapshot.id;
      var type = snapshot.modelName;
      url = this.urlPrefix(url, this.buildURL(type, id, snapshot, 'findHasMany'));
      return this.ajax(url, 'GET');
    },
    fetchServerTime: function fetchServerTime() {
      var url = "".concat(this.host, "/time");
      return this.ajax(url, 'GET').then(function (result) {
        return new Date(result.time);
      });
    }
  });

  _exports.default = _default;

  _model.default.reopen({
    // only save the modified attributes to the server
    saveChanges: function saveChanges() {
      var attributes = this.changedAttributes();

      if (Object.keys(attributes).length === 0) {
        return Ember.RSVP.resolve(this);
      }

      var changes = {};

      for (var key in attributes) {
        var value = attributes[key];
        changes[key] = value[1];
      }

      var type = this.constructor.modelName;
      return this.store.adapterFor('application').updateAttributes(type, this, changes);
    },
    updateAttributes: function updateAttributes(attributes) {
      var type = this.constructor.modelName;
      return this.store.adapterFor('application').updateAttributes(type, this, attributes);
    },
    markRecordAsDeleted: function markRecordAsDeleted() {
      var type = this.constructor.modelName;
      return this.store.adapterFor('application').markRecordAsDeleted(type, this);
    }
  });
});