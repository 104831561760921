define("frontend/pods/components/ui-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/rYavb0W",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"box-generic padding-none\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h5\"],[14,0,\"strong innerAll border-bottom margin-none bg-gray\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"innerAll\"],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui-box/template.hbs"
    }
  });

  _exports.default = _default;
});