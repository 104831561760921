define("frontend/pods/components/recording-player/component", ["exports", "frontend/utils/time-format"], function (_exports, _timeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RecordingPlayerComponent = Ember.Component.extend({
    localClassNames: ['recording-player'],
    src: null,
    duration: null,
    title: null,
    playIcon: 'play',
    buttonClasses: 'btn btn-default btn-circle',
    isShowing: Ember.computed.bool('src'),
    classNameBindings: ['isShowing::hidden'],
    isLoaded: Ember.computed('src', 'audioService.src', function () {
      return this.src === this.get('audioService.src');
    }),
    isPlaying: Ember.computed.and('isLoaded', 'audioService.isPlaying'),
    canPlay: Ember.computed.bool('src'),
    canPause: Ember.computed.readOnly('isPlaying'),
    audioService: Ember.inject.service('audio-player'),
    position: Ember.computed('isLoaded', 'audioService.position', function () {
      var result;

      if (this.isLoaded) {
        result = this.get('audioService.position') || 0;
      } else {
        result = 0;
      }

      return _timeFormat.default.mmss(result);
    }),
    durationDisplay: Ember.computed('audioService.duration', function () {
      var result = this.get('audioService.duration') || 0;
      return _timeFormat.default.mmss(result);
    }),
    playIconClass: Ember.computed('playIcon', function () {
      var playIcon = this.playIcon;
      return "fa fa-fw fa-".concat(playIcon);
    }),
    actions: {
      play: function play() {
        if (!this.canPlay) {
          return;
        }

        return this.audioService.play(this.src, {
          duration: this.duration
        });
      },
      pause: function pause() {
        if (!this.canPause) {
          return;
        }

        return this.audioService.pause();
      },
      positionChanged: function positionChanged(value) {
        return this.set('audioService.position', value);
      },
      sliderMouseDown: function sliderMouseDown() {
        this._wasPlayingOnMouseDown = this.isPlaying;

        if (this._wasPlayingOnMouseDown) {
          return this.audioService.pause();
        }
      },
      sliderMouseUp: function sliderMouseUp() {
        var _this = this;

        if (this.isLoaded && !this.isPlaying && this._wasPlayingOnMouseDown) {
          Ember.run.scheduleOnce('afterRender', function () {
            return _this.audioService.play();
          });
        }
      }
    }
  });
  var _default = RecordingPlayerComponent;
  _exports.default = _default;
});