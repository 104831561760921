define("frontend/pods/components/ui/dropdown-button/menu-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "anooOmq+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,3],[\"dropdown-menu \",[35,2],\" \",[35,1]],null],\" \",[30,[36,0],[\"panel\"],[[\"from\"],[\"frontend/pods/components/ui/dropdown-button/menu-component/styles\"]]]]]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"class\",\"positionClass\",\"concat\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/ui/dropdown-button/menu-component/template.hbs"
    }
  });

  _exports.default = _default;
});