define("frontend/pods/components/partial/lead/call-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hNIvJ+uL",
    "block": "{\"symbols\":[\"call\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"h4\"],[14,0,\"innerAll margin-none border-bottom\"],[12],[10,\"i\"],[14,0,\"fa fa-fw fa-clock-o pull-right text-muted\"],[12],[13],[2,\" Call History\"],[13],[2,\"\\n\\t\"],[10,\"ul\"],[14,0,\"timeline-activity list-unstyled\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,0,[\"outboundCalls\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,2],null,[[\"call\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[6,[37,1],[[35,0,[\"outboundCallsPromise\",\"isPending\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"li\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"i\"],[14,0,\"list-icon fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"block\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"caret\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"box-generic\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"timeline-top-info border-bottom\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"span\"],[12],[2,\"Loading call history...\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"separator bottom\"],[12],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"li\"],[12],[2,\"No outbound calls have been made to this lead.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"if\",\"partial/lead/call-history/outbound-call\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/partial/lead/call-history/template.hbs"
    }
  });

  _exports.default = _default;
});