define("frontend/helpers/simple-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function escapeHtml(string) {
    var entityMap = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '/': '&#x2F;',
      '`': '&#x60;',
      '=': '&#x3D;'
    };
    return String(string).replace(/[&<>"'`=/]/g, function (s) {
      return entityMap[s];
    });
  }

  function convertHyperlinks(text) {
    var urlRegex = /(https?:&#x2F;&#x2F;[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      // file name
      var title = decodeURIComponent(url).split('&#x2F;').pop().split('?')[0].split('#')[0];

      if (!title) {
        // domain name
        var domainRegex = new RegExp(/:&#x2F;&#x2F;[&#x2F;]?([^&#x2F;?#]+)/);
        var domainMatch = domainRegex.exec(url);
        title = domainMatch && domainMatch[1];
      }

      if (!title) {
        title = 'link';
      }

      return "<a href=\"".concat(url, "\" target=\"_blank\">[").concat(title, "]</a>");
    });
  }

  function convertNewLines(text) {
    return text.split('\n').join('<br>');
  }

  var _default = Ember.Helper.helper(function simpleFormat(params) {
    var result = params[0];

    if (result) {
      result = escapeHtml(result);
      result = convertHyperlinks(result);
      result = convertNewLines(result);
      return Ember.String.htmlSafe(result);
    }

    return result;
  });

  _exports.default = _default;
});