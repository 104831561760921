define("frontend/pods/organizations/organization/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q9SQ2s1P",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"dashboard\"],[[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"dashboard\",\"organization-dashboard\"]}",
    "meta": {
      "moduleName": "frontend/pods/organizations/organization/index/template.hbs"
    }
  });

  _exports.default = _default;
});