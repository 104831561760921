define("frontend/services/call-notifications", ["exports", "frontend/utils/web-socket"], function (_exports, _webSocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // The push state object is a class that ensures the most recent data is presented.
  // During the call to _update(), it verifies that the time the server sent the notification
  // is the most recent before making the update.
  var PushStateObject = Ember.Object.extend({
    _time: null,
    update: function update(data) {
      if (data._time) {
        var _time = this._time;

        if (_time && _time > data._time) {
          return;
        }
      }

      return this.setProperties(data);
    }
  });

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    authentication: Ember.inject.service(),
    webSocket: Ember.inject.service(),
    alerts: Ember.inject.service(),
    channel: Ember.computed('authentication.userId', function () {
      var userId = this.get('authentication.userId');

      if (userId) {
        return "/users/".concat(userId, "/call");
      }
    }),
    _subscription: null,
    // the newest call state -- this can be set / bound externally to initialize the state,
    // and an observer will update it when a new message arrives
    callState: null,
    // for agents: the call state of the client that the agent is currently calling on behalf of --
    // the agent will assume idle (and update this property accordingly) when a new call begins,
    // and an observer will updated it when a new message arrives
    clientState: null,
    callService: Ember.inject.service('call'),
    // the most recent state changed notification
    stateChangedNotification: Ember.computed(function () {
      return PushStateObject.create();
    }),
    // the most recent client state changed notification
    clientStateChangedNotification: Ember.computed(function () {
      return PushStateObject.create();
    }),
    // the keys are the lead IDs, and the values are push state objects of the latest notifications
    leadOutboundCallStateNotifications: Ember.computed(function () {
      return {};
    }),
    // an array of outbound calls this user was connected to
    userOutboundCalls: Ember.computed(function () {
      return [];
    }),
    // an array of outbound calls agents have made on this user's belhalf
    agentOutboundCalls: Ember.computed(function () {
      return [];
    }),
    // an array of the agent calls that have been released
    releasedOutboundCalls: Ember.computed(function () {
      return [];
    }),
    // an array of outbound calls where a voice mail was dropped
    voiceMailDroppedCalls: Ember.computed(function () {
      return [];
    }),
    subscribe: function subscribe() {
      var _this = this;

      var channel = this.channel;

      if (!channel) {
        return;
      }

      if (this._subscription) {
        return;
      }

      return this.set('_subscription', _webSocket.default.Subscription.create({
        client: this.webSocket,
        channel: channel,
        onNewMessage: function onNewMessage(message) {
          return _this._onNewMessage(message);
        }
      }));
    },
    unsubscribe: function unsubscribe() {
      if (!this._subscription) {
        return;
      }

      this._subscription.unsubscribe();

      return this.set('_subscription', null);
    },
    // occurs any time a message is received -- it is preferred to use a computed property reading the newest related message,
    // but sometimes this is necessary (e.g. several simultaneous outbound calls)
    _onNewMessage: function _onNewMessage(message) {
      switch (message.event) {
        case 'state_changed':
          return this.stateChangedNotification.update(message);

        case 'client_state_changed':
          return this.clientStateChangedNotification.update(message);

        case 'lead_outbound_call_state':
          return this._updateLeadOutboundCall(message);

        case 'released_outbound_call':
          return this._releasedOutboundCall(message);

        case 'voice_mail_delivered':
          return this._addVoiceMailDroppedCall(message);

        case 'detecting_voice_mail':
          return this._didStartVoiceMailDetection(message);

        case 'canceled_voice_mail':
          return this._didCancelVoiceMailDetection(message);

        case 'eavesdrop_started':
          return this._eavesdropStarted(message);

        case 'alert':
          return this._displayAlert(message);

        default:
          return console.log("Unknown push notification: ", message);
      }
    },
    // occurs whenever a lead outbound call message is received (i.e. an agent is calling the user's lead) --
    // retrieves the outbound call model and updates its properties accordingly
    _updateLeadOutboundCall: function _updateLeadOutboundCall(message) {
      var _this2 = this;

      // update the push state object for this lead
      var notifications = this.leadOutboundCallStateNotifications;
      var notification = notifications[message.id] || (notifications[message.id] = PushStateObject.create());
      notification.update(message); // update the state of the outbound call model

      var id = message.id;
      return this.store.find('outbound-call', id).then(function (outboundCall) {
        outboundCall.set('callState', notification.get('state')); // put the call in the agent array

        return _this2.agentOutboundCalls.addObject(outboundCall);
      });
    },
    // occurs when an agent has officially transitioned off the lead record
    // (i.e. they completed documenting it and / or they have moved onto a different lead)
    _releasedOutboundCall: function _releasedOutboundCall(message) {
      var _this3 = this;

      console.log("AGENT RELEASED LEAD: ", message);
      return this.store.find('outbound-call', message.outbound_call_id).then(function (outboundCall) {
        return _this3.releasedOutboundCalls.addObject(outboundCall);
      });
    },
    _detectingVoiceMailOutboundCallId: null,
    isDetectingVoiceMail: Ember.computed('outboundCallId', '_detectingVoiceMailOutboundCallId', function () {
      var outboundCallId = this.outboundCallId;
      return outboundCallId && outboundCallId === this._detectingVoiceMailOutboundCallId;
    }),
    _didStartVoiceMailDetection: function _didStartVoiceMailDetection(message) {
      if (message.id === this.outboundCallId) {
        console.log("Started voicemail detection: ", message);
        return this.set('_detectingVoiceMailOutboundCallId', message.id);
      }
    },
    _didCancelVoiceMailDetection: function _didCancelVoiceMailDetection(message) {
      if (message.id === this._detectingVoiceMailOutboundCallId) {
        console.log("Canceled voicemail detection: ", message);
        return this.set('_detectingVoiceMailOutboundCallId', null);
      }
    },
    // occurs whenever a client call state message is received -- updates the property accordingly
    // this is done through onNewMessage instead of a computed property because the client changes
    _updateClientCallState: Ember.observer('clientStateChangedNotification.to', function () {
      var state = this.get('clientStateChangedNotification.to');

      if (state) {
        return this.set('clientState', state);
      }
    }),
    _updateCallState: Ember.observer('stateChangedNotification.to', function () {
      // update the call state
      var callState;
      var notification = this.stateChangedNotification;

      if (notification) {
        callState = notification.get('to');
      } // avoid race conditions of notifications coming in out of order


      if (this.callState === 'connected' && (callState === 'connecting' || callState === 'dialing')) {
        callState = 'connected';
      }

      if (this.callState === 'paused' && callState === 'disconnecting') {
        callState = 'paused';
      }

      if (callState) {
        this.set('callState', callState);
      } // update the current lead and outbound call


      var leadId = notification.get('lead_id');
      var outboundCallId = notification.get('outbound_call_id');
      var connected = callState === 'dialing' || callState === 'connecting' || callState === 'connected' || callState === 'disconnecting';

      if (connected) {
        if (leadId) {
          this.set('leadId', leadId.toString());
        }

        if (outboundCallId) {
          return this.set('outboundCallId', outboundCallId);
        }
      } else {
        this.set('leadId', null);
        return this.set('outboundCallId', null);
      }
    }),
    _newOutboundCall: Ember.observer('outboundCallId', function () {
      var _this4 = this;

      // reset the client state since the call is new (and presumably the client is available)
      this.set('clientStateChangedNotification.to', 'idle'); // put the call in the user outbound call array

      var outboundCallId = this.outboundCallId;

      if (outboundCallId) {
        return this.store.find('outbound-call', outboundCallId).then(function (outboundCall) {
          return _this4.userOutboundCalls.addObject(outboundCall);
        });
      }
    }),
    _addVoiceMailDroppedCall: function _addVoiceMailDroppedCall(message) {
      var _this5 = this;

      return this.store.find('outbound-call', message.id).then(function (outboundCall) {
        return _this5.voiceMailDroppedCalls.addObject(outboundCall);
      });
    },
    _initializeProperties: Ember.on('init', function () {
      this.getProperties('callState', 'clientState', 'outboundCallId');
      return this.getProperties('stateChangedNotification.to', 'clientStateChangedNotification.to');
    }),
    _displayAlert: function _displayAlert(message) {
      var systemAlerts = this.alerts;

      switch (message.type) {
        case 'no_leads_available':
          this.set('callService.listComplete', 'true');
          return systemAlerts.success('<strong>Well done, your list is complete!</strong> All available leads have been called. To continue dialing, please select a different list.');

        case 'unable_to_transfer':
          return systemAlerts.warning('<strong>We could not transfer this call.</strong> Please wrap up the call, and report this to support.');

        case 'call_ended_prematurely':
          this.callService.trigger('callFailed', message);
          return systemAlerts.info("The call ended with a reason of <strong>".concat(message.reason, "</strong>."));
      }
    },
    _eavesdropStarted: function _eavesdropStarted(message) {
      var callSession = this.callService.callSession;

      if (callSession) {
        callSession.reload();
      }
    },
    _channelChanged: Ember.observer('channel', function () {
      if (this._subscription) {
        this.set('channel', this.channel);
      }
    })
  });

  _exports.default = _default;
});