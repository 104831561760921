define("frontend/controllers/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    clients: Ember.computed('model.@each.role', 'model.@each.isDisabled', function () {
      return this._filterByRole('client', false);
    }),
    clientManagers: Ember.computed('model.@each.role', 'model.@each.isDisabled', function () {
      return this._filterByRole('client_manager', false);
    }),
    teamManagers: Ember.computed('model.@each.role', 'model.@each.isDisabled', function () {
      return this._filterByRole('team_manager', false);
    }),
    agents: Ember.computed('model.@each.role', 'model.@each.isDisabled', function () {
      return this._filterByRole('agent', false);
    }),
    disabledAgents: Ember.computed('model.@each.role', 'model.@each.isDisabled', function () {
      return this._filterByRole('agent', true);
    }),
    admins: Ember.computed('model.@each.role', 'model.@each.isDisabled', function () {
      return this._filterByRole('admin', false);
    }),
    disabled: Ember.computed('model.@each.isDisabled', function () {
      return this.model.filterBy('isDisabled', true);
    }),
    _filterByRole: function _filterByRole(role, disabled) {
      return this.model.filter(function (i) {
        return i.get('role') === role && i.get('isDisabled') === disabled;
      });
    },
    filteredRole: null,
    isFilteredByDisabled: false,
    isFilteredByClients: Ember.computed.equal('filteredRole', 'client'),
    isFilteredByClientManagers: Ember.computed.equal('filteredRole', 'client_manager'),
    isFilteredByTeamManagers: Ember.computed.equal('filteredRole', 'team_manager'),
    isFilteredByAgents: Ember.computed.equal('filteredRole', 'agent'),
    isFilteredByAdmins: Ember.computed.equal('filteredRole', 'admin'),
    isUnfiltered: Ember.computed('filteredRole', 'isFilteredByDisabled', function () {
      return !this.filteredRole && !this.isFilteredByDisabled;
    }),
    usersFilteredByDisabled: Ember.computed('model.@each.isDisabled', 'isFilteredByDisabled', function () {
      var isDisabled = this.isFilteredByDisabled;
      return this.model.filterBy('isDisabled', isDisabled);
    }),
    usersFilteredByRole: Ember.computed('model.@each.role', 'filteredRole', function () {
      var role = this.filteredRole;

      if (!role) {
        return this.model;
      }

      return this.model.filterBy('role', role);
    }),
    usersFilteredByText: Ember.computed('filterText', 'model.@each.firstName', 'model.@each.lastName', 'model.@each.email', function () {
      var filterText = (this.filterText || '').toLowerCase();

      if (!this.filterText) {
        return this.model;
      }

      return this.model.filter(function (item) {
        var p = [item.firstName, item.lastName, item.email];
        return !!p.find(function (value) {
          return (value || '').toLowerCase().indexOf(filterText) > -1;
        });
      });
    }),
    filteredUsers: Ember.computed('usersFilteredByDisabled', 'usersFilteredByRole', 'usersFilteredByText', function () {
      var p = this.getProperties('usersFilteredByDisabled', 'usersFilteredByRole', 'usersFilteredByText');
      return p.usersFilteredByDisabled.filter(function (i) {
        return p.usersFilteredByRole.indexOf(i) > -1;
      }).filter(function (i) {
        return p.usersFilteredByText.indexOf(i) > -1;
      });
    }),
    sortedUsersTeamManager: Ember.computed('authenticationService.activeUser.teamId', function () {
      var teamId = this.get('authenticationService.activeUser.teamId');
      return this.store.query('user', {
        team_id: teamId
      });
    }),
    sortedUsersClientManager: Ember.computed('authenticationService.activeUser.organizationId', function () {
      var organizationId = this.get('authenticationService.activeUser.organizationId');
      return this.store.query('user', {
        organization_id: organizationId
      });
    }),
    selectedUser: null,
    recentlyViewedUsers: null,
    isAdmin: Ember.computed.oneWay('authenticationService.isAdmin'),
    isClientManager: Ember.computed.equal('authenticationService.activeRole', 'client_manager'),
    isTeamManager: Ember.computed.equal('authenticationService.activeRole', 'team_manager'),
    canEdit: Ember.computed.or('isAdmin', 'isClientManager', 'isTeamManager'),
    addToRecentlyViewed: function addToRecentlyViewed(user) {
      if (!user) {
        return;
      }

      if (!this.recentlyViewedUsers) {
        this.set('recentlyViewedUsers', []);
      }

      var users = this.recentlyViewedUsers;

      if (users.indexOf(user) === -1) {
        users.insertAt(0, user);
      }

      var maxCount = 10;

      if (users.length > maxCount) {
        return users.removeAt(maxCount);
      }
    },
    actions: {
      filterRole: function filterRole(role) {
        this.setProperties({
          filteredRole: role,
          isFilteredByDisabled: false
        });
        return this.transitionToRoute('users');
      },
      filterDisabled: function filterDisabled() {
        this.setProperties({
          filteredRole: null,
          isFilteredByDisabled: true
        });
        return this.transitionToRoute('users');
      }
    }
  });

  _exports.default = _default;
});