define("frontend/pods/components/recording-controller/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    recording: null,
    onRecord: function onRecord() {},
    tagName: '',
    store: Ember.inject.service(),
    alerts: Ember.inject.service('alerts'),
    canPlay: Ember.computed.readOnly('recording.canPlay'),
    canStop: Ember.computed.readOnly('recording.canStop'),
    canRecord: Ember.computed('recording', 'recording.canRecord', function () {
      return Ember.isEmpty(this.recording) || this.get('recording.canRecord');
    }),
    _executeServerAction: function _executeServerAction(action) {
      var adapter = this.store.adapterFor('application');
      return adapter.executeServerAction('recording', this.get('recording.id'), action);
    },
    play: function play() {
      var _this = this;

      var alertMessage = 'The recording could not be played.  Make sure you are connected over voice and are not actively dialing.';
      return this._executeServerAction('play').catch(function () {
        return _this.alerts.error(alertMessage);
      });
    },
    stop: function stop() {
      var _this2 = this;

      var alertMessage = 'The recording could not be stopped.  Make sure you are connected over voice and are not actively dialing.';

      if (this.recording.isPlaying) {
        return this._executeServerAction('stop').catch(function () {
          return _this2.alerts.error(alertMessage);
        });
      } else if (this.recording.isRecording) {
        return this._executeServerAction('stop_recording').catch(function () {
          return _this2.alerts.error(alertMessage);
        });
      }
    },
    record: function record(options) {
      var _this3 = this;

      var alertMessage = 'The recording could not be recorded.  Make sure you are connected over voice and are not actively dialing.';
      var recording = this.store.createRecord('recording');

      if (options) {
        recording.setProperties(options);
      }

      return recording.save().then(function () {
        // typically the state will be set by a push notification,
        // but the push notification will be sent prior to the request completing -- set it manually here
        recording.set('state', 'recording');

        _this3.onRecord(recording);
      }).catch(function () {
        return _this3.alerts.error(alertMessage);
      });
    }
  });

  _exports.default = _default;
});