define("frontend/pods/components/soft-phone/mute-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "txvkXUSX",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[16,0,[31,[[30,[36,3],[\"btn btn-small \",[30,[36,2],[[35,1,[\"isMuted\"]],\"btn-danger\",\"btn-success\"],null]],null],\" \",[30,[36,0],[\"mute\"],[[\"from\"],[\"frontend/pods/components/soft-phone/mute-button/styles\"]]]]]],[4,[38,5],[[32,0],[35,4]],null],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"isMuted\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-fw fa-microphone\"],[12],[13],[2,\" Unmute\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-fw fa-microphone-slash\"],[12],[13],[2,\" Mute\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"softphone\",\"if\",\"concat\",\"toggleMute\",\"action\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/soft-phone/mute-button/template.hbs"
    }
  });

  _exports.default = _default;
});