define("frontend/services/web-socket", ["exports", "frontend/models/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // manages the server connection and all subscriptions
  var _default = Ember.Service.extend({
    client: null,
    authentication: Ember.inject.service(),
    sessionId: Ember.computed.oneWay('authentication.id'),
    url: Ember.computed(function () {
      return _meta.default.get('webSocketUrl');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      return this._initializeClient();
    },
    subscribe: function subscribe(channel, callback) {
      return this.client.subscribe(channel, callback);
    },
    unsubscribe: function unsubscribe(channel, callback) {
      var client = this.client;
      return client.unsubscribe(channel, callback);
    },

    get channels() {
      return this.client._channels.getKeys();
    },

    disconnect: function disconnect(options) {
      // only disconnect if there are no more subscriptions or it is being forced
      if (options == null) {
        options = {};
      }

      if (this.get('channels.length') !== 0 && !options.force) {
        return false;
      }

      this.client.disconnect(); // once disconnected, the client does not reconnect properly -- just reinitialize the client

      return this._initializeClient();
    },
    _initializeClient: function _initializeClient() {
      var client = new Faye.Client(this.url); // add this object as an extension so that incoming() and outgoing() will be called

      client.addExtension(this);
      this.set('client', client);
      return client;
    },
    // occurs when an incoming message is received
    incoming: function incoming(message, callback) {
      if (message.channel !== "/meta/connect") {
        console.log("incoming", message);
      }

      if (message.data && message.ext && message.ext._time) {
        message.data._time = message.ext._time;
      }

      if (message.error) {
        console.warn("Error from notification server: ".concat(message.error));
      }

      callback(message); // disconnect on successful unsubscribe (if there are no other subscriptions)

      if (message.channel === '/meta/unsubscribe' && message.successful) {
        return this.disconnect();
      }
    },
    // occurs when a message is to be sent
    outgoing: function outgoing(message, callback) {
      if (message.channel !== "/meta/connect") {
        console.log("outgoing", message);
      }

      if (message.channel === '/meta/subscribe') {
        if (!message.ext) {
          message.ext = {};
        }

        message.ext.session_id = this.sessionId;
      }

      return callback(message);
    }
  });

  _exports.default = _default;
});