define("frontend/pods/components/soft-phone/digits-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zvyGy1bd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[34,2],\" \",[30,[36,1],[\"digits\"],[[\"from\"],[\"frontend/pods/components/soft-phone/digits-bar/styles\"]]]]]],[12],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"     \\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"digits\",\"local-class\",\"class\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/soft-phone/digits-bar/template.hbs"
    }
  });

  _exports.default = _default;
});