define("frontend/pods/components/organization-service-applications-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationServiceApplicationsEditorComponent = Ember.Component.extend({
    organization: null,
    value: null,
    store: Ember.computed.readOnly('organization.store'),
    serviceApplications: Ember.computed('store', function () {
      var store = this.store;

      if (!store) {
        return;
      }

      return store.findAll('service-application');
    }),
    newServiceApplication: null,
    actions: {
      addServiceApplication: function addServiceApplication() {
        var serviceApplication = this.newServiceApplication;

        if (serviceApplication) {
          this.value.addObject(serviceApplication);
          return this.set('newServiceApplication', null);
        }
      },
      removeServiceApplication: function removeServiceApplication(serviceApplicationOrganization) {
        return this.value.removeObject(serviceApplicationOrganization);
      }
    }
  });
  var _default = OrganizationServiceApplicationsEditorComponent;
  _exports.default = _default;
});