define("frontend/pods/outbound-voice-mail-recordings/outbound-voice-mail-recording/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OutboundVoiceMailRecordingRoute = Ember.Route.extend({
    requiresLogin: true,
    model: function model(params) {
      return this.store.find('outbound-voice-mail-recording', params.outbound_voice_mail_recording_id);
    }
  });
  var _default = OutboundVoiceMailRecordingRoute;
  _exports.default = _default;
});