define("frontend/serializers/application", ["exports", "@ember-data/serializer/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _rest.default.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    keyForRelationship: function keyForRelationship(key, relationship, method) {
      if (relationship === 'hasMany') {
        return "".concat(Ember.String.underscore(key), "_ids");
      } else {
        return "".concat(Ember.String.underscore(key), "_id");
      }
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return Ember.String.underscore(modelName);
    },
    extractErrors: function extractErrors(store, typeClass, payload, id) {
      if (payload && payload.isAdapterError) {
        // return it as a hash
        var hash = {};
        payload.errors.forEach(function (e) {
          var f = e.source.pointer.split('/').pop();
          var existing = hash[f];

          if (existing) {
            hash[f] = existing + ', ' + e.detail;
          } else {
            hash[f] = e.detail;
          }
        });
        return hash;
      } else if (payload && _typeof(payload) === 'object' && !payload.isAdapterError && payload.errors && _typeof(payload.errors) === 'object' && !Array.isArray(payload.errors)) {
        // return it as an array
        var result = [];
        Object.keys(payload.errors).forEach(function (key) {
          payload.errors[key].forEach(function (e) {
            result.push({
              detail: e,
              source: {
                pointer: "data/attributes/".concat(key)
              }
            });
          });
        });
        return result;
      } else {
        return payload;
      }
    }
  });

  _exports.default = _default;
});