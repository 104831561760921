define("frontend/pods/components/soft-phone/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "softphone": "_softphone_6li8qu",
    "status": "_status_6li8qu",
    "connecting": "_connecting_6li8qu",
    "disconnecting": "_disconnecting_6li8qu",
    "dialpad": "_dialpad_6li8qu"
  };
  _exports.default = _default;
});