define("frontend/pods/lead-lists/lead-list/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadListIndexRoute = Ember.Route.extend({
    model: function model() {
      var result = this.modelFor('lead-lists.lead-list');
      result.refreshLeads();
      return result;
    }
  });
  var _default = LeadListIndexRoute;
  _exports.default = _default;
});