define("frontend/pods/components/ui/dropdown-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-dropdown-button": "_ui-dropdown-button_1wbt4s"
  };
  _exports.default = _default;
});