define("frontend/pods/dashboard/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardIndexRoute = Ember.Route.extend({
    model: function model() {
      var userId;
      var id = 'singleton';
      var m = this.store.peekRecord('outbound-call-analytic', id);

      if (m) {
        return m;
      }

      if (!this.get('authenticationService.isAdmin')) {
        userId = this.get('authenticationService.activeUserId');
      }

      var today = (0, _moment.default)().startOf('day').toDate();
      this.store.pushPayload({
        outbound_call_analytic: {
          id: id,
          userId: userId,
          start_time: today.toISOString()
        }
      });
      m = this.store.peekRecord('outbound-call-analytic', id);
      m.save();
      return m;
    }
  });
  var _default = DashboardIndexRoute;
  _exports.default = _default;
});