define("frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transfer-failed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "755eZVMD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"transfer-failed\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transfer-failed/styles\"]]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"container\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transfer-failed/styles\"]]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n      \"],[10,\"strong\"],[12],[2,\"The call could not be transferred.\"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"call\",\"canClientConnect\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        The client is currently unavailable.\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"call\",\"isConnected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[2,\"You are still connected, so please wrap up the call professionally.\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        You have been disconnected.\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"actions\"],[[\"from\"],[\"frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transfer-failed/styles\"]]]]]],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-lg btn-danger\"],[4,[38,4],[\"click\",[30,[36,3],[[32,0,[\"hangup\"]]],null]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-fw fa-sign-out\"],[12],[13],[2,\" End Call\\n      \"],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-inverse\"],[4,[38,4],[\"click\",[32,0,[\"back\"]]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-fw fa-chevron-left\"],[12],[13],[2,\" Back\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"not\",\"if\",\"perform\",\"on\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/agent/agent-console/current-call/actions/states/lead/transfer-failed/template.hbs"
    }
  });

  _exports.default = _default;
});