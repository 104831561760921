define("frontend/pods/components/organization-dashboard/component", ["exports", "frontend/models/lead", "frontend/models/organization", "frontend/models/outbound-call"], function (_exports, _lead, _organization, _outboundCall) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationDashboardComponent = Ember.Component.extend({
    organization: null,
    dashboard: null,
    dialsPerHour: Ember.computed('dashboard.dialsPerHour', function () {
      return this._round(this.get('dashboard.dialsPerHour'));
    }),
    connectionsPerHour: Ember.computed('dashboard.connectionsPerHour', function () {
      return this._round(this.get('dashboard.connectionsPerHour'));
    }),
    _round: function _round(val) {
      if (val) {
        return Math.round(val * 10) / 10;
      } else {
        return 0;
      }
    },
    pieChartData: Ember.computed('dashboard.statusesByDial', function () {
      var statusesByDial = this.get('dashboard.statusesByDial') || {};
      var results = [];

      for (var _i = 0, _Object$keys = Object.keys(statusesByDial || {}); _i < _Object$keys.length; _i++) {
        var actualStatus = _Object$keys[_i];
        var count = statusesByDial[actualStatus];
        var name = actualStatus;

        if (count > 0) {
          results.push([actualStatus, count]);
        }
      }

      return {
        data: {
          type: 'pie',
          columns: results
        }
      };
    }),
    callResultPieChartData: Ember.computed('dashboard.callResultsByDial', function () {
      var callResultsByDial = this.get('dashboard.callResultsByDial') || {};
      var results = [];

      for (var _i2 = 0, _Object$keys2 = Object.keys(callResultsByDial || {}); _i2 < _Object$keys2.length; _i2++) {
        var callResult = _Object$keys2[_i2];
        var count = callResultsByDial[callResult];
        var name = callResult;

        if (count > 0) {
          results.push([callResult, count]);
        }
      }

      return {
        data: {
          type: 'pie',
          columns: results
        }
      };
    }),
    usageByUserChartData: Ember.computed('dashboard.usageByUser', function () {
      var u;
      var usageByUser = this.get('dashboard.usageByUser') || [];
      usageByUser = usageByUser.filter(function (u) {
        return u.paused_time || u.idle_time || u.connected_time;
      });
      var columns = [];
      var row = ['x'];

      for (var _i3 = 0, _Array$from = Array.from(usageByUser); _i3 < _Array$from.length; _i3++) {
        u = _Array$from[_i3];
        var userId = u.user_id;
        var user = this.get('dashboard.store').peekRecord('user', userId);
        row.push(user.get('name'));
      }

      columns.push(row);

      for (var _i4 = 0, _arr = ['paused_time', 'idle_time', 'connected_time']; _i4 < _arr.length; _i4++) {
        var t = _arr[_i4];
        row = [t];

        for (var _i5 = 0, _Array$from2 = Array.from(usageByUser); _i5 < _Array$from2.length; _i5++) {
          u = _Array$from2[_i5];
          row.push(this._round((u[t] || 0) / 60 / 60));
        }

        columns.push(row);
      }

      return {
        data: {
          x: 'x',
          type: 'bar',
          columns: columns,
          groups: [['paused_time', 'idle_time', 'connected_time']],
          names: {
            paused_time: 'Paused Time',
            idle_time: 'Idle Time',
            connected_time: 'Connected Time'
          },
          order: null
        },
        tooltip: {
          format: {
            value: function value(_value) {
              return "".concat(_value, " Hours");
            }
          }
        },
        axis: {
          rotated: true,
          x: {
            type: 'category'
          },
          y: {
            label: {
              text: 'Hours',
              position: 'outer-center'
            }
          }
        },
        color: {
          pattern: ['#FAA43A', '#DECF3F', '#60BD68']
        }
      };
    }),
    dialsOverTimeChartData: Ember.computed('dashboard.dialsOverTime', 'dashboard.params', function () {
      var count, d, dateFormat, t, ticks, time;
      var dialsOverTime = this.get('dashboard.dialsOverTime.items') || [];
      var interval = this.get('dashboard.dialsOverTime.interval');
      var isDayInterval = interval === 'day' || interval === 'week' || interval === 'month';
      var dialsHash = {};

      for (var _i6 = 0, _Array$from3 = Array.from(dialsOverTime); _i6 < _Array$from3.length; _i6++) {
        d = _Array$from3[_i6];
        // parse the ISO format
        time = Date.parse(d.time); // the time parsing assumes UTC, which is great for times but not for just dates,
        // since it assumes midnight UTC.  In this case, add the timezone offset
        // to make it midnight local time.

        if (isDayInterval) {
          var date = new Date(d.time);
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
          time = date.valueOf();
        }

        dialsHash[time] = d.count;
      } // insert a zero value before and after each date if necessary


      var intervalFn = 'Date';
      var intervalAmount = 1;

      switch (interval) {
        case 'hour':
          intervalFn = 'Hours';
          break;

        case 'week':
          intervalFn = 'Date';
          intervalAmount = 7;
          break;

        case 'month':
          intervalFn = 'Month';
          break;
      }

      var times = [];

      for (var _i7 = 0, _Object$keys3 = Object.keys(dialsHash || {}); _i7 < _Object$keys3.length; _i7++) {
        t = _Object$keys3[_i7];
        var v = dialsHash[t];
        times.push(parseInt(t));
      }

      times = times.sort();

      for (var _i8 = 0, _Array$from4 = Array.from(times); _i8 < _Array$from4.length; _i8++) {
        t = _Array$from4[_i8];

        if (dialsHash[t] !== 0) {
          var name, name1;
          var dateBefore = new Date(t);
          dateBefore["set".concat(intervalFn)](dateBefore["get".concat(intervalFn)]() - intervalAmount);

          if (!dialsHash[name = dateBefore.valueOf()]) {
            dialsHash[name] = 0;
          }

          var dateAfter = new Date(t);
          dateAfter["set".concat(intervalFn)](dateAfter["get".concat(intervalFn)]() + intervalAmount);

          if (!dialsHash[name1 = dateAfter.valueOf()]) {
            dialsHash[name1] = 0;
          }
        }
      }

      var augmented = [];

      for (var _i9 = 0, _Object$keys4 = Object.keys(dialsHash || {}); _i9 < _Object$keys4.length; _i9++) {
        time = _Object$keys4[_i9];
        count = dialsHash[time];
        augmented.push({
          time: parseInt(time),
          count: count
        });
      }

      augmented = augmented.sortBy('time'); // remove the "bookend" zeroes that were added in the previous step
      //augmented.shift()
      //augmented.pop()

      times = augmented.map(function (i) {
        return i.time;
      });
      var values = augmented.map(function (i) {
        return i.count;
      });
      var startTime = this.get('dashboard.params.start_time');

      if (startTime) {
        startTime = Date.parse(startTime);
      }

      var endTime = this.get('dashboard.params.end_time');
      endTime = endTime ? Date.parse(endTime) : new Date().valueOf();
      var s = startTime || times[0];

      if (s) {
        var duration = endTime - s; // equal intervals, rounded to the nearest second

        var roundTo = 1000 * 60;
        ticks = [0, 1, 2, 3, 4, 5].map(function (t) {
          return Math.round((s + duration * t / 6) / roundTo) * roundTo;
        });
      } else {
        ticks = [];
      } // add the label as the first item in the array


      times.unshift('Time');
      values.unshift('Dials');

      switch (interval) {
        case 'hour':
          dateFormat = '%I:%M';
          break;

        case 'month':
          dateFormat = '%b';
          break;

        default:
          dateFormat = '%b %d';
      } // create a function to format the value (in seconds) to time


      var formatDateFn = function formatDateFn(value, format) {
        d = new Date(value);
        return d3.time.format(format)(d);
      };

      return {
        data: {
          x: 'Time',
          type: 'area',
          columns: [times, values]
        },
        legend: {
          show: false
        },
        tooltip: {
          format: {
            title: function title(x) {
              return formatDateFn(x, dateFormat);
            }
          }
        },
        axis: {
          x: {
            label: {
              text: 'Date',
              position: 'outer-center'
            },
            min: startTime,
            max: endTime,
            tick: {
              values: ticks,
              format: function format(x) {
                return formatDateFn(x, dateFormat);
              }
            }
          },
          y: {
            label: {
              text: 'Dials',
              position: 'outer-middle'
            }
          }
        }
      };
    }),
    dialsByHourChartData: Ember.computed('dashboard.dialsByHour', 'dashboard.connectionsByHour', 'dashboard.params', function () {
      var counts, day, dials, hour, hourOfWeek;
      var dialsByHour = this.get('dashboard.dialsByHour') || {};
      var connectionsByHour = this.get('dashboard.connectionsByHour') || {}; // Convert to local time - this assumes all records are in the current Daylight Savings period.
      // Not ideal, but the only way to handle it otherwise is to have all data in the client
      // so the local time conversion can be accurate.  This is close enough for now.

      var timezoneOffset = new Date().getTimezoneOffset();
      var hoursInWeek = 7 * 24;
      var offsetDials = {};

      for (var _i10 = 0, _Object$keys5 = Object.keys(dialsByHour || {}); _i10 < _Object$keys5.length; _i10++) {
        hourOfWeek = _Object$keys5[_i10];
        dials = dialsByHour[hourOfWeek];
        var offsetHour = hourOfWeek - timezoneOffset / 60; // wrap it around to the end of the week if it winds up at the beginning

        if (offsetHour < 0) {
          offsetHour = hoursInWeek + offsetHour;
        } else if (offsetHour > hoursInWeek) {
          offsetHour = offsetHour - hoursInWeek;
        }

        offsetDials[offsetHour] = {
          dials: dials,
          connections: connectionsByHour[hourOfWeek] || 0
        };
      } // format: day[hour] = count


      var days = {};

      for (var _i11 = 0, _Object$keys6 = Object.keys(offsetDials || {}); _i11 < _Object$keys6.length; _i11++) {
        hourOfWeek = _Object$keys6[_i11];
        counts = offsetDials[hourOfWeek];
        day = Math.floor(hourOfWeek / 24);
        hour = hourOfWeek % 24;

        if (!days[day]) {
          days[day] = {};
        }

        days[day][hour] = counts;
      }

      var x = ['day'];
      var y = ['hour'];
      counts = [];

      for (var _i12 = 0, _Array$from5 = Array.from(Object.keys(days).map(function (i) {
        return parseInt(i);
      })); _i12 < _Array$from5.length; _i12++) {
        var d = _Array$from5[_i12];

        for (var _i13 = 0, _Array$from6 = Array.from(Object.keys(days[d]).map(function (i) {
          return parseInt(i);
        })); _i13 < _Array$from6.length; _i13++) {
          var h = _Array$from6[_i13];
          x.push(d);
          y.push(h);
          counts.push(days[d][h]);
        }
      }

      var maxDialsByHour = Math.max.apply(this, counts.map(function (i) {
        return i.dials;
      }));
      var maxConnectionRateByHour = Math.max.apply(this, counts.map(function (i) {
        return i.connections / i.dials;
      }));
      var colorScale = d3.scale.linear().range(['#d62728', '#bcbd22', '#2ca02c']).domain([0, maxConnectionRateByHour / 2, maxConnectionRateByHour]);
      var result = {
        //interaction:
        //  enabled: false
        data: {
          type: 'scatter',
          columns: [x, y],
          xs: {
            'hour': 'day'
          },
          color: function color(_color, d) {
            // set the color based on the connection rate
            return colorScale(counts[d.index].connections / counts[d.index].dials);
          }
        },
        point: {
          r: function r(d) {
            // size the bubble by the number of dials
            return counts[d.index].dials / maxDialsByHour * 20;
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          contents: function contents(d, defaultTitleFormat, defaultValueFormat, color) {
            d = d[0];
            day = defaultTitleFormat(d.x);
            hour = defaultValueFormat(d.value);
            var title = "".concat(day, " @ ").concat(hour);
            dials = counts[d.index].dials;
            var connections = counts[d.index].connections;
            var connectionRate = Math.round(Math.floor(connections / dials * 1000) / 10);
            result = "<table class='c3-tooltip'>";
            result += "<tr><th colspan='2'>" + title + "</th></tr>";
            result += "<tr><td class='name'>Dials<td class='value'>" + dials + "</td></tr>";
            result += "<tr><td class='name'>Connection Rate<td class='value'>" + "".concat(connectionRate, "%") + "</td></tr>";
            result += "</table>";
            return result;
          }
        },
        axis: {
          x: {
            padding: 0.2,
            tick: {
              fit: false,
              values: [0, 1, 2, 3, 4, 5, 6],
              format: function format(d) {
                return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][d];
              }
            }
          },
          y: {
            tick: {
              fit: true,
              values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
              format: function format(d) {
                if (d === 0) {
                  return "12a";
                } else if (d < 12) {
                  return "".concat(d, "a");
                } else if (d === 12) {
                  return "12p";
                } else {
                  return "".concat(d - 12, "p");
                }
              }
            }
          }
        }
      };
      return result;
    })
  });
  var _default = OrganizationDashboardComponent;
  _exports.default = _default;
});