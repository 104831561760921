define("frontend/pods/components/chat-conversations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "On0+GjA5",
    "block": "{\"symbols\":[\"conversation\"],\"statements\":[[10,\"ul\"],[14,0,\"chat-conversations\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,0,[\"conversations\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"chat-conversation\"],[12],[1,[30,[36,1],null,[[\"conversation\",\"chatService\"],[[32,1],[35,0]]]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"chatService\",\"chat-conversation\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/chat-conversations/template.hbs"
    }
  });

  _exports.default = _default;
});