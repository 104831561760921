define("frontend/pods/components/service-provider-lead-status-map-edit/component", ["exports", "frontend/models/lead"], function (_exports, _lead) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ServiceProviderLeadStatusMapEditComponent = Ember.Component.extend({
    serviceProvider: null,
    onChange: function onChange() {},
    leadStatuses: Ember.computed.readOnly('serviceProvider.settings.lead_statuses'),
    leadStatusMap: Ember.computed.alias('serviceProvider.settings.lead_status_map'),
    statusValues: _lead.default.statusValues,
    values: Ember.computed('leadStatuses', 'leadStatusMap', function () {
      var leadStatuses = this.leadStatuses || {};
      var leadStatusMap = this.leadStatusMap || {};
      var result = [];

      for (var _i = 0, _Object$keys = Object.keys(leadStatuses || {}); _i < _Object$keys.length; _i++) {
        var type = _Object$keys[_i];
        var statuses = leadStatuses[type];

        if (!statuses) {
          statuses = [];
        }

        if (!leadStatusMap[type]) {
          leadStatusMap[type] = {};
        }

        for (var _i2 = 0, _Array$from = Array.from(statuses); _i2 < _Array$from.length; _i2++) {
          var s = _Array$from[_i2];
          var existingMap = leadStatusMap[type][s.name];
          var status = existingMap ? existingMap.status : s.maps_to;
          var isTransferDefault = !!(existingMap && existingMap.is_transfer_default);
          result.push(Ember.Object.create({
            type: type,
            masterStatus: s.name,
            status: status,
            isTransferDefault: isTransferDefault
          }));
        }
      }

      return result;
    }),
    modifiedLeadStatusMap: Ember.computed('values.@each.status', 'values.@each.isTransferDefault', function () {
      var values = this.values;
      var result = {};

      for (var _i3 = 0, _Array$from2 = Array.from(values); _i3 < _Array$from2.length; _i3++) {
        var s = _Array$from2[_i3];
        var p = s.getProperties('type', 'status', 'masterStatus', 'isTransferDefault');

        if (!result[p.type]) {
          result[p.type] = {};
        }

        result[p.type][p.masterStatus] = {
          status: p.status,
          is_transfer_default: p.isTransferDefault
        };
      }

      return result;
    }),
    _valuesChanged: Ember.observer('modifiedLeadStatusMap', function () {
      return this.onChange(this.modifiedLeadStatusMap);
    }),
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('values', 'modifiedLeadStatusMap');
    }),
    actions: {
      isTransferDefaultChanged: function isTransferDefaultChanged(isDefault, status) {
        var values = this.values;
        return values.forEach(function (v) {
          return v.set('isTransferDefault', v === status && isDefault);
        });
      }
    }
  });
  var _default = ServiceProviderLeadStatusMapEditComponent;
  _exports.default = _default;
});