define("frontend/pods/components/view/ui-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNameBindings: [':btn', 'isShowing::hidden', 'isSmall:btn-small', 'isPrimary:btn-primary', 'isInverse:btn-inverse', 'isSuccess:btn-success', 'isWarning:btn-warning', 'isDanger:btn-danger'],
    attributeBindings: ['disabled'],
    isShowing: true,
    isEnabled: true,
    isDisabled: Ember.computed.not('isEnabled'),
    disabled: Ember.computed.alias('isDisabled'),
    onClick: function onClick() {},
    isSmall: false,
    isWaiting: false,
    _isWaitingChanged: Ember.observer('isWaiting', function () {
      return this.set('isEnabled', !this.isWaiting);
    }),
    style: null,
    isPrimary: Ember.computed.equal('style', 'primary'),
    isInverse: Ember.computed.equal('style', 'inverse'),
    isSuccess: Ember.computed.equal('style', 'success'),
    isWarning: Ember.computed.equal('style', 'warning'),
    isDanger: Ember.computed.equal('style', 'danger'),
    click: function click() {
      this.onClick();
    },
    _initializeProperties: Ember.on('init', function () {
      return this.getProperties('isPrimary', 'isInverse', 'isSuccess', 'isWarning', 'isDanger', 'isEnabled', 'isDisabled');
    })
  });

  _exports.default = _default;
});