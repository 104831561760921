define("frontend/pods/components/chat-conversation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ChatConversationComponent = Ember.Component.extend((_EmberComponent$exte = {
    chatService: null,
    conversation: null,
    windowOpen: Ember.computed(function () {
      return true;
    }),
    unread: Ember.computed(function () {
      return false;
    }),
    messages: Ember.computed.filter('chatService.messages', function (message, index) {
      return this.get('conversation.conversation') === message.conversation;
    }),
    inboundMessages: Ember.computed('conversation.conversation', 'messages.[]', function () {
      var inboundMessages;
      var conversation = this.get('conversation.conversation');
      var messages = this.messages || [];
      return inboundMessages = messages.filter(function (m) {
        return m.email === conversation;
      });
    }),
    lastInboundMessage: Ember.computed.readOnly('inboundMessages.lastObject'),
    lastReadMessage: null
  }, _defineProperty(_EmberComponent$exte, "unread", Ember.computed('lastInboundMessage', 'lastReadMessage', function () {
    var lastInboundMessage = this.lastInboundMessage;
    var lastReadMessage = this.lastReadMessage;
    return lastInboundMessage && lastInboundMessage !== lastReadMessage;
  })), _defineProperty(_EmberComponent$exte, "userId", Ember.computed('conversation', function () {
    return this.conversation.user_id;
  })), _defineProperty(_EmberComponent$exte, "actions", {
    toggleWindow: function toggleWindow() {
      return this.toggleProperty('windowOpen');
    },
    markRead: function markRead() {
      return this.set('lastReadMessage', this.lastInboundMessage);
    },
    endConversation: function endConversation() {
      var conversation = this.conversation;
      return this.chatService.endConversation(conversation);
    }
  }), _EmberComponent$exte));
  var _default = ChatConversationComponent;
  _exports.default = _default;
});