define("frontend/pods/leads/index/route", ["exports", "frontend/utils/paginated"], function (_exports, _paginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadsIndexRoute = Ember.Route.extend({
    model: function model(params) {
      var m = _paginated.default.Array.create({
        store: this.store,
        pageSize: 50
      });

      m.find('lead', {
        q: params.q,
        sort_by: this.sortBy,
        direction: this.direction
      });
      return m;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      return controller.clear();
    }
  });
  var _default = LeadsIndexRoute;
  _exports.default = _default;
});