define("frontend/pods/components/platform-notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DMjzX8Zg",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"close\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"dismiss\",[32,1]],null],[12],[2,\"×\"],[13],[2,\"\\n\\n    \"],[10,\"strong\"],[12],[2,\"System Notification:\"],[13],[2,\"\\n    \"],[1,[32,1,[\"text\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"activeNotifications\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/platform-notifications/template.hbs"
    }
  });

  _exports.default = _default;
});