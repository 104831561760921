define("frontend/models/outreach-sequence-step", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    serviceProviderId: (0, _model.attr)('string'),
    stepNumber: (0, _model.attr)('number'),
    sequenceId: (0, _model.attr)('number'),
    sequenceName: (0, _model.attr)('string'),
    displayName: (0, _model.attr)('string')
  });

  _exports.default = _default;
});