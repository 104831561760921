define("frontend/adapters/organization-phone-number", ["exports", "frontend/adapters/application", "frontend/utils/hash-utils"], function (_exports, _application, _hashUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    searchAvailable: function searchAvailable(prefix, provider) {
      var queryString = this.queryStringFor({
        q: prefix,
        provider: provider
      });
      var url = "".concat(this.buildURL('organization-phone-number'), "/search_available?").concat(queryString);
      return this.ajax(url).then(function (response) {
        return (0, _hashUtils.camelizeKeys)(response);
      });
    }
  });

  _exports.default = _default;
});