define("frontend/pods/components/ui/dropdown-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    localClassNames: ['ui-dropdown-button'],
    onOpen: function onOpen() {},
    onClose: function onClose() {},
    onCanClose: function onCanClose() {
      return true;
    },
    isOpen: false,
    didOpen: function didOpen() {
      if (this.onOpen) {
        this.onOpen();
      }

      this.set('isOpen', true);
    },
    didClose: function didClose() {
      if (this.onClose) {
        this.onClose();
      }

      this.set('isOpen', false);
    },
    attemptClose: function attemptClose() {
      if (this.onCanClose) {
        return this.onCanClose;
      } else {
        return true;
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._didOpen = Ember.run.bind(this, this.didOpen);
      this._didClose = Ember.run.bind(this, this.didClose);
      this._attemptClose = Ember.run.bind(this, this.attemptClose); // using addEventListener does not work in Bootstrap v4, must use $().on()

      this.$().on('shown.bs.dropdown', this._didOpen);
      this.$().on('hidden.bs.dropdown', this._didClose);
      this.$().on('hide.bs.dropdown', this._attemptClose);
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('shown.bs.dropdown', this._didOpen);
      this.$().off('hidden.bs.dropdown', this._didClose);
      this.$().off('hide.bs.dropdown', this._attemptClose);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});