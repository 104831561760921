define("frontend/pods/components/lead-picture/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LeadPictureComponent = Ember.Component.extend({
    lead: null,
    size: 100,
    width: Ember.computed.alias('size'),
    style: Ember.computed('size', function () {
      return "width: ".concat(this.size, "px");
    })
  });
  var _default = LeadPictureComponent;
  _exports.default = _default;
});