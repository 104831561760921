define("frontend/pods/components/detect-voice-mail-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MFD4b4Qv",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"detect-voice-mail-modal-background animated fadeIn\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"detect-voice-mail-modal\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"detect-voice-mail-modal-header\"],[12],[2,\"Leave a Voicemail\"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"detect-voice-mail-modal-body\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Wait until you hear the beep, then click \"],[10,\"strong\"],[12],[2,\"Leave Voicemail\"],[13],[2,\" immediately.  Your call will end, the voicemail will be played, and the call will automatically hang up when finished.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"detect-voice-mail-modal-buttons\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-success\"],[4,[38,0],[[32,0],\"leaveVoicemail\"],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-envelope\"],[12],[13],[2,\" Leave Voicemail\\n        \"],[13],[2,\"\\n\\n        \"],[11,\"button\"],[24,0,\"btn btn-inverse\"],[4,[38,0],[[32,0],\"cancel\"],null],[12],[2,\"\\n          Cancel\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"isShowingModal\",\"if\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/detect-voice-mail-modal/template.hbs"
    }
  });

  _exports.default = _default;
});