define("frontend/pods/components/chat-input/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ChatInputComponent = Ember.Component.extend({
    to: null,
    chatMessage: null,
    chatService: null,
    actions: {
      sendMessageOnEnter: function sendMessageOnEnter(value, event) {
        if (event.keyCode === 13) {
          this.chatService.sendMessage(value, this.to);
          return this.set('chatMessage', '');
        }
      }
    }
  });
  var _default = ChatInputComponent;
  _exports.default = _default;
});