define("frontend/pods/auth/login/route", ["exports", "frontend/utils/oauth-authentication"], function (_exports, _oauthAuthentication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    requiresLogin: false,
    model: function model() {
      return this.store.createRecord('session');
    },
    actions: {
      login: function login() {
        var _this = this;

        var session = this.get('controller.model');
        return session.save().then(function () {
          return _this.authenticationService.login(session);
        }).then(function () {
          return _this.send('redirectAfterLogin');
        }).then(null, function () {});
      },
      salesforceLogin: function salesforceLogin() {
        var _this2 = this;

        var model = this.get('controller.model');
        model.get('errors').clear();
        var headerData = this.store.adapterFor('application').get('headerData');

        var auth = _oauthAuthentication.default.create({
          provider: 'salesforce',
          providerName: 'Salesforce',
          headerData: headerData,
          command: 'login'
        });

        return auth.authenticate().then(function (response) {
          model.unloadRecord();

          _this2.store.pushPayload('session', {
            session: response.session
          });

          model = _this2.store.peekRecord('session', response.session.id);
          return _this2.authenticationService.login(model);
        }).then(function () {
          return _this2.send('redirectAfterLogin');
        }).catch(function (response) {
          return model.get('errors').add('email', response.message);
        });
      },
      dynamicsLogin: function dynamicsLogin() {
        var _this3 = this;

        var model = this.get('controller.model');
        model.get('errors').clear();
        var headerData = this.store.adapterFor('application').get('headerData');

        var auth = _oauthAuthentication.default.create({
          provider: 'azure_oauth2',
          providerName: 'Dynamics 365',
          headerData: headerData,
          command: 'login'
        });

        return auth.authenticate().then(function (response) {
          model.unloadRecord();

          _this3.store.pushPayload('session', {
            session: response.session
          });

          model = _this3.store.peekRecord('session', response.session.id);
          return _this3.authenticationService.login(model);
        }).then(function () {
          return _this3.send('redirectAfterLogin');
        }).catch(function (response) {
          return model.get('errors').add('email', response.message);
        });
      },
      hubspotLogin: function hubspotLogin() {
        var _this4 = this;

        var model = this.get('controller.model');
        model.get('errors').clear();
        var headerData = this.store.adapterFor('application').get('headerData');

        var auth = _oauthAuthentication.default.create({
          provider: 'hubspot',
          providerName: 'Hubspot',
          headerData: headerData,
          command: 'login'
        });

        return auth.authenticate().then(function (response) {
          model.unloadRecord();

          _this4.store.pushPayload('session', {
            session: response.session
          });

          model = _this4.store.peekRecord('session', response.session.id);
          return _this4.authenticationService.login(model);
        }).then(function () {
          return _this4.send('redirectAfterLogin');
        }).catch(function (response) {
          return model.get('errors').add('email', response.message);
        });
      },
      outreachLogin: function outreachLogin() {
        var _this5 = this;

        var model = this.get('controller.model');
        model.get('errors').clear();
        var headerData = this.store.adapterFor('application').get('headerData');

        var auth = _oauthAuthentication.default.create({
          provider: 'outreach',
          providerName: 'Outreach',
          headerData: headerData,
          command: 'login'
        });

        return auth.authenticate().then(function (response) {
          model.unloadRecord();

          _this5.store.pushPayload('session', {
            session: response.session
          });

          model = _this5.store.peekRecord('session', response.session.id);
          return _this5.authenticationService.login(model);
        }).then(function () {
          return _this5.send('redirectAfterLogin');
        }).catch(function (response) {
          return model.get('errors').add('email', response.message);
        });
      },
      salesloftLogin: function salesloftLogin() {
        var _this6 = this;

        var model = this.get('controller.model');
        model.get('errors').clear();
        var headerData = this.store.adapterFor('application').get('headerData');

        var auth = _oauthAuthentication.default.create({
          provider: 'salesloft',
          providerName: 'Salesloft',
          headerData: headerData,
          command: 'login'
        });

        return auth.authenticate().then(function (response) {
          model.unloadRecord();

          _this6.store.pushPayload('session', {
            session: response.session
          });

          model = _this6.store.peekRecord('session', response.session.id);
          return _this6.authenticationService.login(model);
        }).then(function () {
          return _this6.send('redirectAfterLogin');
        }).catch(function (response) {
          return model.get('errors').add('email', response.message);
        });
      },
      zohoLogin: function zohoLogin() {
        var _this7 = this;

        var model = this.get('controller.model');
        model.get('errors').clear();
        var headerData = this.store.adapterFor('application').get('headerData');

        var auth = _oauthAuthentication.default.create({
          provider: 'zoho',
          providerName: 'Zoho',
          headerData: headerData,
          command: 'login'
        });

        return auth.authenticate().then(function (response) {
          model.unloadRecord();

          _this7.store.pushPayload('session', {
            session: response.session
          });

          model = _this7.store.peekRecord('session', response.session.id);
          return _this7.authenticationService.login(model);
        }).then(function () {
          return _this7.send('redirectAfterLogin');
        }).catch(function (response) {
          return model.get('errors').add('email', response.message);
        });
      },
      pipedriveLogin: function pipedriveLogin() {
        var _this8 = this;

        var model = this.get('controller.model');
        model.get('errors').clear();
        var headerData = this.store.adapterFor('application').get('headerData');

        var auth = _oauthAuthentication.default.create({
          provider: 'pipedrive',
          providerName: 'Pipedrive',
          headerData: headerData,
          command: 'login'
        });

        return auth.authenticate().then(function (response) {
          model.unloadRecord();

          _this8.store.pushPayload('session', {
            session: response.session
          });

          model = _this8.store.peekRecord('session', response.session.id);
          return _this8.authenticationService.login(model);
        }).then(function () {
          return _this8.send('redirectAfterLogin');
        }).catch(function (response) {
          return model.get('errors').add('email', response.message);
        });
      }
    }
  });

  _exports.default = _default;
});