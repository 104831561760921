define("frontend/pods/leads/lead/edit/controller", ["exports", "frontend/pods/leads/lead/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LeadEditController = _controller.default.extend();

  var _default = LeadEditController;
  _exports.default = _default;
});